<template>
    <modal :show="show">
        <div class="modal-header">
            <h5 class="modal-title">رفع</h5>
            <a href="javascript:void(0)" v-on:click="close()" class="close">
                <em class="icon ni ni-cross"></em>
            </a>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <input class="form-control file-input" ref="imgupload" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="uploadImage('onClick')" id="file-input">
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <xlsx-workbook v-if="isErrorFileDownload" class="float-end">
                <xlsx-sheet :collection="sheet.data"
                            v-for="sheet in sheets"
                            :key="sheet.name"
                            :sheet-name="sheet.name" />
                <xlsx-download :filename="'Template.xlsx'">
                    <button class="btn btn-danger" data-toggle="tooltip"  data-placement="top" title="Download Error File"><i class="fa fa-download"></i> Error File</button>
                </xlsx-download>
            </xlsx-workbook>
            <button type="button" class="btn btn-primary btn-round" v-on:click="Save()">{{ $t('CreateGroupSession.Save') }}</button>
            <a href="javascript:void(0)" class="btn btn-danger btn-round" v-on:click="close()">{{ $t('CreateGroupSession.Cancel') }}</a>
        </div>
        <loading :name="loading" :active.sync="loading"
                 :can-cancel="false"
                 :is-full-page="true"></loading>
    </modal>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
    import readXlsxFile from 'read-excel-file'
    export default {
        components: {
            Loading,
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload
        },
        props: ['show','id'],
        data: function () {
            return {
                loading: false,
                image: '../../images/Placeholder.svg',
                data: [],
                url: '',
                filePath: null,

                sheets: [],
                isErrorFileDownload: false,
                sessionId: '',
                studentList: [],
                errorStudentList: [],
            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },

            uploadImage() {
                var root = this;
                var file = this.$refs.imgupload.files[0];

                
                root.studentList = [];
                readXlsxFile(file).then((allRows) => {
                    if (allRows.length > 1) {
                        allRows.splice(0, 1)
                        allRows.forEach(function (data) {
                            root.studentList.push({
                                firstName: data[1],
                                middleName: data[2],
                                lastName: data[3],
                                registrationCode: data[4],
                                nationalId: data[5],
                                phoneNumber: data[6],
                                dateOfBirth: data[7],
                                gender: data[8],
                                email: data[9],
                                universityLevelName: data[10],
                                studentCategoryName: data[11],
                            })
                        })
                    }
                });
            },

            Save: function () {
                var root = this;
                var token = '';
                this.loading = true;

                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                root.$https.post('/Sessions/SendPreviousSessionEmail?sessionId=' + this.sessionId, this.studentList, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$swal({
                            title: root.$t('Notification.Added'),
                            text: root.$t('Notification.DataSucess'),
                            type: 'success',
                            icon: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonsStyling: false
                        });

                        root.errorStudentList = [];
                        response.data.forEach(function (data, index) {
                            root.errorStudentList.push({
                                Sr: index + 1,
                                FirstName: data.firstName,
                                MiddleName: data.middleName,
                                LastName: data.lastName,
                                RegistrationCode: data.registrationCode,
                                NationalId: data.nationalId,
                                PhoneNumber: data.phoneNumber,
                                DateOfBirth: data.dateOfBirth,
                                Gender: data.gender,
                                Email: data.email,
                                UniversityLevelName: data.universityLevelName,
                                StudentCategoryName: data.studentCategoryName,
                                Error: data.error,
                            })
                        });

                        root.sheets.push({ name: "SheetOne", data: root.errorStudentList });
                        if (root.errorStudentList.length > 0) {
                            root.isErrorFileDownload = true;
                        }
                        else {
                            root.close();
                        }
                    }
                    root.loading = false;

                });

            },

        },
        mounted: function () {
            this.sessionId = this.id;
        },
    }
</script>