<template>
    <modal :show="show">
        <div class="modal-header">
            <h5 class="modal-title">{{ $t('Dashboard.SecondSurveyQuestions') }}</h5>
            <a href="javascript:void(0)" v-on:click="close()" class="close">
                <em class="icon ni ni-cross"></em>
            </a>
        </div>
        <div class="modal-body">
            <div class="row">
                <div v-for="item in surveyQuestions.questionList" :key=" item.id" class="col-md-12 form-group">
                    <input v-model="item.question" class="form-control" disabled />
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-round" v-on:click="Save()">ارسل بريد الكتروني</button>
            <button type="button" class="btn btn-danger btn-round" v-on:click="close()">{{ $t('CreateGroupSession.Cancel') }}</button>
        </div>
        <loading :name="loading" :active.sync="loading"
                 :can-cancel="false"
                 :is-full-page="true"></loading>
    </modal>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    export default {
        components: {
            Loading
        },
        props: ['surveyQuestion', 'show'],
        data: function () {
            return {
                loading: false,
                surveyQuestions: '',
            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },

            Save: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Sessions/SendSecondSurveyEmail?id=' + this.surveyQuestions.id, { headers: { "Authorization": `Bearer ${token}` } })
                root.loading = false;
                root.$swal({
                    title: root.$t('Notification.Updated'),
                    text: root.$t('Notification.StatusUpdated'),
                    type: 'success',
                    icon: 'success',
                    confirmButtonClass: "btn btn-success",
                    buttonsStyling: false
                });
                root.close();
            },
        },
        mounted: function () {
            this.surveyQuestions = this.surveyQuestion;
        },
    }
</script>