<template>
    <div class="main-region">
        <div class="page-header">
            <div>
                <h1 class="page-title"> {{ $t("StudentFeedback.Heading") }}</h1>
            </div>
            <div class="ms-auto pageheader-btn">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a href="javascript:void(0);"> {{ $t("StudentFeedback.Heading") }}</a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                        {{ $t("StudentFeedback.Dashboard") }}
                    </li>
                </ol>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="card product-sales-main">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table border text-nowrap text-md-nowrap table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th> {{ $t("StudentFeedback.Questions") }}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(item, index) in studentSessionFeedBack.sessionFeedbackQuestion">
                                        <tr :key="index">
                                            <td>{{index+1}}.</td>
                                            <td colspan="2"> {{item.question}}</td>
                                        </tr>
                                        <tr :key="index +'a'">
                                            <td class="text-end" colspan="3">
                                                <a v-on:click="item.review=1" href="javascript:void(0)" style="text-decoration: none; color: #ff8d00;">
                                                    <span class="review-color" v-if="item.review==0 || item.review<1">
                                                        <i class="fa fa-star-o str" aria-hidden="true"></i>
                                                    </span>
                                                    <span class="review-color" v-if="item.review>=1">
                                                        <i class="fa fa-star str" aria-hidden="true"></i>
                                                    </span>
                                                </a>
                                                <br />
                                                ( غير راضٍ تمامًا)
                                            </td>
                                        </tr>
                                        <tr :key="index +'b'">
                                            <td class="text-end" colspan="3">
                                                <a v-on:click="item.review=2" href="javascript:void(0)" style="text-decoration: none; color: #ff8d00;">
                                                    <span class="review-color" v-if="item.review==0 || item.review<2">
                                                        <i class="fa fa-star-o str" aria-hidden="true"></i>
                                                        <i class="fa fa-star-o str" aria-hidden="true"></i>
                                                    </span>
                                                    <span class="review-color" v-if="item.review>=2">
                                                        <i class="fa fa-star str" aria-hidden="true"></i>
                                                        <i class="fa fa-star str" aria-hidden="true"></i>
                                                    </span>
                                                </a>
                                                <br />
                                                (غير راضٍ)
                                            </td>
                                        </tr>
                                        <tr :key="index +'c'">
                                            <td class="text-end" colspan="3">
                                                <a v-on:click="item.review=3" href="javascript:void(0)" style="text-decoration: none; color: #ff8d00;">
                                                    <span class="review-color" v-if="item.review==0 || item.review<3">
                                                        <i class="fa fa-star-o str" aria-hidden="true"></i>
                                                        <i class="fa fa-star-o str" aria-hidden="true"></i>
                                                        <i class="fa fa-star-o str" aria-hidden="true"></i>
                                                    </span>
                                                    <span class="review-color" v-if="item.review>=3">
                                                        <i class="fa fa-star str" aria-hidden="true"></i>
                                                        <i class="fa fa-star str" aria-hidden="true"></i>
                                                        <i class="fa fa-star str" aria-hidden="true"></i>
                                                    </span>
                                                </a>
                                                <br />
                                                (محايد)
                                            </td>
                                        </tr>
                                        <tr :key="index +'d'">
                                            <td class="text-end" colspan="3">
                                                <a v-on:click="item.review=4" href="javascript:void(0)" style="text-decoration: none; color: #ff8d00;">
                                                    <span class="review-color" v-if="item.review==0 || item.review<4">
                                                        <i class="fa fa-star-o str" aria-hidden="true"></i>
                                                        <i class="fa fa-star-o str" aria-hidden="true"></i>
                                                        <i class="fa fa-star-o str" aria-hidden="true"></i>
                                                        <i class="fa fa-star-o str" aria-hidden="true"></i>
                                                    </span>
                                                    <span class="review-color" v-if="item.review>=4">
                                                        <i class="fa fa-star str" aria-hidden="true"></i>
                                                        <i class="fa fa-star str" aria-hidden="true"></i>
                                                        <i class="fa fa-star str" aria-hidden="true"></i>
                                                        <i class="fa fa-star str" aria-hidden="true"></i>
                                                    </span>
                                                </a>
                                                <br />
                                                (راضٍ)
                                            </td>
                                        </tr>
                                        <tr :key="index +'e'">
                                            <td class="text-end" colspan="3">
                                                <a v-on:click="item.review=5" href="javascript:void(0)" style="text-decoration: none; color: #ff8d00;">
                                                    <span class="review-color" v-if="item.review==0 || item.review<5">
                                                        <i class="fa fa-star-o str" aria-hidden="true"></i>
                                                        <i class="fa fa-star-o str" aria-hidden="true"></i>
                                                        <i class="fa fa-star-o str" aria-hidden="true"></i>
                                                        <i class="fa fa-star-o str" aria-hidden="true"></i>
                                                        <i class="fa fa-star-o str" aria-hidden="true"></i>
                                                    </span>
                                                    <span class="review-color" v-if="item.review>=5">
                                                        <i class="fa fa-star str" aria-hidden="true"></i>
                                                        <i class="fa fa-star str" aria-hidden="true"></i>
                                                        <i class="fa fa-star str" aria-hidden="true"></i>
                                                        <i class="fa fa-star str" aria-hidden="true"></i>
                                                        <i class="fa fa-star str" aria-hidden="true"></i>
                                                    </span>
                                                </a>
                                                <br />
                                                ( راضٍ تمامًا)
                                            </td>
                                        </tr>
                                        <tr :key="index +'f'">
                                            <td class="text-end" colspan="3">
                                                <hr />
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-12" hidden>
                <h4>{{ $t("StudentFeedback.Suggestions") }}</h4>
                <textarea class="form-control mb-4" rows="3" v-model="studentSessionFeedBack.remarks"></textarea>
            </div>

            <div class="col-sm-4" v-if="studentSessionFeedBack.isAdd && studentSessionFeedBack.subjectOfSession!=1 && !studentSessionFeedBack.isFromQr && !studentSessionFeedBack.isPrevious">
                <h4>{{ $t("StudentFeedback.Code") }}</h4>
                <input class="form-control mb-4" v-model="studentSessionFeedBack.code" />
            </div>

            <div class="col-sm-12 mb-4">
                <div class="btn-list">
                    <button v-if="studentSessionFeedBack.isAdd" v-on:click="Save()" v-bind:disabled="studentSessionFeedBack.sessionFeedbackQuestion.filter(x => x.review==null).length > 0" class="btn btn-primary">{{ $t("StudentFeedback.Save") }}</button>
                    <a v-on:click="GoTo()" href="javascript:void(0)" class="btn btn-light">{{ $t("StudentFeedback.Cancel") }}</a>
                </div>
            </div>
            <loading :name="loading" :active.sync="loading"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
        </div>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";

    export default {
        name: "StudentFeedback",
        components: {
            Loading
        },
        data: function () {
            return {
                loading: false,
                show: false,
                isQuickWorkShop: false,
                studentSessionFeedBack: {
                    id: '00000000-0000-0000-0000-000000000000',
                    sessionFeedbackQuestion: [],
                    sessionId: '',
                    code: '',
                    remarks: '',
                    studentId: '',
                    IsAdd: false,
                    isPrevious: false,
                    fromEmailLink: false,
                    isFromQr: false,
                }
            };
        },
        methods: {
            GoTo: function () {
                if (this.studentSessionFeedBack.fromEmailLink) {
                    this.$router.push('/');
                }
                else {
                    this.$router.push('/StudentDashboard');
                }
            },

            Save: function () {
                var root = this;
                this.loading = true;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.post('/Home/SaveStudentFeedback', this.studentSessionFeedBack, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.$swal({
                                title: 'تم الإرسال!',
                                text: 'للحصول على الشهادة نأمل منكم الدخول على حسابكم في منصة الارشاد المهني في الجامعات ',
                                type: 'success',
                                icon: 'success',
                                confirmButtonText: 'تسجيل الدخول',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            });
                            root.loading = false;
                            root.GoTo();
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            StudentFeedback: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/account/StudentFeedback?id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        
                        var data = response.data;
                        root.studentSessionFeedBack.sessionFeedbackQuestion = data.sessionFeedbackQuestion;
                        root.studentSessionFeedBack.sessionId = data.sessionId;
                        root.studentSessionFeedBack.remarks = data.remarks;
                        root.studentSessionFeedBack.isAdd = data.isAdd;
                        root.studentSessionFeedBack.fromEmailLink = true;
                        root.studentSessionFeedBack.studentId = data.studentId;
                        root.studentSessionFeedBack.isPrevious = data.isPrevious;
                        root.studentSessionFeedBack.subjectOfSession = data.subjectOfSession;
                        root.isQuickWorkShop = data.isQuickWorkShop;
                        root.studentSessionFeedBack.isFromQr = data.isFromQr;
                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            type: 'error',
                            title: 'خطأ',
                            text: "لم يسمح لك لاعطاء الاستطلاع.",
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });
                });
            },
        },
        created: function () {
            
            if (this.$route.query.id != undefined) {
                this.StudentFeedback(this.$route.query.id);
            }


            if (this.$route.query.data != undefined) {
                var data = this.$route.query.data;
                this.studentSessionFeedBack.sessionFeedbackQuestion = data.sessionFeedbackQuestion;
                this.studentSessionFeedBack.sessionId = data.sessionId;
                this.studentSessionFeedBack.remarks = data.remarks;
                this.studentSessionFeedBack.isAdd = data.isAdd;
                this.studentSessionFeedBack.fromEmailLink = false;
                this.studentSessionFeedBack.subjectOfSession = data.subjectOfSession;
                this.studentSessionFeedBack.studentId = data.studentId;
                this.studentSessionFeedBack.isFromQr = data.isFromQr;
            }
        },
        mounted: function () { },
    };
</script>
<style scoped>
    .card-header {
        display: flex;
        justify-content: space-between;
    }

    .btn-primary:hover {
        color: white !important;
    }

    .str {
        font-size: 30px !important;
        margin-right: 10px;
    }
</style>
