<template>
    
        <el-date-picker v-model="inputValue"
                        type="datetime"
                        style="width: 100%; "
                        :disabled="disabled"                        
                        placeholder="حدد التاريخ والوقت">
        </el-date-picker>
    
</template>

<script>
    import moment from 'moment'
    export default {
        props: ['value','disabled'],
        data: function () {
            return {
                inputValue: '',
                
            }
        },
        mounted: function () {

            this.inputValue = this.value;
        },
        updated: function () {
            
            var input = this.inputValue == null ? null : moment(String(this.inputValue)).format('LLLL');
            this.inputValue = input;
            this.$emit('input', this.inputValue);
        }
    }
</script>
<style>
    .el-picker-panel__footer{
        direction:ltr;
    }
</style>
