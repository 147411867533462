<template>
    <div class="page-main">
        <div class="card p-5 mb-5 mt-3">
            <div class="backtomain mb-3">
                <div class="logo mr-3">

                </div><br>
            </div>
            <div class="signup_form_title mb-5">
                <div class="row">
                    <div class="col-lg-6">
                        <h1><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> {{ $t('Dashboard.CreatePreviousWorkshop') }}</font></font></h1>
                    </div>
                    <div class="col-lg-6 text-end">
                        <!--<a href="/assets/StudentImportTemplate.xlsx" class="btn btn-primary">تحميل</a>-->
                        <a href="javascript:void(0)" v-on:click="DownloadTemplateXlx()" class="btn btn-primary">تحميل</a>

                    </div>
                </div>

            </div>

            <div class="row">
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('CreateWorkshop.ServiceType') }}</label>
                    <typesofsessiondropdown v-model="createWorkShopSession.workshopType" :values="createWorkShopSession.workshopType" :key="refreshRender" @input="SessionRender()" :disabled="role=='Super Admin'?true:false" />

                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('CreateWorkshop.ServiceAddres') }}</label>
                    <sessiontypedropdown v-model="createWorkShopSession.sessionTypeId" :values="createWorkShopSession.sessionTypeId" :key="refreshRender" :subjectOfSession="'WorkShop'" @input="getSessionDetail" :disabled="role=='Super Admin'?true:false" />
                    <div class="checkbox">
                        <div class="custom-checkbox custom-control">
                            <input type="checkbox" v-model="createWorkShopSession.hideSessionType" data-checkboxes="mygroup" :key="refreshRender" class="custom-control-input" id="checkbox">
                            <label for="checkbox" class="custom-control-label">اخفاء عنوان الخدمة</label>
                        </div>
                    </div>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>  {{ $t('CreateWorkshop.SessionStartDate') }} </label>
                    <datetimepicker v-model="createWorkShopSession.sessionStart" :values="createWorkShopSession.sessionStart" :key="refreshRender" @input="GetSessionDuration()" :disabled="role=='Super Admin'?true:false" />
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('CreateWorkshop.SessionDuration') }}</label>
                    <sessiondurationsdropdown @input="GetSessionDuration" :values="createWorkShopSession.sessionDurationId" :key="refreshRender" :disabled="role=='Super Admin'?true:false" />
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label for="inputState"> {{ $t('CreateWorkshop.AvailableAppointments') }}</label>
                    <datetimepicker v-model="createWorkShopSession.sessionEnd" :values="createWorkShopSession.sessionEnd" :disabled="true" :key="sessionEndRender" />
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('CreateWorkshop.AffiliateUniversities') }}</label>
                    <universitymultiselectdropdown v-model="createWorkShopSession.universityId" :values="createWorkShopSession.universityId" @input="SessionRender()" :disabled="role=='Super Admin'?true:false" />
                </div>
                
                <div v-if="createWorkShopSession.workshopType==1" class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>  {{ $t('CreateWorkshop.RemoteLink') }} </label>
                    <input v-model="createWorkShopSession.remoteLink" type="text" class="form-control p-3" disabled v-if="role =='Super Admin'">
                    <input v-model="createWorkShopSession.remoteLink" type="text" class="form-control p-3" v-else>
                </div>
                <div v-if="createWorkShopSession.workshopType==2" class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('CreateWorkshop.MeetingPlace') }}</label>
                    <sessionlocationdropdown v-model="createWorkShopSession.sessionLocationId" :values="createWorkShopSession.sessionLocationId" :subjectOfSession="3" :universityId="createWorkShopSession.universityId" :key="sessionRender" :disabled="role=='Super Admin'?true:false" />
                </div>
                <div v-if="createWorkShopSession.workshopType==2" class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('CreateWorkshop.NumberOfStudents') }}</label>
                    <input v-model="createWorkShopSession.studentLimit" type="number" class="form-control" disabled v-if="role =='Super Admin'">
                    <input v-model="createWorkShopSession.studentLimit" type="number" class="form-control" v-else>
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('CreateWorkshop.UndergraduateLevelForStudents') }}</label>
                    <universtylevelmultiselectdropdown v-model="universityLevelIds" v-bind:values="universityLevelIds" :selectAllLevel="selectAllLevel" :key="universityLevelRender" :disabled="role=='Super Admin'?true:false" />
                    <div class="checkbox">
                        <div class="custom-checkbox custom-control">
                            <input type="checkbox" v-model="selectAllLevel" @change="SlectAllLevel()" data-checkboxes="mygroup" class="custom-control-input" :key="refreshRender" id="checkbox-2" disabled v-if="role =='Super Admin'">
                            <input type="checkbox" v-model="selectAllLevel" @change="SlectAllLevel()" data-checkboxes="mygroup" class="custom-control-input" :key="refreshRender" id="checkbox-2" v-else>
                            <label for="checkbox-2" class="custom-control-label">الجميع</label>
                        </div>
                    </div>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.Gender') }}</label>
                    <genderdropdown v-model="createWorkShopSession.gender" :values="createWorkShopSession.gender" :both="true" :key="refreshRender" :disabled="role=='Super Admin'?true:false" />
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>استيراد ملف</label>
                    <input class="form-control file-input" ref="imgupload" :key="refreshRender" type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" @change="uploadImage('onClick')" id="file-input">
                </div>

                <div class="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label>{{ $t('SessionType.Description') }}</label>
                    <textarea v-model="description" class="form-control p-3" disable readonly />
                </div>
            </div>

            <div class="row mt-3 btn_student_group ">
                <div class="col-md-6">
                    <button v-on:click="SaveNewUser()" v-bind:disabled="$v.$invalid" class="btn btn-primary register">{{ $t('CreateWorkshop.Save') }}</button>
                    <a v-on:click="GoTo()" class="btn btn-light">{{ $t('CreateWorkshop.Cancel') }}</a>
                </div>
                <div class="col-md-6 text-end">
                    <xlsx-workbook v-if="isErrorFileDownload">
                        <xlsx-sheet :collection="sheet.data"
                                    v-for="sheet in sheets"
                                    :key="sheet.name"
                                    :sheet-name="sheet.name" />
                        <xlsx-download :filename="'Template.xlsx'">
                            <button class="btn btn-danger" data-toggle="tooltip" data-placement="top" title="Download Error File"><i class="fa fa-download"></i> Error File</button>
                        </xlsx-download>
                    </xlsx-workbook>
                </div>
            </div>

            <loading :name="loading" :active.sync="loading"
                     :can-cancel="false"
                     :is-full-page="true"></loading>

        </div>
    </div>
</template>
<script>
    import { required } from 'vuelidate/lib/validators';
    import moment from 'moment';
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx"
    import readXlsxFile from 'read-excel-file'
    export default {
        name: 'CreateWorkshop',
        components: {
            Loading,
            XlsxWorkbook,
            XlsxSheet,
            XlsxDownload
        },
        data() {
            return {
                sheets: [],
                loading: false,
                isErrorFileDownload: false,
                selectAllLevel: false,
                universityLevelRender: 0,
                sessionEndRender: 0,
                sessionRender: 0,
                refreshRender: 0,
                minute: 0,
                description: '',
                role: '',
                universityLevelIds: [],
                studentList: [],
                errorStudentList: [],
                createWorkShopSession: {
                    id: '00000000-0000-0000-0000-000000000000',
                    workshopType: '',
                    sessionTypeId: '',
                    sessionStart: '',
                    sessionDurationId: '',
                    sessionEnd: '',
                    universityId: '',
                    universityLevelId: '',
                    sessionLocationId: '',
                    studentLimit: 0,
                    remoteLink: '',
                    gender: '',
                    hideSessionType: false,
                    previousSession: true,
                    filePath: '',
                },
                filePath: null,
                image: '../../images/Placeholder.svg',
            }
        },
        validations: {
            createWorkShopSession:
            {
                workshopType:
                {
                    required
                },
                sessionTypeId:
                {
                    required
                },
                sessionStart:
                {
                    required
                },
                sessionDurationId:
                {
                    required
                },
                sessionEnd:
                {
                    required
                },
                universityId:
                {
                    required
                },
                gender:
                {
                    required
                },
            }
        },

        methods: {
            uploadImage() {
                var root = this;
                var file = this.$refs.imgupload.files[0];

                
                root.studentList = [];
                readXlsxFile(file).then((allRows) => {
                    if (allRows.length > 1) {
                        allRows.splice(0, 1)
                        allRows.forEach(function (data) {
                            root.studentList.push({
                                firstName: data[1],
                                middleName: data[2],
                                lastName: data[3],
                                nationalId: data[4],
                                phoneNumber: data[5],
                                dateOfBirth: data[6],
                                gender: data[7],
                                email: data[8],
                                universityLevelName: data[9],
                                studentCategoryName: data[10],
                                universityName: data[11],
                                collegeName: data[12],
                            })
                        })
                    }
                });
            },

            SessionRender: function () {
                this.sessionRender++;
            },

            getSessionDetail(data) {
                /*const newString = data.description.replace(/\s+/g, ' ').trim();*/
                this.description = data.description;
                this.createWorkShopSession.sessionTypeId = data.id;

            },

            SlectAllLevel: function () {

                if (this.selectAllLevel) {
                    this.universityLevelIds = [];
                    this.universityLevelRender++;
                }
                else {
                    this.universityLevelIds = [];
                    this.universityLevelRender++;
                }
            },

            GetSessionDuration: function (duration) {
                if (duration != undefined) {
                    this.createWorkShopSession.sessionDurationId = duration.id;
                    this.minute = duration.minutes;
                    if (this.createWorkShopSession.sessionStart != null && this.createWorkShopSession.sessionStart != '') {
                        this.createWorkShopSession.sessionEnd = moment(this.createWorkShopSession.sessionStart).add(duration == undefined ? 0 : duration.minutes, 'minutes').format('llll');
                        this.sessionEndRender++;
                    }
                }
                else {
                    if (this.createWorkShopSession.sessionStart != null && this.createWorkShopSession.sessionStart != '') {
                        this.createWorkShopSession.sessionEnd = moment(this.createWorkShopSession.sessionStart).add(this.minute, 'minutes').format('llll');
                        this.sessionEndRender++;
                    }
                }
            },

            GoTo: function () {
                if (this.role == "Super Admin") {
                    this.$router.push('/SessionsReport');
                }
                else {
                    this.$router.push('/AdvisorDashboard');
                }
            },

            getLevelId: function (value) {
                var levelId = [];
                for (var i = 0; i < value.length; i++) {
                    levelId[i] = value[i].id
                }
                return levelId;
            },

            SendEmailDetails: function (id) {
                var root = this;
                var token = '';
                this.loading = true;

                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                root.$https.post('/Sessions/SendPreviousSessionEmail?sessionId=' + id, this.studentList, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$swal({
                            title: root.$t('Notification.Created'),
                            text: root.$t('Notification.SessionCreated'),
                            type: 'success',
                            icon: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonsStyling: false
                        });
                        root.createWorkShopSession = {
                            id: '00000000-0000-0000-0000-000000000000',
                            workshopType: '',
                            sessionTypeId: '',
                            sessionStart: '',
                            sessionDurationId: '',
                            sessionEnd: '',
                            universityId: '',
                            universityLevelId: '',
                            sessionLocationId: '',
                            studentLimit: 0,
                            remoteLink: '',
                            gender: '',
                            hideSessionType: false,
                            previousSession: true,
                            filePath: '',
                        };
                        root.description = '';
                        root.universityLevelIds = [];
                        root.studentList = [];
                        root.selectAllLevel = false;

                        root.universityLevelRender++;
                        root.sessionEndRender++;
                        root.sessionRender++;
                        root.refreshRender++;

                        root.loading = false;


                        root.errorStudentList = [];
                        response.data.forEach(function (data, index) {
                            root.errorStudentList.push({
                                Sr: index + 1,
                                FirstName: data.firstName,
                                MiddleName: data.middleName,
                                LastName: data.lastName,
                                //RegistrationCode: data.registrationCode,
                                NationalId: data.nationalId,
                                PhoneNumber: data.phoneNumber,
                                DateOfBirth: data.dateOfBirth,
                                Gender: data.gender,
                                Email: data.email,
                                UniversityLevelName: data.universityLevelName,
                                StudentCategoryName: data.studentCategoryName,
                                universityName: data.universityName,
                                collegeName: data.collegeName,
                                Error: data.error,
                            })
                        });

                        root.sheets.push({ name: "SheetOne", data: root.errorStudentList });
                        if (root.errorStudentList.length > 0) {
                            root.isErrorFileDownload = true;
                        }
                        else {
                            root.GoTo();
                        }
                    }
                    root.loading = false;

                });

            },
            SaveNewUser: function () {
                var root = this;
                this.loading = true;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                if (this.universityLevelIds != null && this.universityLevelIds != undefined && this.universityLevelIds != '') {
                    this.createWorkShopSession.universityLevelId = this.getLevelId(this.universityLevelIds);
                }

                root.$https.post('/Sessions/SaveWorkShopSession', this.createWorkShopSession, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            //if (response.data.permissionOnSessionCreation || response.data.smsPermissionOnSessionCreation) {
                            //    root.SendEmailDetails(response.data.id, response.data.permissionOnSessionCreation, response.data.smsPermissionOnSessionCreation);
                            //}
                            root.SendEmailDetails(response.data.id, response.data.permissionOnSessionCreation, response.data.smsPermissionOnSessionCreation);

                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false;
                    });
            },

            DownloadTemplateXlx: function () {
                var root = this;
                root.loading = true;
                root.$https.get('/Sessions/StudentExcelTemplate', { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'StudentImportTemplate.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        root.loading = false;
                    }).catch(error => {
                        console.log(error);
                        root.loading = false;
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: 'خطأ',
                                text: "غير مسموح لك بتنزيل شهادتك.",
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                    });
            },

        },
        created: function () {
            if (this.$route.query.data != undefined) {
                this.createWorkShopSession = this.$route.query.data;
                this.universityLevelIds = this.$route.query.data.universityLevelId;

            }
            this.role = localStorage.getItem('RoleName');
        },
        mounted: function () {
            if (this.$route.query.data != undefined) {
                this.minute = this.$route.query.data.minutes;
                if (this.createWorkShopSession.sessionStart != null && this.createWorkShopSession.sessionStart != '') {
                    this.createWorkShopSession.sessionEnd = moment(this.createWorkShopSession.sessionStart).add(this.minute, 'minutes').format('llll');
                    this.sessionEndRender++;
                }
            }
        },
    }
</script>
<style scoped>
    .btn-primary {
        margin-left: 10px;
    }

    @media only screen and (max-width: 600px) {
        .register {
            max-width: 40%;
        }

        .login {
            max-width: 40%;
        }

        .btn_student_group {
            justify-content: center;
        }
    }
</style>