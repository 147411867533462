<template>
    <div class="main-sec mt-3">
        <div class="col-md-6 card ">

            <div class="modal-header">
                <h5 class="modal-title"> {{ $t('AddAdviserSlots.Heading') }}</h5>
            </div>
            <div class="modal-body">
                <div class="row">


                    <div class="form-group col-md-8">
                        <label>{{ $t('AddAdviserSlots.AdviserSlotsStartDate') }}</label> <br />
                        <datetimepicker v-model="newAdviserSlot.fromDateTime" />
                    </div>

                    <div class="form-group col-md-8">
                        <label>{{ $t('AddAdviserSlots.AdviserSlotsEndDate') }}</label> <br />
                        <datetimepicker v-model="newAdviserSlot.toDateTime" />
                    </div>

                    <!--<div class="form-group col-md-8">
                        <label>{{ $t('AddAdviserSlots.University') }}</label>
                        <universitydropdown v-model="newAdviserSlot.universityId" :alluniversities="true" />
                    </div>-->
                    <div class="form-group col-md-8">
                        <label>{{ $t('AddAdviserSlots.University') }}</label>
                        <universitymultiselectdropdown v-model="newAdviserSlot.universityId" :values="newAdviserSlot.universityId" />
                    </div>
                    <div class="form-group col-md-8">
                        <label>{{ $t('AddAdviserSlots.Gender') }}</label>
                        <genderdropdown v-model="newAdviserSlot.gender" :values="newAdviserSlot.gender" :both="true" />
                    </div>
                </div>
            </div>
            <div class="modal-footer ">
                <button type="button" v-bind:disabled="$v.$invalid" class="btn btn-primary btn-round" v-on:click="SaveStudentCategory()">{{ $t('AddAdviserSlots.Save') }}</button>
                <button type="button" class="btn btn-danger btn-round" v-on:click="close" href="javascript:void(0)">{{ $t('AddAdviserSlots.Cancel') }}</button>
            </div>

            <loading :name="loading" :active.sync="loading"
                     :can-cancel="false"
                     :is-full-page="true"></loading>

        </div>
    </div>
</template>

<script>
    import { required } from "vuelidate/lib/validators";
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    export default {
        name: "addAdviserSlots",
        components: {
            Loading
        },
        data: function () {

            return {
                newAdviserSlot: {
                    id: '00000000-0000-0000-0000-000000000000',
                    fromDateTime: '',
                    toDateTime: '',
                    universityId: [],
                    gender: '',
                    isMultipleUniversity: true,
                },

                loading: false,


            }
        },
        validations: {
            newAdviserSlot: {
                fromDateTime: {
                    required: required
                },
                toDateTime: {
                    required: required
                }
            }
        },
        methods: {
            close: function () {
                this.$router.push('/AdviserSlots');
            },

            SaveStudentCategory: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                this.newAdviserSlot.isMultipleUniversity = true;


                root.$https.post('/Setup/SaveAdviserSlot', this.newAdviserSlot, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.loading = false;
                            root.$swal({
                                title: root.$t('Notification.Added'),
                                text: root.$t('Notification.DataSucess'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    root.close();
                                    root.loading = false;
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },
        },
        created: function () {
        },
        mounted: function () {
        },
    }
</script>
