<template>
    <div class="main-studentSession  mt-3 row">
        <div class="col-md-12  col-xl-6 ">
            <div class="card">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('CreateStudentSession.Heading') }}</h5>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label>{{ $t('CreateStudentSession.SessionType') }}</label>
                            <sessiontypedropdown v-model="createStudentSession.sessionTypeId" :values="createStudentSession.sessionTypeId" :subjectOfSession="'Individual'" :disabled="true" />
                        </div>

                        <div class="form-group col-md-12">
                            <label> {{ $t('CreateGroupSession.SessionStartDate') }}</label>
                            <datetimepicker v-model="createStudentSession.sessionStart" :values="createStudentSession.sessionStart" :disabled="true" />
                        </div>

                        <div class="form-group col-md-12">
                            <label for="inputState">  {{ $t('CreateGroupSession.AvailableAppointments') }}</label>
                            <datetimepicker v-model="createStudentSession.sessionEnd" :values="createStudentSession.sessionEnd" :disabled="true" />
                        </div>

                        <div class="form-group col-md-12">
                            <label>  {{ $t('CreateGroupSession.MeetingPlace') }}</label>
                            <sessionlocationdropdown v-model="createStudentSession.sessionLocationId" :values="createStudentSession.sessionLocationId" :subjectOfSession="1" :disabled="true" />
                        </div>
                    </div>
                </div>
                <div class="modal-footer ">
                    <button type="button" class="btn btn-primary btn-round" v-on:click="SaveStudentSession()">{{ $t('CreateStudentSession.Save') }}</button>
                    <button type="button" class="btn btn-danger btn-round" v-on:click="close" href="javascript:void(0)">{{ $t('CreateStudentSession.Cancel') }}</button>
                </div>
            </div>
        </div>

        <loading :name="loading" :active.sync="loading"
                 :can-cancel="false"
                 :is-full-page="true"></loading>
    </div>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";

    export default {
        name: 'CreateStudentSession',
        components: {
            Loading
        },
        data: function () {
            return {
                createStudentSession: {
                    id: '00000000-0000-0000-0000-000000000000',
                    sessionTypeId: '',
                    sessionStart: '',
                    sessionEnd: '',
                    sessionLocationId: '',

                },
                loading: false,
                slotDate: '',
                options: '',
            }
        },
        methods: {
            GoTo: function (link) {
                this.$router.push(link);
            },

            close: function () {
                this.$router.push('/AdvisorDashboard');
            },


            SaveStudentSession: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.post('/Sessions/EditSession', this.createStudentSession, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.loading = false;
                            root.$swal({
                                title: root.$t('Notification.Added'),
                                text: root.$t('Notification.DataSucess'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    root.close();
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

        },
        created: function () {
            if (this.$route.query.data != undefined) {
                var data = this.$route.query.data;
                this.createStudentSession.id = data.id;
                this.createStudentSession.sessionTypeId = data.sessionTypeId;
                this.createStudentSession.sessionStart = data.sessionStart;
                this.createStudentSession.sessionEnd = data.sessionEnd;
                this.createStudentSession.sessionLocationId = data.sessionLocationId;
            }
        },
        mounted: function () {

        },
    }
</script>
<style scoped>
    .time-tag {
        padding: 10px;
        border: 1px solid rgb(219, 219, 219);
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

        .time-tag:hover {
            background-color: rgb(0,144,0);
            color: #fff;
        }

    .active_div {
        background-color: rgb(0,144,0) !important;
        color: #fff !important;
    }
</style>