<template>
    <div class="main-region">
        <div class="page-header">
            <div>
                <h1 class="page-title"> أذونات</h1>
            </div>
            <div class="ms-auto pageheader-btn">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">أذونات</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ $t('Regions.Dashboard') }}</li>
                </ol>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-12">


                <div class="card product-sales-main">
                    <div class="card-body">
                        <div class="panel panel-primary">
                            <div class="tab-menu-heading border-bottom-0">
                                <div class="tabs-menu4 border-bottomo-sm">
                                    <!-- Tabs -->
                                    <nav class="nav d-sm-flex d-block">
                                        <a class="nav-link border border-bottom-0 br-sm-5 me-2 active" data-bs-toggle="tab"  href="javascript:void(0)" v-on:click="MakeActive('Student')">
                                            الطالب
                                        </a>
                                        <a class="nav-link border border-bottom-0 br-sm-5 me-2" data-bs-toggle="tab" href="javascript:void(0)" v-on:click="MakeActive('Adviser')" >
                                            المرشدين
                                        </a>
                                        <a class="nav-link border border-bottom-0 br-sm-5 me-2" data-bs-toggle="tab" href="javascript:void(0)" v-on:click="MakeActive('University')">
                                            الجامعة
                                        </a>
                                    </nav>
                                </div>
                            </div>
                            <div class="panel-body tabs-menu-body">
                                <div class="tab-content">
                                    <div class="tab-pane active" v-if="active=='Student'">
                                        <div class="">
                                            <form  enctype="multipart/form-data" class="max-width">

                                                <div class="contact-us">

                                                    <div class="form-group row">

                                                        <div class="col-sm-12 col-md-3  required-circle" style=" margin-left: -10px ; padding-left: 1px ;">
                                                            <label class="color-for-text-title font-weight-bold">الطالب</label>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12">
                                                            <studentDropdown v-model="studentIds" v-bind:values="studentIds" :key="studentLevelRender" :allStudent="true"/>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row">

                                                        <div class="col-sm-12 col-md-12  required-circle" style=" margin-left: -10px ; padding-left: 1px ;">
                                                            <label class="color-for-text-title font-weight-bold">{{ $t('ContactUs.Title') }}</label>

                                                        </div>
                                                        <div class="col-sm-12 col-md-12">
                                                            <input v-model="emailNotification.subject" class="form-control" data-val="true" data-val-required="حقل &quot;عنوان الرسالة&quot; مطلوب" id="Subject" name="Subject" :placeholder="$t('ContactUs.Title')" type="text" value="" />
                                                        </div>
                                                    </div>

                                                    <div class="form-group row">

                                                        <div class="col-sm-12 col-md-3  required-circle" style=" margin-left: -10px ; padding-left: 1px ;">
                                                            <label class="color-for-text-title font-weight-bold">{{ $t('ContactUs.Inquiry') }}</label>

                                                        </div>
                                                        <div class="col-sm-12 col-md-12">
                                                            <textarea v-model="emailNotification.description" class="form-control" cols="20" data-val="true" data-val-required="حقل &quot;الاستفسار&quot; مطلوب" id="Inquiry" name="Inquiry" :placeholder="$t('ContactUs.Inquiry')" rows="5"></textarea>
                                                        </div>
                                                    </div>
                                                    <p class="response contactModalResponse"></p>
                                                    <div class="text-right d-flex align-items-baseline">
                                                        <button v-on:click="SaveEmailNotification()" class="btn btn-primary">حفظ</button>
                                                    </div>


                                                </div>
                                            </form>
                                        </div>
                                       
                                    </div>

                                    <div class="tab-pane active" v-if="active=='Adviser'">

                                        <div class="">
                                            <form enctype="multipart/form-data" class="max-width">

                                                <div class="contact-us">

                                                    <div class="form-group row">

                                                        <div class="col-sm-12 col-md-3  required-circle" style=" margin-left: -10px ; padding-left: 1px ;">
                                                            <label class="color-for-text-title font-weight-bold">المرشدين</label>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12">
                                                            <adviserMultiSelectDropdown v-model="adviserIds" v-bind:values="adviserIds" :key="studentLevelRender" :allAdviser="true"/>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row">

                                                        <div class="col-sm-12 col-md-12  required-circle" style=" margin-left: -10px ; padding-left: 1px ;">
                                                            <label class="color-for-text-title font-weight-bold">{{ $t('ContactUs.Title') }}</label>

                                                        </div>
                                                        <div class="col-sm-12 col-md-12">
                                                            <input v-model="emailNotification.subject" class="form-control" data-val="true" data-val-required="حقل &quot;عنوان الرسالة&quot; مطلوب" id="Subject" name="Subject" :placeholder="$t('ContactUs.Title')" type="text" value="" />
                                                        </div>
                                                    </div>

                                                    <div class="form-group row">

                                                        <div class="col-sm-12 col-md-3  required-circle" style=" margin-left: -10px ; padding-left: 1px ;">
                                                            <label class="color-for-text-title font-weight-bold">{{ $t('ContactUs.Inquiry') }}</label>

                                                        </div>
                                                        <div class="col-sm-12 col-md-12">
                                                            <textarea v-model="emailNotification.description" class="form-control" cols="20" data-val="true" data-val-required="حقل &quot;الاستفسار&quot; مطلوب" id="Inquiry" name="Inquiry" :placeholder="$t('ContactUs.Inquiry')" rows="5"></textarea>
                                                        </div>
                                                    </div>
                                                    <p class="response contactModalResponse"></p>
                                                    <div class="text-right d-flex align-items-baseline">
                                                        <button v-on:click="SaveEmailNotification()" class="btn btn-primary">حفظ</button>
                                                    </div>


                                                </div>
                                            </form>
                                        </div>
                                       
                                    </div>

                                    <div class="tab-pane active" v-if="active=='University'">
                                        <div class="">
                                            <form  enctype="multipart/form-data" class="max-width">

                                                <div class="contact-us">

                                                    <div class="form-group row">

                                                        <div class="col-sm-12 col-md-3  required-circle" style=" margin-left: -10px ; padding-left: 1px ;">
                                                            <label class="color-for-text-title font-weight-bold"> الجامعة </label>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12">
                                                            <multiuniverstydropdown  v-model="universityIds" v-bind:values="universityIds"/>
                                                        </div>
                                                    </div>

                                                    <div class="form-group row">

                                                        <div class="col-sm-12 col-md-12  required-circle" style=" margin-left: -10px ; padding-left: 1px ;">
                                                            <label class="color-for-text-title font-weight-bold">{{ $t('ContactUs.Title') }}</label>

                                                        </div>
                                                        <div class="col-sm-12 col-md-12">
                                                            <input v-model="emailNotification.subject" class="form-control" data-val="true" data-val-required="حقل &quot;عنوان الرسالة&quot; مطلوب" id="Subject" name="Subject" :placeholder="$t('ContactUs.Title')" type="text" value="" />
                                                        </div>
                                                    </div>

                                                    <div class="form-group row">

                                                        <div class="col-sm-12 col-md-3  required-circle" style=" margin-left: -10px ; padding-left: 1px ;">
                                                            <label class="color-for-text-title font-weight-bold">{{ $t('ContactUs.Inquiry') }}</label>

                                                        </div>
                                                        <div class="col-sm-12 col-md-12">
                                                            <textarea v-model="emailNotification.description" class="form-control" cols="20" data-val="true" data-val-required="حقل &quot;الاستفسار&quot; مطلوب" id="Inquiry" name="Inquiry" :placeholder="$t('ContactUs.Inquiry')" rows="5"></textarea>
                                                        </div>
                                                    </div>
                                                    <p class="response contactModalResponse"></p>
                                                    <div class="text-right d-flex align-items-baseline">
                                                        <button v-on:click="SaveEmailNotification()" class="btn btn-primary">حفظ</button>
                                                    </div>


                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <loading :name="loading" :active.sync="loading"
                 :can-cancel="false"
                 :is-full-page="true"></loading>
    </div>
</template>

<script>
    import "vue-loading-overlay/dist/vue-loading.css";
    import { required } from "vuelidate/lib/validators";
    import Loading from 'vue-loading-overlay';

    export default {
        name: 'Permissions',
        components: {
            Loading
        },
        data: function () {
            return {
                studentLevelRender: 0,
                active: 'Student',
              
                loading: false,

                searchQuery: null,
                searchQueryAdviser: null,
                studentIds: [],
                adviserIds: [],
                universityIds:[],
                emailNotification: {
                    id: '00000000-0000-0000-0000-000000000000',
                    mailType:'Student',
                    studentId: '',
                    subject: '',
                    description: '',
                    adviserId: '',
                    universityId:''
                },
                file1: null,
            }
        },
        validations: {
            emailNotification: {
                subject: {
                    required: required
                },
                description: {
                    required: required
                },
            }
        },

        methods: {
            MakeActive: function (active) {
                this.active = active;
                this.emailNotification.mailType = active;
            },

            GoTo: function (link) {
                this.$router.push(link);
            },
            GoToHome: function () {
                this.$router.push('/')
            },
            close: function () {
                this.$router.push('/EmailNotification');
            },

            getStudentId: function (value) {
                var levelId = [];
                for (var i = 0; i < value.length; i++) {
                    levelId[i] = value[i].id
                }
                return levelId;
            },
            getadviserId: function (value) {
                var levelId = [];
                for (var i = 0; i < value.length; i++) {
                    levelId[i] = value[i].id
                }
                return levelId;
            },
            getuniversityId: function (value) {
                var levelId = [];
                for (var i = 0; i < value.length; i++) {
                    levelId[i] = value[i].id
                }
                return levelId;
            },
            SaveEmailNotification: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if (this.studentIds != null && this.studentIds != undefined && this.studentIds != '') {
                    this.emailNotification.studentId = this.getStudentId(this.studentIds);
                }
                if (this.adviserIds != null && this.adviserIds != undefined && this.adviserIds != '') {
                    this.emailNotification.adviserId = this.getadviserId(this.adviserIds);
                }
                if (this.universityIds != null && this.universityIds != undefined && this.universityIds != '') {
                    this.emailNotification.universityId = this.getuniversityId(this.universityIds);
                }
                root.$https.post('/Home/SaveEmailNotification', this.emailNotification, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        
                        if (response.data.isSuccess) {
                            root.loading = false;
                            root.$swal({
                                title: root.$t('Notification.Added'),
                                text: root.$t('Notification.DataSucess'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    root.$router.go();
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: 'Error!',
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: 'Error',
                                text: "Something Went Wrong",
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },
        },
        created: function () {
            if (this.$route.query.data != undefined) {
                
                this.studentIds = this.$route.query.data.studentId;
            }
        },
        mounted: function () {

        },
    }
</script>
<style scoped>
    .navy {
        background-color: white;
        border-radius: 0.75rem !important;
        background: #fff;
        border: none !important;
        box-shadow: 0rem 0rem 0.9375rem -0.625rem grey;
        color: black;
        font-size: 16px;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        cursor: pointer;
    }

        .navy:hover {
            background-color: #009000;
            color: white !important;
        }

    .hed {
        color: #009000;
        font-size: 32px;
    }
</style>
<style scoped>
    .landing-nav {
        padding: 20px 0px 20px 0px;
    }

    .header {
        border-bottom: none !important;
        background: transparent !important;
    }

    .page-main {
        background-size: cover;
    }

    .m {
        background-image: url("/assets/images/landing/bg.jpg");
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .banner {
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: white;
    }

    .demo-footer {
        background-color: #092f57;
    }

    .btn-outline-primary {
        color: #009000 !important;
        border: 1px solid #009000;
    }

        .btn-outline-primary:hover {
            color: white !important;
        }

    .parent {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        padding-bottom: 50px;
    }

        .parent p {
            color: white;
            margin-top: 56px;
            font-size: 16px;
        }

    .banner h1 {
        font-weight: 700;
        font-size: 58px;
        margin-top: 150px;
    }

    .banner p {
        margin-top: 30px;
        font-size: 16px;
    }

    .con {
        color: white !important;
        margin-top: 20px;
        padding: 20px;
        cursor: pointer;
    }

    @media (min-width: 1274px) {
        .header {
            padding-right: 50px;
        }
    }

    @media screen and (min-width: 300px) and (max-width: 780px) {
        .parent {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: 4fr;
        }

            .parent p {
                margin-top: 36px;
            }

        .btn {
            min-width: 100px !important;
            padding: 3px !important;
            border: 1px solid white;
            color: white !important;
        }
    }

    .ft {
        margin-top: 40px !important;
        margin-right: 200px;
    }

    .copy {
        background-color: #001b39;
        color: white;
        text-align: center;
    }
</style>

