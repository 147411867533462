<template>
    <div class="main-advisor-slot">

        <!-- PAGE-HEADER -->
        <div class="page-header">
            <div>
                <h1 class="page-title"> {{ $t('AdviserSlots.Heading') }}</h1>
            </div>
            <div class="ms-auto pageheader-btn">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);"> {{ $t('AdviserSlots.Heading') }}</a></li>
                    <li class="breadcrumb-item active" aria-current="page"> {{ $t('AdviserSlots.Dashboard') }}</li>
                </ol>
            </div>
        </div>
        <!-- PAGE-HEADER END -->
        <!-- ROW-4 -->
        <div class="row">
            <div class="col-12 col-sm-12">
                <div class="card product-sales-main">
                    <div class="card-header border-bottom ac">
                        <div class="form-group">
                            <div class="input-group">
                                <input v-model="search" class="form-control fc" id="basic-addon6" :placeholder=" $t('AdviserSlots.Search')" type="text">

                            </div>
                        </div>
                        <!--<a v-on:click="GoTo('/AddAdviserSlot')" href="javascript:void(0)" class="btn btn-primary btn-pill slide-item">{{ $t('AdviserSlots.AddAdviserSlots') }}</a>-->
                        <a v-on:click="pdfShow=true" href="javascript:void(0)" v-if="slotList.length>0" class="btn btn-primary btn-pill slide-item"><i class="mdi mdi-file-pdf"></i> جلسة فردية</a>
                        <a v-on:click="GoTo('/AddAdviserMultiUniversitySlot')" href="javascript:void(0)" class="btn btn-primary btn-pill slide-item">{{ $t('AdviserSlots.AddAdviserSlots') }}</a>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table border text-nowrap text-md-nowrap table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{{ $t('AdviserSlots.AdviserSlotsStartDate') }}</th>
                                        <th>{{ $t('AdviserSlots.AdviserSlotsEndDate') }}</th>
                                        <th>{{ $t('AdviserSlots.University') }}</th>
                                        <th>{{ $t('AdviserSlots.Gender') }}</th>
                                        <th>{{ $t('AdviserSlots.Status') }}</th>
                                        <th>{{ $t('AdviserSlots.Action') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(slot, index) in slotList" :key="slot.id">
                                        <td v-if="currentPage === 1">
                                            {{index+1}}
                                        </td>
                                        <td v-else>
                                            {{((currentPage*10)-10) +(index+1)}}
                                        </td>
                                        <td>{{GetDate(slot.fromDateTime)}}</td>
                                        <td>{{GetDate(slot.toDateTime)}}</td>
                                        <td>
                                            <span v-for="(item, index) in slot.universityId" :key="index">
                                                {{index > 0? ',' : ''}} ({{item.name}})
                                            </span>
                                        </td>
                                        <td>
                                            <span v-if="slot.gender==1"> {{ $t('AdviserSlots.Male') }} </span>
                                            <span v-if="slot.gender==2">{{ $t('AdviserSlots.Female') }}</span>
                                            <span v-if="slot.gender==3">{{ $t('AdviserSlots.Both') }}</span>
                                        </td>
                                        <td>
                                            <span v-if="slot.isActive" class="badge rounded-pill bg-primary-gradient my-1">{{ $t('AdviserSlots.Active') }}</span>
                                            <span v-else class="badge rounded-pill bg-danger-gradient my-1">{{ $t('AdviserSlots.InActive') }}</span>
                                        </td>
                                        <td>
                                            <a v-on:click="Delete(slot.id)" href="javascript:void(0)" class="btn btn-sm btn-danger"><i class="fa fa-trash-o" aria-hidden="true"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>

                        <div class="row">
                            <div class="col-md-6 text-right">
                                <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            </div>
                            <div class="col-md-6">
                                <div class="overflow-auto" v-on:click="getPage()" style="float:left">
                                    <b-pagination pills size="md" v-model="currentPage"
                                                  :total-rows="rowCount"
                                                  :per-page="10"
                                                  :first-text="this.$t('Tabel.First')"
                                                  :prev-text="this.$t('Tabel.Previous')"
                                                  :next-text="this.$t('Tabel.Next')"
                                                  :last-text="this.$t('Tabel.Last')"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- COL END -->
        </div>
        <individualsessionqrprint v-if="pdfShow" @close="pdfShow=false" />

    </div>

</template>

<script>
    import moment from 'moment'
    export default {
        name: 'AdviserSlots',
        data: function () {
            return {

                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                pageSize: '',
                loading: false,
                slotList: [],

                show: false,
                pdfShow: false,
            }
        },

        watch: {
            search: function (val) {
                this.GetData(val, 1);
            }
        },
        methods: {
            GoTo: function (link) {
                this.$router.push(link);
            },

            GetDate: function (date) {
                return moment(date).format('llll');
            },

            getPageSize: function (isDesc) {
                this.isDesc = isDesc;
                this.getPage();
            },

            getPage: function () {
                this.GetData(this.search, this.currentPage);
            },

            GetData: function (search, currentPage) {

                var root = this;
                var url = '/Setup/AdviserSlotList?searchTerm=' + search + '&pageNumber=' + currentPage + '&userId=' + localStorage.getItem('UserID');
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.slotList = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        type: 'error',
                        title: root.$t('Notification.Error'),
                        text: root.$t('Notification.SomethingWrong'),
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                }).finally(() => root.loading = false);
            },

            Delete: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Setup/DeleteAdviserSlot?id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data.isSuccess) {
                        root.loading = false;
                        root.$swal({
                            title: root.$t('Notification.Added'),
                            text: root.$t('Notification.DataSucess'),
                            type: 'success',
                            icon: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonsStyling: false
                        });
                        root.getPage();
                    }
                    else {
                        root.$swal({
                            title: root.$t('Notification.Error'),
                            text: response.data.isAddUpdate,
                            type: 'error',
                            icon: 'error',
                            confirmButtonClass: "btn btn-danger",
                            buttonsStyling: false
                        });
                        root.loading = false;
                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            type: 'error',
                            title: root.$t('Notification.Error'),
                            text: root.$t('Notification.SomethingWrong'),
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });
                    root.loading = false;
                }).finally(() => root.loading = false);
            },

            EditInfo: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Setup/GetStudentCategoryDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$router.push({
                            path: '/addStudentCategory',
                            query: { data: response.data }
                        })
                    }
                });
            },
        },
        created: function () {

        },

        mounted: function () {
            this.GetData(this.search, 1);
        },
    }
</script>
<style scoped>
    .card-header {
        display: flex;
        justify-content: space-between;
    }

    .btn-primary:hover {
        color: white !important;
    }
</style>


