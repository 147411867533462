import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export const store= new Vuex.Store({
    state: {
        companyList: []
  },
  mutations: {
  },
    actions: {
        GetComapanyList: function (commit, companyList) {
             //eslint-disable-line
            store.state.companyList = companyList;
        }
    },
  modules: {
  }
});
