<template>
    <div hidden id='purchaseInvoice'>
        <div class="row  mt-5">
            <div class="col-12 logo text-center">
                <img src="/assets/images/subol_logo.png" />
            </div>
            <div class="col-6 pr-5 py-2 my-5 pt-3" style="text-align:end; background:#009000 !important; color:white;">
                <h2>    {{advisorEmail}} :  البريد الإلكتروني</h2>
            </div>
            <div class="col-6 pr-5 py-2 my-5 pt-3" style="text-align:end; background:#009000 !important; color:white;">
                <h2>  المرشد : {{advisorName}}</h2>
            </div>

        </div>
        <div class="row pr-3" style=" text-align: start; direction: rtl;">
            
            <div class="col-lg-12 " style="margin-top:200px; display:flex; justify-content:center; ">
                <qr-code :text="path" error-level="L"></qr-code>
            </div>
        </div>
    </div>

</template>

<script>
    import Vue from 'vue';
    import VueHtmlToPaper from 'vue-html-to-paper';
    import VueQRCodeComponent from 'vue-qrcode-component'
    Vue.component('qr-code', VueQRCodeComponent)

    const options = {
        name: '_blank',
        specs: [
            'fullscreen=yes',
            'titlebar=yes',
            'scrollbars=yes'
        ],
        styles: [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
            'https://unpkg.com/kidlat-css/css/kidlat.css'
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: window.document.title, // override the window title
    }
    Vue.use(VueHtmlToPaper, options);

    export default {
        //props: ['sessiondetail'],

        data: function () {
            return {
                advisorName: '',
                advisorEmail: '',
                path: '',
            }
        },

        methods: {
            printInvoice: function () {
                var root = this;
                this.$htmlToPaper('purchaseInvoice', options, () => {
                    root.$emit('close');
                });
            },

        },
        mounted: function () {
            
            var root = this;
            
            this.advisorName = localStorage.getItem('FullName');
            this.advisorEmail = localStorage.getItem('UserEmail');
            this.path = window.location.origin + '/joinindividualsession?id=' + localStorage.getItem('UserID');

            setTimeout(function () {
                root.printInvoice();
            }, 125)
        },

    }
</script>

