<template>
    <div class="page">
        <div class="page-main">
            <!-- app-Header -->
            <div class="hor-header header">
                <div class="container main-container">
                    <div class="d-flex pt-1">
                        <img style="width:200px;" src="/assets/images/subol_logo.png" v-on:click="GoTo('/')" />
                        <ul id="firstUl" class="navbar-nav mr-auto">
                            <li class="nav-item ">
                                <a class="text-dark m3-3 vll text-decoration-none" href="#">نبذة عن سبل</a>
                            </li>
                            <li class="nav-item ">
                                <a class="text-dark m3-3 vll text-decoration-none" href="#">إحصائيات</a>
                            </li>
                            <li class="nav-item ">
                                <a class="text-dark m3-3 vll text-decoration-none" href="#">الأسئلة الشائعة</a>
                            </li>
                            <li class="nav-item ">
                                <a class="text-dark m3-3 vll text-decoration-none" href="#">اتصل بنا</a>
                            </li>
                        </ul>
                        <!-- LOGO -->

                        <div class="d-flex order-lg-2 ms-auto header-right-icons mt-3" id="hide">
                            <button class="navbar-toggler navresponsive-toggler d-md-none ms-auto" type="button"
                                data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent-4"
                                aria-controls="navbarSupportedContent-4" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon fe fe-more-vertical" style="color:#fff!important"></span>
                            </button>

                            <div class="navbar navbar-collapse responsive-navbar p-0">
                                <div class="collapse navbar-collapse" id="navbarSupportedContent-4">
                                    <div class="d-flex order-lg-2 m-4 m-lg-0 m-md-1">
                                        <a v-on:click="GoTo('/Login')" href="javascript:void(0)"
                                            class="btn btn-pill btn-primary btn-w-md py-2 ct">
                                            {{ $t('LandingPage.Login') }}
                                        </a>
                                        <a v-on:click="GoTo('/Register')" href="javascript:void(0)"
                                            class="btn btn-pill btn-primary btn-w-md py-2 ms-1 ct">
                                            {{ $t('LandingPage.Signup') }}
                                        </a>

                                        <a v-on:click="GoTo('/ContactUs')"
                                            class=" btn btn-pill btn-primary btn-w-md py-2 ms-1 ct">{{
                                                $t('LandingPage.ContactUs') }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="p-0" id="forHide">
                        <div>
                            <div class="d-flex order-lg-2 my-2 m-lg-0 m-md-1">
                                <a v-on:click="GoTo('/Login')" href="javascript:void(0)"
                                    class="btn btn-pill btn-primary btn-w-md py-2 ct">
                                    {{ $t('LandingPage.Login') }}
                                </a>
                                <a v-on:click="GoTo('/Register')" href="javascript:void(0)"
                                    class="btn btn-pill btn-primary btn-w-md py-2 ms-1 ct">
                                    {{ $t('LandingPage.Signup') }}
                                </a>

                                <a v-on:click="GoTo('/ContactUs')"
                                    class=" btn btn-pill btn-primary btn-w-md py-2 ms-1 ct">{{ $t('LandingPage.ContactUs')
                                    }}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom_nav_bar green_bg_nav">
                    <div class="row">
                        <div class="col-sm-8 col-lg-10 col-8  nav_green">

                            <ul class="navbar-nav mr-auto lg-nav">

                                <li class="nav-item d-flex ">
                                    <div class="nav-link">
                                        <a class="text_wh" @click="GoTo('/TendencyScale')" href="javascript:void(0)">
                                            <img class="nav_img" src="/assets/images/landing/icon26.png" alt="مقياس الميول">
                                            مقياس الميول
                                        </a>
                                    </div>
                                </li>

                                <li class="nav-item d-flex ">
                                    <div class="nav-link">
                                        <a class="text_wh"
                                            href="https://subol.sa/Dashboard/FutureOccupations/Index?isInJourney=false)">
                                            <img class="nav_img" src="/assets/images/landing/icon25.png" alt="مهن المستقبل">
                                            مهن المستقبل
                                        </a>

                                    </div>
                                </li>
                                <li class="nav-item d-flex dropdown">
                                    <div class="nav-link">
                                        <a class="text_wh" href="https://subol.sa/Dashboard/PGT?isInJourney=false">
                                            <img class="nav_img" src="/assets/images/landing/icon23.png"
                                                alt="أدوات الإرشاد المهني">
                                            أدوات الإرشاد المهني
                                        </a>
                                    </div>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item"
                                            href="https://subol.sa/Dashboard/Articles?articleType=4&isInJourney=False">قصص
                                            تشجيعية</a>
                                        <a class="dropdown-item"
                                            href="https://subol.sa/Dashboard/Articles?articleType=5&isInJourney=False">مهارات
                                            إعداد السيرة الذاتية</a>
                                        <a class="dropdown-item"
                                            href="https://subol.sa/Dashboard/Articles?articleType=6&isInJourney=False">مقالات
                                            تثقيفية</a>
                                        <a class="dropdown-item"
                                            href="https://subol.sa/Dashboard/Articles?articleType=7&isInJourney=False">مهارات
                                            المقابلة الشخصية</a>
                                        <a class="dropdown-item"
                                            href="https://subol.sa/Dashboard/Articles/Standards?articleType=9&isInJourney=False">معايير
                                            التثقيف والارشاد المهني</a>
                                    </div>
                                </li>
                                <li class="nav-item d-flex ">
                                    <div class="nav-link">
                                        <a class="text_wh"
                                            href="https://subol.sa/Dashboard/PGT/CommunicatePgOptions?isInJourney=false">
                                            <img class="nav_img" src="/assets/images/landing/icon25.png"
                                                alt="تواصل مع المرشد المهني)">
                                            تواصل مع المرشد المهني
                                        </a>
                                        <span class="navbar-label--new" style="color: red;font-size: 60%;">جديد</span>
                                    </div>
                                </li>
                            </ul>

                            <div class="btn-group mt-2 mb-2 mb-nav text-center">
                                <button type="button" class="dropdown-toggle" data-bs-toggle="dropdown"
                                    aria-expanded="false" style="color:#fff">
                                    خدمات سبل
                                </button>
                                <ul class="dropdown-menu" role="menu"
                                    style="background: rgba(29, 181, 7, 0.7); max-width:200px">

                                    <li class="nav-item d-flex ">
                                        <div class="nav-link">
                                            <a class="text_wh" href="javascript:void(0)" @click="GoTo('/TendencyScale')">
                                                <img class="nav_img" src="/assets/images/landing/icon26.png"
                                                    alt="مقياس الميول">
                                                مقياس الميول
                                            </a>
                                        </div>
                                    </li>
                                    <li class="nav-item d-flex ">
                                        <div class="nav-link">
                                            <a class="text_wh"
                                                href="https://subol.sa/Dashboard/FutureOccupations/Index?isInJourney=false">
                                                <img class="nav_img" src="/assets/images/landing/icon25.png"
                                                    alt="مهن المستقبل">
                                                مهن المستقبل
                                            </a>

                                        </div>
                                    </li>
                                    <li class="nav-item d-flex dropdown">
                                        <div class="nav-link">
                                            <a class="text_wh" href="https://subol.sa/Dashboard/PGT?isInJourney=false">
                                                <img class="nav_img" src="/assets/images/landing/icon23.png"
                                                    alt="أدوات الإرشاد المهني">
                                                أدوات الإرشاد المهني
                                            </a>
                                        </div>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item"
                                                href="https://subol.sa/Dashboard/Articles?articleType=4&isInJourney=False">قصص
                                                تشجيعية</a>
                                            <a class="dropdown-item"
                                                href="https://subol.sa/Dashboard/Articles?articleType=5&isInJourney=False">مهارات
                                                إعداد السيرة الذاتية</a>
                                            <a class="dropdown-item"
                                                href="https://subol.sa/Dashboard/Articles?articleType=6&isInJourney=False">مقالات
                                                تثقيفية</a>
                                            <a class="dropdown-item"
                                                href="https://subol.sa/Dashboard/Articles?articleType=7&isInJourney=False">مهارات
                                                المقابلة الشخصية</a>
                                            <a class="dropdown-item"
                                                href="https://subol.sa/Dashboard/Articles/Standards?articleType=9&isInJourney=False">معايير
                                                التثقيف والارشاد المهني</a>
                                        </div>
                                    </li>
                                    <li class="nav-item d-flex ">
                                        <div class="nav-link">
                                            <a class="text_wh"
                                                href="https://subol.sa/Dashboard/PGT/CommunicatePgOptions?isInJourney=false">
                                                <img class="nav_img" src="/assets/images/landing/icon25.png"
                                                    alt="تواصل مع المرشد المهني)">
                                                تواصل مع المرشد المهني
                                            </a>
                                            <span class="navbar-label--new" style="color: red;font-size: 60%;">جديد</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-2 col-4 nav_green_search">
                            <div class="form-inline search_bg" id="searchform">
                                <span class="mx-2 nav_green_srch">
                                    <button class=" btn-search-in-header">
                                        <img src="/assets/images/landing/search.png" alt="search">
                                    </button>

                                </span>
                                <input name="term" style="color:#fff!important;padding:0px;"
                                    class="form-control ls mr-sm-2 green_search" type="search" placeholder="بحث....."
                                    aria-label="Search" is-init="true">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- /app-Header -->
            <!-- /app-banner -->
            <div class="container" style="padding:0px">
                <div class="card">
                    <div class="card-header border-bottom">
                        <h3 class="card-title font-weight-bold"><strong>الأسئلة الشائعة لخدمة المستفيدين</strong></h3>
                    </div>
                    <div class="card-body">
                        
                        <div aria-multiselectable="true" class="accordion" id="accordion" role="tablist">
                            <div class="card border-0">
                                <div class="card-header border-bottom-0" id="headingOne" role="tab"> <a
                                        aria-controls="collapseOne" aria-expanded="true" data-bs-toggle="collapse"
                                        href="#collapseOne" class="accor-basic  active"> <strong>السؤال #1 ما هو برنامج الارشاد المهني في الجامعات؟ </strong></a> </div>
                                <div aria-labelledby="headingOne" class="collapse show" data-bs-parent="#accordion"
                                    id="collapseOne" role="tabpanel" style="">
                                    <div class="card-body br-bottom-radius-5"> <strong>الجواب:</strong>التشارك والتكامل مع الجامعات لتعزيز مفهوم الارشاد المهني من خلال انشاء مكاتب للإرشاد المهني في الجامعات بهدف موائمة مخرجات التعليم مع متطلبات سوق العمل وتمكين الطلاب والطالبات والخريجين من التعرف على مهارات ومتطلبات سوق العمل الحالي والمستقبلي </div>
                                </div>
                            </div>
                            <div class="card  border-0">
                                <div class="card-header border-bottom-0" id="headingtwo" role="tab"> <a
                                        aria-controls="collapsetwo" aria-expanded="false" data-bs-toggle="collapse"
                                        href="#collapsetwo" class="accor-basic collapsed"> <strong>السؤال #2 ما هي أهداف برنامج الارشاد المهني في الجامعات؟ </strong></a> </div>
                                <div aria-labelledby="headingtwo" class="collapse" data-bs-parent="#accordion"
                                    id="collapsetwo" role="tabpanel" style="">
                                    <div class="card-body br-bottom-radius-5"> <strong>الجواب:</strong>- رفع مستوى الخدمات المقدمة في الإرشاد المهني للطلبة والخريجين.
- رفع مستوى المعرفة للميول والقدرات الذاتية للمستفيدين وربطها بجميع التخصصات والمهن ومساراتها واحتياجات سوق العمل.
- تطوير مهارات التخطيط المهني لدى الطلاب ومساعدتهم على اتخاذ القرارات التعليمية والمهنية المناسبة للحصول على الوظيفة.
- المساهمة في رفع الوعي حول أهمية جميع المهن وتحسين الصورة الذهنية عن ثقافة العمل وأهميته.
- رفع الوعي حول المهارات المطلوبة في سوق العمل والمهن المستقبلية.
 </div>
                                </div>
                            </div>
                            <div class="card  border-0">
                                <div class="card-header border-bottom-0" id="headingthree" role="tab"> <a
                                        aria-controls="collapsethree" aria-expanded="false" data-bs-toggle="collapse"
                                        href="#collapsethree" class="accor-basic collapsed"> <strong>السؤال #3 ما هي الفئة المستهدفة؟</strong></a> </div>
                                <div aria-labelledby="headingthree" class="collapse" data-bs-parent="#accordion"
                                    id="collapsethree" role="tabpanel" style="">
                                    <div class="card-body br-bottom-radius-5"> <strong>الجواب:</strong> - طلاب الجامعة
-  الخـــــــريجين
- الكادر التعليمي والاداري
 </div>
                                </div>
                            </div>
                            <div class="card border-0">
                                <div class="card-header border-bottom-0" id="headingfour" role="tab"> <a
                                        aria-controls="collapsefour" aria-expanded="false" data-bs-toggle="collapse"
                                        href="#collapsefour" class="accor-basic collapsed"><strong>السؤال #4 هل هناك ورش عمل تقام في الجامعات؟</strong></a> </div>
                                <div aria-labelledby="headingfour" class="collapse" data-bs-parent="#accordion"
                                    id="collapsefour" role="tabpanel" style="">
                                    <div class="card-body br-bottom-radius-5"> <strong>الجواب:</strong>نعم،   </div>
                                </div>
                            </div>
                            <div class="card  border-0">
                                <div class="card-header border-bottom-0" id="headingfive" role="tab"> <a
                                        aria-controls="collapsefive" aria-expanded="false" data-bs-toggle="collapse"
                                        href="#collapsefive" class="accor-basic collapsed"><strong>السؤال #5 هل هناك ورش عمل عن بُعد؟</strong></a> </div>
                                <div aria-labelledby="headingfive" class="collapse" data-bs-parent="#accordion"
                                    id="collapsefive" role="tabpanel" style="">
                                    <div class="card-body br-bottom-radius-5"> <strong>الجواب:</strong>نعم،   </div>
                                </div>
                            </div>
                            <div class="card border-0">
                                <div class="card-header border-bottom-0" id="headingsix" role="tab"> <a
                                        aria-controls="collapsesix" aria-expanded="false" data-bs-toggle="collapse"
                                        href="#collapsesix" class="accor-basic collapsed"><strong>السؤال #6 هل يوجد شهادات حضور لورش العمل؟</strong></a> </div>
                                <div aria-labelledby="headingsix" class="collapse" data-bs-parent="#accordion"
                                    id="collapsesix" role="tabpanel" style="">
                                    <div class="card-body br-bottom-radius-5">   <strong>الجواب:</strong>  نعم. يوجد شهادات حضور لورش العمل معتمدة من صندوق تنمية الموارد البشرية</div>
                                </div>
                            </div>
                            <div class="card  border-0">
                                <div class="card-header border-bottom-0" id="headingseven" role="tab"> <a
                                        aria-controls="collapseseven" aria-expanded="false" data-bs-toggle="collapse"
                                        href="#collapseseven" class="accor-basic collapsed"><strong>السؤال #7 ما هي طريقة التسجيل في خدمة الإرشاد المهني في الجامعات؟</strong></a> </div>
                                <div aria-labelledby="headingseven" class="collapse" data-bs-parent="#accordion"
                                    id="collapseseven" role="tabpanel" style="">
                                    <div class="card-body br-bottom-radius-5">  <strong>الجواب:</strong> يمكنك التسجيل عن طريق زيارة الرابط www.suboluniv.com </div>
                                </div>
                            </div>
                            <div class="card  border-0">
                                <div class="card-header border-bottom-0" id="headingeight" role="tab"> <a
                                        aria-controls="collapseeight" aria-expanded="false" data-bs-toggle="collapse"
                                        href="#collapseeight" class="accor-basic collapsed"><strong>السؤال #8 أواجه مشكلة في الدخول لحسابي، ماذا أفعل؟</strong></a> </div>
                                <div aria-labelledby="headingeight" class="collapse" data-bs-parent="#accordion"
                                    id="collapseeight" role="tabpanel" style="">
                                    <div class="card-body br-bottom-radius-5"> <strong>الجواب:</strong> يمكنك التواصل مع الدعم الفني من خلال الرابط www.suboluniv.com/ContactUs  </div>
                                </div>
                            </div>
                            <div class="card  border-0">
                                <div class="card-header border-bottom-0" id="headingnine" role="tab"> <a
                                        aria-controls="collapsenine" aria-expanded="false" data-bs-toggle="collapse"
                                        href="#collapsenine" class="accor-basic collapsed"><strong>السؤال #9 هل يمكنني التسجيل في أكثر من جلسة إرشادية؟</strong></a> </div>
                                <div aria-labelledby="headingnine" class="collapse" data-bs-parent="#accordion"
                                    id="collapsenine" role="tabpanel" style="">
                                    <div class="card-body br-bottom-radius-5"> <strong>الجواب:</strong> نعم، يمكنك ذلك  </div>
                                </div>
                            </div>
                            <div class="card  border-0">
                                <div class="card-header border-bottom-0" id="headingten" role="tab"> <a
                                        aria-controls="collapseten" aria-expanded="false" data-bs-toggle="collapse"
                                        href="#collapseten" class="accor-basic collapsed"><strong>السؤال #10 هل يمكنني التسجيل في اكثر من ورشة عمل؟</strong></a> </div>
                                <div aria-labelledby="headingten" class="collapse" data-bs-parent="#accordion"
                                    id="collapseten" role="tabpanel" style="">
                                    <div class="card-body br-bottom-radius-5"> <strong>الجواب:</strong>نعم يمكنك ذلك  </div>
                                </div>
                            </div>
                            <div class="card  border-0">
                                <div class="card-header border-bottom-0" id="headingeleven" role="tab"> <a
                                        aria-controls="collapseeleven" aria-expanded="false" data-bs-toggle="collapse"
                                        href="#collapseeleven" class="accor-basic collapsed"><strong>السؤال #11 ماهي خدمات الارشاد المهني التي تقدم في برنامج الإرشاد المهني في الجامعات؟</strong></a> </div>
                                <div aria-labelledby="headingeleven" class="collapse" data-bs-parent="#accordion"
                                    id="collapseeleven" role="tabpanel" style="">
                                    <div class="card-body br-bottom-radius-5">  <strong>الجواب:</strong>- جلسات ارشاد مهني فردية
- جلسات ارشاد مهني جماعية
- ورش عمل</div>
                                </div>
                            </div>
                            <div class="card  border-0">
                                <div class="card-header border-bottom-0" id="headingtwelve" role="tab"> <a
                                        aria-controls="collapsetwelve" aria-expanded="false" data-bs-toggle="collapse"
                                        href="#collapsetwelve" class="accor-basic collapsed"><strong>السؤال #12 كيف تصلني الشهادة الورش التدريبية ؟</strong></a> </div>
                                <div aria-labelledby="headingtwelve" class="collapse" data-bs-parent="#accordion"
                                    id="collapsetwelve" role="tabpanel" style="">
                                    <div class="card-body br-bottom-radius-5">  <strong>الجواب:</strong>عن طريق منصة سبل للإرشاد المهني في الجامعات www.suboluniv.com  </div>
                                </div>
                            </div>
                            <div class="card border-0">
                                <div class="card-header border-bottom-0" id="headingthirteen" role="tab"> <a
                                        aria-controls="collapsethireteen" aria-expanded="false" data-bs-toggle="collapse"
                                        href="#collapsethireteen" class="accor-basic collapsed"><strong>السؤال #13 هل هناك مؤهلات محددة للانضمام الى منصة سبل للإرشاد المهني في  للجامعات ؟</strong></a> </div>
                                <div aria-labelledby="headingthirteen" class="collapse" data-bs-parent="#accordion"
                                    id="collapsethireteen" role="tabpanel" style="">
                                    <div class="card-body br-bottom-radius-5"> <strong>الجواب:</strong>   دبلوم – بكالوريوس – ماجستير – دكتوراه كادر تعليمي واداري في الجامعات    </div>
                                </div>
                            </div>
                            <div class="card  border-0">
                                <div class="card-header border-bottom-0" id="headingfourteen" role="tab"> <a
                                        aria-controls="collapsefourteen" aria-expanded="false" data-bs-toggle="collapse"
                                        href="#collapsefourteen" class="accor-basic collapsed"><strong>السؤال #14 كم مدة الورشة التدريبية ؟</strong></a> </div>
                                <div aria-labelledby="headingfourteen" class="collapse" data-bs-parent="#accordion"
                                    id="collapsefourteen" role="tabpanel" style="">
                                    <div class="card-body br-bottom-radius-5">  <strong>الجواب:</strong> ساعه واحدة  </div>
                                </div>
                            </div>
                            <div class="card  border-0">
                                <div class="card-header border-bottom-0" id="headingfifteen" role="tab"> <a
                                        aria-controls="collapsefifteen" aria-expanded="false" data-bs-toggle="collapse"
                                        href="#collapsefifteen" class="accor-basic collapsed"><strong>السؤال #15 هل يحق لي ان احضر أكثر من مرة ورش تدريبية؟</strong></a> </div>
                                <div aria-labelledby="headingfifteen" class="collapse" data-bs-parent="#accordion"
                                    id="collapsefifteen" role="tabpanel" style="">
                                    <div class="card-body br-bottom-radius-5">  <strong>الجواب:</strong> نعم  </div>
                                </div>
                            </div>
                            <div class="card  border-0">
                                <div class="card-header border-bottom-0" id="headingsixteen" role="tab"> <a
                                        aria-controls="collapsesixteen" aria-expanded="false" data-bs-toggle="collapse"
                                        href="#collapsesixteen" class="accor-basic collapsed"><strong>السؤال #16 ماهي الطريقة التي استطيع ان احصل على شهادة الورشة التدريبية في حال لم تصلني ؟</strong></a> </div>
                                <div aria-labelledby="headingsixteen" class="collapse" data-bs-parent="#accordion"
                                    id="collapsesixteen" role="tabpanel" style="">
                                    <div class="card-body br-bottom-radius-5"> <strong>الجواب:</strong> يمكنك التواصل مع الدعم الفني من خلال الرابط www.suboluniv.com/ContactUs  </div>
                                </div>
                            </div>
                            <div class="card border-0">
                                <div class="card-header border-bottom-0" id="headingseventeen" role="tab"> <a
                                        aria-controls="collapseseventeen" aria-expanded="false" data-bs-toggle="collapse"
                                        href="#collapseseventeen" class="accor-basic collapsed"><strong>السؤال #17 هل يوجد شهادات حضور لجلسات الارشاد الجماعي والفردي؟</strong></a> </div>
                                <div aria-labelledby="headingseventeen" class="collapse" data-bs-parent="#accordion"
                                    id="collapseseventeen" role="tabpanel" style="">
                                    <div class="card-body br-bottom-radius-5"> <strong>الجواب:</strong>  لا يوجد شهادات حضور لجلسات الارشاد المهني الجماعي و الفردي </div>
                                </div>
                            </div>
                            <div class="card border-0">
                                <div class="card-header border-bottom-0" id="headingeighteen" role="tab"> <a
                                        aria-controls="collapseeighteen" aria-expanded="false" data-bs-toggle="collapse"
                                        href="#collapseeighteen" class="accor-basic collapsed"><strong>السؤال #18 ما المقصود بالجلسات الجماعية ؟</strong></a> </div>
                                <div aria-labelledby="headingeighteen" class="collapse" data-bs-parent="#accordion"
                                    id="collapseeighteen" role="tabpanel" style="">
                                    <div class="card-body br-bottom-radius-5"> <strong>الجواب:</strong> علاقة إرشادية بين المرشد المهني ومجموعة من الطلبة لهم نفس الاهتمام حول موضوع معين، يتم مناقشته لزيادة استبصار هم والعمل على إبجاد الخيارات والحلول الأمثل.  </div>
                                </div>
                            </div>
                           
                           


                            <div class="card  border-0">
                                <div class="card-header border-bottom-0" id="headingnineteen" role="tab"> <a
                                        aria-controls="collapsenineteen" aria-expanded="false" data-bs-toggle="collapse"
                                        href="#collapsenineteen" class="accor-basic collapsed"> <strong class="">السؤال # 19 كيف لي أن اكون على دراية بورش العمل المقدمة من برنامج الإرشاد المهني بالجامعات؟</strong> </a> </div>
                                <div aria-labelledby="headingnineteen" class="collapse" data-bs-parent="#accordion"
                                    id="collapsenineteen" role="tabpanel" style="">
                                    <div class="card-body br-bottom-radius-5">  <strong>الجواب:</strong> من خلال التسجيل بمنصة الإرشاد المهني بالجامعات ، يصلك إشعار على البريد الالكتروني  </div>
                                </div>
                            </div>
                           
                            
                        </div><!-- accordion -->
                    </div>
                </div>
            </div>

        </div>

        <div class="footer_bg position-relative">

            <div class="position-relative">

                <img src="/assets/images/landing/bott-left-green-footer.png" class="bottLeftGreenFooter"
                    alt="bott-left-green-footer">
                <img src="/assets/images/landing/bott-left-orange-footer.png" class="bottLeftOrangeFooter"
                    alt="bott-left-orange-footer">
                <img src="/assets/images/landing/bott-right-orange-footer.png" class="bottRightOrangeFooter"
                    alt="bott-right-orange-footer">
                <img src="/assets/images/landing/bott-right-blue-footer.png" class="bottRightBlueFooter"
                    alt="bott-right-blue-footer">
                <div class="text-center text-light mb-0 bottomFooter">
                    {{ $t('LandingPage.Footer') }} {{ currentDate }} ©
                </div>
            </div>

        </div>
        <!-- Footer close -->
    </div>
</template>
<script >



export default {
    name: "FandQs",
    data: function () {
        return {
            video: false,
            comp: false,
            currentDate: ''
        }
    },
    methods: {
        GoTo: function (link) {
            this.$router.push(link);
            this.comp = true;
        },
    },
    mounted: function () {
        if (this.$route.path == "/TendencyScale") {
            this.comp = true;
        }
        this.currentDate = new Date().getFullYear();
    }
};
</script>
<style scoped>
@font-face {
    font-family: kufi;
    src: url(/assets/Droid_Kufi.ttf);
}

.page {
    font-family: kufi;
}


.landing-nav {
    padding: 20px 0px 20px 0px;
}

.hor-header {
    background: transparent;
}

.header {
    border-bottom: none !important;
}

.banner_txt {
    padding: 0px !important;
}

.text_wh {
    color: #fff !important;
}

.green_bg_nav {
    background-image: url("/assets/images/landing/nv_des.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.nav_green {
    padding-right: 4rem;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.navbar-nav .nav-link {
    margin-left: 0.1875rem;
    margin-right: 0.1875rem;
    filter: drop-shadow(0px 0px 5px darkgreen);
}

.dropdown-menu {
    position: absolute !important;
    left: -74px !important;
    border-radius: 10px;
    padding: 10px;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #009000 !important;
}


.form-inline {
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
}

.nav_green_search {
    padding: 0px !important;
    display: flex;
}

.green_search {
    max-width: 120px;
    background: transparent !important;
    border: none !important;
}

.mb-nav {
    display: none
}

.nav-link.active,
.nav-link:hover,
.nav-link.focus {
    background-image: url("/assets/images/landing/dd1.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.vll {
    border-left: 0.125rem solid #009000;
    padding-right: 0.2rem;
    padding-left: 0.2rem;
    font-size: 1rem;
}

#firstUl {
    align-items: flex-end;
    padding-right: 10px;
    display: none;
}

.vjs-big-play-button {
    width: 160px !important;
    height: 155px !important;
    background: url("/assets/images/landing/play-btn.png") -190px -10px;
    transition: none;
    border: 0;
}

.vjs-big-play-button:hover {
    background-position: -0.625rem -0.625rem;
}

.video_container {
    position: relative;
    width: 100%;
    height: 100vh;
    max-height: 630px;
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 630px;
}


.banner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
}

.demo-footer {
    background-color: #092f57;
}

.btn-outline-primary {
    color: #009000 !important;
    border: 1px solid #009000;
}

.btn-outline-primary:hover {
    color: white !important;
}

.parent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    padding-bottom: 50px;
}

.parent p {
    color: white;
    margin-top: 56px;
    font-size: 16px;
}

.banner h1 {
    font-weight: 700;
    font-size: 58px;
}

.banner p {
    margin-top: 30px;
    font-size: 16px;
}

.con {
    color: white !important;
    margin-top: 20px;
    padding: 20px;
    cursor: pointer;
}

.header {
    padding-bottom: 0px !important;
}

.main-container {
    padding-bottom: 1rem !important;
}

.inner {
    margin-top: 150px;
}

.dropdown-item {
    font-size: 1rem;
    line-height: 2rem;
}

.green_search::placeholder {
    color: #fff;
    font-size: 1rem;
}

.banner_txt p {
    font-weight: 300;
    line-height: 2;
    font-size: 1.0rem;
    font-family: kufi;
}

.footer_bg {
    background-color: #093254;
    text-align: center !important;
}

.pt-5 {
    padding-top: 3rem !important;
}

.pb-5 {
    padding-bottom: 3rem !important;
}

.position-relative {
    position: relative !important;
}

img.topLeftFooter {
    position: absolute;
    left: 0;
    top: -2.5rem;
}

.footer_img {
    width: 7rem;
}

img.bottLeftGreenFooter {
    position: absolute;
    left: 0;
    top: -2.1875rem;
    z-index: 3;
}

img.bottLeftOrangeFooter {
    left: 1.25rem;
    position: absolute;
    z-index: 1;
    top: -2.1875rem;
}

img.bottRightOrangeFooter {
    position: absolute;
    z-index: 1;
    right: 3.75rem;
    top: -1.875rem;
}

img.bottRightBlueFooter {
    position: absolute;
    z-index: 3;
    top: -3.125rem;
    right: 0;
}

.bottomFooter {
    background: #001b39;
    z-index: 2;
    position: relative;
    padding: 1rem;
}

.text-light {
    color: #fff !important;
    font-size: 1rem !important;
    line-height: 2rem;
}

.unified-number {
    background: #fff !important;
    color: #212529 !important;
}

#forHide {
    display: none;
}

@media screen and (min-width: 1500px) {
    .navbar-nav .nav-link {
        padding-left: 2.15rem;
        padding-right: 2.15rem;
        font-size: 1.2rem;
    }
}

@media screen and (max-width: 1180px) {
    .header.hor-header {
        position: sticky !important;
    }

    .lg-nav {
        display: none;
    }

    .mb-nav {
        display: block;
    }
}

@media screen and (max-width: 780px) {
    #hide {
        display: none;
    }

    #forHide {
        display: block;
    }

    .navresponsive-toggler {
        display: none;
    }

    #navbarSupportedContent-4 {
        display: none;
    }

    .parent {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 4fr;
    }

    .parent p {
        margin-top: 36px;
    }

    .btn {
        min-width: 85px !important;
        padding: 3px !important;
        border: 1px solid #009000;
        color: #009000 !important;
    }

    .ct {
        color: white !important;
    }

    .green_bg_nav {
        background-image: url("/assets/images/landing/nv_mob.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }


    .green_search {
        max-width: 65px
    }

    .inner {
        margin-top: 50px;
    }

    .banner_txt {
        padding: 30px !important;
    }

    .nav_green_srch {
        display: none;
    }
}

.ft {
    margin-top: 40px !important;
    margin-right: 200px;
}

.copy {
    background-color: #001b39;
    color: white;
    text-align: center;
}

.link_hov {
    color: #019000 !important;
}

.link_hov:hover {
    color: #fff !important;
}

</style>

