<template>
    <div class="main-reviewerdashboard">
        <div class="page-header">
            <div>
                <h1 class="page-title">{{$t('ReviewerDashboard.Heading')}}</h1>
            </div>
            <div class="ms-auto pageheader-btn">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">{{$t('ReviewerDashboard.Heading')}}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{$t('ReviewerDashboard.Dashboard')}}</li>
                </ol>
            </div>
        </div>

        <div class="card border-top">
            <div class="wideget-user-tab">
                <div class="tab-menu-heading">
                    <div class="tabs-menu1">
                        <ul class="nav">
                            <li><a href="#calender" class="active show" data-bs-toggle="tab">{{$t('ReviewerDashboard.Evaluation')}}</a></li>
                            <li><a href="#editProfile" data-bs-toggle="tab">{{$t('ReviewerDashboard.Dashboard')}}</a></li>
                            <li><a href="#email" @click="isFirstSurvey=true" data-bs-toggle="tab">{{$t('ReviewerDashboard.SessionReports')}}</a></li>
                            <li><a href="#SecondSurvey" @click="isSecondSurvey=true" data-bs-toggle="tab">{{$t('ReviewerDashboard.SessionSecondSurvey')}}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-content">
            <div class="tab-pane" id="SecondSurvey">
                <div class="row">
                    <div class="col-12 col-sm-12 ">

                        <reviewer-feedback-session :survey="'second'" v-if="isSecondSurvey==true" />

                        <div class="card">
                            <div class="card-header border-bottom">
                                <h3 class="card-title">{{$t('ReviewerDashboard.Averagesessioncompleted')}}</h3>
                            </div>
                            <div class="card-body">
                                <div id="chart" :key="render">
                                    <apexchart type="bar" height="350" :options="chartOptions5" :series="series5"></apexchart>
                                </div>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header border-bottom">
                                <h3 class="card-title">{{$t('ReviewerDashboard.Completedevaluationrate')}}</h3>
                            </div>
                            <div class="card-body">
                                <div id="chart">
                                    <apexchart type="pie" width="380" :options="chartOptions6" :series="series6"></apexchart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="email">
                <div class="row">
                    <div class="col-12 col-sm-12 ">

                        <reviewer-feedback-session :survey="'first'" v-if="isFirstSurvey==true" />

                        <div class="card">
                            <div class="card-header border-bottom">
                                <h3 class="card-title">{{$t('ReviewerDashboard.Averagesessioncompleted')}}</h3>
                            </div>
                            <div class="card-body">
                                <div id="chart" :key="render">
                                    <apexchart type="bar" height="350" :options="chartOptions3" :series="series3"></apexchart>
                                </div>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header border-bottom">
                                <h3 class="card-title">{{$t('ReviewerDashboard.Completedevaluationrate')}}</h3>
                            </div>
                            <div class="card-body">
                                <div id="chart">
                                    <apexchart type="pie" width="380" :options="chartOptions4" :series="series4"></apexchart>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane" id="editProfile">
                <div class="row">
                    <div class="col-12 col-sm-12 ">

                        <div class="card">
                            <div class="card-header border-bottom">
                                <h3 class="card-title">{{$t('ReviewerDashboard.Universities')}}</h3>
                            </div>
                            <div class="card-body">

                                <div id="chart">
                                    <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
                                </div>

                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header border-bottom d-flex flex-column align-items-baseline">
                                <h3 class="card-title">{{$t('ReviewerDashboard.Sessions')}}</h3>

                                <div class="btn-list mt-3">
                                    <a v-on:click="Details('Day')" href="javascript:void(0)" class="btn btn-primary">{{$t('ReviewerDashboard.Day')}}</a>
                                    <a v-on:click="Details('Month')" href="javascript:void(0)" class="btn btn-primary">{{$t('ReviewerDashboard.Month')}}</a>
                                    <!--<a v-on:click="Details('Year')" href="javascript:void(0)" class="btn btn-primary">{{$t('ReviewerDashboard.Year')}}</a>-->
                                </div>
                            </div>
                            <div class="card-body">
                                <div id="chart" :key="render">
                                    <apexchart type="area" height="350" :options="chartOptions1" :series="series1"></apexchart>
                                </div>
                            </div>
                        </div>

                        <div class="card">
                            <div class="card-header border-bottom">
                                <h3 class="card-title">{{$t('ReviewerDashboard.SessionPercentage')}}</h3>
                            </div>
                            <div class="card-body">

                                <div id="chart">
                                    <apexchart type="pie" width="380" :options="chartOptions2" :series="series2"></apexchart>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="tab-pane active show" id="calender">
                <div class="row">
                    <div class="col-md-12 col-xl-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="apexcharts-legend apexcharts-align-center apx-legend-position-bottom" xmlns="http://www.w3.org/1999/xhtml" style="inset: auto 0px 1px; position: absolute; max-height: 175px;">
                                    <div class="apexcharts-legend-series" rel="1" style="margin: 2px 5px;">
                                        <span class="apexcharts-legend-marker" rel="1" style="background: #009000 !important; color: #009000; height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 2px; "></span>
                                        <span class="apexcharts-legend-text" rel="1" style="color: rgb(55, 61, 63); font-size: 12px; font-weight: 400;">الجلسات المنتهية</span>
                                    </div>
                                    <div class="apexcharts-legend-series" rel="2" style="margin: 2px 5px;">
                                        <span class="apexcharts-legend-marker" rel="2"  style="background: #F3AB2C !important; color: #F3AB2C; height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 2px; "></span>
                                        <span class="apexcharts-legend-text" rel="2" style="color: rgb(55, 61, 63); font-size: 12px; font-weight: 400;">المواعيد المجدولة</span>
                                    </div>
                                    <div class="apexcharts-legend-series" rel="3" seriesname="إعطاءxالمسح" data:collapsed="false" style="margin: 2px 5px;">
                                        <span class="apexcharts-legend-marker" style="background: #818285 !important; color: #818285; height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 2px; "></span>
                                        <span class="apexcharts-legend-text" rel="3" da style="color: rgb(55, 61, 63); font-size: 12px; font-weight: 400;">الجلسات المعلقة</span>
                                    </div>
                                </div>

                                <full-calendar :events="events" :config="config" @event-created="eventRender()"></full-calendar>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script src="https://cdn.jsdelivr.net/npm/apexcharts"></script>

<script>
    import VueApexCharts from 'vue-apexcharts'
    import { FullCalendar } from 'vue-full-calendar'
    import 'fullcalendar/dist/fullcalendar.css'


    export default {
        name: 'ReviewerDashboard',
        components: {
            apexchart: VueApexCharts,
            FullCalendar
        },
        data: function () {
            return {
                render: 0,
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                pageSize: '',
                loading: false,
                isSecondSurvey: false,
                isFirstSurvey: false,
                reviewerDashboardList: [],

                events: [],
                config: {
                    buttonText: {
                        today: 'اليوم',
                        month: 'شهري',
                        week: 'اسبوعي',
                        day: 'يومي',
                        list: 'قائمة'
                    },
                    locale: 'ar',
                    navLinks: true,
                    nowIndicator: true,
                    selectable: true,
                    selectMirror: true,
                    headerToolbar: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
                    },
                    defaultView: 'month'
                },

                //Chart 1 Universities
                series: [{
                    name: 'الجلسات المعلقة',
                    data: []
                },
                {
                    name: 'المواعيد المجدولة',
                    data: []
                }, {
                    name: 'الجلسات المنتهية',
                    data: []
                }],
                chartOptions: {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: [],
                    },
                    yaxis: {
                        title: {
                            text: 'الجامعات'
                        }
                    },
                    fill: {
                        opacity: 1
                    },

                },

                //Chart 2 Sessions
                series1: [{
                    name: 'الجلسات',
                    data: []
                }],
                chartOptions1: {
                    colors: ['#f2bd12', '#f2bd12', '#f2bd12'],
                    chart: {
                        height: 350,
                        type: 'area'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    xaxis: {
                        type: '',
                        categories: []
                    },
                },

                //Chart 3 SessionPieChart
                series2: [],
                chartOptions2: {
                    chart: {
                        width: 380,
                        type: 'pie',
                    },
                    labels: ['الجلسات المعلقة', 'المواعيد المجدولة', 'الجلسات المنتهية'],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },

                // Chart 4 Session Reports

                series3: [{
                    name: 'المسح المطلوب',
                    data: []
                },
                {
                    name: 'إعطاء المسح',
                    data: []
                }],
                chartOptions3: {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: [],
                    },
                    yaxis: {
                        title: {
                            text: 'الجامعات'
                        }
                    },
                    fill: {
                        opacity: 1
                    },

                },

                series5: [{
                    name: 'المسح المطلوب',
                    data: []
                },
                {
                    name: 'إعطاء المسح',
                    data: []
                }],
                chartOptions5: {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: [],
                    },
                    yaxis: {
                        title: {
                            text: 'الجامعات'
                        }
                    },
                    fill: {
                        opacity: 1
                    },

                },

                //Chart 5 Survey Pie Chart
                series4: [],
                chartOptions4: {
                    chart: {
                        width: 380,
                        type: 'pie',
                    },
                    labels: ['المسح المطلوب', 'إعطاء المسح'],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },
                //Chart 6 Second Survey Pie Chart
                series6: [],
                chartOptions6: {
                    chart: {
                        width: 380,
                        type: 'pie',
                    },
                    labels: ['المسح المطلوب', 'إعطاء المسح'],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                },
            }

        },
        watch: {
            search: function () {

            }
        },
        methods: {


            GoTo: function (link) {
                this.$router.push(link);
            },

            getPageSize: function (isDesc) {
                this.isDesc = isDesc;
                this.getPage();
            },

            getPage: function () {

            },

            GetInfo: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Home/ReviewerDashboardMain', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {

                        root.reviewerDashboardList = response.data;

                        // Pie Graph
                        root.events = response.data.sessionCalendar;
                        

                        // Pie Graph
                        root.series2.push(response.data.pendingSession);
                        root.series2.push(response.data.activeSession);
                        root.series2.push(response.data.completedSession);


                      
                        //Colunm Graph
                        root.reviewerDashboardList.universitySessionGraph.forEach((item) => {

                            root.series[0].data.push(
                                item.pendingSession
                            );
                            root.series[1].data.push(
                                item.activeSession
                            );
                            root.series[2].data.push(
                                item.completedSession
                            );
                            root.chartOptions.xaxis.categories.push(item.universityName)
                        });

                
                        // Line Graph
                        root.series1[0].data = [];
                        root.chartOptions1.xaxis.categories = [];
                        root.reviewerDashboardList.sessionLineGraph.forEach((item) => {
                            root.chartOptions1.xaxis.categories.push(
                                item.day
                            );
                            root.series1[0].data.push(
                                item.dayValue
                            );
                        });


                        //Colunm Graph
                        root.reviewerDashboardList.surveyBarGraph.forEach((item) => {
                            root.series3[0].data.push(
                                item.requiredSurvey
                            );
                            root.series3[1].data.push(
                                item.giveSurvey
                            );
                            root.chartOptions3.xaxis.categories.push(item.universityName)
                        });

                        //Second Colunm Graph
                        root.reviewerDashboardList.surveyBarGraph.forEach((item) => {
                            root.series5[0].data.push(
                                item.requiredSurvey
                            );
                            root.series5[1].data.push(
                                item.giveSecondSurvey
                            );
                            root.chartOptions5.xaxis.categories.push(item.universityName)
                        });

                        // Pie Graph
                        root.series4.push(response.data.totalRequiredSurvey);
                        root.series4.push(response.data.totalGiveSurvey);
                        
                        //Second Survey Pie Graph
                        root.series6.push(response.data.totalRequiredSurvey);
                        root.series6.push(response.data.totalSecondGiveSurvey);

                        root.render++;
                    

                    }
                });
            },

            Details: function (type) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Home/ReviewerSessionYearList?type=' + type, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        // Line Graph
                        root.series1[0].data = [];
                        root.chartOptions1.xaxis.categories = [];
                        if (type == 'Day') {
                            response.data.forEach((item) => {
                                root.chartOptions1.xaxis.categories.push(
                                    item.day
                                );
                                root.series1[0].data.push(
                                    item.dayValue
                                );
                            });
                        }
                        if (type == 'Month') {
                            response.data.forEach((item) => {
                                root.chartOptions1.xaxis.categories.push(
                                    item.monthName
                                );
                                root.series1[0].data.push(
                                    item.month
                                );
                            });
                        }
                        root.render++;
                    }
                });
            },
        },
        created: function () {
            this.GetInfo();


        },
        mounted: function () {


        },
    }
</script>
<style scoped>
    .card-header {
        display: flex;
        justify-content: space-between;
    }
</style>
<style>
    .fc .fc-toolbar.fc-header-toolbar {
        display: block !important;
    }

    .fc-icon {
        height: 2em !important;
    }

    .fc .fc-button-group > .fc-button {
        height: 40px !important;
    }

    .fc .fc-button:not(:disabled) {
        height: 40px !important;
    }
</style>



