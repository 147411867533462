<template>
    <div>
        <multiselect v-model="DisplayValue" :close-on-select="false" :options="options" :multiple="true" track-by="name" :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true" :limit="2" v-bind:placeholder=" $t('Dropdown.SelectOption')">
            <p slot="noResult" class="text-danger"> {{ $t('Dropdown.StudentCategory') }}</p>
            <p slot="noOptions" class="text-danger"></p>
        </multiselect>
    </div>
</template>
<script>
    import Multiselect from "vue-multiselect";
    export default {
        name: "studentDropdown",
        props: ["values", "universityId", "gender", "universityLevelIds", "allStudent"],

        components: {
            Multiselect,

        },
        data: function () {
            return {
                options: [],
                value: '',
                render: 0
            }
        },
        watch: {
            universityId: function () {

                this.getData();
            },
            gender: function () {

                this.getData();
            },
            universityLevelIds: function () {

                this.getData();
            },
        },
        methods: {
            getData: function () {

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.get('/account/GetStudentList?isDropDown=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {


                    if (response.data != null) {
                        root.options = [];
                        
                        if (root.allStudent) {
                            response.data.results.forEach(function (cat) {

                                root.options.push({
                                    id: cat.id,
                                    name: cat.fullName
                                });
                            })
                        }
                        else {
                            response.data.results.forEach(function (cat) {
                                
                                if (root.universityId != null && root.gender != 0 && (root.gender == cat.gender || root.gender == 3) && root.universityLevelIds != '' && root.universityLevelIds != null && root.universityLevelIds != undefined && root.universityLevelIds.find((value) => value.id == cat.universityLevelId) != undefined && root.universityId.find((value) => value.id == cat.universityId) != undefined) {

                                    root.options.push({
                                        id: cat.id,
                                        name: cat.fullName
                                    })
                                }

                            })
                        }
                    }
                }).then(function () {
                    root.value = root.options.find(function (x) {
                        return x.id == root.values;
                    })
                    root.$emit('input', root.value);
                });
            },
        },
        computed: {
            DisplayValue: {
                get: function () {
                    if (this.value != "" || this.value != undefined) {
                        return this.value;
                    }
                    return this.values;
                },
                set: function (value) {
                    this.value = value;
                    this.$emit('input', value == null ? null : value);
                }
            }
        },
        mounted: function () {
            this.getData();
        },
    }
</script>