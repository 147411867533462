<template>
    <div class="page-main">

        <!-- app-Header -->
        <div class="header sticky hor-header">
            <div class="main-container container">
                <div class="d-flex">
                    <a aria-label="Hide Sidebar" class="app-sidebar__toggle" data-bs-toggle="sidebar" href="#"></a>
                    <!-- sidebar-toggle-->
                    <a v-if="role=='Student'" v-on:click="GoTo('/')" class=" logo-horizontal" data-bs-toggle="slide" href="javascript:void(0)">
                        <img src="/assets/images/brand/logo.png" class="header-brand-img desktop-logo" alt="logo">
                        <img src="/assets/images/brand/logo-3.png" class="header-brand-img light-logo1"
                             alt="logo">
                    </a>
                    <a v-if="role=='Adviser'" v-on:click="GoTo('/')" class=" logo-horizontal" data-bs-toggle="slide" href="javascript:void(0)">
                        <img src="/assets/images/brand/logo.png" class="header-brand-img desktop-logo" alt="logo">
                        <img src="/assets/images/brand/logo-3.png" class="header-brand-img light-logo1"
                             alt="logo">
                    </a>
                    <a v-if="role=='Super Admin'" v-on:click="GoTo('/')" class=" logo-horizontal" data-bs-toggle="slide" href="javascript:void(0)">
                        <img src="/assets/images/brand/logo.png" class="header-brand-img desktop-logo" alt="logo">
                        <img src="/assets/images/brand/logo-3.png" class="header-brand-img light-logo1"
                             alt="logo">
                    </a>
                    <a v-if="role=='Reviewer'" v-on:click="GoTo('/')" class=" logo-horizontal" data-bs-toggle="slide" href="javascript:void(0)">
                        <img src="/assets/images/brand/logo.png" class="header-brand-img desktop-logo" alt="logo">
                        <img src="/assets/images/brand/logo-3.png" class="header-brand-img light-logo1"
                             alt="logo">
                    </a>
                    <!-- LOGO -->
                    <div class="main-header-center ms-3 d-none d-xl-block">
                        <input class="form-control" :placeholder="$t('LayoutMenu.Search')" type="search">
                        <button class="btn">
                            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24"><path d="M21.2529297,17.6464844l-2.8994141-2.8994141c-0.0021973-0.0021973-0.0043945-0.0043945-0.0065918-0.0065918c-0.8752441-0.8721313-2.2249146-0.9760132-3.2143555-0.3148804l-0.8467407-0.8467407c1.0981445-1.2668457,1.7143555-2.887146,1.715332-4.5747681c0.0021973-3.8643799-3.1286621-6.9989014-6.993042-7.0011597S2.0092773,5.1315308,2.007019,8.9959106S5.1356201,15.994812,9,15.9970703c1.6889038,0.0029907,3.3114014-0.6120605,4.5789185-1.7111206l0.84729,0.84729c-0.6630859,0.9924316-0.5566406,2.3459473,0.3208618,3.2202759l2.8994141,2.8994141c0.4780884,0.4786987,1.1271973,0.7471313,1.8037109,0.7460938c0.6766357,0.0001831,1.3256226-0.2686768,1.803894-0.7472534C22.2493286,20.2558594,22.2488403,18.6417236,21.2529297,17.6464844z M9.0084229,14.9970703c-3.3120728,0.0023193-5.9989624-2.6807861-6.0012817-5.9928589S5.6879272,3.005249,9,3.0029297c1.5910034-0.0026855,3.1175537,0.628479,4.2421875,1.7539062c1.1252441,1.1238403,1.7579956,2.6486206,1.7590942,4.2389526C15.0036011,12.3078613,12.3204956,14.994751,9.0084229,14.9970703z M20.5458984,20.5413818c-0.604126,0.6066284-1.5856934,0.6087036-2.1923828,0.0045166l-2.8994141-2.8994141c-0.2913818-0.2910156-0.4549561-0.6861572-0.4544678-1.0979614C15.0006714,15.6928101,15.6951294,15,16.5507812,15.0009766c0.4109497-0.0005493,0.8051758,0.1624756,1.0957031,0.453125l2.8994141,2.8994141C21.1482544,18.9584351,21.1482544,19.9364624,20.5458984,20.5413818z" /></svg>
                        </button>
                    </div>
                    <div class="d-flex order-lg-2 ms-auto header-right-icons">
                        <div class="dropdown d-xl-none d-md-block d-none">
                            <a href="#" class="nav-link icon" data-bs-toggle="dropdown">
                                <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24"><path fill="" d="M21.2529297,17.6464844l-2.8994141-2.8994141c-0.0021973-0.0021973-0.0043945-0.0043945-0.0065918-0.0065918c-0.8752441-0.8721313-2.2249146-0.9760132-3.2143555-0.3148804l-0.8467407-0.8467407c1.0981445-1.2668457,1.7143555-2.887146,1.715332-4.5747681c0.0021973-3.8643799-3.1286621-6.9989014-6.993042-7.0011597S2.0092773,5.1315308,2.007019,8.9959106S5.1356201,15.994812,9,15.9970703c1.6889038,0.0029907,3.3114014-0.6120605,4.5789185-1.7111206l0.84729,0.84729c-0.6630859,0.9924316-0.5566406,2.3459473,0.3208618,3.2202759l2.8994141,2.8994141c0.4780884,0.4786987,1.1271973,0.7471313,1.8037109,0.7460938c0.6766357,0.0001831,1.3256226-0.2686768,1.803894-0.7472534C22.2493286,20.2558594,22.2488403,18.6417236,21.2529297,17.6464844z M9.0084229,14.9970703c-3.3120728,0.0023193-5.9989624-2.6807861-6.0012817-5.9928589S5.6879272,3.005249,9,3.0029297c1.5910034-0.0026855,3.1175537,0.628479,4.2421875,1.7539062c1.1252441,1.1238403,1.7579956,2.6486206,1.7590942,4.2389526C15.0036011,12.3078613,12.3204956,14.994751,9.0084229,14.9970703z M20.5458984,20.5413818c-0.604126,0.6066284-1.5856934,0.6087036-2.1923828,0.0045166l-2.8994141-2.8994141c-0.2913818-0.2910156-0.4549561-0.6861572-0.4544678-1.0979614C15.0006714,15.6928101,15.6951294,15,16.5507812,15.0009766c0.4109497-0.0005493,0.8051758,0.1624756,1.0957031,0.453125l2.8994141,2.8994141C21.1482544,18.9584351,21.1482544,19.9364624,20.5458984,20.5413818z" /></svg>
                            </a>
                            <div class="dropdown-menu header-search dropdown-menu-start">
                                <div class="input-group w-100 p-2">
                                    <input type="text" class="form-control" :placeholder="$t('LayoutMenu.Search')">
                                    <div class="input-group-text btn btn-primary">
                                        <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24"><path d="M21.2529297,17.6464844l-2.8994141-2.8994141c-0.0021973-0.0021973-0.0043945-0.0043945-0.0065918-0.0065918c-0.8752441-0.8721313-2.2249146-0.9760132-3.2143555-0.3148804l-0.8467407-0.8467407c1.0981445-1.2668457,1.7143555-2.887146,1.715332-4.5747681c0.0021973-3.8643799-3.1286621-6.9989014-6.993042-7.0011597S2.0092773,5.1315308,2.007019,8.9959106S5.1356201,15.994812,9,15.9970703c1.6889038,0.0029907,3.3114014-0.6120605,4.5789185-1.7111206l0.84729,0.84729c-0.6630859,0.9924316-0.5566406,2.3459473,0.3208618,3.2202759l2.8994141,2.8994141c0.4780884,0.4786987,1.1271973,0.7471313,1.8037109,0.7460938c0.6766357,0.0001831,1.3256226-0.2686768,1.803894-0.7472534C22.2493286,20.2558594,22.2488403,18.6417236,21.2529297,17.6464844z M9.0084229,14.9970703c-3.3120728,0.0023193-5.9989624-2.6807861-6.0012817-5.9928589S5.6879272,3.005249,9,3.0029297c1.5910034-0.0026855,3.1175537,0.628479,4.2421875,1.7539062c1.1252441,1.1238403,1.7579956,2.6486206,1.7590942,4.2389526C15.0036011,12.3078613,12.3204956,14.994751,9.0084229,14.9970703z M20.5458984,20.5413818c-0.604126,0.6066284-1.5856934,0.6087036-2.1923828,0.0045166l-2.8994141-2.8994141c-0.2913818-0.2910156-0.4549561-0.6861572-0.4544678-1.0979614C15.0006714,15.6928101,15.6951294,15,16.5507812,15.0009766c0.4109497-0.0005493,0.8051758,0.1624756,1.0957031,0.453125l2.8994141,2.8994141C21.1482544,18.9584351,21.1482544,19.9364624,20.5458984,20.5413818z" /></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- SEARCH -->
                        <button class="navbar-toggler navresponsive-toggler d-md-none ms-auto" type="button"
                                data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent-4"
                                aria-controls="navbarSupportedContent-4" aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon fe fe-more-vertical"></span>
                        </button>
                        <div class="navbar navbar-collapse responsive-navbar p-0">
                            <div class="collapse navbar-collapse" id="navbarSupportedContent-4">
                                <div class="d-flex order-lg-2">
                                    <div class="dropdown d-md-none d-flex">
                                        <a href="#" class="nav-link icon" data-bs-toggle="dropdown">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="header-icon" enable-background="new 0 0 24 24" viewBox="0 0 24 24"><path d="M21.2529297,17.6464844l-2.8994141-2.8994141c-0.0021973-0.0021973-0.0043945-0.0043945-0.0065918-0.0065918c-0.8752441-0.8721313-2.2249146-0.9760132-3.2143555-0.3148804l-0.8467407-0.8467407c1.0981445-1.2668457,1.7143555-2.887146,1.715332-4.5747681c0.0021973-3.8643799-3.1286621-6.9989014-6.993042-7.0011597S2.0092773,5.1315308,2.007019,8.9959106S5.1356201,15.994812,9,15.9970703c1.6889038,0.0029907,3.3114014-0.6120605,4.5789185-1.7111206l0.84729,0.84729c-0.6630859,0.9924316-0.5566406,2.3459473,0.3208618,3.2202759l2.8994141,2.8994141c0.4780884,0.4786987,1.1271973,0.7471313,1.8037109,0.7460938c0.6766357,0.0001831,1.3256226-0.2686768,1.803894-0.7472534C22.2493286,20.2558594,22.2488403,18.6417236,21.2529297,17.6464844z M9.0084229,14.9970703c-3.3120728,0.0023193-5.9989624-2.6807861-6.0012817-5.9928589S5.6879272,3.005249,9,3.0029297c1.5910034-0.0026855,3.1175537,0.628479,4.2421875,1.7539062c1.1252441,1.1238403,1.7579956,2.6486206,1.7590942,4.2389526C15.0036011,12.3078613,12.3204956,14.994751,9.0084229,14.9970703z M20.5458984,20.5413818c-0.604126,0.6066284-1.5856934,0.6087036-2.1923828,0.0045166l-2.8994141-2.8994141c-0.2913818-0.2910156-0.4549561-0.6861572-0.4544678-1.0979614C15.0006714,15.6928101,15.6951294,15,16.5507812,15.0009766c0.4109497-0.0005493,0.8051758,0.1624756,1.0957031,0.453125l2.8994141,2.8994141C21.1482544,18.9584351,21.1482544,19.9364624,20.5458984,20.5413818z" /></svg>
                                        </a>
                                        <div class="dropdown-menu header-search dropdown-menu-start">
                                            <div class="input-group w-100 p-2">
                                                <input type="text" class="form-control" :placeholder="$t('LayoutMenu.Search')">
                                                <div class="input-group-text btn btn-primary">
                                                    <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24"><path d="M21.2529297,17.6464844l-2.8994141-2.8994141c-0.0021973-0.0021973-0.0043945-0.0043945-0.0065918-0.0065918c-0.8752441-0.8721313-2.2249146-0.9760132-3.2143555-0.3148804l-0.8467407-0.8467407c1.0981445-1.2668457,1.7143555-2.887146,1.715332-4.5747681c0.0021973-3.8643799-3.1286621-6.9989014-6.993042-7.0011597S2.0092773,5.1315308,2.007019,8.9959106S5.1356201,15.994812,9,15.9970703c1.6889038,0.0029907,3.3114014-0.6120605,4.5789185-1.7111206l0.84729,0.84729c-0.6630859,0.9924316-0.5566406,2.3459473,0.3208618,3.2202759l2.8994141,2.8994141c0.4780884,0.4786987,1.1271973,0.7471313,1.8037109,0.7460938c0.6766357,0.0001831,1.3256226-0.2686768,1.803894-0.7472534C22.2493286,20.2558594,22.2488403,18.6417236,21.2529297,17.6464844z M9.0084229,14.9970703c-3.3120728,0.0023193-5.9989624-2.6807861-6.0012817-5.9928589S5.6879272,3.005249,9,3.0029297c1.5910034-0.0026855,3.1175537,0.628479,4.2421875,1.7539062c1.1252441,1.1238403,1.7579956,2.6486206,1.7590942,4.2389526C15.0036011,12.3078613,12.3204956,14.994751,9.0084229,14.9970703z M20.5458984,20.5413818c-0.604126,0.6066284-1.5856934,0.6087036-2.1923828,0.0045166l-2.8994141-2.8994141c-0.2913818-0.2910156-0.4549561-0.6861572-0.4544678-1.0979614C15.0006714,15.6928101,15.6951294,15,16.5507812,15.0009766c0.4109497-0.0005493,0.8051758,0.1624756,1.0957031,0.453125l2.8994141,2.8994141C21.1482544,18.9584351,21.1482544,19.9364624,20.5458984,20.5413818z" /></svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- SEARCH -->
                                    <!-- SHORTCUTS -->
                                    <div class="dropdown d-md-flex">
                                        <div class="input-group mb-3"> <input aria-describedby="basic-addon2" v-model="registerLink" id="basic-addon2" aria-label="Recipient's username" class="form-control" placeholder="Recipient's username" type="text"> <span class="input-group-text bg-primary-transparent text-primary" style="cursor:pointer;" @click="copyText()">{{copy}}</span> </div>
                                        <a class="nav-link icon full-screen-link nav-link-bg">
                                            <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" viewBox="0 0 24 24"><path d="M8.5,21H3v-5.5C3,15.223877,2.776123,15,2.5,15S2,15.223877,2,15.5v6.0005493C2.0001831,21.7765503,2.223999,22.0001831,2.5,22h6C8.776123,22,9,21.776123,9,21.5S8.776123,21,8.5,21z M8.5,2H2.4993896C2.2234497,2.0001831,1.9998169,2.223999,2,2.5v6.0005493C2.0001831,8.7765503,2.223999,9.0001831,2.5,9h0.0006104C2.7765503,8.9998169,3.0001831,8.776001,3,8.5V3h5.5C8.776123,3,9,2.776123,9,2.5S8.776123,2,8.5,2z M21.5,15c-0.276123,0-0.5,0.223877-0.5,0.5V21h-5.5c-0.276123,0-0.5,0.223877-0.5,0.5s0.223877,0.5,0.5,0.5h6.0006104C21.7765503,21.9998169,22.0001831,21.776001,22,21.5v-6C22,15.223877,21.776123,15,21.5,15z M21.5,2h-6C15.223877,2,15,2.223877,15,2.5S15.223877,3,15.5,3H21v5.5005493C21.0001831,8.7765503,21.223999,9.0001831,21.5,9h0.0006104C21.7765503,8.9998169,22.0001831,8.776001,22,8.5V2.4993896C21.9998169,2.2234497,21.776001,1.9998169,21.5,2z" /></svg>
                                        </a>

                                    </div>
                                    <!-- FULL-SCREEN -->
                                    <!-- NOTIFICATIONS -->
                                    <div class="dropdown d-md-flex profile-1 ">
                                        <a href="#" data-bs-toggle="dropdown" class="nav-link pe-2 leading-none d-flex animate ">
                                            <span v-if="filePath != null && filePath != undefined && filePath != ''">
                                                <img :src="'data:image/png;base64,' + filePath" alt="profile-user"
                                                     class="avatar  profile-user brround cover-image">
                                            </span>
                                            <span v-else>
                                                <img src="assets/images/default-avatar.png" alt="profile-user"
                                                     class="avatar  profile-user brround cover-image">
                                            </span>
                                            <div class="text-center p-1 d-flex d-lg-none-max">
                                                <h6 class="mb-0" id="profile-heading">{{fullName}}<i class="user-angle ms-1 fa fa-angle-down "></i></h6>
                                            </div>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                                            <a v-on:click="GoTo('/Profile')" class="dropdown-item" href="javascript:void(0)">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-inner-icn" enable-background="new 0 0 24 24" viewBox="0 0 24 24"><path d="M14.6650391,13.3672485C16.6381226,12.3842773,17.9974365,10.3535767,18,8c0-3.3137207-2.6862793-6-6-6S6,4.6862793,6,8c0,2.3545532,1.3595581,4.3865967,3.3334961,5.3690186c-3.6583862,1.0119019-6.5859375,4.0562134-7.2387695,8.0479736c-0.0002441,0.0013428-0.0004272,0.0026855-0.0006714,0.0040283c-0.0447388,0.272583,0.1399536,0.5297852,0.4125366,0.5745239c0.272522,0.0446777,0.5297241-0.1400146,0.5744629-0.4125366c0.624939-3.8344727,3.6308594-6.8403931,7.465332-7.465332c4.9257812-0.8027954,9.5697632,2.5395508,10.3725586,7.465332C20.9594727,21.8233643,21.1673584,21.9995117,21.4111328,22c0.0281372,0.0001831,0.0562134-0.0021362,0.0839844-0.0068359h0.0001831c0.2723389-0.0458984,0.4558716-0.303833,0.4099731-0.5761719C21.2677002,17.5184937,18.411377,14.3986206,14.6650391,13.3672485z M12,13c-2.7614136,0-5-2.2385864-5-5s2.2385864-5,5-5c2.7600708,0.0032349,4.9967651,2.2399292,5,5C17,10.7614136,14.7614136,13,12,13z" /></svg>
                                                {{ $t('LayoutMenu.Profile') }}
                                            </a>

                                            <a class="dropdown-item" href="javascript:void(0)" v-on:click="logout()">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="w-inner-icn" enable-background="new 0 0 24 24" viewBox="0 0 24 24"><path d="M10.6523438,16.140625c-0.09375,0.09375-0.1464233,0.2208862-0.1464233,0.3534546c0,0.276123,0.2238159,0.5,0.499939,0.500061c0.1326294,0.0001221,0.2598267-0.0525513,0.3534546-0.1464844l4.4941406-4.4941406c0.000061-0.000061,0.0001221-0.000061,0.0001831-0.0001221c0.1951294-0.1952515,0.1950684-0.5117188-0.0001831-0.7068481L11.359314,7.1524048c-0.1937256-0.1871338-0.5009155-0.1871338-0.6947021,0c-0.1986084,0.1918335-0.2041016,0.5083618-0.0122681,0.7069702L14.2930298,11.5H2.5C2.223877,11.5,2,11.723877,2,12s0.223877,0.5,0.5,0.5h11.7930298L10.6523438,16.140625z M16.4199829,3.0454102C11.4741821,0.5905762,5.4748535,2.6099243,3.0200195,7.5556641C2.8970337,7.8029175,2.9978027,8.1030884,3.2450562,8.2260742C3.4923706,8.3490601,3.7925415,8.248291,3.9155273,8.0010376c0.8737793-1.7612305,2.300354-3.1878052,4.0615845-4.0615845C12.428833,1.730835,17.828064,3.5492554,20.0366821,8.0010376c2.2085571,4.4517212,0.3901367,9.8509521-4.0615845,12.0595703c-4.4517212,2.2085571-9.8510132,0.3901367-12.0595703-4.0615845c-0.1229858-0.2473145-0.4231567-0.3480835-0.6704102-0.2250977c-0.2473145,0.1229858-0.3480835,0.4230957-0.2250977,0.6704102c1.6773682,3.4109497,5.1530762,5.5667114,8.9541016,5.5537109c3.7976685,0.0003662,7.2676392-2.1509399,8.9560547-5.5526733C23.3850098,11.4996338,21.3657227,5.5002441,16.4199829,3.0454102z" /></svg>
                                                {{ $t('LayoutMenu.Logout') }}
                                            </a>
                                        </div>
                                    </div>
                                    <!-- Profile -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /app-Header -->
        <!--APP-SIDEBAR-->
        <div class="sticky">
            <div class="app-sidebar__overlay active" data-bs-toggle="sidebar"></div>
            <div class="app-sidebar open ps ps--active-y horizontal-main">
                <div class="side-header">
                    <a class="header-brand1" href="index.html">
                        <img src="/assets/images/brand/logo.png" class="header-brand-img desktop-logo" alt="logo">
                        <img src="/assets/images/brand/logo-1.png" class="header-brand-img toggle-logo" alt="logo">
                        <img src="/assets/images/brand/logo-2.png" class="header-brand-img light-logo" alt="logo">
                        <img src="/assets/images/brand/logo-3.png" class="header-brand-img light-logo1" alt="logo">
                    </a><!-- LOGO -->
                </div>
                <div class="main-sidemenu is-expanded container">
                    <div class="slide-left disabled active is-expanded d-none" id="slide-left">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             fill="#7b8191" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z" />
                        </svg>
                    </div>
                    <ul class="side-menu">
                        <li>
                            <h3>Menu</h3>
                        </li>
                        <li class="slide">
                            <a v-if="role=='Student'" v-on:click="GoTo('/StudentDashboard')" class="side-menu__item has-link" data-bs-toggle="slide" href="javascript:void(0)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="side-menu__icon" enable-background="new 0 0 24 24" viewBox="0 0 24 24"><path d="M19.9794922,7.9521484l-6-5.2666016c-1.1339111-0.9902344-2.8250732-0.9902344-3.9589844,0l-6,5.2666016C3.3717041,8.5219116,2.9998169,9.3435669,3,10.2069702V19c0.0018311,1.6561279,1.3438721,2.9981689,3,3h2.5h7c0.0001831,0,0.0003662,0,0.0006104,0H18c1.6561279-0.0018311,2.9981689-1.3438721,3-3v-8.7930298C21.0001831,9.3435669,20.6282959,8.5219116,19.9794922,7.9521484z M15,21H9v-6c0.0014038-1.1040039,0.8959961-1.9985962,2-2h2c1.1040039,0.0014038,1.9985962,0.8959961,2,2V21z M20,19c-0.0014038,1.1040039-0.8959961,1.9985962-2,2h-2v-6c-0.0018311-1.6561279-1.3438721-2.9981689-3-3h-2c-1.6561279,0.0018311-2.9981689,1.3438721-3,3v6H6c-1.1040039-0.0014038-1.9985962-0.8959961-2-2v-8.7930298C3.9997559,9.6313477,4.2478027,9.0836182,4.6806641,8.7041016l6-5.2666016C11.0455933,3.1174927,11.5146484,2.9414673,12,2.9423828c0.4853516-0.0009155,0.9544067,0.1751099,1.3193359,0.4951172l6,5.2665405C19.7521973,9.0835571,20.0002441,9.6313477,20,10.2069702V19z" /></svg>
                                <span class="side-menu__label">{{ $t('LayoutMenu.Dashboard') }}</span>
                            </a>
                            <a v-if="role=='Adviser'" v-on:click="GoTo('/AdvisorDashboard')" class="side-menu__item has-link" data-bs-toggle="slide" href="javascript:void(0)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="side-menu__icon" enable-background="new 0 0 24 24" viewBox="0 0 24 24"><path d="M19.9794922,7.9521484l-6-5.2666016c-1.1339111-0.9902344-2.8250732-0.9902344-3.9589844,0l-6,5.2666016C3.3717041,8.5219116,2.9998169,9.3435669,3,10.2069702V19c0.0018311,1.6561279,1.3438721,2.9981689,3,3h2.5h7c0.0001831,0,0.0003662,0,0.0006104,0H18c1.6561279-0.0018311,2.9981689-1.3438721,3-3v-8.7930298C21.0001831,9.3435669,20.6282959,8.5219116,19.9794922,7.9521484z M15,21H9v-6c0.0014038-1.1040039,0.8959961-1.9985962,2-2h2c1.1040039,0.0014038,1.9985962,0.8959961,2,2V21z M20,19c-0.0014038,1.1040039-0.8959961,1.9985962-2,2h-2v-6c-0.0018311-1.6561279-1.3438721-2.9981689-3-3h-2c-1.6561279,0.0018311-2.9981689,1.3438721-3,3v6H6c-1.1040039-0.0014038-1.9985962-0.8959961-2-2v-8.7930298C3.9997559,9.6313477,4.2478027,9.0836182,4.6806641,8.7041016l6-5.2666016C11.0455933,3.1174927,11.5146484,2.9414673,12,2.9423828c0.4853516-0.0009155,0.9544067,0.1751099,1.3193359,0.4951172l6,5.2665405C19.7521973,9.0835571,20.0002441,9.6313477,20,10.2069702V19z" /></svg>
                                <span class="side-menu__label">{{ $t('LayoutMenu.Dashboard') }}</span>
                            </a>
                            <a v-if="role=='Reviewer'" v-on:click="GoTo('/ReviewerDashboard')" class="side-menu__item has-link" data-bs-toggle="slide" href="javascript:void(0)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="side-menu__icon" enable-background="new 0 0 24 24" viewBox="0 0 24 24"><path d="M19.9794922,7.9521484l-6-5.2666016c-1.1339111-0.9902344-2.8250732-0.9902344-3.9589844,0l-6,5.2666016C3.3717041,8.5219116,2.9998169,9.3435669,3,10.2069702V19c0.0018311,1.6561279,1.3438721,2.9981689,3,3h2.5h7c0.0001831,0,0.0003662,0,0.0006104,0H18c1.6561279-0.0018311,2.9981689-1.3438721,3-3v-8.7930298C21.0001831,9.3435669,20.6282959,8.5219116,19.9794922,7.9521484z M15,21H9v-6c0.0014038-1.1040039,0.8959961-1.9985962,2-2h2c1.1040039,0.0014038,1.9985962,0.8959961,2,2V21z M20,19c-0.0014038,1.1040039-0.8959961,1.9985962-2,2h-2v-6c-0.0018311-1.6561279-1.3438721-2.9981689-3-3h-2c-1.6561279,0.0018311-2.9981689,1.3438721-3,3v6H6c-1.1040039-0.0014038-1.9985962-0.8959961-2-2v-8.7930298C3.9997559,9.6313477,4.2478027,9.0836182,4.6806641,8.7041016l6-5.2666016C11.0455933,3.1174927,11.5146484,2.9414673,12,2.9423828c0.4853516-0.0009155,0.9544067,0.1751099,1.3193359,0.4951172l6,5.2665405C19.7521973,9.0835571,20.0002441,9.6313477,20,10.2069702V19z" /></svg>
                                <span class="side-menu__label">{{ $t('LayoutMenu.Dashboard') }}</span>
                            </a>
                            <a v-if="role=='Super Admin'" v-on:click="GoTo('/Dashboard')" class="side-menu__item has-link" data-bs-toggle="slide" href="javascript:void(0)">
                                <svg xmlns="http://www.w3.org/2000/svg" class="side-menu__icon" enable-background="new 0 0 24 24" viewBox="0 0 24 24"><path d="M19.9794922,7.9521484l-6-5.2666016c-1.1339111-0.9902344-2.8250732-0.9902344-3.9589844,0l-6,5.2666016C3.3717041,8.5219116,2.9998169,9.3435669,3,10.2069702V19c0.0018311,1.6561279,1.3438721,2.9981689,3,3h2.5h7c0.0001831,0,0.0003662,0,0.0006104,0H18c1.6561279-0.0018311,2.9981689-1.3438721,3-3v-8.7930298C21.0001831,9.3435669,20.6282959,8.5219116,19.9794922,7.9521484z M15,21H9v-6c0.0014038-1.1040039,0.8959961-1.9985962,2-2h2c1.1040039,0.0014038,1.9985962,0.8959961,2,2V21z M20,19c-0.0014038,1.1040039-0.8959961,1.9985962-2,2h-2v-6c-0.0018311-1.6561279-1.3438721-2.9981689-3-3h-2c-1.6561279,0.0018311-2.9981689,1.3438721-3,3v6H6c-1.1040039-0.0014038-1.9985962-0.8959961-2-2v-8.7930298C3.9997559,9.6313477,4.2478027,9.0836182,4.6806641,8.7041016l6-5.2666016C11.0455933,3.1174927,11.5146484,2.9414673,12,2.9423828c0.4853516-0.0009155,0.9544067,0.1751099,1.3193359,0.4951172l6,5.2665405C19.7521973,9.0835571,20.0002441,9.6313477,20,10.2069702V19z" /></svg>
                                <span class="side-menu__label">{{ $t('LayoutMenu.Dashboard') }}</span>
                            </a>
                        </li>
                        <li>
                            <h3>{{ $t('LayoutMenu.Components') }}</h3>
                        </li>
                        <li class="slide" v-if="role=='Super Admin' || role=='Reviewer'">
                            <a class="side-menu__item" data-bs-toggle="slide" href="#">
                                <svg xmlns="http://www.w3.org/2000/svg" class="side-menu__icon" enable-background="new 0 0 24 24" viewBox="0 0 24 24"><path d="M21.5,21h-19C2.223877,21,2,21.223877,2,21.5S2.223877,22,2.5,22h19c0.276123,0,0.5-0.223877,0.5-0.5S21.776123,21,21.5,21z M4.5,18.0888672h5c0.1326294,0,0.2597656-0.0527344,0.3534546-0.1465454l10-10c0.000061,0,0.0001221-0.000061,0.0001831-0.0001221c0.1951294-0.1952515,0.1950684-0.5117188-0.0001831-0.7068481l-5-5c0-0.000061-0.000061-0.0001221-0.0001221-0.0001221c-0.1951904-0.1951904-0.5117188-0.1951294-0.7068481,0.0001221l-10,10C4.0526733,12.3291016,4,12.4562378,4,12.5888672v5c0,0.0001831,0,0.0003662,0,0.0005493C4.0001831,17.8654175,4.223999,18.0890503,4.5,18.0888672z M14.5,3.2958984l4.2930298,4.2929688l-2.121582,2.121582l-4.2926025-4.293396L14.5,3.2958984z M5,12.7958984l6.671814-6.671814l4.2926025,4.293396l-6.6713867,6.6713867H5V12.7958984z" /></svg>
                                <span class="side-menu__label">  {{ $t('LayoutMenu.SetupForm') }} </span><i class="angle fa fa-angle-right"></i>
                            </a>
                            <ul class="slide-menu">
                                <li class="side-menu-label1"><a href="javascript:void(0)">{{ $t('LayoutMenu.Forms') }}</a></li>
                                <li><a v-on:click="GoTo('/SessionDuration')" href="javascript:void(0)" class="slide-item">{{ $t('LayoutMenu.SessionDuration') }}</a></li>
                                <li><a v-on:click="GoTo('/Regions')" href="javascript:void(0)" class="slide-item">{{ $t('LayoutMenu.Region') }}</a></li>
                                <li><a v-on:click="GoTo('/Cities')" href="javascript:void(0)" class="slide-item">{{ $t('LayoutMenu.City') }}</a></li>
                                <li><a v-on:click="GoTo('/StudentCategories')" href="javascript:void(0)" class="slide-item">{{ $t('LayoutMenu.StudentCategory') }}</a></li>
                                <li><a v-on:click="GoTo('/Qualifications')" href="javascript:void(0)" class="slide-item">{{ $t('LayoutMenu.Qualification') }}</a></li>
                                <li><a v-on:click="GoTo('/UniversityLevels')" href="javascript:void(0)" class="slide-item">{{ $t('LayoutMenu.UniversityLevel') }}</a></li>
                                <li><a v-on:click="GoTo('/Specializations')" href="javascript:void(0)" class="slide-item">{{ $t('LayoutMenu.Specialization') }}</a></li>
                                <li><a v-on:click="GoTo('/Universities')" href="javascript:void(0)" class="slide-item">{{ $t('LayoutMenu.University') }}</a></li>
                                <li><a v-on:click="GoTo('/College')" href="javascript:void(0)" class="slide-item">{{ $t('LayoutMenu.College') }}</a></li>
                                <li><a v-on:click="GoTo('/SessionTypes')" href="javascript:void(0)" class="slide-item"> {{ $t('LayoutMenu.SessionType') }}</a></li>
                                <li><a v-on:click="GoTo('/SessionLocations')" href="javascript:void(0)" class="slide-item">{{ $t('LayoutMenu.SessionLocation') }}</a></li>
                                <li><a v-on:click="GoTo('/SurveyQuestions')" href="javascript:void(0)" class="slide-item">{{ $t('Dashboard.SurveyQuestions') }}</a></li>
                                <li><a v-on:click="GoTo('/SecondSurveyQuestions')" href="javascript:void(0)" class="slide-item">{{ $t('Dashboard.SecondSurveyQuestions') }}</a></li>
                                <li><a v-on:click="GoTo('/Holidays')" href="javascript:void(0)" class="slide-item">{{ $t('LayoutMenu.Holidays') }}</a></li>
                                <!--<li><a v-on:click="GoTo('/SecondSurveyEmailDays')" href="javascript:void(0)" class="slide-item">{{ $t('Dashboard.SecondSurveyQuestionDays') }}</a></li>-->
                            </ul>
                        </li>
                        <li class="slide" v-if="role=='Super Admin' || role=='Reviewer'">
                            <a class="side-menu__item has-link" data-bs-toggle="slide" href="javascript:void(0)">
                                <span class="side-menu__label"> {{ $t('LayoutMenu.Contact') }}</span><i class="angle fa fa-angle-right"></i>
                            </a>
                            <ul class="slide-menu">
                                <li><a v-on:click="GoTo('/StudentList')" href="javascript:void(0)" class="slide-item">{{ $t('LayoutMenu.StudentRegistration') }}</a></li>
                                <li><a v-on:click="GoTo('/AdminList')" href="javascript:void(0)" class="slide-item">{{ $t('LayoutMenu.AdminRegistration') }}</a></li>
                                <li><a v-on:click="GoTo('/AdviserList')" href="javascript:void(0)" class="slide-item">{{ $t('LayoutMenu.AdvisorRegistration') }}</a></li>
                                <li><a v-on:click="GoTo('/ReviewerList')" href="javascript:void(0)" class="slide-item">{{ $t('LayoutMenu.ReviewerRegistration') }}</a></li>
                            </ul>
                        </li>

                        <li class="slide" v-if="role=='Super Admin' || role=='Reviewer'">
                            <a class="side-menu__item has-link" data-bs-toggle="slide" href="javascript:void(0)">

                                <span class="side-menu__label"> {{ $t('LayoutMenu.Reports') }}</span><i class="angle fa fa-angle-right"></i>
                            </a>
                            <ul class="slide-menu">
                                <li><a v-on:click="GoTo('/Statistics')" href="javascript:void(0)" class="slide-item">{{ $t('Dashboard.Statistics') }}</a></li>
                                <li><a v-on:click="GoTo('/StudentReport')" href="javascript:void(0)" class="slide-item">{{ $t('Dashboard.StundentReport') }}</a></li>
                                <li><a v-on:click="GoTo('/ReportRequirement')" href="javascript:void(0)" class="slide-item">{{ $t('Dashboard.ReportRequirement') }}</a></li>
                                <li><a v-on:click="GoTo('/SessionsReport')" href="javascript:void(0)" class="slide-item">{{ $t('ReviewerDashboard.Sessions') }}</a></li>
                                <li><a v-on:click="GoTo('/SessionsFeedBackReport')" href="javascript:void(0)" class="slide-item">تقرير ملاحظات الجلسة</a></li>
                                <!--<li><a v-on:click="GoTo('/SessionSecondSurveyReport')" href="javascript:void(0)" class="slide-item">{{ $t('ReviewerDashboard.SessionSecondSurveyReport') }}</a></li>-->

                            </ul>
                        </li>
                        <li class="slide" v-if="role=='Super Admin' || role=='Reviewer'">
                            <a class="side-menu__item has-link" data-bs-toggle="slide" href="javascript:void(0)">
                                <span class="side-menu__label"> {{ $t('LayoutMenu.Synchronization') }}</span><i class="angle fa fa-angle-right"></i>
                            </a>
                            <ul class="slide-menu">
                                <li><a v-on:click="GoTo('/SyncSetting')" href="javascript:void(0)" class="slide-item">{{ $t('LayoutMenu.SyncSetting') }}</a></li>
                                <li><a v-on:click="GoTo('/LogReport')" href="javascript:void(0)" class="slide-item">تقرير السجل</a></li>
                                <li><a v-on:click="GoTo('/SyncDataReport')" href="javascript:void(0)" class="slide-item">تقرير البيانات المتزامنة </a></li>
                                <li><a v-on:click="GoTo('/NotSyncDataReport')" href="javascript:void(0)" class="slide-item">لا يوجد تقرير مزامنة البيانات </a></li>
                            </ul>
                        </li>

                        <li class="slide" v-if="role=='Super Admin' ">
                            <a v-on:click="GoTo('/Permissions')" class="side-menu__item has-link" data-bs-toggle="slide" href="javascript:void(0)">
                                <span class="side-menu__label"> أذونات</span>
                            </a>
                        </li>
                        <li class="slide" v-if="role=='Super Admin' ">
                            <a v-on:click="GoTo('/ContactList')" class="side-menu__item has-link" data-bs-toggle="slide" href="javascript:void(0)">
                                <span class="side-menu__label"> {{ $t('ContactUs.ContactUs') }}</span>
                            </a>
                        </li>

                        <!--<li class="slide" v-if="role=='Super Admin' ">
        <a class="side-menu__item has-link" v-on:click="GoTo('/EmailNotification')" data-bs-toggle="slide" href="javascript:void(0)">
            <span class="side-menu__label"> {{ $t('Dashboard.EmailNotification') }}</span>
        </a>
    </li>-->

                        <li class="slide" v-if="role=='Adviser'">
                            <a class="side-menu__item has-link" v-on:click="GoTo('/AdviserSessionLocation')" data-bs-toggle="slide" href="javascript:void(0)">
                                <span class="side-menu__label">{{ $t('LayoutMenu.SessionVenue') }}</span>
                            </a>
                        </li>

                        <!--<li class="slide" v-if="role=='Adviser'">
        <a class="side-menu__item has-link" v-on:click="GoTo('/AdviserSessionType')" data-bs-toggle="slide" href="javascript:void(0)">
            <span class="side-menu__label">  نوع الجلسة الارشادية </span>
        </a>
    </li>-->

                        <li class="slide" v-if="role=='Adviser'">
                            <a class="side-menu__item has-link" v-on:click="GoTo('/AdviserStudentList')" data-bs-toggle="slide" href="javascript:void(0)">
                                <span class="side-menu__label">  {{ $t('Dashboard.StundentReport') }} </span>
                            </a>
                        </li>

                        <!--<li class="slide" v-if="role=='Adviser'">
        <a class="side-menu__item has-link" data-bs-toggle="slide" href="javascript:void(0)">

            <span class="side-menu__label"> الجلسات</span><i class="angle fa fa-angle-right"></i>
        </a>
        <ul class="slide-menu">
            <li><a v-on:click="GoTo('/CreateMultiUniversityGroupSession')" href="javascript:void(0)" class="slide-item"> انشاء جلسة جماعية</a></li>
            <li><a v-on:click="GoTo('/CreateMultiUniversityWorkshop')" href="javascript:void(0)" class="slide-item"> انشاء ورشة عمل</a></li>
            <li><a v-on:click="GoTo('/CreateMultiUniversityQuickWorkshop')" href="javascript:void(0)" class="slide-item"> انشاء ورشة عمل سريعة</a></li>

        </ul>
    </li>-->

                        <li class="slide" v-if="role=='Adviser'">
                            <a class="side-menu__item has-link" v-on:click="GoTo('/CreatePreviousGroupSession')" data-bs-toggle="slide" href="javascript:void(0)">
                                <span class="side-menu__label">  {{ $t('Dashboard.CreatePreviousGroupSession') }} </span>
                            </a>
                        </li>
                        <li class="slide" v-if="role=='Adviser'">
                            <a class="side-menu__item has-link" v-on:click="GoTo('/CreatePreviousWorkshop')" data-bs-toggle="slide" href="javascript:void(0)">
                                <span class="side-menu__label">  {{ $t('Dashboard.CreatePreviousWorkshop') }} </span>
                            </a>
                        </li>
                    </ul>
                    <div class="slide-right" id="slide-right">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#7b8191"
                             width="24" height="24" viewBox="0 0 24 24">
                            <path d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z" />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <!--/APP-SIDEBAR-->
        <!--app-content open-->
        <div class="main-content mt-0 hor-content">
            <div class="side-app">
                <div class="main-container container">
                    <router-view></router-view>
                </div>
            </div>
        </div>

        <loading :name="loading" :active.sync="loading"
                 :can-cancel="false"
                 :is-full-page="true"></loading>

        <footer class="footer">
            <div class="container">
                <div class="row align-items-center flex-row-reverse">
                    <div class="col-md-12 col-sm-12 text-center">
                        {{$t('LayoutMenu.Footer')}}
                    </div>
                </div>
            </div>
        </footer>
    </div>


</template>


<script>
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    export default {
        components: {
            Loading
        },
        name: 'Layout',
        data() {
            return {
                langs: ['en', 'ar'],
                fullName: '',
                displayUserName: '',
                displayUserEmail: '',
                role: '',
                userDetail: '',
                render: 0,
                shortName: '',
                show: false,
                loading: false,
                filePath: null,
                copy: 'Copy',
                registerLink: 'https://suboluniv.com/Register'
            }
        },
        methods: {

            GoTo: function (link) {
                this.$router.push(link);
            },

            copyText: function () {
                navigator.clipboard.writeText(this.registerLink);
                this.copy = 'Copied';

            },
            setLocale(locale) {
                this.$i18n.locale = locale
                localStorage.setItem('locales', locale);

            },
            logout: function () {
                var root = this;
                var url = '/account/logout';
                root.$https.post(url, this.login).then(function (response) {

                    if (response.data == "Success") {
                        root.$session.destroy();
                        localStorage.removeItem('locales');
                        root.$router.push('/')
                    }
                    else {
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Error Logging Out',

                            });
                    }

                });

            },

            GetBaseImage: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                if (localStorage.getItem('ProfilePicture') != 'NULL') {
                    root.$https.get('/Home/GetBaseImage?filePath=' + localStorage.getItem('ProfilePicture'), { headers: { "Authorization": `Bearer ${token}` } })
                        .then(function (response) {
                            if (response.data != null) {
                                root.filePath = response.data;
                            }
                        }, function (error) {
                            console.log(error);
                        });
                }
            },

            SendSecondSurvey: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.loading = true;
                root.$https.get('/Home/SendSecondSurveyEmailQuery', { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        root.loading = false;
                        if (response.data != null) {
                            if (response.data.isSuccess) {
                                console.log('Success');
                            }
                            else {
                                console.log('Error');
                            }

                        }
                        else {
                            console.log('Error');
                        }
                    }, function (error) {
                        console.log(error);
                        root.loading = false;
                    });


            }
        },
        mounted: function () {

            if (this.$session.exists()) {
                this.displayUserName = localStorage.getItem('UserName');
                this.displayUserEmail = localStorage.getItem('UserEmail');
                this.role = localStorage.getItem('RoleName');
                this.fullName = localStorage.getItem('FullName');
                this.GetBaseImage();

            }
            if (!this.$session.exists()) {
                this.$router.push('/');
            }
        }
    }
</script>
<style>
    .fc {
        border-radius: 20px;
    }
</style>