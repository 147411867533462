<template>
    <div class="card product-sales-main">
        <div class="card-header border-bottom ac">
            <div class="form-group">
                <div class="input-group">
                    <input v-model="search" class="form-control fc" id="basic-addon6" :placeholder=" $t('Regions.Search')" type="text">
                </div>
            </div>
            <a @click="showModel()" href="javascript:void(0)" class="btn btn-primary btn-pill slide-item">{{ $t('University.AddUniversity') }}</a>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table border text-nowrap text-md-nowrap table-hover">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>الاسم</th>
                            <th>وصف</th>
                            <th>{{ $t('SessionLcoation.Action') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="( attachment, index) in attachmentList" :key=" attachment.id">
                            <td v-if="currentPage === 1">
                                {{index+1}}
                            </td>
                            <td v-else>
                                {{((currentPage*10)-10) +(index+1)}}
                            </td>
                            <td>{{ attachment.name}}</td>

                            <td>{{ attachment.description}}</td>
                            <td>
                                <a v-on:click="DownloadAttachment(attachment.filePath)" class="btn btn-primary btn-sm m-1" href="javascript:void(0)"><i class="fa fa-download" aria-hidden="true"></i></a>
                                <a v-on:click="Delete(attachment.filePath, attachment.id)" class="btn btn-danger btn-sm" href="javascript:void(0)"><i class="fa fa-trash" aria-hidden="true"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <div class="col-md-6 text-right">
                    <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                    <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                    <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                    <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                    <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                    <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                </div>
                <div class="col-md-6">
                    <div class="overflow-auto" v-on:click="getPage()" style="float:left">
                        <b-pagination pills size="md" v-model="currentPage"
                                      :total-rows="rowCount"
                                      :per-page="10"
                                      :first-text="this.$t('Tabel.First')"
                                      :prev-text="this.$t('Tabel.Previous')"
                                      :next-text="this.$t('Tabel.Next')"
                                      :last-text="this.$t('Tabel.Last')"></b-pagination>
                    </div>
                </div>
            </div>
        </div>
        <add-attachment-model :id="id" :show="show" :key="render" @close="Close()" />

    </div>
</template>

<script>
    export default {
        props: ['id'],
        name: 'AttachmentList',
        data: function () {
            return {
                loading: false,
                render: 0,
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                pageSize: '',
                attachmentList: [],

                show: false,
            }
        },
        watch: {
            search: function (val) {
                this.GetData(val, 1);
            }
        },
        methods: {
            showModel: function () {
                this.show = !this.show;
                this.render++;
            },

            Close: function () {
                this.show = false;
                this.getPage();
            },

            getPage: function () {
                this.GetData(this.search, this.currentPage);
            },

            GetData: function (search, currentPage) {
                var root = this;
                var url = '';
             
                if (this.id != '' && this.id != null && this.id != undefined) {
                    url = '/Setup/ProfileAttachmentList?searchTerm=' + search + '&pageNumber=' + currentPage + '&userId=' + this.id;
                }
                else {
                    url = '/Setup/ProfileAttachmentList?searchTerm=' + search + '&pageNumber=' + currentPage + '&userId=' +  localStorage.getItem('UserID');

                }

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.attachmentList = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        type: 'error',
                        title: root.$t('Notification.Error'),
                        text: root.$t('Notification.SomethingWrong'),
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                }).finally(() => root.loading = false);
            },

            DownloadAttachment(path) {

                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var ext = path.split('.')[1];
                root.$https.get('/Home/DownloadFile?filePath=' + path, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'file.' + ext);
                        document.body.appendChild(link);
                        link.click();
                    });
            },

            Delete: function (path, id) {
                var root = this;

                root.$swal({
                    title: root.$t('Delete.Title'),
                    text: root.$t('Delete.Text'),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonClass: "btn btn-success",
                    cancelButtonClass: "btn btn-danger",
                    confirmButtonText: root.$t('Delete.ConfirmButtonText'),
                    cancelButtonText: root.$t('Delete.CancelButtonText'),
                    reverseButtons: true
                }).then(function (result) {
                    if (result.isConfirmed) {
                        var token = '';
                        if (root.$session.exists()) {
                            token = localStorage.getItem('token');
                        }
                        root.$https.get('/Setup/DeleteFileAttachment?path=' + path + '&id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                            .then(function (response) {
                                if (response.data) {
                                    root.$swal.fire({
                                        icon: 'success',
                                        title: root.$t('Delete.SuccessTitle'),
                                        text: root.$t('Delete.SuccessText'),
                                        type: 'success',
                                        confirmButtonClass: "btn btn-success",
                                        buttonsStyling: false,
                                    });
                                    root.getPage();

                                } else {
                                    root.$swal.fire({
                                        icon: 'error',
                                        title: 'Error!',
                                        text: 'Error',
                                        type: 'success',
                                        cancelButtonClass: "btn btn-danger",
                                        buttonsStyling: false,
                                    });
                                }
                            })
                    }
                    else {
                        root.$swal(root.$t('Delete.Cancelled'), root.$t('Delete.StillIntact'), 'info');
                    }
                });
            },

        },
        created: function () {

        },
        mounted: function () {
            this.GetData(this.search, 1);
        },
    }
</script>
<style scoped>
    .card-header {
        display: flex;
        justify-content: space-between;
    }

    .btn-primary:hover {
        color: white !important;
    }
</style>


