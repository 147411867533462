<template>
    <div>
        <multiselect v-model="DisplayValue" :options="options" :multiple="true" track-by="name" :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true" v-bind:placeholder=" $t('Dropdown.SelectOption')">
            <p slot="noResult" class="text-danger"> {{ $t('Dropdown.MultiUniversity') }}</p>
            <p slot="noOptions" class="text-danger"></p>
        </multiselect>
    </div>
</template>
<script>
    import Multiselect from "vue-multiselect";
    export default {
        name: "multiuniverstydropdown",
        props: ["values","allUniversity"],

        components: {
            Multiselect,

        },
        data: function () {
            return {
                options: [],
                value: [],
                render: 0
            }
        },
        methods: {
            getData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                var allUniversity = false;
                if (this.allUniversity) {
                    allUniversity = true
                }

                this.$https.get('/Setup/UniversityList?isDropDown=true' + '&allUniversity=' + allUniversity, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {


                    if (response.data != null) {
                        response.data.results.forEach(function (cat) {

                            root.options.push({
                                id: cat.id,
                                name: cat.name
                            })
                        })
                    }
                }).then(function () {
                    if (root.allUniversity) {
                            root.options.forEach(function (opt) {
                                root.value.push(opt);
                            });
                            root.$emit('input', root.value);
                        }
                        else{
                            if (root.values != undefined || root.values != null) {
                                root.values.forEach(function (y) {
                                    var cat = root.options.find(function (x) {
                                        return x.id == y;
                                    })
                                    root.value.push({
                                        id: cat.id,
                                        name: cat.name
                                    })
                                })
                            }
                        }
                    

                });
            },
        },
        computed: {
            DisplayValue: {
                get: function () {
                    if (this.value != "" || this.value != undefined) {
                        return this.value;
                    }
                    return this.values;
                },
                set: function (value) {
                    this.value = value;
                    this.$emit('input', value);
                }
            }
        },
        mounted: function () {
            this.getData();
        },
    }
</script>