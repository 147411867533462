<template>
    <div class="main-studentdashboard">

        <!-- PAGE-HEADER -->
        <div class="page-header">
            <div>
                <h1 class="page-title"> {{ $t('StudentDashboard.Heading') }}</h1>
            </div>
            <div class="ms-auto pageheader-btn">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)"> {{ $t('StudentDashboard.Heading') }}</a></li>
                    <li class="breadcrumb-item active" aria-current="page"> {{ $t('StudentDashboard.Dashboard') }}</li>
                </ol>
            </div>
        </div>

        <!-- PAGE-HEADER END -->
        <!-- ROW-2 -->
        <div class="row mt-5">
            <div class="col-sm-12 col-md-12 col-xl-4 col-lg-6">
                <div class="row">

                    <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12">
                        <div class="card overflow-hidden">
                            <div class="card-header border-bottom mt-5 mb-5">
                                <div class="col-4 ">
                                    <img v-if="filePath != null && filePath != undefined && filePath != ''" :src="'data:image/png;base64,' + filePath" />
                                    <img v-else v-bind:src="image" />

                                </div>
                                <div class="col-8 ">
                                    <h4>{{fullName}}</h4>
                                    <p>{{displayUserEmail}}</p>

                                </div>
                            </div>
                            <div class="card-body customers mb-1 ">
                                <h4>{{$t('StudentDashboard.AffiliateUniversities')}}</h4>
                                <p>
                                    <span v-for="uni in dashboardData.universityName" :key="uni">{{uni}}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-8 ">
                <div class="card banner pt-5 pb-5">
                    <div class="card-header flex-column align-items-baseline">
                        <h1 class="pb-3 ">{{$t('StudentDashboard.careercounseling')}}</h1>
                        <p>
                            {{ $t('StudentDashboard.careercounselingdefination') }}<br />
                            {{$t('StudentDashboard.careercounselingdefinationtwo') }}
                        </p>
                    </div>
                    <div class="card-body pt-0">
                        <button v-on:click="GoTo('/CreateStudentSession')" class="btn btn-primary btn-pill pt-2 pb-2 m-1">
                            {{$t('StudentDashboard.StudentButtonC')}}
                        </button>
                        <button v-on:click="GoTo('/GroupSessionList')" class="btn btn-light btn-pill pt-2 pb-2 m-1">    {{$t('StudentDashboard.StudentButtonG')}}</button>
                        <button v-on:click="GoTo('/WorkshopList')" class="btn btn-light btn-pill pt-2 pb-2 m-1"> {{$t('StudentDashboard.StudentButtonW')}}</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- ROW-2 END -->
        <!-- ROW-3 -->
        <div class="row">
            <div class="col-lg-6 col-sm-12 col-md-6 col-xl-4">
                <div class="card overflow-hidden">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-2 fw-semibold">{{dashboardData.pendingSession}}</h3>
                                <p class="text-muted  mb-0"> {{$t('StudentDashboard.Pendingsessions') }} </p>
                            </div>
                            <div class="col col-auto top-icn dash mt-1">
                                <img src="/assets/images/landing/base-1.svg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-md-6 col-xl-4">
                <div class="card overflow-hidden">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-2 fw-semibold">{{dashboardData.activeSession}}</h3>
                                <p class="text-muted  mb-0">{{$t('StudentDashboard.ScheduledAppointments') }}</p>
                            </div>
                            <div class="col col-auto top-icn dash mt-1">
                                <img src="/assets/images/landing/base-2.svg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-md-6 col-xl-4">
                <div class="card overflow-hidden">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-2 fw-semibold">{{dashboardData.completedSession}}</h3>
                                <p class="text-muted  mb-0">{{$t('StudentDashboard.EndedsessionsH') }} </p>
                            </div>
                            <div class="col col-auto top-icn dash mt-1">
                                <img src="/assets/images/landing/base-3.svg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ROW-3 END-->
        <!-- PAGE-BANNER END -->
        
        <StudentActivePendingSession />
        <StudentCompletedSession />

    </div>

</template>

<script>
    import StudentActivePendingSession from '../Dashboard/StudentActivePendingSession.vue'
    import StudentCompletedSession from '../Dashboard/StudentCompletedSession.vue'
    export default {
        name: 'StudentDashboard',
        components: {
            StudentActivePendingSession,
            StudentCompletedSession
        },
        data: function () {
            return {
                loading: false,
                displayUserEmail: '',
                fullName: '',
                displayProfilePicture: '',
                dashboardData: '',
                image: '../../assets/images/default-avatar.png',
                filePath: null

            }
        },
        watch: {

        },
        methods: {
            GoTo: function (link) {
                this.$router.push(link);
            },

            GoToGroupSession() {
                this.$router.push('/CreateGroupSession')

            },

            GetInfo: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Home/StudentDashboard', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.dashboardData = response.data;
                    }
                });
            },

            GetBaseImage: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if (localStorage.getItem('ProfilePicture') != 'null') {
                    root.$https.get('/Home/GetBaseImage?filePath=' + localStorage.getItem('ProfilePicture'), { headers: { "Authorization": `Bearer ${token}` } })
                        .then(function (response) {
                            if (response.data != null) {
                                root.filePath = response.data;
                            }
                        }, function (error) {
                            console.log(error);
                        });
                }

            }
        },
        created: function () {
            this.GetInfo();
            this.GetBaseImage();
        },
        mounted: function () {

            this.displayUserEmail = localStorage.getItem('UserEmail');
            this.fullName = localStorage.getItem('FullName');

        },
    }
</script>
<style scoped>
    .card-header {
        display: flex;
        justify-content: space-between;
    }

    .banner {
        background-image: url(/assets/images/landing/123.jpg);
        background-repeat: no-repeat;
        background-size: contain;
    }

        .banner h1 {
            color: #009000;
        }

    .card-header h4 {
        color: #009000;
    }

    .card-body p {
        color: #009000;
    }
</style>



