<template>
    <div class="main-sec mt-3">
        <div class=" col-md-6 card">
            <div class="modal-header">
                <h5 class="modal-title">{{ $t('AddQualification.Heading') }}</h5>
                <!--<a href="javascript:void(0)" v-on:click="close()" class="close">
                    <em class="icon ni ni-cross"></em>
                </a>-->
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="form-group col-md-12">
                        <label>{{ $t('AddQualification.QualificationName') }}</label>
                        <input v-model="newQualification.name" @focus="$event.target.select()" class="form-control" />
                    </div>
                    <div class="form-group col-md-8">
                        <div class="material-switch">
                            <input id="someSwitchOptionPrimary" name="someSwitchOption001" v-model="newQualification.isActive" type="checkbox" checked />
                            <label for="someSwitchOptionPrimary" class="label-primary"></label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer ">
                <button type="button" class="btn btn-primary btn-round" v-bind:disabled="$v.$invalid" v-on:click="SaveQualification()">{{ $t('AddQualification.Save') }}</button>
                <button type="button" class="btn btn-danger btn-round" v-on:click="close" href="javascript:void(0)">{{ $t('AddQualification.Cancel') }}</button>
            </div>


            <loading :name="loading" :active.sync="loading"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
        </div>
    </div>
</template>
<script>
    import { required } from "vuelidate/lib/validators";
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";

    export default {
        name: 'AddQualification',
        components: {
            Loading
        },
        props: ['bonusAmount', 'props', 'show'],
        data: function () {
            return {
                newQualification: {
                    id: '00000000-0000-0000-0000-000000000000',
                    name: '',
                    isActive: true,
                },
                loading: false,
            }
        },
        validations: {
            newQualification: {
                name: {
                    required: required
                }
            }
        },
        methods: {
            close: function () {
                this.$router.push('/Qualifications');
            },

            SaveQualification: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.post('/Setup/SaveQualification', this.newQualification, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.loading = false;
                            root.$swal({
                                title: root.$t('Notification.Added'),
                                text: root.$t('Notification.DataSucess'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    root.close();
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

        },
        created: function () {
            if (this.$route.query.data != undefined) {
                this.newQualification = this.$route.query.data;
            }
        },
        mounted: function () {
        },
    }
</script>
