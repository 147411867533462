<template>
    <div class="main-container">
        <div class="page-header">
            <div>
                <h1 class="page-title">{{ $t('SessionLcoation.Heading') }}</h1>
            </div>
            <div class="ms-auto pageheader-btn">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t('SessionLcoation.Heading') }}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ $t('SessionLcoation.Dashboard') }}</li>
                </ol>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-12">
                <div class="card product-sales-main">
                    <div class="card-header border-bottom ac">
                        <div class="row" style="width:100%">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <div class="input-group" style="max-width:200px">
                                        <input v-model="search" class="form-control fc" id="basic-addon6" :placeholder=" $t('SessionLcoation.Search')" type="text">
                                    </div>


                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label></label>
                                    <div class=" btn-primary btn btn-pill" style="float:left">
                                        <a v-on:click="DowmloadCSV" href="javascript:void(0)" class=" slide-item py-2">{{ $t('Statistics.DownloadCsv') }}</a>

                                    </div>
                                </div>
                            </div>
                        </div>

                       <!-- <a v-on:click="GoTo('/AddAdviserSessionLocation')" href="javascript:void(0)" class="btn btn-primary btn-pill slide-item ">{{ $t('SessionLcoation.AddSessionLcoation') }}</a>-->
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table border text-nowrap text-md-nowrap table-hover">
                                <thead>
                                    <tr>
                                    <tr>
                                        <th>
                                            #
                                        </th>
                                        <th>
                                            الاسم
                                        </th>
                                        <th>
                                            الرقم الجامعي
                                        </th>
                                        <th>
                                            رقم الهوية
                                        </th>
                                        <th>
                                            رقم الجوال
                                        </th>
                                        <th>
                                            تاريخ الميلاد
                                        </th>
                                        <th>
                                            نوع الجنس
                                        </th>

                                        <th>
                                            البريد الإلكتروني
                                        </th>
                                        <th>
                                            المنطقة
                                        </th>
                                        <th>
                                            المدينة
                                        </th>
                                        <th>
                                            الجامعة
                                        </th>
                                        <th>
                                            التخصص الجامعي
                                        </th>
                                        <th>
                                            المستوى الجامعي
                                        </th>
                                        <th>
                                            فئة الطالب
                                        </th>
                                        <th>
                                            المؤهل
                                        </th>
                                        <th>{{ $t('Regions.Action') }}</th>
                                        <th>{{ $t('SessionLcoation.Action') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="( adviserStudent, index) in AdviserStudentList" :key=" adviserStudent.id">
                                        <td v-if="currentPage === 1">
                                            {{index+1}}
                                        </td>
                                        <td v-else>
                                            {{((currentPage*10)-10) +(index+1)}}
                                        </td>
                                        <td>{{ adviserStudent.fullName}}</td>

                                        <td>
                                            <span v-if="adviserStudent.registrationCode != null">
                                                {{adviserStudent.registrationCode}}
                                            </span>
                                            <span v-if="adviserStudent.registrationCode == null || adviserStudent.registrationCode == ''">
                                                ---
                                            </span>
                                        </td>

                                        <td>{{ adviserStudent.nationalId}}</td>
                                        <td>
                                            {{adviserStudent.phoneNumber}}
                                        </td>
                                        <td>
                                            {{adviserStudent.dateOfBirth}}
                                        </td>
                                        <td>
                                            <span v-if="adviserStudent.gender==1">
                                                ذكر
                                            </span>
                                            <span v-if="adviserStudent.gender==2">
                                                انثى
                                            </span>
                                        </td>
                                        <td>
                                            {{adviserStudent.email}}
                                        </td>
                                        <td>
                                            <span v-if="adviserStudent.regionName != null">
                                                {{adviserStudent.regionName}}
                                            </span>
                                            <span v-if="adviserStudent.regionName == null">
                                                ---
                                            </span>
                                        </td>
                                        <td>
                                            <span v-if="adviserStudent.cityName != null">
                                                {{adviserStudent.cityName}}
                                            </span>
                                            <span v-if="adviserStudent.cityName == null">
                                                ---
                                            </span>
                                        </td>
                                        <td>
                                            <span v-if="adviserStudent.universityName != null">
                                                {{adviserStudent.universityName}}
                                            </span>
                                            <span v-if="adviserStudent.universityName == null">
                                                ---
                                            </span>
                                        </td>
                                        <td>
                                            <span v-if="adviserStudent.specializationName != null">
                                                {{adviserStudent.specializationName}}
                                            </span>
                                            <span v-if="adviserStudent.specializationName == null">
                                                ---
                                            </span>
                                        </td>
                                        <td>
                                            <span v-if="adviserStudent.universityLevelName != null">
                                                {{adviserStudent.universityLevelName}}
                                            </span>
                                            <span v-if="adviserStudent.universityLevelName == null">
                                                ---
                                            </span>
                                        </td>
                                        <td>
                                            <span v-if="adviserStudent.categoryName != null">
                                                {{adviserStudent.categoryName}}
                                            </span>
                                            <span v-if="adviserStudent.categoryName == null">
                                                ---
                                            </span>
                                        </td>
                                        <td>
                                            <span v-if="adviserStudent.qualificatioName != null">
                                                {{adviserStudent.qualificatioName}}
                                            </span>
                                            <span v-if="adviserStudent.qualificatioName == null">
                                                ---
                                            </span>
                                        </td>
                                        <td>
                                            <span v-if="adviserStudent.isActive" class="badge rounded-pill bg-primary-gradient my-1">{{ $t('Regions.Active') }}</span>
                                            <span v-else class="badge rounded-pill bg-danger-gradient my-1">{{ $t('Regions.InActive') }}</span>
                                        </td>
                                        <td>
                                            <a v-on:click="EditInfo(adviserStudent.id)" href="javascript:void(0)"><img src="/assets/images/Edit.svg" /></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="row">
                            <div class="col-md-6 text-right">
                                <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            </div>
                            <div class="col-md-6">
                                <div class="overflow-auto" v-on:click="getPage()" style="float:left">
                                    <b-pagination pills size="md" v-model="currentPage"
                                                  :total-rows="rowCount"
                                                  :per-page="10"
                                                  :first-text="this.$t('Tabel.First')"
                                                  :prev-text="this.$t('Tabel.Previous')"
                                                  :next-text="this.$t('Tabel.Next')"
                                                  :last-text="this.$t('Tabel.Last')"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import moment from 'moment'
    export default {
        name: 'AdviserStudentList',
        data: function () {
            return {
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                pageSize: '',
                loading: false,
                AdviserStudentList: [],

                show: false,
            }
        },
        watch: {
            search: function (val) {
                this.GetData(val, 1);
            }
        },
        methods: {
            GoTo: function (link) {
                this.$router.push(link);
            },
            DowmloadCSV: function () {
                var root = this;
                root.loading = true;
                var token = '';

                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/account/StudentListCsvs', { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Student List.csv');
                        document.body.appendChild(link);
                        link.click();
                        root.loading = false;
                    });
            },

            getPageSize: function (isDesc) {
                this.isDesc = isDesc;
                this.getPage();
            },

            getPage: function () {
                this.GetData(this.search, this.currentPage);
            },

            GetData: function (search, currentPage) {
                var root = this;
                var url = '/Home/AdviserStudentLists?searchTerm=' + search + '&pageNumber=' + currentPage;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.AdviserStudentList = response.data.results;
                        root.AdviserStudentList.forEach((item) => {
                            item.dateOfBirth= moment(item.dateOfBirth).format('DD/MM/YYYY');
                        });                       
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;

                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        type: 'error',
                        title: root.$t('Notification.Error'),
                        text: root.$t('Notification.SomethingWrong'),
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                }).finally(() => root.loading = false);
            },


            EditInfo: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Home/AdviserStudentDetail?id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$router.push({
                            path: '/EditAdviserStudent',
                            query: { data: response.data }
                        })
                    }
                });
            },



        },
        created: function () {

        },
        mounted: function () {
            this.GetData(this.search, 1);
        },
    }
</script>
<style scoped>
    .card-header {
        display: flex;
        justify-content: space-between;
    }

    .btn-primary a {
        color: white !important;
    }

</style>


