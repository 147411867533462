<template>
    <div class="profile">
        <div class="page-header">
            <div>
                <h1 class="page-title">{{ $t("Profile.Heading") }}</h1>
            </div>
            <div class="ms-auto pageheader-btn">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t("Profile.PersonalProfile") }}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ $t("Profile.Dashboard") }}</li>
                </ol>
            </div>
        </div>
        <div class="row" id="user-profile">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-lg-12 col-md-12 col-xl-9">
                                <div class="d-flex flex-wrap align-items-center">
                                    <div class="profile-img-main rounded">
                                        <uploadimage @picPath="getPicture" :path="profileList.profilePicture" :key="genderRender" />
                                    </div>
                                    <div class="ms-4">
                                        <h4>{{fullName}}</h4>
                                        <p class="text-muted mb-2"> {{$t('Profile.MemberSince')}} {{registrationDate}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 col-xl-6">
                            </div>
                        </div>
                    </div>
                    <div class="border-top">
                        <div class="wideget-user-tab">
                            <div class="tab-menu-heading">
                                <div class="tabs-menu1">
                                    <ul class="nav">

                                        <li><a href="#editProfile" class="active show" data-bs-toggle="tab">{{ $t("Profile.PersonalProfile") }}</a></li>
                                        <li><a href="#email" data-bs-toggle="tab">{{ $t("Profile.ProfileEmail") }}</a></li>
                                        <li><a href="#accountSettings" data-bs-toggle="tab">{{ $t("Profile.ProfilePassword") }}</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content">

                    <div class="tab-pane active show" id="editProfile">
                        <div class="card">
                            <div class="card-body border-0">
                                <form class="form-horizontal">
                                    <div class="row p-5 mb-4">
                                        <p class="mb-4 text-17">{{ $t("Profile.PersonalProfile") }}</p>
                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="username" class="form-label">{{ $t("Profile.MobileNumber") }}</label>
                                                <input type="text" class="form-control" v-model="$v.profileList.phoneNumber.$model" placeholder="05xxxxxxxx">
                                                <span v-if="$v.profileList.phoneNumber.$error" class="error text-danger">
                                                    <span v-if="!$v.profileList.phoneNumber.phoneNumber">الرجاء كتابة رقم هاتف محمول صحيح</span>
                                                    <span v-if="!$v.profileList.phoneNumber.minLength">الرجاء كتابة رقم هاتف محمول صحيح</span>

                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="username" class="form-label">{{ $t("Profile.FirstName") }}</label>
                                                <input type="text" class="form-control" v-model="$v.profileList.firstName.$model">
                                                <span v-if="$v.profileList.firstName.$error" class="error text-danger">
                                                    <span v-if="!$v.profileList.firstName.arabic">يرجى كتابه الاسم باللغة العربية<br /></span>
                                                    <span v-if="!$v.profileList.firstName.minLength">يرجى كتابه الاسم بالكامل</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="firstname" class="form-label">{{ $t("Profile.FatherName") }}</label>
                                                <input type="text" class="form-control" v-model="$v.profileList.middleName.$model">
                                                <span v-if="$v.profileList.middleName.$error" class="error text-danger">
                                                    <span v-if="!$v.profileList.middleName.arabic">يرجى كتابه اسم الاب باللغة العربية<br /></span>
                                                    <span v-if="!$v.profileList.middleName.minLength">يرجى كتابه اسم الاب بالكامل</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="lastname" class="form-label">{{ $t("Profile.FamilyName") }}</label>
                                                <input type="text" class="form-control" v-model="$v.profileList.lastName.$model">
                                                <span v-if="$v.profileList.lastName.$error" class="error text-danger">
                                                    <span v-if="!$v.profileList.lastName.arabic">
                                                        يرجى كتابه لقب العائلة باللغة العربية
                                                        <br />
                                                    </span>
                                                    <span v-if="!$v.profileList.lastName.minLength">يرجى كتابه لقب العائلة بالكامل</span>
                                                </span>
                                            </div>
                                        </div>

                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <label>{{ $t('AdvisorRegistered.DateOfBirth') }}</label>
                                            <datepicker v-model="profileList.dateOfBirth" :key="dateOfBirthRender" />
                                        </div>

                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label class="form-label">{{ $t("Profile.IdentificationNumber") }}</label>
                                                <input type="text" class="form-control" v-model="$v.profileList.nationalId.$model" v-on:keyup="CheckNationalId(profileList.nationalId)">
                                                <span v-if="$v.profileList.nationalId.$error" class="error text-danger">
                                                    <span v-if="!$v.profileList.nationalId.number">الرجاء كتابة الرقم فقط</span>
                                                </span>
                                                <span v-if="isValidNationalId" class="text-danger">لا يمكن أن يكون أكثر أو أقل من 10 أرقام ويبدأ دائمًا بـ "1" أو "2"</span>
                                            </div>
                                        </div>
                                        <div v-if="role=='Student'" class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="designation" class="form-label">{{ $t("Profile.UniverstyNumber") }}</label>
                                                <input type="text" class="form-control" id="designation" v-model="profileList.registrationCode">
                                            </div>
                                        </div>
                                        <div v-if="role=='Student' || role == 'Adviser'" class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label class="form-label">{{ $t("Profile.Region") }}</label>
                                                <regionsdropdown v-model="profileList.regionId" :values="profileList.regionId" @input="UniversityCityRender()" :key="regionRender" />
                                            </div>
                                        </div>
                                        <div v-if="role=='Student'" class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="designation" class="form-label">{{ $t("Profile.City") }}</label>
                                                <citydropdown v-model="profileList.cityId" :values="profileList.cityId" :regionid="profileList.regionId" :key="cityRender" />
                                            </div>
                                        </div>
                                        <div v-if="role=='Student'" class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="designation" class="form-label">{{ $t("Profile.University") }}</label>
                                                <universitydropdown v-model="profileList.universityId" :values="profileList.universityId" @input="CollegeRender()" :regionid="profileList.regionId" :key="cityRender" />
                                            </div>
                                        </div>
                                        <div v-if="role=='Student'" class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="designation" class="form-label">{{ $t('Register.College') }}</label>
                                                <college-dropdown v-model="profileList.collegeId" :universityid="profileList.universityId" :isall="false" :key="collegeRender" :values="profileList.collegeId" />
                                            </div>
                                        </div>
                                        <div v-if="role=='Adviser'" class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="designation" class="form-label">{{ $t("Profile.University") }}</label>
                                                <multiuniverstydropdown v-model="universityIds" :values="profileList.universityId" :allUniversity="selectAllUniversity" :key="regionRender" />
                                                <div class="checkbox">
                                                    <div class="custom-checkbox custom-control">
                                                        <input type="checkbox" v-model="selectAllUniversity" @change="SlectAllUniversity()"
                                                               data-checkboxes="mygroup" class="custom-control-input" id="checkbox-3">
                                                        <label for="checkbox-3" class="custom-control-label">الجميع</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label class="form-label">{{ $t("Profile.Gender") }}</label>
                                                <genderdropdown v-model="profileList.gender" :values="profileList.gender" :key="genderRender" />
                                            </div>
                                        </div>
                                        <div v-if="role=='Student'" class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label class="form-label">{{ $t("Profile.Undergraduatelevel") }}</label>
                                                <universtyleveldropdown v-model="profileList.universityLevelId" :values="profileList.universityLevelId" :key="dateOfBirthRender" />
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-12 col-xl-8">
                                            <button type="button" v-bind:disabled="$v.$invalid || isValidNationalId" v-on:click="SaveUser()" class="btn btn-primary btn-pill pt-2 pb-2">{{ $t("Profile.Save") }}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane " id="email">
                        <div class="card ">
                            <div class="card-body border-0">
                                <form class="form-horizontal">
                                    <div class="row p-5 mb-4">
                                        <p class="mb-4 text-17">{{ $t("Profile.EmailManagement") }}</p>

                                        <div class="col-md-12 col-lg-6 col-xl-6">
                                            <div class="form-group">
                                                <label class="form-label">{{ $t("Profile.ProfileEmail") }}</label>
                                                <input type="text" class="form-control" v-model="profileList.email">
                                            </div>
                                        </div>

                                        <div v-if="!isHidden" class="col-md-12 col-lg-6 col-xl-6">
                                            <div class="form-group">
                                                <label class="form-label">{{ $t("SessionActive.Code") }}</label>
                                                <input type="text" class="form-control" v-model="code">
                                            </div>
                                        </div>


                                        <div class="col-md-12 col-lg-12 col-xl-8">
                                            <a v-if="isHidden" href="javascript:void(0)" v-on:click="ChangeEmail(profileList.id, profileList.email),isHidden = false" class="btn btn-primary ">تحقق</a>
                                            <a v-if="!isHidden" href="javascript:void(0)" v-on:click="EmailVerification(profileList.id, profileList.email), isHidden = false" class="btn btn-primary ">{{ $t("Profile.Save") }}</a>


                                            <a href="javascript:void(0)" class="btn btn-danger mx-3" v-on:click="GoTo()">{{ $t('AddSessionLcoation.Cancel') }}</a>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="tab-pane" id="accountSettings">
                        <div class="card ">
                            <div class="card-body border-0">
                                <form class="form-horizontal">
                                    <div class="row p-5 mb-4">
                                        <p class="mb-4 text-17">{{ $t("Profile.ChangePassword") }}</p>

                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="currentPassword" class="form-label">{{ $t("Profile.CurrentPassword") }}</label>
                                                <input type="password" class="form-control" v-model="profileList.oldPassword">
                                            </div>
                                        </div>
                                        <div class="col-md-0 col-lg-6 col-xl-6"></div>
                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="newPassword" class="form-label">{{ $t("Profile.NewPassword") }}</label>
                                                <input type="password" class="form-control" v-model="profileList.password">
                                                <span v-if="profileList.password != null && profileList.password.length != 8" class="error text-danger">
                                                    <span>ثمانية خانات على الاقل</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-0 col-lg-6 col-xl-6"></div>
                                        <div class="col-md-12 col-lg-12 col-xl-6">
                                            <div class="form-group">
                                                <label for="confirmPassword" class="form-label">{{ $t("Profile.ConfirmPassword") }}</label>
                                                <input type="password" class="form-control" v-model="profileList.confirmPassword">
                                                <span v-if="profileList.confirmPassword != profileList.password" class="error text-danger">
                                                    <span>كلمة المرور غير مطابقة</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-md-0 col-lg-6 col-xl-6"></div>

                                        <div class="col-md-12 col-lg-12 col-xl-12 mt-5">
                                            <button v-bind:disabled="profileList.oldPassword==null ||profileList.password==null ||profileList.confirmPassword==null || (profileList.password != null && profileList.password.length != 8) || profileList.confirmPassword != profileList.password" type="button" v-on:click="SaveUser()" class="btn btn-primary btn-pill pt-2 pb-2 mr-5">{{ $t("Profile.Save") }}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <attachment-list />
            </div>
            <loading :name="loading" :active.sync="loading"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import { required, maxLength, minLength } from 'vuelidate/lib/validators';
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    export default {
        name: 'Profile',
        components: {
            Loading
        },
        data: function () {
            return {
                isValidNationalId: false,
                selectAllUniversity: false,
                show: false,
                isHidden: true,
                loading: false,
                code: '',
                id: '',
                fullName: '',
                universityIds: [],
                cityRender: 0,
                regionRender: 0,
                dateOfBirthRender: 0,
                universitiesRender: 0,
                collegeRender: 0,
                genderRender: 0,
                displayUserName: '',
                registrationDate: '',
                displayProfilePicture: '',
                role: '',
                localrole: '',
                profileList: {
                    id: '',
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    roleName: 'Student',
                    registrationCode: '',
                    nationalId: '',
                    phoneNumber: '',
                    profilePicture: '',
                    dateOfBirth: '',
                    gender: 0,
                    email: '',
                    cityId: '',
                    universityId: '',
                    collegeId: '',
                    studentCategoryId: '',
                    qualificationId: '',
                    regionId: '',
                    specializationId: '',
                    universityLevelId: '',
                    password: '',
                    confirmPassword: '',
                    oldPassword: '',
                    isActive: false,
                    profileAttachment: [],


                },
            }
        },
        validations: {
            profileList:
            {
                firstName:
                {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    arabic(name) {
                        return /^[\u0621-\u064A\s]+$/.test(name)
                    }
                },
                middleName:
                {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    arabic(name) {
                        return /^[\u0621-\u064A\s]+$/.test(name)
                    }
                },
                lastName:
                {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    arabic(name) {
                        return /^[\u0621-\u064A\s]+$/.test(name)
                    }
                },
                nationalId:
                {
                    required,
                    number(id) {
                        return /^[0-9\u0660-\u0669]+$/.test(id)
                    }
                },
                phoneNumber:
                {
                    maxLength: maxLength(15),
                    minLength: minLength(10),
                    phonenumber(number) {
                        return /^[0-9\u0660-\u0669]+$/.test(number)
                    }
                }
            },

        },
        watch: {

        },
        methods: {
            CheckNationalId: function (no) {
                
                if (no.length != 10) {
                    this.isValidNationalId = true;
                }
                else {
                    var letter = no.substring(0, 1)
                    if (letter != '1' && letter != '2' && letter != '١' && letter != '٢') {
                        this.isValidNationalId = true;
                    }
                    else {
                        this.isValidNationalId = false;
                    }
                }

            },

            SlectAllUniversity: function () {
                this.profileList.universityId = [];
                this.regionRender++;
            },

            CollegeRender: function () {
                this.collegeRender++;
                this.profileList.collegeId = '';
            },

            ChangeEmail: function (id, email) {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('/Home/VerficationEmail?studentId=' + id + '&email=' + email, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {

                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.loading = false;
                            root.$swal({
                                title: root.$t('Notification.Added'),
                                text: root.$t('Notification.DataSucess'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    root.loading = false;
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            logout: function () {
                var root = this;
                var url = '/account/logout';
                root.$https.post(url, this.login).then(function (response) {

                    if (response.data == "Success") {
                        root.$session.destroy();
                        localStorage.removeItem('locales');
                        root.$router.push('/')
                    }
                    else {
                        root.$swal.fire(
                            {
                                icon: 'error',
                                title: 'Error Logging Out',

                            });
                    }

                });

            },

            EmailVerification: function (id, email) {
                var root = this;
                this.loading = true;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('/Home/SaveVerficationEmail?studentId=' + id + '&email=' + email + "&code=" + this.code, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {

                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.loading = false;
                            root.$swal({
                                title: root.$t('Notification.Added'),
                                text: root.$t('Notification.DataSucess'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    root.logout();
                                    root.loading = false;

                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },


            GoTo: function () {
                if (this.localrole == "Super Admin") {
                    this.$router.push('/Dashboard');
                }
                else if (this.localrole == "Adviser") {
                    this.$router.push('/AdvisorDashboard');
                }

                else {
                    this.$router.push('/StudentDashboard');
                }

            },
            getPicture: function (path) {
                this.profileList.profilePicture = path;
                localStorage.setItem('ProfilePicture', path)
            },

            UniversityCityRender: function () {
                this.cityRender++;
                this.profileList.cityId = '';
                this.profileList.universityId = '';
                this.universityIds = [];
            },

            getLevelId: function (value) {
                var levelId = [];
                for (var i = 0; i < value.length; i++) {
                    levelId[i] = value[i].id
                }
                return levelId;
            },


            SaveUser: function () {
                var root = this;
                this.loading = true;

                this.fullName = this.profileList.firstName + this.profileList.middleName + this.profileList.lastName;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                var url = '';
                if (this.role == 'Adviser') {
                    if (this.universityIds != null && this.universityIds != undefined && this.universityIds != '') {
                        this.profileList.universityId = this.getLevelId(this.universityIds);
                    }

                    url = '/account/AdviserRegister';
                }
                else {
                    url = '/account/StudentRegister'
                }
                debugger;
                root.$https.post(url, this.profileList, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {

                            root.$swal({
                                title: root.$t('Notification.Created'),
                                text: 'تم تحديث البيانات بنجاح',
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            });
                            localStorage.setItem('FullName', root.fullName)
                            root.loading = false;
                            root.GoTo();
                        }
                        else {

                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        debugger;
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            GetData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                var url = '';
                if (this.role == 'Adviser') {
                    url = '/account/GetAdviserDetail?id=' + localStorage.getItem('UserID');
                }
                else {
                    url = '/account/GetStudentDetail?id=' + localStorage.getItem('UserID');
                }
                
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            root.profileList = response.data;
                            root.registrationDate = moment(response.data.registrationDate).format('l');

                            root.genderRender++;
                            root.universitiesRender++;
                            root.regionRender++;
                            root.dateOfBirthRender++;
                            root.cityRender++;
                            root.collegeRender++;
                            root.CheckNationalId(root.profileList.nationalId);
                        }

                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire({
                            icon: 'error',
                            type: 'error',
                            title: root.$t('Notification.Error'),
                            text: root.$t('Notification.SomethingWrong'),
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });
                    }).finally(() => root.loading = false);

            },
        },

        created: function () {

            if (this.$session.exists()) {
                this.displayUserName = localStorage.getItem('UserName');
                this.displayProfilePicture = localStorage.getItem('ProfilePicture');
                this.fullName = localStorage.getItem('FullName');
                this.role = localStorage.getItem('RoleName');
                this.GetData();
            }
            this.localrole = localStorage.getItem('RoleName');
        },
        mounted: function () {

        },
    }
</script>
<style scoped>
    .custom-file-label {
        display: none;
    }

    #profilePicture {
        max-height: 96px;
        max-width: 96px;
    }

    .btn-primary:hover {
        color: white !important;
    }

    .card {
        margin-bottom: 0px !important;
    }

    .bg-wh {
        background-color: white;
    }

    .ac a:focus {
        color: white !important;
    }
</style>


