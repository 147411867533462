<template>
    <div class="main-session-details pb-5">
        <div class="page-header">
            <div>
                <h1 class="page-title">
                    <font style="vertical-align: inherit;"><font style="vertical-align: inherit;">  {{ $t("SessionActive.Heading") }}</font></font>
                </h1>
            </div>
        </div>
        <div class="row">
            <div class="card">
                <div class="row">

                    <div class="col-12 col-md-12 p-4 fanimate">
                        <h3 class="mx-3 text-primary">{{ $t("SessionActive.SessionStatements") }}</h3>
                    </div>

                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">{{ $t("SessionActive.SessionType") }}</h4>
                        <p class="text-muted mb-3 mx-1">{{sessionActive.sessionTypeName}}</p>
                    </div>
                    <div v-if="sessionActive.subjectOfSession==2 || sessionActive.subjectOfSession==3" class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1"> {{ $t("SessionActive.SessionDuration") }}</h4>
                        <p class="text-muted mb-3 mx-1">{{sessionActive.sessionDurationName}}</p>
                    </div>
                    <!--<div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">عدد المقاعد</h4>
                        <p class="text-muted mb-3 mx-1">{{sessionActive.studentLimit}}</p>
                    </div>

                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">مكان الجلسة</h4>
                        <p class="text-muted mb-3 mx-1">{{sessionActive.sessionLocationName}}</p>
                    </div>-->

                    <div v-if="sessionActive.workshopType==0 || sessionActive.workshopType==2" class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">
                            {{ $t("CreateGroupSessionDetails.MeetingPlace") }}
                        </h4>
                        <p class="text-muted mb-3 mx-1">{{sessionActive.sessionLocationName}}</p>
                    </div>

                    <div v-if="sessionActive.workshopType==1" class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">
                            {{ $t("SessionActive.RemoteLink") }}
                        </h4>
                        <p class="text-muted mb-3 mx-1">
                            <a :href="sessionActive.remoteLink" class="btn btn-light" target="_blank"><i class="fa fa-link" aria-hidden="true"></i></a>

                        </p>
                    </div>
                    <div v-if="sessionActive.workshopType==2" class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">{{ $t("SessionActive.AvailableSeats") }}</h4>
                        <p class="text-muted mb-3 mx-1">{{sessionActive.remainingSeats}}</p>
                    </div>
                    <div v-if="sessionActive.workshopType==2" class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">
                            {{sessionActive.StudentLimit}}
                        </h4>
                        <p class="text-muted mb-3 mx-1">{{sessionActive.studentLimit}}</p>
                    </div>



                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">{{ $t("SessionActive.SessionEndTime") }}</h4>
                        <p class="text-muted mb-3 mx-1">{{sessionActive.sessionEndTime}}</p>
                    </div>
                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">{{ $t("SessionActive.SessionStartTime") }}</h4>
                        <p class="text-muted mb-3 mx-1">{{sessionActive.sessionStartTime}}</p>
                    </div>

                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">{{ $t("SessionActive.AdviserUserName") }}</h4>
                        <p class="text-muted mb-3 mx-1">{{sessionActive.adviserUserName}}</p>
                    </div>

                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1"> {{ $t("SessionEnded.UniversityName") }}</h4>
                        <span v-for="(item, index) in sessionActive.universityName" :key="index">
                            {{index>0? ',' : ''}} ({{item.name}})
                        </span>
                    </div>

                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1"> {{ $t("SessionEnded.TotalStudentJoin") }}</h4>
                        {{sessionActive.totalStudentJoin}}
                    </div>
                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">{{ $t("SessionEnded.Code") }}</h4>
                        {{sessionActive.code}}
                    </div>

                    <div class="col-lg-12 p-4 fanimate">
                        <h4 class="mx-1">
                            {{ $t("SessionActive.UniversityLevel") }}
                        </h4>

                        <p class="text-muted mb-3 mx-1">
                            <span v-for="( universityLevelName) in sessionActive.universityLevelIdName" :key=" universityLevelName.id">({{universityLevelName}})</span>
                        </p>
                        <!--<p class="text-muted mb-3 mx-1 ></p>-->
                    </div>

                </div>
            </div>

            <div class="card" v-for="( sessionStudentName) in sessionActive.sessionStudents" :key=" sessionStudentName.id">
                <div class="row">
                    <div class="col-12 col-md-12 p-4 fanimate">
                        <h3 class="mx-3 text-primary">  {{ $t("SessionActive.StudentsStatment") }}</h3>
                    </div>
                    <table class="table table-border text-nowrap text-md-nowrap">
                        <thead>
                            <tr>
                                <th> {{ $t("SessionActive.Picture") }}</th>
                                <th>{{ $t("SessionActive.Name") }}</th>
                                <th>{{ $t("SessionActive.Email") }}</th>
                                <th> {{ $t("SessionActive.IdentificationNumber") }}</th>
                                <th>{{ $t("SessionActive.StudentCategory") }}</th>
                                <th>{{ $t("SessionActive.Qualification") }}</th>
                                <th>{{ $t("SessionActive.UniversityLevelName") }}</th>
                                <th>{{ $t("SessionEnded.UniversityName") }}</th>
                                <th>{{ $t("SessionActive.Reason") }}</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr v-bind:class="{'bg_danger':sessionStudentName.isCancel}">
                                <td>

                                    <div class="d-flex flex-wrap align-items-center">
                                        <div class="profile-img-main rounded">
                                            <img v-if="sessionStudentName.profilePicture!='' && sessionStudentName.profilePicture!=null" :src="'data:image/png;base64,' + sessionStudentName.profilePicture" alt="img" class="m-0 p-1 rounded hrem-8" />
                                            <img v-else src="/assets/images/landing/dumy.svg" alt="img" class="m-0 p-1 rounded hrem-8" />
                                        </div>
                                    </div>

                                </td>
                                <td>

                                    <p class="text-muted mb-3 mx-1">{{sessionStudentName.fullName}}</p>
                                </td>
                                <td>
                                    <p class="text-muted mb-3 mx-1">{{sessionStudentName.email}}</p>
                                </td>
                                <td>
                                    <p class="text-muted mb-3 mx-1">{{sessionStudentName.phoneNumber}}</p>
                                </td>
                                <td>
                                    <p v-if="sessionStudentName.categoryName != null" class="text-muted mb-3 mx-1">{{sessionStudentName.categoryName}}</p>
                                    <p v-if="sessionStudentName.categoryName == null" class="text-muted mb-3 mx-1">---</p>
                                </td>
                                <td>
                                    <p v-if="sessionStudentName.qualificatioName != null" class="text-muted mb-3 mx-1">{{sessionStudentName.qualificatioName}}</p>
                                    <p v-if="sessionStudentName.qualificatioName == null" class="text-muted mb-3 mx-1">---</p>
                                </td>
                                <td>
                                    <p v-if="sessionStudentName.universityLevelName != null" class="text-muted mb-3 mx-1">{{sessionStudentName.universityLevelName}}</p>
                                    <p v-if="sessionStudentName.universityLevelName == null" class="text-muted mb-3 mx-1">---</p>
                                </td>
                                <td>
                                    <p v-if="sessionStudentName.universityName != null" class="text-muted mb-3 mx-1">{{sessionStudentName.universityName}}</p>
                                    <p v-if="sessionStudentName.universityName == null" class="text-muted mb-3 mx-1">---</p>
                                </td>
                                <td>
                                    {{sessionStudentName.cancelReason}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div v-if="sessionActive.subjectOfSession==1 && sessionActive.statusId==2" class="col-lg-4 fanimate">
            <h4 class="">
                {{ $t("SessionActive.Code") }}
            </h4>
            <input v-model="code" class="form-control p-3" />
        </div>
        <div class="row mt-3 btn_student_group ">
            <div class="col-md-12">
                <button v-on:click="Save()" class="btn btn-primary register me-2">
                    {{ $t("SessionActive.Save") }}
                </button>
                <a v-on:click="GoTo()" href="javascript:void(0)" class="btn btn-light">{{ $t("SessionActive.Cancel") }}</a>
            </div>
        </div>
        <loading :name="loading" :active.sync="loading"
                 :can-cancel="false"
                 :is-full-page="true"></loading>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    export default {
        name: "SessionDetail",
        components: {
            Loading
        },
        data() {
            return {
                loading: false,
                code: '',

                sessionActive: {

                },
            };
        },


        methods: {
            GoTo: function () {
                this.$router.push("/AdvisorDashboard");
            },

            SendEmailDetails: function (id) {
                var root = this;
                var token = '';
                this.loading = true;

                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Sessions/SendSessionSurveyNotification?sessionId=' + id, { headers: { "Authorization": `Bearer ${token}` } });
                root.loading = false;
                root.GoTo();

            },

            Save: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('/Sessions/ActivateSession?id=' + this.sessionActive.id + '&code=' + this.code, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {

                        if (response.data.isSuccess) {
                            root.$swal({
                                title: root.$t('Notification.Created'),
                                text: root.$t('Notification.SessionCreated') + ' ' + response.data.isAddUpdate,
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            });
                            root.loading = false;
                            root.SendEmailDetails(response.data.id);

                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

        },
        created: function () {

            if (this.$route.query.data != undefined) {
                this.sessionActive = this.$route.query.data;
            }
        },
        mounted: function () {

        }
    };
</script>

<style scoped>
    .profile-img-main {
        max-width: 60px;
    }

    .bg_danger {
        background-color: rgb(255 107 107 / 10%);
    }
</style>
