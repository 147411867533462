<template>
    <div>
        <multiselect v-model="DisplayValue" :options="options" :multiple="false" track-by="name" :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true"  v-bind:placeholder=" $t('Dropdown.SelectOption')">
            <p slot="noResult" class="text-danger">  </p>
            <p slot="noOptions" class="text-danger"></p>
        </multiselect>
    </div>
</template>
<script>
    import Multiselect from "vue-multiselect";
    export default {
        name: "collegedropdown",
        props: ["values", "universityid", "isall"],

        components: {
            Multiselect,

        },
        data: function () {
            return {
                options: [],
                value: '',
                render: 0
            }
        },
        methods: {
            getData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                this.$https.get('/Setup/CollegeList?isDropDown=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    
                    if (response.data != null) {
                        response.data.results.forEach(function (cat) {
                            if (root.isall) {
                                root.options.push({
                                    id: cat.id,
                                    name: cat.name
                                })
                            }
                            else {
                                if (root.universityid != null && root.universityid != '' && root.universityid != undefined && root.universityid == cat.universityId) {
                                    root.options.push({
                                        id: cat.id,
                                        name: cat.name
                                    })
                                }
                            }
                            
                        })
                    }
                }).then(function () {
                    root.value = root.options.find(function (x) {
                        return x.id == root.values;

                    })
                });
            },
        },
        computed: {
            DisplayValue: {
                get: function () {
                    if (this.value != "" || this.value != undefined) {
                        return this.value;
                    }
                    return this.values;
                },
                set: function (value) {
                    this.value = value;
                    this.$emit('input', value == null ? null : value.id);                   
                }
            }
        },
        mounted: function () {
            this.getData();
        },
    }
</script>