<template>
    <div>
        <multiselect v-model="DisplayValue" :options="options"  :multiple="false" track-by="name" :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true" v-bind:placeholder=" $t('Dropdown.SelectOption')">
            <p slot="noResult" class="text-danger">  {{ $t('Dropdown.UniversityLevel') }}</p>
            <p slot="noOptions" class="text-danger"></p>
        </multiselect>
    </div>
</template>
<script>
    import Multiselect from "vue-multiselect";
    export default {
        name: "universtylevel",
        props: ["values", 'studentCategoryId'],

        components: {
            Multiselect,

        },
        data: function () {
            return {
                options: [],
                value: '',
                render: 0
            }
        },
        methods: {
            getData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.get('/Setup/UniversityLevelList?isDropDown=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        
                        if (root.studentCategoryId == 'e3c0e4db-a923-406b-98cd-0139694362c6' || root.studentCategoryId == 'b12b3957-2c34-48e9-86c2-2958f230dd6f') {
                            response.data.results.forEach(function (cat) {
                                if (cat.id == '07faacf8-a7a7-4851-b56d-32483688d52e' || cat.id == '38dab185-99a6-4bb0-a05f-e10515a659b9') {
                                    root.options.push({
                                        id: cat.id,
                                        name: cat.name
                                    })
                                }
                            })
                        }
                        else {
                            response.data.results.forEach(function (cat) {
                                if (cat.id != '07faacf8-a7a7-4851-b56d-32483688d52e' && cat.id != '38dab185-99a6-4bb0-a05f-e10515a659b9') {
                                    root.options.push({
                                        id: cat.id,
                                        name: cat.name
                                    })
                                }
                            })
                        }
                    }
                }).then(function () {
                    
                    if (root.studentCategoryId == 'e3c0e4db-a923-406b-98cd-0139694362c6') {
                        root.value = root.options.find(function (x) {
                            return x.id == '07faacf8-a7a7-4851-b56d-32483688d52e';
                        })
                        root.$emit('input', root.value == null ? null : root.value.id);
                    }
                    else if (root.studentCategoryId == 'b12b3957-2c34-48e9-86c2-2958f230dd6f') {
                        root.value = root.options.find(function (x) {
                            return x.id == '38dab185-99a6-4bb0-a05f-e10515a659b9';
                        })
                        root.$emit('input', root.value == null ? null : root.value.id);
                    }
                    else {
                        root.value = root.options.find(function (x) {
                            return x.id == root.values;
                        })
                    }
                    
                });
            },
        },
        computed: {
            DisplayValue: {
                get: function () {
                    if (this.value != "" || this.value != undefined) {
                        return this.value;
                    }
                    return this.values;
                },
                set: function (value) {
                    this.value = value;
                    this.$emit('input', value == null ? null : value.id);
                }
            }
        },
        mounted: function () {
            this.getData();
        },
    }
</script>