
<template>
    <div class="nk-content nk-content-fluid">
        <div class="container-xl wide-lg">
            <div class="nk-content-body">
                <div class="nk-block-head nk-block-head-sm">
                    <div class="nk-block-between">
                        <div class="nk-block-head-content">
                            <h3 class="nk-block-title page-title">All Customer</h3>
                            <div class="nk-block-des text-soft">
                                <p>You have total {{rowCount}} Customer.</p>
                            </div>
                        </div><!-- .nk-block-head-content -->
                        <div class="nk-block-head-content">
                            <div class="toggle-wrap nk-block-tools-toggle">
                                <a href="javascript:void(0)" class="btn btn-icon btn-trigger toggle-expand me-n1" data-target="pageMenu"><em class="icon ni ni-menu-alt-r"></em></a>
                                <div class="toggle-expand-content" data-content="pageMenu">
                                    <ul class="nk-block-tools g-3">
                                        <li><a href="javascript:void(0)" class="btn btn-white btn-outline-light"><em class="icon ni ni-download-cloud"></em><span>Export</span></a></li>
                                        <li class="nk-block-tools-opt">
                                            <a href="javascript:void(0)" v-on:click="AddSignup()" class=" btn btn-icon btn-primary"><em class="icon ni ni-plus"></em></a>
                                        </li>
                                    </ul>
                                </div>
                            </div><!-- .toggle-wrap -->
                        </div><!-- .nk-block-head-content -->
                    </div><!-- .nk-block-between -->
                </div><!-- .nk-block-head -->
                <div class="nk-block">
                    <div class="card card-bordered card-stretch">
                        <div class="card-inner-group">
                            <div class="card-inner position-relative card-tools-toggle">
                                <div class="card-title-group">
                                    <div class="card-tools">
                                        <div class="form-inline flex-nowrap gx-3">
                                            <div class="form-wrap w-150px">
                                                <div class="dropdown">
                                                    <a class="btn btn-primary dropdown-toggle" href="javascript:void(0)" data-bs-toggle="dropdown">Bulk Action</a>
                                                    <div class="dropdown-menu">
                                                        <ul class="link-list-opt">
                                                            <li><a href="javascript:void(0)" v-on:click="UpdateApprovalStatus('EmailVerified')"><span>Email Verified</span></a></li>
                                                            <li><a href="javascript:void(0)" v-on:click="UpdateApprovalStatus('Approved')"><span>Profile Approved</span></a></li>
                                                            <li><a href="javascript:void(0)" v-on:click="UpdateApprovalStatus('Active')"><span>Active User</span></a></li>
                                                            <li><a href="javascript:void(0)" v-on:click="UpdateApprovalStatus('In-Active')"><span>In-Active User</span></a></li>                                                            
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div><!-- .form-inline -->
                                    </div><!-- .card-tools -->
                                    <div class="card-tools me-n1">
                                        <ul class="btn-toolbar gx-1">
                                            <li>
                                                <a href="javascript:void(0)" class="btn btn-icon search-toggle toggle-search" data-target="search"><em class="icon ni ni-search"></em></a>
                                            </li><!-- li -->
                                            <li class="btn-toolbar-sep"></li><!-- li -->
                                            <li>
                                                <div class="toggle-wrap">
                                                    <a href="javascript:void(0)" class="btn btn-icon btn-trigger toggle" data-target="cardTools"><em class="icon ni ni-menu-right"></em></a>
                                                    <div class="toggle-content" data-content="cardTools">
                                                        <ul class="btn-toolbar gx-1">
                                                            <li class="toggle-close">
                                                                <a href="javascript:void(0)" class="btn btn-icon btn-trigger toggle" data-target="cardTools"><em class="icon ni ni-arrow-left"></em></a>
                                                            </li><!-- li -->
                                                            <li>
                                                                <div class="dropdown">
                                                                    <a href="javascript:void(0)" class="btn btn-trigger btn-icon dropdown-toggle" data-bs-toggle="dropdown">
                                                                        <div class="dot dot-primary"></div>
                                                                        <em class="icon ni ni-filter-alt"></em>
                                                                    </a>
                                                                    <div class="filter-wg dropdown-menu dropdown-menu-xl dropdown-menu-end">
                                                                        <div class="dropdown-head">
                                                                            <span class="sub-title dropdown-title">Filter Users</span>
                                                                            <div class="dropdown">
                                                                                <a href="javascript:void(0)" class="btn btn-sm btn-icon">
                                                                                    <em class="icon ni ni-more-h"></em>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                        <div class="dropdown-body dropdown-body-rg">
                                                                            <div class="row gx-6 gy-3">
                                                                                <div class="col-6">
                                                                                    <div class="custom-control custom-control-sm custom-checkbox">
                                                                                        <input type="checkbox" class="custom-control-input" id="hasBalance">
                                                                                        <label class="custom-control-label" for="hasBalance"> Have Balance</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-6">
                                                                                    <div class="custom-control custom-control-sm custom-checkbox">
                                                                                        <input type="checkbox" class="custom-control-input" id="hasKYC">
                                                                                        <label class="custom-control-label" for="hasKYC"> KYC Verified</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-6">
                                                                                    <div class="form-group">
                                                                                        <label class="overline-title overline-title-alt">Role</label>
                                                                                        <select class="form-select js-select2">
                                                                                            <option value="any">Any Role</option>
                                                                                            <option value="investor">Investor</option>
                                                                                            <option value="seller">Seller</option>
                                                                                            <option value="buyer">Buyer</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-6">
                                                                                    <div class="form-group">
                                                                                        <label class="overline-title overline-title-alt">Status</label>
                                                                                        <select class="form-select js-select2">
                                                                                            <option value="any">Any Status</option>
                                                                                            <option value="active">Active</option>
                                                                                            <option value="pending">Pending</option>
                                                                                            <option value="suspend">Suspend</option>
                                                                                            <option value="deleted">Deleted</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-12">
                                                                                    <div class="form-group">
                                                                                        <button type="button" class="btn btn-secondary">Filter</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="dropdown-foot between">
                                                                            <a class="clickable" href="javascript:void(0)">Reset Filter</a>
                                                                            <a href="javascript:void(0)">Save Filter</a>
                                                                        </div>
                                                                    </div><!-- .filter-wg -->
                                                                </div><!-- .dropdown -->
                                                            </li><!-- li -->
                                                            <li>
                                                                <div class="dropdown">
                                                                    <a href="javascript:void(0)" class="btn btn-trigger btn-icon dropdown-toggle" data-bs-toggle="dropdown">
                                                                        <em class="icon ni ni-setting"></em>
                                                                    </a>
                                                                    <div class="dropdown-menu dropdown-menu-xs dropdown-menu-end">
                                                                        <ul class="link-check">
                                                                            <li><span>Show</span></li>
                                                                            <li v-bind:class="pageSize != 20 && pageSize != 50 ? 'active' : ''"><a v-on:click="getPageSize(10, isDesc)" href="javascript:void(0)">10</a></li>
                                                                        </ul>
                                                                        <ul class="link-check">
                                                                            <li><span>Order</span></li>
                                                                            <li v-bind:class="!isDesc ? 'active' : ''"><a v-on:click="getPageSize(pageSize, false)" href="javascript:void(0)">ASC</a></li>
                                                                            <li v-bind:class="isDesc ? 'active' : ''"><a v-on:click="getPageSize(pageSize, true)" href="javascript:void(0)">DESC</a></li>

                                                                        </ul>
                                                                    </div>
                                                                </div><!-- .dropdown -->
                                                            </li><!-- li -->
                                                        </ul><!-- .btn-toolbar -->
                                                    </div><!-- .toggle-content -->
                                                </div><!-- .toggle-wrap -->
                                            </li><!-- li -->
                                        </ul><!-- .btn-toolbar -->
                                    </div><!-- .card-tools -->
                                </div><!-- .card-title-group -->
                                <div class="card-search search-wrap" data-search="search">
                                    <div class="card-body">
                                        <div class="search-content">
                                            <a href="javascript:void(0)" class="search-back btn btn-icon toggle-search" data-target="search"><em class="icon ni ni-arrow-left"></em></a>
                                            <input v-model="search" type="text" class="form-control border-transparent form-focus-none" placeholder="Search by user or email">
                                            <button class="search-submit btn btn-icon"><em class="icon ni ni-search"></em></button>
                                        </div>
                                    </div>
                                </div><!-- .card-search -->
                            </div><!-- .card-inner -->
                            <div class="card-inner p-0">
                                <div class="nk-tb-list nk-tb-ulist">
                                    <div class="nk-tb-item nk-tb-head">
                                        <div class="nk-tb-col nk-tb-col-check">
                                            <div class="custom-control custom-control-sm custom-checkbox notext">
                                                <input v-model="selectAll" @click="select" type="checkbox" class="custom-control-input" id="uid">
                                                <label class="custom-control-label" for="uid"></label>
                                            </div>
                                        </div>
                                        <div class="nk-tb-col"><span class="sub-text">User</span></div>
                                        <div class="nk-tb-col tb-col-md"><span class="sub-text">Registered Date</span></div>
                                        <div class="nk-tb-col tb-col-md"><span class="sub-text">Phone</span></div>
                                        <div class="nk-tb-col tb-col-md"><span class="sub-text">Role</span></div>
                                        <div class="nk-tb-col tb-col-md"><span class="sub-text">Status</span></div>
                                        <div class="nk-tb-col nk-tb-col-tools"><span class="sub-text">Action</span></div>
                                    </div><!-- .nk-tb-item -->

                                    <div v-for="details in userList" v-bind:key="details.id" class="nk-tb-item">
                                        <div class="nk-tb-col nk-tb-col-check">
                                            <div class="custom-control custom-control-sm custom-checkbox notext">
                                                <input :value="details.id" v-model="selected" type="checkbox" class="custom-control-input" :id="details.email">
                                                <label class="custom-control-label" :for="details.email"></label>
                                            </div>
                                        </div>
                                        <div class="nk-tb-col">
                                            <a href="javascript:void(0)" v-on:click="EditInfo(details.id)">
                                                <div class="user-card">
                                                    <div class="user-avatar bg-primary text-uppercase">
                                                        <span>{{GetShortName(details)}}</span>
                                                    </div>
                                                    <div class="user-info">
                                                        <span class="tb-lead">{{details.fullName}} <span class="dot dot-success d-md-none ms-1"></span></span>
                                                        <span v-if="details.emailConfirmed"><em class="icon text-success ni ni-check-circle"></em> {{details.email}}</span>
                                                        <span v-else><em class="icon text-danger ni ni-check-circle"></em> {{details.email}}</span>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="nk-tb-col tb-col-md">
                                            <span>{{details.date}}</span>
                                        </div>
                                        <div class="nk-tb-col tb-col-md">
                                            <span>{{details.phoneNumber}}</span>
                                        </div>
                                        <div class="nk-tb-col tb-col-md">
                                            {{details.roleName}}
                                        </div>
                                        <div class="nk-tb-col tb-col-md">
                                            <span v-if="details.isActive" class="tb-status text-success">Active</span>
                                            <span v-else class="tb-status text-danger">In-Active</span>
                                        </div>
                                        <div class="nk-tb-col nk-tb-col-tools">
                                            <ul class="nk-tb-actions gx-1">                                                
                                                <li>
                                                    <div class="drodown">
                                                        <a href="javascript:void(0)" class="dropdown-toggle btn btn-icon btn-trigger" data-bs-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                                                        <div class="dropdown-menu dropdown-menu-end">
                                                            <ul class="link-list-opt no-bdr">
                                                                <li><a href="javascript:void(0)" v-on:click="EditInfo(details.id)"><em class="icon ni ni-eye"></em><span>View Details</span></a></li>
                                                                <li><a href="javascript:void(0)"><em class="icon ni ni-repeat"></em><span>Transaction</span></a></li>
                                                                <li><a href="javascript:void(0)"><em class="icon ni ni-activity-round"></em><span>Activities</span></a></li>
                                                                <li class="divider"></li>
                                                                <li><a  v-on:click="ResetPass(details.id)" href="javascript:void(0)"><em class="icon ni ni-shield-star"></em><span>Reset Pass</span></a></li>
                                                                <li><a v-on:click="Reset2FA(details.id)" href="javascript:void(0)"><em class="icon ni ni-shield-off"></em><span>Reset 2FA</span></a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div><!-- .nk-tb-item -->
                                </div><!-- .nk-tb-list -->
                            </div><!-- .card-inner -->
                            <div class="card-inner">
                                <div class="nk-block-between-md g-3">
                                    <div class="g">
                                        <div class="pagination justify-content-center justify-content-md-start">
                                            <div class="overflow-auto" v-on:click="getPage()">
                                                <b-pagination pills size="md" v-model="currentPage"
                                                              :total-rows="rowCount"
                                                              :per-page="10"
                                                             :first-text="this.$t('Tabel.First')"
                                                              :prev-text="this.$t('Tabel.Previous')"
                                                              :next-text="this.$t('Tabel.Next')"
                                                              :last-text="this.$t('Tabel.Last')"></b-pagination>
                                            </div>
                                        </div><!-- .pagination -->
                                    </div>
                                    <div class="g">
                                        <div class="pagination-goto d-flex justify-content-center justify-content-md-start gx-3 text-capitalize">
                                            <span v-if="currentPage===1 && rowCount === 0"> Showing 0 to 0 of 0 entries</span>
                                            <span v-else-if="currentPage===1 && rowCount < 10"> Showing {{currentPage}} to {{rowCount}} of {{rowCount}} entries</span>
                                            <span v-else-if="currentPage===1 && rowCount >= 11  "> Showing {{currentPage}} to {{currentPage*10}} of {{rowCount}} entries</span>
                                            <span v-else-if="currentPage===1"> Showing {{currentPage}} to {{currentPage*10}} of {{rowCount}} entries</span>
                                            <span v-else-if="currentPage!==1 && currentPage!==pageCount"> Showing {{(currentPage*10)-9}} to {{currentPage*10}} of {{rowCount}} entries</span>
                                            <span v-else-if="currentPage === pageCount"> Showing {{(currentPage*10)-9}} to {{rowCount}} of {{rowCount}} entries</span>
                                        </div>
                                    </div><!-- .pagination-goto -->
                                </div><!-- .nk-block-between -->
                            </div><!-- .card-inner -->
                        </div><!-- .card-inner-group -->
                    </div><!-- .card -->
                </div><!-- .nk-block -->
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'signUp',
        data: function () {
            return {
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                pageSize: '',
                isDesc: false,

                loading: false,
                userList: [],

                selected: [],
                selectAll: false,

                updateApprovalStatus: {
                    id: [],
                    approvalStatus: '',
                },

                user: {
                    id: '',
                    isActive: '',
                    isUser: true,
                },
            }
        },
        watch: {
            search: function (val) {
                this.GetData(val, 1);
            }
        },
        methods: {
            select: function () {
                
                this.selected = [];
                if (!this.selectAll) {
                    for (let i in this.userList) {
                        this.selected.push(this.userList[i].id);
                    }
                }
            },
            
            Reset2FA: function (id) {
                
                this.selected = [];
                this.selected.push(id);
                this.UpdateApprovalStatus('Reset2FA');
            },
            
            ResetPass: function (id) {
                
                this.selected = [];
                this.selected.push(id);
                this.UpdateApprovalStatus('ResetPass');
            },
            

            UpdateApprovalStatus: function (approvalStatus) {
                var root = this;
                this.loading = true;
                
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.updateApprovalStatus.approvalStatus = approvalStatus;
                this.updateApprovalStatus.id = this.selected;

                root.$https.post('/Contact/UpdateApprovalStatus', this.updateApprovalStatus, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        root.selected = [];
                        if (response.data.isSuccess) {
                            root.loading = false;
                            root.$swal({
                                title: root.$t('Notification.Created'),
                                text: 'User Status has been Updated successfully.',
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    root.getPage();
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-info",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },


            GetShortName: function (user) {
                var first = user.firstName.substr(0, 1);
                var second = user.lastName.substr(0, 1);
                return first + second;
            },

            AddSignup: function () {
                this.$router.push('/NewUser')
            },

            getPageSize: function (pageSize, isDesc) {
                
                this.pageSize = pageSize;
                this.isDesc = isDesc;
                this.getPage();
            },
            
            getPage: function () {
                this.GetData(this.search, this.currentPage);
            },

            GetData: function (search, currentPage) {
                var root = this;
                var url = '/Contact/UserList?searchTerm=' + search + '&pageNumber=' + currentPage + '&pageSize=' + this.pageSize + '&isDesc=' + this.isDesc;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.userList = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire({
                            icon: 'error',
                            type: 'error',
                            title: root.$t('Notification.Error'),
                            text: root.$t('Notification.SomethingWrong'),
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });
                }).finally(() => root.loading = false);
            },


            EditInfo: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Contact/UserDetail?id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {

                        root.$router.push({
                            path: '/UserProfileInfo',
                            query: { data: response.data }
                        })
                    }
                });

            },

            EditEmployeeStatus: function (Id, isActive) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.user.id = Id;
                this.user.isActive = !isActive;
                root.$https.post('/EmployeeRegistration/SaveEmployeeStatus', root.user, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != '00000000-0000-0000-0000-000000000000') {
                            root.GetData();
                            root.$swal.fire({
                                icon: 'success',
                                title: 'Employee Status Change',
                                showConfirmButton: false,
                                timer: 1800,
                                timerProgressBar: true,
                            });
                        }
                    },
                        function (error) {
                            root.loading = false;
                            console.log(error);
                        });
            }
        },
        created: function () {
            
        },
        mounted: function () {
            this.GetData(this.search, 1);
        },
        updated: function () {
            
            if (this.selected.length < this.userList.length) {
                this.selectAll = false;
            }
            else if (this.selected.length == this.userList.length) {
                if (this.selected.length == 0) {
                    this.selectAll = false;
                }
                else {
                    this.selectAll = true
                }
            }
        }
    }
</script>