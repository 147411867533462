<template>
    <!-- PAGE-BANNER END -->
    <div class="row">
        <div class="col-12 col-sm-12">
            <div class="card product-sales-main">
                <div class="card-header border-bottom">
                    <div class="col-md-9">
                        <h3 class="card-title mb-0">{{$t('ReviewerDashboard.Registeredcareer')}}</h3>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <div class="input-group">
                                <input v-model="search" class="form-control fc" id="basic-addon6" :placeholder=" $t('Regions.Search')" type="text">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <div class="form-group">
                            <div class="btn-group">
                                <button type="button" class="btn btn-outline-default btn-pill py-1 dropdown-toggle" data-bs-toggle="dropdown">
                                    عمل <span class="caret"></span>
                                </button>
                                <ul class="dropdown-menu" role="menu">
                                    <li>
                                        <a v-on:click="DowmloadCSV()" href="javascript:void(0)" class="btn btn-pill slide-item">{{ $t('Statistics.DownloadCsv') }}</a>
                                    </li>
                                    <li><a v-on:click="DownloadPdf()" href="javascript:void(0)" class="btn btn-pill slide-item">تحميل Pdf</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table border text-nowrap text-md-nowrap table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>رقم للتذكرة</th>
                                    <th>{{$t('AdvisorDashboard.University')}}</th>
                                    <th>{{$t('AdvisorDashboard.TimeAndDate')}}</th>
                                    <th>{{$t('AdvisorDashboard.WorkshopType')}}</th>
                                    <th>
                                        عدد المستفيدين
                                    </th>
                                    <th>
                                        عدد التقيم
                                    </th>
                                    <th>
                                        إعادة النظر
                                    </th>
                                    <th>
                                        {{ $t('City.Action') }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="( sessionCompleted, index) in completedSessions" :key=" sessionCompleted.id" v-bind:class="{'bg_danger':sessionCompleted.isCancel}">
                                    <td v-if="currentPage === 1">
                                        {{index+1}}
                                    </td>
                                    <td v-else>
                                        {{((currentPage*10)-10) +(index+1)}}
                                    </td>
                                    <td>{{ sessionCompleted.code}}</td>
                                    <td>{{ sessionCompleted.universityIdName}}</td>

                                    <td>{{ sessionCompleted.sessionStartTime}}</td>

                                    <td>
                                        <span v-if="sessionCompleted.subjectOfSession==1"> {{$t('AdvisorDashboard.IndividualSession')}}</span>
                                        <span v-if="sessionCompleted.subjectOfSession==2"> {{$t('AdvisorDashboard.GroupSession')}}</span>
                                        <span v-if="sessionCompleted.subjectOfSession==3">{{$t('AdvisorDashboard.WorkShop')}}</span>
                                    </td>
                                    <td>{{ sessionCompleted.feedBackRequired}}</td>
                                    <td v-if="survey=='first'">{{ sessionCompleted.feedBackGive}}</td>
                                    <td v-if="survey=='second'">{{ sessionCompleted.secondFeedBackGive}}</td>
                                    <td v-if="survey=='first'">{{parseFloat(sessionCompleted.feedBackRequired==0? 0 : sessionCompleted.feedBackGive / sessionCompleted.feedBackRequired * 100).toFixed(0)}}%</td>
                                    <td v-if="survey=='second'">{{parseFloat(sessionCompleted.feedBackRequired==0? 0 : sessionCompleted.secondFeedBackGive / sessionCompleted.feedBackRequired * 100).toFixed(0)}}%</td>
                                    <td>
                                        <a v-on:click="Details(sessionCompleted.id)" href="javascript:void(0)" class="btn btn-primary"><i class="fa fa-eye" aria-hidden="true"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="row">
                        <div class="col-md-6 text-right">
                            <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                            <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        </div>
                        <div class="col-md-6">
                            <div class="overflow-auto" v-on:click="getPage()" style="float:left">
                                <b-pagination pills size="md" v-model="currentPage"
                                              :total-rows="rowCount"
                                              :per-page="10"
                                              :first-text="this.$t('Tabel.First')"
                                              :prev-text="this.$t('Tabel.Previous')"
                                              :next-text="this.$t('Tabel.Next')"
                                              :last-text="this.$t('Tabel.Last')"></b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <loading :name="loading" :active.sync="loading"
                     :can-cancel="false"
                     :is-full-page="true">
            </loading>
        </div>
    </div>

</template>

<script>
    import moment from 'moment';
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    export default {
        props: ['survey'],
        name: 'ReviewerFeedBackSessionList',
        components: {
            Loading
        },
        data: function () {
            return {
                render: 0,

                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                pageSize: '',
                loading: false,
                completedSessions: [],

            }
        },
        watch: {
            search: function (val) {
                this.CompletedData(val, 1);
            },

        },
        methods: {

            GoTo: function (link) {
                this.$router.push(link);
            },


            getPage: function () {
                this.CompletedData(this.search, this.currentPage);
            },


            CompletedData: function (search, currentPage) {
                var url = '/Home/ReviewerFeedBackSessionList?searchTerm=' + search + '&pageNumber=' + currentPage;

                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.completedSessions = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        type: 'error',
                        title: root.$t('Notification.Error'),
                        text: root.$t('Notification.SomethingWrong'),
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                }).finally(() => root.loading = false);
            },

            DowmloadCSV: function () {
                var root = this;
                var token = '';
                root.loading = true;

                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Home/ReviewerFeedBackSessionListCsv?survey=' + this.survey, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Student Report.csv');
                        document.body.appendChild(link);
                        link.click();
                        root.loading = false;

                    });
            },

            DownloadPdf: function () {
                var root = this;
                root.loading = true;

                root.$https.get('/Home/ReviewerFeedBackSessionListPdf?survey=' + this.survey, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        var date = moment().format('DD MMM YYYY');
                        link.setAttribute('download', 'Student Report Pdf ' + date + '.pdf');
                        document.body.appendChild(link);
                        link.click();
                        root.loading = false;

                    });
            },
            Details: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Home/SessionStudentDetails?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$router.push({
                            path: '/SessionStudentDetails',
                            query: { data: response.data, screenpath: root.$route.path }
                        })
                    }
                });
            },
        },

        created: function () {


        },
        mounted: function () {
            this.CompletedData(this.search, 1);
        },
    }
</script>
<style scoped>
    .card-header {
        display: flex;
        justify-content: space-between;
    }

    .banner {
        background-image: url(/assets/images/landing/123.jpg);
        background-repeat: no-repeat;
        background-size: contain;
    }

    .banner h1 {
        color: #009000;
    }

    .card-header h4 {
        color: #009000;
    }

    .card-body p {
        color: #009000;
    }

    .bg_danger {
        background-color: rgb(255 107 107 / 10%);
    }

    .slide-item:hover {
        background-color: rgba(0, 144, 0, 0.9);
        color: white !important;
    }

    @media screen and (min-width: 280px) and (max-width: 1080px) {
        .btn {
            width: 150px !important;
        }
    }
</style>



