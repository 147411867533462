<template>
    <div class="page-main">
        <div class="card p-5 mb-5 mt-3">
            <div class="backtomain mb-3">
                <div class="logo mr-3">

                </div><br>
            </div>
            <div class="signup_form_title mb-5">
                <h1>
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;"> {{ $t('CreateWorkshop.Heading') }}</font>
                    </font>
                </h1>
            </div>

            <div class="row">
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('CreateWorkshop.ServiceType') }}</label>
                    <typesofsessiondropdown v-model="createWorkShopSession.workshopType"
                        :values="createWorkShopSession.workshopType" @input="SessionRender()"
                        :disabled="role == 'Super Admin' ? true : false" />

                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('CreateWorkshop.ServiceAddres') }}</label>
                    <sessiontypedropdown v-model="createWorkShopSession.sessionTypeId"
                        :values="createWorkShopSession.sessionTypeId" :subjectOfSession="'WorkShop'"
                        @input="getSessionDetail" :disabled="role == 'Super Admin' ? true : false" />
                    <div class="checkbox">
                        <div class="custom-checkbox custom-control">
                            <input type="checkbox" v-model="createWorkShopSession.hideSessionType" data-checkboxes="mygroup"
                                class="custom-control-input" id="checkbox">
                            <label for="checkbox" class="custom-control-label">اخفاء عنوان الخدمة</label>
                        </div>
                    </div>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('CreateWorkshop.SessionStartDate') }} </label>
                    <datetimepicker v-model="createWorkShopSession.sessionStart"
                        :values="createWorkShopSession.sessionStart" @input="GetSessionDuration()"
                        :disabled="role == 'Super Admin' ? true : false" />
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('CreateWorkshop.SessionDuration') }}</label>
                    <sessiondurationsdropdown @input="GetSessionDuration" :values="createWorkShopSession.sessionDurationId"
                        :disabled="role == 'Super Admin' ? true : false" />
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label for="inputState"> {{ $t('CreateWorkshop.AvailableAppointments') }}</label>
                    <datetimepicker v-model="createWorkShopSession.sessionEnd" :values="createWorkShopSession.sessionEnd"
                        :disabled="true" :key="sessionEndRender" />
                </div>

                <!--<div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('CreateWorkshop.AffiliateUniversities') }}</label>
                    <universitydropdown v-model="createWorkShopSession.universityId" :values="createWorkShopSession.universityId" :alluniversities="true" @input="SessionRender()" :disabled="role=='Super Admin'?true:false" />
                </div>-->
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('CreateWorkshop.AffiliateUniversities') }}</label>
                    <universitymultiselectdropdown v-model="createWorkShopSession.universityId"
                        :values="createWorkShopSession.universityId" :selectAllUniversity="selectAllUniversity"
                        :key="universityRender" @input="SessionRender()" :disabled="role == 'Super Admin' ? true : false" />
                    <div class="checkbox">
                        <div class="custom-checkbox custom-control">
                            <input type="checkbox" v-model="selectAllUniversity" @change="SlectAllUniversity()"
                                data-checkboxes="mygroup" class="custom-control-input" id="checkbox-3" disabled
                                v-if="role == 'Super Admin'">
                            <input type="checkbox" v-model="selectAllUniversity" @change="SlectAllUniversity()"
                                data-checkboxes="mygroup" class="custom-control-input" id="checkbox-3" v-else>
                            <label for="checkbox-3" class="custom-control-label">الجميع</label>
                        </div>
                    </div>
                </div>

                <div v-if="createWorkShopSession.workshopType == 1" class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('CreateWorkshop.RemoteLink') }} </label>
                    <input v-model="createWorkShopSession.remoteLink" type="text" class="form-control p-3" disabled
                        v-if="role == 'Super Admin'">
                    <input v-model="createWorkShopSession.remoteLink" type="text" class="form-control p-3" v-else>
                </div>

                <div v-if="createWorkShopSession.workshopType == 2" class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('CreateWorkshop.MeetingPlace') }}</label>
                    <sessionlocationdropdown v-model="createWorkShopSession.sessionLocationId"
                        :values="createWorkShopSession.sessionLocationId" :subjectOfSession="3" :multiuniversity="true"
                        :universityId="createWorkShopSession.universityId" :key="sessionRender"
                        :disabled="role == 'Super Admin' ? true : false" />
                </div>

                <div v-if="createWorkShopSession.workshopType == 2" class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('CreateWorkshop.NumberOfStudents') }}</label>
                    <input v-model="createWorkShopSession.studentLimit" type="number" class="form-control" disabled
                        v-if="role == 'Super Admin'">
                    <input v-model="createWorkShopSession.studentLimit" type="number" class="form-control" v-else>
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('CreateWorkshop.UndergraduateLevelForStudents') }}</label>
                    <universtylevelmultiselectdropdown v-model="universityLevelIds" v-bind:values="universityLevelIds"
                        :selectAllLevel="selectAllLevel" :key="universityLevelRender"
                        :disabled="role == 'Super Admin' ? true : false" />
                    <div class="checkbox">
                        <div class="custom-checkbox custom-control">
                            <input type="checkbox" v-model="selectAllLevel" @change="SlectAllLevel()"
                                data-checkboxes="mygroup" class="custom-control-input" id="checkbox-2" disabled
                                v-if="role == 'Super Admin'">
                            <input type="checkbox" v-model="selectAllLevel" @change="SlectAllLevel()"
                                data-checkboxes="mygroup" class="custom-control-input" id="checkbox-2" v-else>
                            <label for="checkbox-2" class="custom-control-label">الجميع</label>
                        </div>
                    </div>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.Gender') }}</label>
                    <genderdropdown v-model="createWorkShopSession.gender" :values="createWorkShopSession.gender"
                        :both="true" :disabled="role == 'Super Admin' ? true : false" />
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <div class="input-group">
                        <label>تاريخ انتهاء التسجيل</label>
                        <datepicker v-model="createWorkShopSession.registrationEndDate" />
                    </div>
                </div>

                <div class="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <label>{{ $t('SessionType.Description') }}</label>
                    <textarea v-model="description" class="form-control p-3" disable readonly />
                </div>
            </div>

            <div class="row mt-3 btn_student_group ">
                <div class="col-md-12">
                    <button v-on:click="SaveNewUser()" v-bind:disabled="$v.$invalid" class="btn btn-primary register">{{
                        $t('CreateWorkshop.Save') }}</button>
                    <a v-on:click="GoTo()" class="btn btn-light">{{ $t('CreateWorkshop.Cancel') }}</a>
                </div>
            </div>

            <loading :name="loading" :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>

        </div>
    </div>
</template>
<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import moment from 'moment';
import Loading from 'vue-loading-overlay';
import "vue-loading-overlay/dist/vue-loading.css";

export default {
    name: 'CreateWorkshop',
    components: {
        Loading
    },
    data() {
        return {
            loading: false,
            selectAllLevel: false,
            selectAllUniversity: false,
            universityRender: 0,
            universityLevelRender: 0,
            sessionEndRender: 0,
            sessionRender: 0,
            minute: 0,
            description: '',
            role: '',
            universityLevelIds: [],
            isPresense:false,
            createWorkShopSession: {
                id: '00000000-0000-0000-0000-000000000000',
                workshopType: '',
                sessionTypeId: '',
                sessionStart: '',
                sessionDurationId: '',
                sessionEnd: '',
                universityId: '',
                universityLevelId: '',
                sessionLocationId: '',
                studentLimit: 0,
                remoteLink: '',
                gender: '',
                hideSessionType: false,
                isQuick: true,
                registrationEndDate: '',
             
            },
        }
    },
    validations: {
        createWorkShopSession:
        {
            workshopType:
            {
                required
            },
            sessionTypeId:
            {
                required
            },
            sessionStart:
            {
                required
            },
            sessionDurationId:
            {
                required
            },
            sessionEnd:
            {
                required
            },
            universityId:
            {
                required
            },
            gender:
            {
                required
            },
            sessionLocationId: {
                required: requiredIf(function () {
                    return this.isPresenseMethod(); // Corrected the function call.
                })
            },

        }
    },

    methods: {
        SessionRender: function () {
            this.sessionRender++;
        },
        getSessionDetail(data) {
            /*const newString = data.description.replace(/\s+/g, ' ').trim();*/
            this.description = data.description;
            this.createWorkShopSession.sessionTypeId = data.id;          
        },
        isPresenseMethod() {
            
            if (this.createWorkShopSession.workshopType == 2) {
                return true;
            }
            else {
                return false;
            }
        },
        SlectAllLevel: function () {
            this.universityLevelIds = [];
            this.universityLevelRender++;
        },

        SlectAllUniversity: function () {
            
            this.createWorkShopSession.universityId = [];
            this.universityRender++;
        },


        GetSessionDuration: function (duration) {
            if (duration != undefined) {
                this.createWorkShopSession.sessionDurationId = duration.id;
                this.minute = duration.minutes;
                if (this.createWorkShopSession.sessionStart != null && this.createWorkShopSession.sessionStart != '') {
                    this.createWorkShopSession.sessionEnd = moment(this.createWorkShopSession.sessionStart).add(duration == undefined ? 0 : duration.minutes, 'minutes').format('llll');
                    this.sessionEndRender++;
                }
            }
            else {
                if (this.createWorkShopSession.sessionStart != null && this.createWorkShopSession.sessionStart != '') {
                    this.createWorkShopSession.sessionEnd = moment(this.createWorkShopSession.sessionStart).add(this.minute, 'minutes').format('llll');
                    this.sessionEndRender++;
                }
            }
        },

        GoTo: function () {
            if (this.role == "Super Admin") {
                this.$router.push('/SessionsReport');
            }
            else {
                this.$router.push('/AdvisorDashboard');
            }
        },

        getLevelId: function (value) {
            var levelId = [];
            for (var i = 0; i < value.length; i++) {
                levelId[i] = value[i].id
            }
            return levelId;
        },
        SendEmailDetails: function (id, email, sms) {
            var root = this;
            var token = '';
            this.loading = true;

            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }


            root.$https.get('/Sessions/SendGroupSessionEmail?sessionId=' + id + '&email=' + email + '&sms=' + sms, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {
                    //root.$router.push({
                    //    path: '/SessionActivate',
                    //    query: { data: response.data }
                    //})
                }
                root.loading = false;

            });

        },
        SaveNewUser: function () {
            var root = this;
            this.loading = true;

            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }

            if (this.universityLevelIds != null && this.universityLevelIds != undefined && this.universityLevelIds != '') {
                this.createWorkShopSession.universityLevelId = this.getLevelId(this.universityLevelIds);
            }

            //if (this.createWorkShopSession.universityId != null && this.createWorkShopSession.universityId != undefined && this.createWorkShopSession.universityId != '') {
            //    var univrsityId = this.createWorkShopSession.universityId;
            //    this.createWorkShopSession.universityId = [];
            //    this.createWorkShopSession.universityId.push({ id: univrsityId });
            //}

            this.createWorkShopSession.isQuick = true;

            root.$https.post('/Sessions/SaveWorkShopSession', this.createWorkShopSession, { headers: { "Authorization": `Bearer ${token}` } })
                .then(function (response) {
                    console.log(response.data)
                    if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                        //if (response.data.permissionOnSessionCreation || response.data.smsPermissionOnSessionCreation) {
                        //    root.SendEmailDetails(response.data.id, response.data.permissionOnSessionCreation, response.data.smsPermissionOnSessionCreation);
                        //}
                        root.SendEmailDetails(response.data.id, true, false);


                        root.$swal({
                            title: root.$t('Notification.Created'),
                            text: root.$t('Notification.SessionCreated'),
                            type: 'success',
                            icon: 'success',
                            confirmButtonClass: "btn btn-success",
                            buttonsStyling: false
                        });
                        root.loading = false;
                        root.GoTo();
                    }
                    else {
                        root.$swal({
                            title: root.$t('Notification.Error'),
                            text: response.data.isAddUpdate,
                            type: 'error',
                            icon: 'error',
                            confirmButtonClass: "btn btn-danger",
                            buttonsStyling: false
                        });
                        root.loading = false;
                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            type: 'error',
                            title: root.$t('Notification.Error'),
                            text: root.$t('Notification.SomethingWrong'),
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                    root.loading = false;
                })
                .finally(() => root.loading = false);
        },
    },
    created: function () {
        if (this.$route.query.data != undefined) {
            this.createWorkShopSession = this.$route.query.data;
            this.universityLevelIds = this.$route.query.data.universityLevelId;

        }
        this.role = localStorage.getItem('RoleName');
    },
    mounted: function () {
        if (this.$route.query.data != undefined) {
            this.minute = this.$route.query.data.minutes;
            if (this.createWorkShopSession.sessionStart != null && this.createWorkShopSession.sessionStart != '') {
                this.createWorkShopSession.sessionEnd = moment(this.createWorkShopSession.sessionStart).add(this.minute, 'minutes').format('llll');
                this.sessionEndRender++;
            }
        }
    },
}
</script>
<style scoped>.btn-primary {
    margin-left: 10px;
}

@media only screen and (max-width: 600px) {
    .register {
        max-width: 40%;
    }

    .login {
        max-width: 40%;
    }

    .btn_student_group {
        justify-content: center;
    }
}</style>