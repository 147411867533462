<template>
    <div class="page-main">
        <div class=" py-5 mb-5">
            <div class="backtomain mb-3">
                <div class="logo mr-3">

                </div><br>
            </div>
            <div class="signup_form_title mb-5">
                <h1><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> {{ $t('AdminRegistered.Heading') }}</font></font></h1>

            </div>

            <div class="row">
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{$t('AdminRegistered.FirstName')}} {{$t('AdminRegistered.FirstNameDescription')}}</label>
                    <input v-model="$v.studentRegister.firstName.$model" type="text" class="form-control">
                    <span v-if="$v.studentRegister.firstName.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.firstName.arabic">يرجى كتابه الاسم باللغة العربية<br /></span>
                        <span v-if="!$v.studentRegister.firstName.minLength">يرجى كتابه الاسم بالكامل</span>
                    </span>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{$t('AdminRegistered.FatherName')}} {{$t('AdminRegistered.FatherNameDescription')}}</label>
                    <input v-model="$v.studentRegister.middleName.$model" type="text" class="form-control">
                    <span v-if="$v.studentRegister.middleName.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.middleName.arabic">يرجى كتابه اسم الاب باللغة العربية<br /></span>
                        <span v-if="!$v.studentRegister.middleName.minLength">يرجى كتابه اسم الاب بالكامل</span>
                    </span>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{$t('AdminRegistered.FamilyName')}}{{$t('AdminRegistered.FamilyNameDescription')}}</label>
                    <input v-model="$v.studentRegister.lastName.$model" type="text" class="form-control">
                    <span v-if="$v.studentRegister.lastName.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.lastName.arabic">يرجى كتابه لقب العائلة باللغة العربية</span>
                        <span v-if="!$v.studentRegister.lastName.minLength">يرجى كتابه لقب العائلة بالكامل</span>
                    </span>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{$t('AdminRegistered.IdentificationNumber')}}</label>
                    <input v-model="$v.studentRegister.nationalId.$model" type="text" class="form-control">
                    <span v-if="$v.studentRegister.nationalId.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.nationalId.number">لا يمكن أن يكون أكثر أو أقل من 10 أرقام ويبدأ دائمًا بـ "1" أو "2"</span>

                    </span>
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{$t('AdminRegistered.MobileNumber')}}</label>
                    <input v-model="$v.studentRegister.phoneNumber.$model" type="text" class="form-control" placeholder="05xxxxxxxx">
                    <span v-if="$v.studentRegister.phoneNumber.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.phoneNumber.phoneNumber">الرجاء كتابة رقم هاتف محمول صحيح</span>
                        <span v-if="!$v.studentRegister.phoneNumber.minLength">الرجاء كتابة رقم هاتف محمول صحيح</span>

                    </span>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{$t('AdminRegistered.DateOfBirth')}} {{$t('AdminRegistered.DateOfBirthDescription')}}</label>
                    <datepicker v-model="studentRegister.dateOfBirth" :value="studentRegister.dateOfBirth" />
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{$t('AdminRegistered.Gender')}}</label>
                    <genderdropdown v-model="$v.studentRegister.gender.$model" :values="studentRegister.gender" />
                    <span v-if="$v.studentRegister.gender.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.gender.required">يرجى اختيار نوع الجنس</span>
                    </span>
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4" v-if="!isUpdate">
                    <label>{{$t('AdminRegistered.Email')}}</label>
                    <input v-model="$v.studentRegister.email.$model" type="email" class="form-control">
                    <span v-if="$v.studentRegister.email.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.email.email">الرجاء إدخال بريد إلكتروني صحيح</span>
                    </span>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4" v-if="!isUpdate">
                    <label>{{$t('AdminRegistered.Password')}}</label>
                    <input v-model="$v.studentRegister.password.$model" v-bind:type="showPassword==false? 'password' : 'text'" class="form-control">
                    <span v-if="$v.studentRegister.password.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.password.minLength">يجب ان لا تقل عن 8 حروف او ارقام</span>
                      =
                    </span>
                    <label class="showpass">
                        <a v-on:click="showPasswords()" href="javascript:void(0)" class="fs-10">عرض كلمة المرور</a>
                    </label>
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4" v-if="!isUpdate">
                    <label>{{$t('AdminRegistered.ConfirmPassword')}}</label>
                    <input v-model="$v.studentRegister.confirmPassword.$model" v-bind:type="showPassword==false? 'password' : 'text'" class="form-control">
                    <span v-if="$v.studentRegister.confirmPassword.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.confirmPassword.sameAsPassword">كلمة المرور غير مطابقة</span>
                    </span>
                    <label class="showpass">
                        <a v-on:click="showPasswords()" href="javascript:void(0)" class="fs-10">عرض كلمة المرور</a>
                    </label>
                </div>


            </div>
            <div class="form-group col-md-8">
                <div class="material-switch">
                    <input id="someSwitchOptionPrimary" name="someSwitchOption001" v-model="studentRegister.isActive" type="checkbox" checked />
                    <label for="someSwitchOptionPrimary" class="label-primary"></label>
                </div>
            </div>
            <div class="row mt-3 btn_student_group ps-3">
                <button v-on:click="SaveNewUser" class="btn btn-primary register col-sm-3 col-md-2 col-lg-1 col-xl-1">{{$t('AdminRegistered.Register')}}</button>
                <button v-on:click="Close" class="btn btn-light ms-3 login col-sm-3 col-md-2 col-lg-2 col-xl-2">{{$t('AdminRegistered.Cancel')}}</button>
            </div>
            <loading :name="loading" :active.sync="loading"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
        </div>
    </div>
</template>

<script>
    import { required, maxLength, sameAs, email, minLength } from 'vuelidate/lib/validators';
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    export default {
        components: {
            Loading
        },
        data() {
            return {
                loading: false,
                isUpdate: false,
                showPassword: false,
                studentRegister: {
                    id: '00000000-0000-0000-0000-000000000000',
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    roleName: 'Super Admin',
                    nationalId: '',
                    phoneNumber: '',
                    dateOfBirth: '',
                    gender: '',
                    email: '',                
                    password: '',
                    confirmPassword: '',
                    isActive: true,
                },
            }
        },
        validations: {
            studentRegister:
            {
                firstName:
                {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    arabic(name) {
                        return /^[\u0621-\u064A\s]+$/.test(name)
                    }
                },
                middleName:
                {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    arabic(name) {
                        return /^[\u0621-\u064A\s]+$/.test(name)
                    }
                },
                lastName:
                {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    arabic(name) {
                        return /^[\u0621-\u064A\s]+$/.test(name)
                    }
                },
               
                gender:
                {
                    required
                },
                
                nationalId:
                {
                    required,
                    number(id) {
                        return /\b[12]\d{9}\b/.test(id)
                    }
                },
                phoneNumber:
                {
                    maxLength: maxLength(15),
                    minLength: minLength(10),
                    phonenumber(number) {
                        return  /^[0-9\u0660-\u0669]+$/.test(number)
                    }
                },
                email:
                {
                    required,
                    email
                },
             
                password:
                {
                    required,
                    minLength: minLength(8),
                   
                },
                confirmPassword:
                {
                    required,
                    sameAsPassword: sameAs('password')
                },
            }
        },

        methods: {
            showPasswords: function () {

                this.showPassword = this.showPassword == true ? false : true;
            },
            GoToHome: function () {
                this.$router.push('/')
            },

            Close: function () {
                this.$router.push('/AdminList')
            },

            SaveNewUser: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.post('/account/StudentRegister', this.studentRegister, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {

                            if (root.studentRegister.id == '00000000-0000-0000-0000-000000000000') {
                                root.SendEmailVerification(response.data.id);
                            }                            

                            root.$swal({
                                title: root.$t('Notification.Created'),
                                text: root.$t('Notification.UserCreated'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            });
                            root.loading = false;
                            root.Close();
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-info",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            SendEmailVerification: function (id) {
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.get('/account/SendEmailVerification?id=' + id, { headers: { "Authorization": `Bearer ${token}` } });

            },
        },
        created: function () {
            if (this.$route.query.data != undefined) {
                this.studentRegister = this.$route.query.data;
                this.isUpdate = true;
            }
        },
    }
</script>
<style scoped>
    @media only screen and (max-width: 600px) {
        .register {
            max-width: 40%;
        }
        .login {
            max-width: 40%;
        }
        .btn_student_group {
            justify-content: center;
        }
    }
</style>