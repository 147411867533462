import Vue from 'vue'
import VueRouter from 'vue-router'
//import i18n from '../i18n'
import Login from '../views/Login.vue'
import Register from '../views/Registered.vue'
import Home from '../views/Layout.vue'
import Dashboard from '../components/Dashboard/Dashboard.vue'
import StudentDashboard from '../components/Dashboard/StudentDashboard.vue'
import AdvisorDashboard from '../components/Dashboard/AdvisorDashboard.vue'
import ReviewerDashboard from '../components/Dashboard/ReviewerDashboard.vue'
import LandingPage from '../views/landingPage.vue'
import JoinStudentSession from '../components/WorkshopList/JoinStudentSession.vue'
import JoinQuickWorkShop from '../components/WorkshopList/JoinQuickWorkShop.vue'
import LandingPg from '../views/landingPg.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'
import FandQs from '../views/FandQs.vue'

//import LandingPage from '../LandingPage'
import TendencyScale from '../components/LandingPage/TendencyScale.vue'

//import App from '../App.vue'
import UserList from '../components/User/UsersList.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'

import SessionDurations from '../components/SessionDurations/SessionDurations.vue'
import AddSessionDurations from '../components/SessionDurations/AddSessionDurations.vue'
import Region from '../components/Region/Region.vue'
import addRegion from '../components/Region/addRegion.vue'
import Qualification from '../components/Qualification/Qualification.vue'
import AddQualification from '../components/Qualification/AddQualification.vue'
import UniversityLevel from '../components/UniversityLevel/UniversityLevel.vue'
import AddUniversityLevel from '../components/UniversityLevel/AddUniversityLevel.vue'
import Specialization from '../components/Specialization/Specialization.vue'
import AddSpecialization from '../components/Specialization/AddSpecialization.vue'

import University from '../components/University/University.vue'
import AddUniversity from '../components/University/AddUniversity.vue'

import StudentCategory from '../components/StudentCategory/StudentCategory.vue'
import addStudentCategory from '../components/StudentCategory/addStudentCategory.vue'

import EmailConfirmed from '../components/User/EmailConfirmed.vue'
import PhoneConfirmed from '../components/User/PhoneConfirmed.vue'

import City from '../components/City/City.vue'
import addCity from '../components/City/addCity.vue'

//College
import College from '../components/College/College.vue'
import addCollege from '../components/College/addCollege.vue'


import SessionType from "../components/SessionType/SessionType.vue"
import AddSessionType from "../components/SessionType/AddSessionType.vue"

import SessionLocation from "../components/SessionLocation/SessionLocation.vue"
import AddSessionLocation from "../components/SessionLocation/AddSessionLocation.vue"

import AdminRegistered from '../components/CreateAdminAccount/AdminRegistered.vue'
import AdviserRegistered from '../components/CreateAdviserAccount/AdviserRegistered.vue'
import ReviewerRegistered from '../components/CreateReviewerAccount/ReviewerRegistered.vue'
import CreateStudentSession from '../components/Sessions/CreateStudentSession.vue'
import joinindividualsession from '../components/WorkshopList/JoinIndividualSession.vue'

import StudentList from '../components/StudentList/StudentList.vue'
import AdminList from '../components/CreateAdminAccount/AdminList.vue'
import AdviserList from '../components/CreateAdviserAccount/AdviserList.vue'
import ReviewerList from '../components/CreateReviewerAccount/ReviewerList.vue'
import CreateGroupSession from '../components/Sessions/CreateGroupSession.vue'
import CreatePreviousGroupSession from '../components/Sessions/CreatePreviousGroupSession.vue'
import CreateWorkshop from '../components/Sessions/CreateWorkshop.vue'
import CreateQuickWorkshop from '../components/Sessions/CreateQuickWorkshop.vue'
import CreatePreviousWorkshop from '../components/Sessions/CreatePreviousWorkshop.vue'

import Profile from '../components/Profile/Profile.vue'
import SessionActivate from '../components/SessionsDetails/SessionActivate.vue'

/*Adviser Slot*/
import AdviserSlots from '../components/AdviserSlot/AdviserSlots.vue'
import AddAdviserSlot from '../components/AdviserSlot/AddAdviserSlot.vue'
import AddAdviserMultiUniversitySlot from '../components/AdviserSlot/AddAdviserMultiUniversitySlot.vue'

import AdviserStudentList from '../components/AdviserStudent/AdviserStudentList.vue'
import EditAdviserStudent from '../components/AdviserStudent/EditAdviserStudent.vue'

import StudentFeedback from '../components/StudentFeedback/StudentFeedback.vue'
import StudentSecondaryFeedback from '../components/StudentFeedback/StudentSecondaryFeedback.vue'
import WorkshopList from '../components/WorkshopList/WorkshopList.vue'
import SessionDetail from '../components/WorkshopList/SessionDetail.vue'
import GroupSessionList from '../components/GroupSessionList/GroupSessionList.vue'

import SessionDetails from '../components/SessionsDetails/SessionDetails.vue'
import SessionEnded from '../components/SessionsDetails/SessionEnded.vue'
import SessionStudentDetails from '../components/SessionsDetails/SessionStudentDetails.vue'

/*Adviser Session Location*/
import AddAdviserSessionLocation from '../components/AdviserSessionLocation/AddSessionLocation.vue'
import AdviserSessionLocation from '../components/AdviserSessionLocation/SessionLocation.vue'

/*Individual Student Session*/
import IndividualStudentSession from '../components/SessionAccepted/IndividualStudentSession.vue'
import SessionView from '../components/SessionsDetails/SessionView.vue'

/*Adviser Session Type*/
import AddAdviserSessionType from '../components/AdviserSessionType/AddAdviserSessionType.vue'
import AdviserSessionType from '../components/AdviserSessionType/AdviserSessionType.vue'

/*Holiday*/
import AddHoliday from '../components/Holiday/AddHoliday.vue'
import Holidays from '../components/Holiday/Holidays.vue'

import SurveyQuestions from '../components/SurveyQuestions/SurveyQuestions.vue'
import SecondSurveyQuestions from '../components/SurveyQuestions/SecondSurveyQuestions.vue'
import Statistics from '../components/Statistics/Statistics.vue'
import ContactUs from '../components/Contact/ContactUs.vue'
import ContactList from '../components/Contact/ContactList.vue'
import ContactDetail from '../components/Contact/ContactDetail.vue'

import StudentReport from '../components/Report/StudentReport.vue'
import ReportRequirement from '../components/Report/ReportRequirement.vue'
import SessionsReport from '../components/Report/SessionsReport.vue'
import SessionsFeedBackReport from '../components/Report/SessionsFeedBackReport.vue'
import SessionSecondSurveyReport from '../components/Report/SessionSecondSurveyReport.vue'

import VueSession from 'vue-session'
import Permissions from '../components/Permissions/Permissions.vue'
import SecondSurveyEmailDays from '../components/Permissions/SecondSurveyEmailDays.vue'
import EmailNotification from '../components/EmailNotification/EmailNotification.vue'
import ImportStudentDetail from '../components/ImportStudentDetails/ImportStudentDetail.vue'

//SessionMultiUniversity
import CreateMultiUniversityQuickWorkshop from '../components/SessionMultiUniversity/CreateMultiUniversityQuickWorkshop.vue'
import CreateMultiUniversityWorkshop from '../components/SessionMultiUniversity/CreateMultiUniversityWorkshop.vue'
import CreateMultiUniversityGroupSession from '../components/SessionMultiUniversity/CreateMultiUniversityGroupSession.vue'


import SyncSetting from '../components/SyncSetting/SyncSetting.vue'
import SyncDataReport from '../components/SyncSetting/SyncDataReport.vue'
import NotSyncDataReport from '../components/SyncSetting/NotSyncDataReport.vue'
import LogReport from '../components/SyncSetting/LogReport.vue'


Vue.use(VueSession);
Vue.use(VueRouter);

const routes = [


    {
        path: '/',

        component:
        {
            render(c) { return c('router-view') }
        },
        children:
            [
                {
                    path: '/LandingPage',
                    name: 'LandingPage',
                    component: LandingPage
                },
                {
                    path: '/TermsAndConditions',
                    name: 'TermsAndConditions',
                    component: TermsAndConditions
                },
                {
                    path: '/FandQs',
                    name: 'FandQs',
                    component: FandQs
                },
                {
                    path: '/',
                    name: 'LandingPg',
                    component: LandingPg,
                    children: [
                        {
                            path: '/TendencyScale',
                            name: 'TendencyScale',
                            component: TendencyScale,
                        }
                        ]
                },
                {
                    path: '/ContactUs',
                    name: 'ContactUs',
                    component: ContactUs,
                },
                {
                    path: '/Register',
                    name: 'Register',
                    component: Register
                },
                {
                    path: '/ResetPassword',
                    name: 'ResetPassword',
                    component: ResetPassword
                },
                {
                    path: '/ForgotPassword',
                    name: 'ForgotPassword',
                    component: ForgotPassword
                },
                {
                    path: '/EmailConfirmed',
                    name: 'EmailConfirmed',
                    component: EmailConfirmed
                },
                {
                    path: '/PhoneConfirmed',
                    name: 'PhoneConfirmed',
                    component: PhoneConfirmed
                },
                {
                    path: '/Login',
                    name: 'Login',
                    component: Login
                },
                {
                    path: '/JoinStudentSession',
                    name: 'JoinStudentSession',
                    component: JoinStudentSession
                },
                {
                    path: '/JoinQuickWorkShop',
                    name: 'JoinQuickWorkShop',
                    component: JoinQuickWorkShop
                },
                {
                    path: '/joinindividualsession',
                    name: 'joinindividualsession',
                    component: joinindividualsession
                },
                {
                    path: '/StudentFeedbacks',
                    name: 'StudentFeedbacks',
                    component: StudentFeedback
                },
                {
                    path: '/StudentSecondaryFeedback',
                    name: 'StudentSecondaryFeedback',
                    component: StudentSecondaryFeedback
                },
                {
                    path: '/Home',
                    name: 'Home',
                    component: Home,
                    children: [
                        {
                            path: '/Dashboard',
                            name: 'Dashboard',
                            component: Dashboard,
                            children: [
                                {
                                    path: '/addRegion',
                                    name: 'addRegion',
                                    component: addRegion
                                },
                                {
                                    path: '/SecondSurveyEmailDays',
                                    name: 'SecondSurveyEmailDays',
                                    component: SecondSurveyEmailDays
                                },
                                {
                                    path: '/Regions',
                                    name: 'Regions',
                                    component: Region
                                },
                                {
                                    path: '/AddSessionDurations',
                                    name: 'AddSessionDurations',
                                    component: AddSessionDurations
                                },
                                {
                                    path: '/SessionDuration',
                                    name: 'SessionDuration',
                                    component: SessionDurations
                                },
                                {
                                    path: '/addCity',
                                    name: 'addCity',
                                    component: addCity
                                },
                                {
                                    path: '/addCollege',
                                    name: 'addCollege',
                                    component: addCollege
                                },
                                {
                                    path: '/College',
                                    name: 'College',
                                    component: College
                                },
                                {
                                    path: '/SyncSetting',
                                    name: 'SyncSetting',
                                    component: SyncSetting
                                },
                                {
                                    path: '/NotSyncDataReport',
                                    name: 'NotSyncDataReport',
                                    component: NotSyncDataReport
                                },
                                {
                                    path: '/SyncDataReport',
                                    name: 'SyncDataReport',
                                    component: SyncDataReport
                                },
                                {
                                    path: '/LogReport',
                                    name: 'LogReport',
                                    component: LogReport
                                },
                                {
                                    path: '/Cities',
                                    name: 'Cities',
                                    component: City
                                },
                                {
                                    path: '/addStudentCategory',
                                    name: 'addStudentCategory',
                                    component: addStudentCategory
                                },
                                {
                                    path: '/StudentCategories',
                                    name: 'StudentCategories',
                                    component: StudentCategory
                                },
                                {
                                    path: '/AddQualification',
                                    name: 'AddQualification',
                                    component: AddQualification
                                },
                                {
                                    path: '/Qualifications',
                                    name: 'Qualifications',
                                    component: Qualification
                                },
                                {
                                    path: '/AddUniversityLevel',
                                    name: 'AddUniversityLevel',
                                    component: AddUniversityLevel
                                },
                                {
                                    path: '/UniversityLevels',
                                    name: 'UniversityLevels',
                                    component: UniversityLevel
                                },
                                {
                                    path: '/AddSpecialization',
                                    name: 'AddSpecialization',
                                    component: AddSpecialization
                                },
                                {
                                    path: '/Specializations',
                                    name: 'Specializations',
                                    component: Specialization
                                },
                                {
                                    path: '/AddUniversity',
                                    name: 'AddUniversity',
                                    component: AddUniversity
                                },
                                {
                                    path: '/Universities',
                                    name: 'Universities',
                                    component: University
                                },
                                {
                                    path: '/addSessionType',
                                    name: 'addSessionType',
                                    component: AddSessionType
                                },
                                {
                                    path: '/SessionTypes',
                                    name: 'SessionTypes',
                                    component: SessionType
                                },
                                {
                                    path: '/AddSessionLocation',
                                    name: 'AddSessionLocation',
                                    component: AddSessionLocation
                                },
                                {
                                    path: '/SessionLocations',
                                    name: 'SessionLocations',
                                    component: SessionLocation
                                },
                                {
                                    path: '/StudentList',
                                    name: 'StudentList',
                                    component: StudentList
                                },
                                {
                                    path: '/AdminList',
                                    name: 'AdminList',
                                    component: AdminList
                                },
                                {
                                    path: '/AdviserList',
                                    name: 'AdviserList',
                                    component: AdviserList
                                },
                                {
                                    path: '/ReviewerList',
                                    name: 'ReviewerList',
                                    component: ReviewerList
                                },
                                {
                                    path: '/AdminRegistered',
                                    name: 'AdminRegistered',
                                    component: AdminRegistered
                                },
                                {
                                    path: '/AdviserRegistered',
                                    name: 'AdviserRegistered',
                                    component: AdviserRegistered
                                },
                                {
                                    path: '/ReviewerRegistered',
                                    name: 'ReviewerRegistered',
                                    component: ReviewerRegistered
                                },
                                {
                                    path: '/SurveyQuestions',
                                    name: 'SurveyQuestions',
                                    component: SurveyQuestions
                                },
                                {
                                    path: '/SecondSurveyQuestions',
                                    name: 'SecondSurveyQuestions',
                                    component: SecondSurveyQuestions
                                },
                                {
                                    path: '/Statistics',
                                    name: 'Statistics',
                                    component: Statistics
                                },
                                {
                                    path: '/StudentReport',
                                    name: 'StudentReport',
                                    component: StudentReport
                                },
                                {
                                    path: '/ReportRequirement',
                                    name: 'ReportRequirement',
                                    component: ReportRequirement
                                },
                                {
                                    path: '/SessionsReport',
                                    name: 'SessionsReport',
                                    component: SessionsReport
                                },
                                {
                                    path: '/SessionsFeedBackReport',
                                    name: 'SessionsFeedBackReport',
                                    component: SessionsFeedBackReport
                                },
                                {
                                    path: '/SessionSecondSurveyReport',
                                    name: 'SessionSecondSurveyReport',
                                    component: SessionSecondSurveyReport
                                },
                             
                                {
                                    path: '/Permissions',
                                    name: 'Permissions',
                                    component: Permissions
                                },
                                {
                                    path: '/EmailNotification',
                                    name: 'EmailNotification',
                                    component: EmailNotification
                                },
                                {
                                    path: '/ImportStudentDetail',
                                    name: 'ImportStudentDetail',
                                    component: ImportStudentDetail
                                },
                                {
                                    path: '/AddHoliday',
                                    name: 'AddHoliday',
                                    component: AddHoliday
                                },
                                {
                                    path: '/Holidays',
                                    name: 'Holidays',
                                    component: Holidays
                                },
                                {
                                    path: '/ContactList',
                                    name: 'ContactList',
                                    component: ContactList
                                },
                                {
                                    path: '/ContactDetail',
                                    name: 'ContactDetail',
                                    component: ContactDetail
                                }



                            ]
                        },
                        {
                            path: '/StudentDashboard',
                            name: 'StudentDashboard',
                            component: StudentDashboard
                        },
                        {
                            path: '/AdvisorDashboard',
                            name: 'AdvisorDashboard',
                            component: AdvisorDashboard
                        },
                        {
                            path: '/ReviewerDashboard',
                            name: 'ReviewerDashboard',
                            component: ReviewerDashboard
                        },
                        {
                            path: '/UserList',
                            name: 'UserList',
                            component: UserList
                        },
                        {
                            path: '/AddAdviserSlot',
                            name: 'AddAdviserSlot',
                            component: AddAdviserSlot
                        },
                        {
                            path: '/AddAdviserMultiUniversitySlot',
                            name: 'AddAdviserMultiUniversitySlot',
                            component: AddAdviserMultiUniversitySlot
                        },
                        {
                            path: '/AdviserSlots',
                            name: 'AdviserSlots',
                            component: AdviserSlots
                        },

                        {
                            path: '/AdviserStudentList',
                            name: 'AdviserStudentList',
                            component: AdviserStudentList
                        },

                        {
                            path: '/EditAdviserStudent',
                            name: 'EditAdviserStudent',
                            component: EditAdviserStudent
                        },

                        {
                            path: '/CreateQuickWorkshop',
                            name: 'CreateQuickWorkshop',
                            component: CreateQuickWorkshop
                        },
                        {
                            path: '/CreateGroupSession',
                            name: 'CreateGroupSession',
                            component: CreateGroupSession
                        },
                        {
                            path: '/CreatePreviousGroupSession',
                            name: 'CreatePreviousGroupSession',
                            component: CreatePreviousGroupSession
                        },
                        {
                            path: '/CreateStudentSession',
                            name: 'CreateStudentSession',
                            component: CreateStudentSession
                        },
                        {
                            path: '/CreateWorkshop',
                            name: 'CreateWorkshop',
                            component: CreateWorkshop
                        },
                        {
                            path: '/CreatePreviousWorkshop',
                            name: 'CreatePreviousWorkshop',
                            component: CreatePreviousWorkshop
                        },
                        {
                            path: '/StudentFeedback',
                            name: 'StudentFeedback',
                            component: StudentFeedback
                        },
                        {
                            path: '/WorkshopList',
                            name: 'WorkshopList',
                            component: WorkshopList
                        },
                        {
                            path: '/GroupSessionList',
                            name: 'GroupSessionList',
                            component: GroupSessionList
                        },
                        {
                            path: '/SessionDetails',
                            name: 'SessionDetails',
                            component: SessionDetails
                        },
                        {
                            path: '/Profile',
                            name: 'Profile',
                            component: Profile
                        },
                        {
                            path: '/AddAdviserSessionLocation',
                            name: 'AddAdviserSessionLocation',
                            component: AddAdviserSessionLocation
                        },
                        {
                            path: '/AdviserSessionLocation',
                            name: 'AdviserSessionLocation',
                            component: AdviserSessionLocation
                        },
                        {
                            path: '/SessionDetail',
                            name: 'SessionDetail',
                            component: SessionDetail
                        },
                        {
                            path: '/IndividualStudentSession',
                            name: 'IndividualStudentSession',
                            component: IndividualStudentSession
                        },
                        {
                            path: '/SessionActivate',
                            name: 'SessionActivate',
                            component: SessionActivate
                        },
                        {
                            path: '/SessionEnded',
                            name: 'SessionEnded',
                            component: SessionEnded
                        },
                        {
                            path: '/SessionStudentDetails',
                            name: 'SessionStudentDetails',
                            component: SessionStudentDetails
                        },
                        {
                            path: '/SessionView',
                            name: 'SessionView',
                            component: SessionView
                        },
                        {
                            path: '/AddAdviserSessionType',
                            name: 'AddAdviserSessionType',
                            component: AddAdviserSessionType
                        },
                        {
                            path: '/AdviserSessionType',
                            name: 'AdviserSessionType',
                            component: AdviserSessionType
                        },
                        {
                            path: '/CreateMultiUniversityQuickWorkshop',
                            name: 'CreateMultiUniversityQuickWorkshop',
                            component: CreateMultiUniversityQuickWorkshop
                        },
                        {
                            path: '/CreateMultiUniversityWorkshop',
                            name: 'CreateMultiUniversityWorkshop',
                            component: CreateMultiUniversityWorkshop
                        },
                        {
                            path: '/CreateMultiUniversityGroupSession',
                            name: 'CreateMultiUniversityGroupSession',
                            component: CreateMultiUniversityGroupSession
                        }

                    ]
                }
            ]
    }
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router
