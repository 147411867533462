<template>
    <div class="page-main">
        <div class="card  p-5 mb-5 mt-3">
            <div class="backtomain mb-3">
                <div class="logo mr-3">
                </div><br>
            </div>
            <div class="signup_form_title mb-5">
                <h1><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> {{ $t('CreateGroupSession.Heading') }}</font></font></h1>
            </div>

            <div class="row">
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('CreateGroupSession.SessionType') }}</label>
                    <sessiontypedropdown v-model="createGroupSession.sessionTypeId" :values="createGroupSession.sessionTypeId" :subjectOfSession="'Group'" @input="getSessionDetail" :disabled="role=='Super Admin'?true:false" />
                    <div class="checkbox">
                        <div class="custom-checkbox custom-control">
                            <input type="checkbox" v-model="createGroupSession.hideSessionType" data-checkboxes="mygroup" class="custom-control-input" id="checkbox">
                            <label for="checkbox" class="custom-control-label">اخفاء عنوان الخدمة</label>
                        </div>
                    </div>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('CreateGroupSession.SessionStartDate') }}</label>
                    <datetimepicker v-model="createGroupSession.sessionStart" :values="createGroupSession.sessionStart" @input="GetSessionDuration()" :disabled="role=='Super Admin'?true:false" />
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('CreateGroupSession.SessionDuration') }}</label>
                    <sessiondurationsdropdown @input="GetSessionDuration" :values="createGroupSession.sessionDurationId" :disabled="role=='Super Admin'?true:false" />
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label for="inputState">  {{ $t('CreateGroupSession.AvailableAppointments') }}</label>
                    <datetimepicker v-model="createGroupSession.sessionEnd" :values="createGroupSession.sessionEnd" :disabled="true" :key="sessionEndRender" />
                </div>

                <!--<div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>  {{ $t('CreateGroupSession.AffiliateUniversities') }}</label>
                    <universitydropdown v-model="createGroupSession.universityId" :alluniversities="true" :values="createGroupSession.universityId" @input="SessionRender()" :disabled="role=='Super Admin'?true:false" />
                </div>-->
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>  {{ $t('CreateGroupSession.AffiliateUniversities') }}</label>
                    <universitymultiselectdropdown v-model="createGroupSession.universityId" :values="createGroupSession.universityId" :selectAllUniversity="selectAllUniversity" :key="universityRender" @input="SessionRender()" :disabled="role=='Super Admin'?true:false" />
                    <div class="checkbox">
                        <div class="custom-checkbox custom-control">
                            <input type="checkbox" v-model="selectAllUniversity" @change="SlectAllUniversity()" data-checkboxes="mygroup" class="custom-control-input" id="checkbox-3" disabled v-if="role =='Super Admin'">
                            <input type="checkbox" v-model="selectAllUniversity" @change="SlectAllUniversity()" data-checkboxes="mygroup" class="custom-control-input" id="checkbox-3" v-else>
                            <label for="checkbox-3" class="custom-control-label">الجميع</label>
                        </div>
                    </div>
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.Gender') }}</label>
                    <genderdropdown v-model="createGroupSession.gender" :values="createGroupSession.gender" :both="true" :disabled="role=='Super Admin'?true:false" @input="SessionRender()" />
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>  {{ $t('CreateGroupSession.MeetingPlace') }}</label>
                    <sessionlocationdropdown v-model="createGroupSession.sessionLocationId" :values="createGroupSession.sessionLocationId" :subjectOfSession="2" :multiuniversity="true" :universityId="createGroupSession.universityId" :key="sessionRender" :disabled="role=='Super Admin'?true:false" />
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('CreateGroupSession.UniversityLevel') }}</label>
                    <universtylevelmultiselectdropdown v-model="universityLevelIds" v-bind:values="universityLevelIds" :selectAllLevel="selectAllLevel" :key="universityLevelRender" :disabled="role=='Super Admin'?true:false" @input="SessionRender()" />
                    <div class="checkbox">
                        <div class="custom-checkbox custom-control">
                            <input type="checkbox" v-model="selectAllLevel" @change="SlectAllLevel()" data-checkboxes="mygroup" class="custom-control-input" id="checkbox-2" disabled v-if="role =='Super Admin'">
                            <input type="checkbox" v-model="selectAllLevel" @change="SlectAllLevel()" data-checkboxes="mygroup" class="custom-control-input" id="checkbox-2" v-else>
                            <label for="checkbox-2" class="custom-control-label">الجميع</label>
                        </div>
                    </div>
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">

                    <label>  {{ $t('CreateGroupSession.Students') }} </label>
                    <studentDropdown v-model="studentIds" :universityId="createGroupSession.universityId" :gender="createGroupSession.gender" :universityLevelIds="universityLevelIds" v-bind:values="studentIds" :key="studentLevelRender" />
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('SessionType.Description') }}</label>
                    <textarea v-model="description" class="form-control p-3" disable readonly />
                </div>
            </div>

            <div class="row mt-3 btn_student_group ">
                <div class="col-md-12">
                    <button v-on:click="SaveNewUser()" v-bind:disabled="$v.$invalid" class="btn btn-primary register">{{ $t('CreateGroupSession.Save') }}</button>
                    <a class="btn btn-light" v-on:click="GoTo()" href="javascript:void(0)">{{ $t('CreateGroupSession.Cancel') }}</a>
                </div>
            </div>

            <loading :name="loading" :active.sync="loading"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
        </div>

    </div>
</template>
<script>
    import { required } from 'vuelidate/lib/validators';
    import moment from 'moment'
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";

    export default {
        name: 'CreateGroupSession',
        components: {
            Loading
        },
        data() {
            return {
                loading: false,
                selectAllLevel: false,
                selectAllUniversity: false,
                universityRender: 0,
                universityLevelRender: 0,
                studentLevelRender: 0,
                sessionEndRender: 0,
                sessionRender: 0,
                minute: 0,
                description: '',
                role: '',
                universityLevelIds: [],
                studentIds: [],
                createGroupSession: {
                    id: '00000000-0000-0000-0000-000000000000',
                    sessionTypeId: '',
                    sessionLocationId: '',
                    sessionStart: '',
                    sessionDurationId: '',
                    sessionEnd: '',
                    universityId: '',
                    universityLevelId: '',
                    gender: '',
                    hideSessionType: false,
                    studentId: ''
                },
            }
        },
        validations: {
            createGroupSession:
            {
                sessionTypeId:
                {
                    required: required
                },
                sessionLocationId:
                {
                    required: required
                },
                sessionStart:
                {
                    required: required
                },
                sessionEnd:
                {
                    required: required
                },
                universityId:
                {
                    required: required
                },
                sessionDurationId:
                {
                    required: required
                },
                gender:
                {
                    required: required
                }
            }
        },

        methods: {
            GoTo: function () {
                if (this.role == "Super Admin") {
                    this.$router.push('/SessionsReport');
                }
                else {
                    this.$router.push('/AdvisorDashboard');
                }

            },

            getSessionDetail(data) {
                /*const newString = data.description.replace(/\s+/g, ' ').trim();*/
                this.description = data.description;
                this.createGroupSession.sessionTypeId = data.id;

            },
            SlectAllLevel: function () {
                this.universityLevelIds = [];
                this.universityLevelRender++;
            },
            SlectAllUniversity: function () {
                
                this.createGroupSession.universityId = [];
                this.universityRender++;
            },

            SessionRender: function () {
                this.sessionRender++;
            },

            GetSessionDuration: function (duration) {

                if (duration != undefined) {
                    this.createGroupSession.sessionDurationId = duration.id;
                    this.minute = duration.minutes;
                    if (this.createGroupSession.sessionStart != null && this.createGroupSession.sessionStart != '') {
                        this.createGroupSession.sessionEnd = moment(this.createGroupSession.sessionStart).add(duration == undefined ? 0 : duration.minutes, 'minutes').format('llll');
                        this.sessionEndRender++;
                    }
                }
                else {
                    if (this.createGroupSession.sessionStart != null && this.createGroupSession.sessionStart != '') {
                        this.createGroupSession.sessionEnd = moment(this.createGroupSession.sessionStart).add(this.minute, 'minutes').format('llll');
                        this.sessionEndRender++;
                    }
                }
            },

            getLevelId: function (value) {
                var levelId = [];
                for (var i = 0; i < value.length; i++) {
                    levelId[i] = value[i].id
                }
                return levelId;
            },
            getStudentId: function (value) {
                var levelId = [];
                for (var i = 0; i < value.length; i++) {
                    levelId[i] = value[i].id
                }
                return levelId;
            },

            SendEmailDetails: function (id) {
                var root = this;
                var token = '';
                this.loading = true;

                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }


                root.$https.get('/Sessions/SendGroupSessionEmail?sessionId=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        //root.$router.push({
                        //    path: '/SessionActivate',
                        //    query: { data: response.data }
                        //})
                    }
                    root.loading = false;

                });

            },
            SaveNewUser: function () {
                var root = this;
                this.loading = true;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                if (this.universityLevelIds != null && this.universityLevelIds != undefined && this.universityLevelIds != '') {
                    this.createGroupSession.universityLevelId = this.getLevelId(this.universityLevelIds);
                }
                if (this.studentIds != null && this.studentIds != undefined && this.studentIds != '') {
                    this.createGroupSession.studentId = this.getStudentId(this.studentIds);
                }

                //if (this.createGroupSession.universityId != null && this.createGroupSession.universityId != undefined && this.createGroupSession.universityId != '') {
                //    var univrsityId = this.createGroupSession.universityId;
                //    this.createGroupSession.universityId = [];
                //    this.createGroupSession.universityId.push({ id: univrsityId });
                //}

                root.$https.post('/Sessions/SaveGroupSession', this.createGroupSession, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {

                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            //if (response.data.permissionOnSessionCreation || response.data.smsPermissionOnSessionCreation) {
                            //    root.SendEmailDetails(response.data.id, response.data.permissionOnSessionCreation, response.data.smsPermissionOnSessionCreation);
                            //}
                            
                            root.SendEmailDetails(response.data.id);

                            root.$swal({
                                title: root.$t('Notification.Created'),
                                text: root.$t('Notification.SessionCreated'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            });
                            root.loading = false;

                            root.GoTo();
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },
        },
        created: function () {
            if (this.$route.query.data != undefined) {
                
                this.createGroupSession = this.$route.query.data;
                this.universityLevelIds = this.$route.query.data.universityLevelId;
                this.studentIds = this.$route.query.data.studentId;

            }

            this.role = localStorage.getItem('RoleName');
        },
        mounted: function () {

            if (this.$route.query.data != undefined) {
                this.minute = this.$route.query.data.minutes;
                if (this.createGroupSession.sessionStart != null && this.createGroupSession.sessionStart != '') {
                    this.createGroupSession.sessionEnd = moment(this.createGroupSession.sessionStart).add(this.minute, 'minutes').format('llll');
                    this.sessionEndRender++;
                }
            }
        },
    }
</script>
<style scoped>
    .btn-primary {
        margin-left: 10px;
    }

    @media only screen and (max-width: 600px) {
        .register {
            max-width: 40%;
        }

        .login {
            max-width: 40%;
        }

        .btn_student_group {
            justify-content: center;
        }
    }
</style>