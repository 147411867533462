<template>
    <div class="main-session-type">
        <div class="page-header">
            <div>
                <h1 class="page-title">{{ $t('SecondSurveyQuestionDays.SecondSurveyQuestionDays') }}</h1>
            </div>
            <div class="ms-auto pageheader-btn">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t('SecondSurveyQuestionDays.SecondSurveyQuestionDays') }}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ $t('SecondSurveyQuestionDays.SecondSurveyQuestionDays') }}</li>
                </ol>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-12">
                <div class="card product-sales-main">

                    <!--start-->
                    <div class="row p-5">
                        <!--<div class="col-md-4 ">
                            <label>{{ $t('SecondSurveyQuestionDays.TotalDays') }}</label>
                            <input v-model="days" type="number" class="form-control" />
                        </div>-->
                        <div class="col-md-4 ">
                            <label>{{ $t('Regions.Active') }}</label>
                            <div class="checkbox">
                                <div class="custom-checkbox custom-control">
                                    <input v-model="isSentMail" type="checkbox" data-checkboxes="mygroup" class="custom-control-input" id="checkbox-2">
                                    <label for="checkbox-2" class="custom-control-label"></label>
                                </div>
                            </div>
                        </div>



                    </div>
                    <div class="row p-5">
                        <div class="col-md-4 mt-5 ac">
                            <button type="button" v-on:click="SaveRecord()" class="btn btn-primary btn-pill float-end"> {{ $t('SecondSurveyQuestionDays.Save') }}</button>
                        </div>



                    </div>

                    <!--end-->

                </div>
            </div>
        </div>
    </div>

</template>

<script>

    export default {

        name: 'SecondSurveyEmailDays',

        data: function () {
            return {
                days: 0,
                isSentMail: false,

            }
        },

        methods: {

            SaveRecord: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                root.$https.get('/Permission/AddSecondSurveyEmailDays?days=' + this.days + '&isSentMail=' + this.isSentMail, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.isSuccess) {
                            root.loading = false;


                            root.$swal({
                                title: root.$t('Notification.Added'),
                                text: root.$t('Notification.DataSucess'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    root.close;

                                }
                            });
                        }

                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            GetData: function () {
                var root = this;
                var url = '/Permission/GetSecondSurveyEmailDays';
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.days = response.data.totalDays;
                        root.isSentMail = response.data.isSentMail;
                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        type: 'error',
                        title: root.$t('Notification.Error'),
                        text: root.$t('Notification.SomethingWrong'),
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                }).finally(() => root.loading = false);
            },

        },

        mounted: function () {
            this.GetData();
        },

    }
</script>
<style scoped>
    .card-header {
        display: flex;
        justify-content: space-between;
    }

    .btn-primary:hover {
        color: white !important;
    }

    .ac button:focus {
        color: white !important;
    }
</style>


