<template>
    <div class="main-advisorDashboard">

        <!-- PAGE-HEADER -->
        <div class="page-header">
            <div>
                <h1 class="page-title">{{$t('AdvisorDashboard.AdvisorDashboard')}}</h1>
            </div>
            <div class="ms-auto pageheader-btn">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0)">{{$t('AdvisorDashboard.AdvisorDashboard')}}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{$t('AdvisorDashboard.Dashboard')}}</li>
                </ol>
            </div>
        </div>

        <!-- PAGE-HEADER END -->
        <!-- PAGE-BANNER  -->
        <!-- ROW-2 -->
        <div class="row mt-5">
            <div class="col-sm-12 col-md-12 col-xl-4 col-lg-6">
                <div class="row">

                    <div class="col-xl-12 col-lg-12 col-md-6 col-sm-12">
                        <div class="card overflow-hidden">
                            <div class="card-header border-bottom mt-5 mb-5">
                                <div class="col-4 ">
                                    <img v-if="filePath != null && filePath != undefined && filePath != ''" :src="'data:image/png;base64,' + filePath" />
                                    <img v-else v-bind:src="image" />
                                </div>
                                <div class="col-8 ">
                                    <h4>{{userName}}</h4>
                                    <p style="font-size:12px;">{{userEmail}}</p>

                                </div>
                            </div>
                            <div class="card-body customers mb-1 ">
                                <h4>{{$t('AdvisorDashboard.AffiliateUniversities')}}</h4>
                                <p>
                                    <span v-for="uni in advisorDashboardMain.universityName" :key="uni"> ({{uni}}) </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-8 ">
                <div class="card banner pt-5 pb-5">
                    <div class="card-header flex-column align-items-baseline">
                        <h1 class="pb-3 ">{{$t('AdvisorDashboard.careercounseling')}}</h1>
                        <p>
                            {{ $t('AdvisorDashboard.careercounselingdefination') }}<br />
                            {{$t('AdvisorDashboard.careercounselingdefinationtwo') }}
                        </p>
                    </div>
                    <div class="card-body pt-0">
                        <a class="btn btn-primary btn-pill pt-2 pb-2 m-1" v-on:click="GoTo('/AdviserSlots')" href="javascript:void(0)">{{ $t('LayoutMenu.DetermineOpeningTimes') }}</a>
                        <a class="btn btn-light btn-pill pt-2 pb-2 m-1" v-on:click="GoTo('/CreateGroupSession')" href="javascript:void(0)">{{$t('AdvisorDashboard.AdvisorButton')}}</a>
                        <!--<a class="btn btn-light btn-pill pt-2 pb-2 m-1" v-on:click="GoTo('/CreateWorkshop')" href="javascript:void(0)">{{$t('AdvisorDashboard.AdvisorButtonN')}}</a>-->
                        <a class="btn btn-light btn-pill pt-2 pb-2 m-1" v-on:click="GoTo('/CreateQuickWorkshop')" href="javascript:void(0)">انشاء ورشة عمل </a>
                        <!--<a v-if="isQuickWorkShop" class="btn btn-light btn-pill pt-2 pb-2 m-1" v-on:click="GoTo('/CreateQuickWorkshop')" href="javascript:void(0)"> انشاء ورشة عمل سريعة</a>-->
                    </div>
                </div>
            </div>
        </div>
        <!-- ROW-2 END -->
        <!-- ROW-3 -->
        <div class="row">
            <div class="col-lg-6 col-sm-12 col-md-6 col-xl-4">
                <div class="card overflow-hidden">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-2 fw-semibold">
                                    {{advisorDashboardMain.pendingSession}}
                                </h3>

                                <p class="text-muted  mb-0">{{$t('AdvisorDashboard.Pendingsessions')}}</p>
                            </div>
                            <div class="col col-auto top-icn dash mt-1">
                                <img src="/assets/images/landing/base-1.svg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-md-6 col-xl-4">
                <div class="card overflow-hidden">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-2 fw-semibold">{{advisorDashboardMain.activeSession}}</h3>

                                <p class="text-muted  mb-0">{{$t('AdvisorDashboard.ScheduledAppointments')}}</p>
                            </div>
                            <div class="col col-auto top-icn dash mt-1">
                                <img src="/assets/images/landing/base-2.svg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-md-6 col-xl-4">
                <div class="card overflow-hidden">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-2 fw-semibold">{{advisorDashboardMain.completedSession}}</h3>

                                <p class="text-muted  mb-0"> {{$t('AdvisorDashboard.Endedsessions')}}</p>
                            </div>
                            <div class="col col-auto top-icn dash mt-1">
                                <img src="/assets/images/landing/base-3.svg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- ROW-3 END-->
        <!-- PAGE-BANNER END -->
        <adviserActivePendingSession />
        <adviserCompletedSession />

        <individualsessionqrprint v-if="pdfShow" @close="pdfShow=false" />

    </div>

</template>

<script>
    import adviserActivePendingSession from '../../components/Dashboard/AdviserActivePendingSession.vue'
    import adviserCompletedSession from '../../components/Dashboard/AdviserCompletedSession.vue'
    export default {
        name: 'AdvisorDashboard',
        components: {
            adviserActivePendingSession,
            adviserCompletedSession
        },
        data: function () {

            return {
                render: 0,

                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                pageSize: '',
                loading: false,
                isQuickWorkShop: false,
                pdfShow: false,

                advisorDashboardMain: '',
                userName: '',
                userEmail: '',

                image: '../../assets/images/default-avatar.png',
                filePath: null
            }
        },
        watch: {


        },
        methods: {

            GoTo: function (link) {
                this.$router.push(link);
            },

            GetInfo: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Home/AdviserDashboardQuery', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.advisorDashboardMain = response.data;
                    }
                });
            },

            GetBaseImage: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                if (localStorage.getItem('ProfilePicture') != "null") {
                    root.$https.get('/Home/GetBaseImage?filePath=' + localStorage.getItem('ProfilePicture'), { headers: { "Authorization": `Bearer ${token}` } })
                        .then(function (response) {
                            if (response.data != null) {
                                root.filePath = response.data;
                            }
                        }, function (error) {
                            console.log(error);
                        });
                }

            },

        },

        created: function () {
            this.GetInfo();
            this.GetBaseImage();
        },
        mounted: function () {
            this.userEmail = localStorage.getItem('UserEmail');
            this.userName = localStorage.getItem('FullName');
            this.isQuickWorkShop = localStorage.getItem('IsQuickWorkShop') == 'true' ? true : false;


        },
    }
</script>
<style scoped>
    .card-header {
        display: flex;
        justify-content: space-between;
    }

    .banner {
        background-image: url(/assets/images/landing/123.jpg);
        background-repeat: no-repeat;
        background-size: contain;
    }

        .banner h1 {
            color: #009000;
        }

    .card-header h4 {
        color: #009000;
    }

    .card-body p {
        color: #009000;
    }
</style>



