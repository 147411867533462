<template>
    <div class="main-region">
        <div class="page-header">
            <div>
                <h1 class="page-title"> أذونات</h1>
            </div>
            <div class="ms-auto pageheader-btn">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">أذونات</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ $t('Regions.Dashboard') }}</li>
                </ol>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-12">


                <div class="card product-sales-main">
                    <div class="card-body">
                        <div class="panel panel-primary">
                            <div class="tab-menu-heading border-bottom-0">
                                <div class="tabs-menu4 border-bottomo-sm">
                                    <!-- Tabs -->
                                    <nav class="nav d-sm-flex d-block">
                                        <a class="nav-link border border-bottom-0 br-sm-5 me-2 active" data-bs-toggle="tab" href="#tab25">
                                            أذونات الشركة
                                        </a>
                                        <a class="nav-link border border-bottom-0 br-sm-5 me-2" data-bs-toggle="tab" href="#tab26">
                                            الجامعة
                                        </a>
                                        <a class="nav-link border border-bottom-0 br-sm-5 me-2" data-bs-toggle="tab" href="#tab27">
                                            المرشدين
                                        </a>
                                    </nav>
                                </div>
                            </div>
                            <div class="panel-body tabs-menu-body">
                                <div class="tab-content">
                                    <div class="tab-pane active " id="tab25">
                                        <div class="table-responsive">
                                            <table class="table border text-nowrap text-md-nowrap table-hover">
                                                <tbody>
                                                    <tr>
                                                        <th class="text-center">اسم</th>
                                                        <th class="text-center">{{$t('AdvisorDashboard.IndividualSession')}}</th>
                                                        <th class="text-center">{{$t('AdvisorDashboard.GroupSession')}}</th>
                                                        <th class="text-center">{{$t('AdvisorDashboard.WorkShop')}}</th>
                                                    </tr>
                                                    <tr v-for="permission in companyPermission" :key="permission.name">
                                                        <td class="text-center">
                                                            <span v-if="permission.name=='Sunday'">يَوم الأحَد</span>
                                                            <span v-if="permission.name=='Monday'">يَوم الإثنين</span>
                                                            <span v-if="permission.name=='Tuesday'">يَوم الثلاثاء</span>
                                                            <span v-if="permission.name=='Wednesday'">يَوم الأربعاء</span>
                                                            <span v-if="permission.name=='Thursday'">يَوم الخميس</span>
                                                            <span v-if="permission.name=='Friday'">يَوم الجمعة</span>
                                                            <span v-if="permission.name=='Saturday'">يَوم السبت</span>
                                                            <span v-if="permission.name=='Holly Days'">عُطْلة</span>
                                                            <span v-if="permission.name=='Session Evaluation'">تقييم الجلسة</span>
                                                            <span v-if="permission.name=='Certificate'">شهادة</span>
                                                            <span v-if="permission.name=='Email Send On Session Creation'">إنشاء الجلسات البريد الإلكتروني</span>
                                                            <span v-if="permission.name=='Send Message On Session Creation'">إنشاء الجلسات رسالة نصية</span>
                                                            <span v-if="permission.name=='Email Send On Session Completion'"> الجلسات إكمال البريد الإلكتروني</span>
                                                            <span v-if="permission.name=='Send Message On Session Completion'"> الجلسات إكمال رسالة نصية</span>
                                                            <span v-if="permission.name=='Email Send On Survey'"> الدراسة الاستقصائية البريد الإلكتروني</span>
                                                            <span v-if="permission.name=='Send Message On Survey'"> الدراسة الاستقصائية رسالة نصية</span>
                                                            <span v-if="permission.name=='Email Send On Certificate'"> شهادة البريد الإلكتروني</span>
                                                            <span v-if="permission.name=='Send Message On Certificate'"> شهادة رسالة نصية</span>
                                                            <span v-if="permission.name=='Email Send On Schedule Task'"> جدول المهام البريد الإلكتروني</span>
                                                            <span v-if="permission.name=='Send Message On Schedule Task'"> جدول المهام رسالة نصية</span>
                                                            <span v-if="permission.name=='Allow To Create Quick WorkShop'"> السماح بإنشاء ورشة عمل سريعة</span>
                                                        </td>
                                                        <td class="text-center">
                                                            <label class="colorinput" v-if="permission.name!='Allow To Create Quick WorkShop'"> <input v-model="permission.individual" :value="permission.name" type="checkbox" class="colorinput-input"> <span class="colorinput-color bg-azure"></span> </label>
                                                        </td>
                                                        <td class="text-center">
                                                            <label class="colorinput" v-if="permission.name!='Allow To Create Quick WorkShop'"> <input v-model="permission.group" :value="permission.name" type="checkbox" class="colorinput-input"> <span class="colorinput-color bg-azure"></span> </label>
                                                        </td>
                                                        <td class="text-center">
                                                            <label class="colorinput"> <input v-model="permission.workShop" :value="permission.name" type="checkbox" class="colorinput-input"> <span class="colorinput-color bg-azure"></span> </label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <button v-on:click="SaveCompanyPermission()" class="btn btn-primary">حفظ</button>
                                    </div>

                                    <div class="tab-pane" id="tab26">
                                        <div class="row mb-3">
                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                                <input class="form-control" type="text" v-model="searchQuery" placeholder="ابحث" />
                                            </div>
                                            <div class="col-sm-12 col-md-6 col-lg-8 col-xl-8 text-left">
                                                <button v-on:click="SaveUniversityPermission()" class="btn btn-primary text-left">حفظ</button>
                                            </div>
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table border text-nowrap text-md-nowrap table-hover">
                                                <tbody v-for="(university, index) in resultQuery" :key="index">
                                                    <tr>
                                                        <th>{{index+1}} : {{university.universityName}}</th>
                                                        <th class="text-center">{{$t('AdvisorDashboard.IndividualSession')}}</th>
                                                        <th class="text-center">{{$t('AdvisorDashboard.GroupSession')}}</th>
                                                        <th class="text-center">{{$t('AdvisorDashboard.WorkShop')}}</th>
                                                    </tr>
                                                    <tr v-for="permission in university.universityPermission" :key="permission.name">
                                                        <td class="text-center">
                                                            <span v-if="permission.name=='Sunday'">يَوم الأحَد</span>
                                                            <span v-if="permission.name=='Monday'">يَوم الإثنين</span>
                                                            <span v-if="permission.name=='Tuesday'">يَوم الثلاثاء</span>
                                                            <span v-if="permission.name=='Wednesday'">يَوم الأربعاء</span>
                                                            <span v-if="permission.name=='Thursday'">يَوم الخميس</span>
                                                            <span v-if="permission.name=='Friday'">يَوم الجمعة</span>
                                                            <span v-if="permission.name=='Saturday'">يَوم السبت</span>
                                                            <span v-if="permission.name=='Holly Days'">عُطْلة</span>
                                                            <span v-if="permission.name=='Session Evaluation'">تقييم الجلسة</span>
                                                            <span v-if="permission.name=='Certificate'">شهادة</span>
                                                            <span v-if="permission.name=='Email Send On Session Creation'">إنشاء الجلسات البريد الإلكتروني</span>
                                                            <span v-if="permission.name=='Send Message On Session Creation'">إنشاء الجلسات رسالة نصية</span>
                                                            <span v-if="permission.name=='Email Send On Session Completion'"> الجلسات إكمال البريد الإلكتروني</span>
                                                            <span v-if="permission.name=='Send Message On Session Completion'"> الجلسات إكمال رسالة نصية</span>
                                                            <span v-if="permission.name=='Email Send On Survey'"> الدراسة الاستقصائية البريد الإلكتروني</span>
                                                            <span v-if="permission.name=='Send Message On Survey'"> الدراسة الاستقصائية رسالة نصية</span>
                                                            <span v-if="permission.name=='Email Send On Certificate'"> شهادة البريد الإلكتروني</span>
                                                            <span v-if="permission.name=='Send Message On Certificate'"> شهادة رسالة نصية</span>
                                                            <span v-if="permission.name=='Email Send On Schedule Task'"> جدول المهام البريد الإلكتروني</span>
                                                            <span v-if="permission.name=='Send Message On Schedule Task'"> جدول المهام رسالة نصية</span>
                                                            <span v-if="permission.name=='Allow To Create Quick WorkShop'"> السماح بإنشاء ورشة عمل سريعة</span>
                                                        </td>
                                                        <td class="text-center">
                                                            <label class="colorinput" v-if="permission.name!='Allow To Create Quick WorkShop'"> <input v-model="permission.individual" :value="permission.name" type="checkbox" class="colorinput-input"> <span class="colorinput-color bg-azure"></span> </label>
                                                        </td>
                                                        <td class="text-center">
                                                            <label class="colorinput" v-if="permission.name!='Allow To Create Quick WorkShop'"> <input v-model="permission.group" :value="permission.name" type="checkbox" class="colorinput-input"> <span class="colorinput-color bg-azure"></span> </label>
                                                        </td>
                                                        <td class="text-center">
                                                            <label class="colorinput"> <input v-model="permission.workShop" :value="permission.name" type="checkbox" class="colorinput-input"> <span class="colorinput-color bg-azure"></span> </label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <button v-on:click="SaveUniversityPermission()" class="btn btn-primary">حفظ</button>
                                    </div>

                                    <div class="tab-pane" id="tab27">
                                        <div class="row mb-3">
                                            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                                                <input class="form-control" type="text" v-model="searchQueryAdviser" placeholder="ابحث" />
                                            </div>
                                            <div class="col-sm-12 col-md-6 col-lg-8 col-xl-8 text-left">
                                                <button v-on:click="SaveAdviserPermission()" class="btn btn-primary text-left">حفظ</button>
                                            </div>
                                        </div>
                                        <div class="table-responsive">
                                            <table class="table border text-nowrap text-md-nowrap table-hover">
                                                <tbody v-for="(adviser, index) in resultQueryAdviser" :key="index">
                                                    <tr>
                                                        <th>{{index+1}} : {{adviser.adviserName}}</th>
                                                        <th class="text-center">{{$t('AdvisorDashboard.IndividualSession')}}</th>
                                                        <th class="text-center">{{$t('AdvisorDashboard.GroupSession')}}</th>
                                                        <th class="text-center">{{$t('AdvisorDashboard.WorkShop')}}</th>
                                                    </tr>
                                                    <tr v-for="permission in adviser.adviserPermission" :key="permission.name">
                                                        <td class="text-center">
                                                            <span v-if="permission.name=='Sunday'">يَوم الأحَد</span>
                                                            <span v-if="permission.name=='Monday'">يَوم الإثنين</span>
                                                            <span v-if="permission.name=='Tuesday'">يَوم الثلاثاء</span>
                                                            <span v-if="permission.name=='Wednesday'">يَوم الأربعاء</span>
                                                            <span v-if="permission.name=='Thursday'">يَوم الخميس</span>
                                                            <span v-if="permission.name=='Friday'">يَوم الجمعة</span>
                                                            <span v-if="permission.name=='Saturday'">يَوم السبت</span>
                                                            <span v-if="permission.name=='Holly Days'">عُطْلة</span>
                                                            <span v-if="permission.name=='Session Evaluation'">تقييم الجلسة</span>
                                                            <span v-if="permission.name=='Certificate'">شهادة</span>
                                                            <span v-if="permission.name=='Email Send On Session Creation'">إنشاء الجلسات البريد الإلكتروني</span>
                                                            <span v-if="permission.name=='Send Message On Session Creation'">إنشاء الجلسات رسالة نصية</span>
                                                            <span v-if="permission.name=='Email Send On Session Completion'"> الجلسات إكمال البريد الإلكتروني</span>
                                                            <span v-if="permission.name=='Send Message On Session Completion'"> الجلسات إكمال رسالة نصية</span>
                                                            <span v-if="permission.name=='Email Send On Survey'"> الدراسة الاستقصائية البريد الإلكتروني</span>
                                                            <span v-if="permission.name=='Send Message On Survey'"> الدراسة الاستقصائية رسالة نصية</span>
                                                            <span v-if="permission.name=='Email Send On Certificate'"> شهادة البريد الإلكتروني</span>
                                                            <span v-if="permission.name=='Send Message On Certificate'"> شهادة رسالة نصية</span>
                                                            <span v-if="permission.name=='Email Send On Schedule Task'"> جدول المهام البريد الإلكتروني</span>
                                                            <span v-if="permission.name=='Send Message On Schedule Task'"> جدول المهام رسالة نصية</span>
                                                            <span v-if="permission.name=='Allow To Create Quick WorkShop'"> السماح بإنشاء ورشة عمل سريعة</span>
                                                        </td>
                                                        <td class="text-center">
                                                            <label class="colorinput" v-if="permission.name!='Allow To Create Quick WorkShop'"> <input v-model="permission.individual" :value="permission.name" type="checkbox" class="colorinput-input"> <span class="colorinput-color bg-azure"></span> </label>
                                                        </td>
                                                        <td class="text-center">
                                                            <label class="colorinput" v-if="permission.name!='Allow To Create Quick WorkShop'"> <input v-model="permission.group" :value="permission.name" type="checkbox" class="colorinput-input"> <span class="colorinput-color bg-azure"></span> </label>
                                                        </td>
                                                        <td class="text-center">
                                                            <label class="colorinput"> <input v-model="permission.workShop" :value="permission.name" type="checkbox" class="colorinput-input"> <span class="colorinput-color bg-azure"></span> </label>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <button v-on:click="SaveAdviserPermission()" class="btn btn-primary">حفظ</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <loading :name="loading" :active.sync="loading"
                 :can-cancel="false"
                 :is-full-page="true"></loading>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    export default {
        name: 'Permissions',
        components: {
            Loading
        },
        data: function () {
            return {
                universityPermission: [],
                adviserPermission: [],
                sessionPermission: [],
                companyPermission: [],
                loading: false,

                searchQuery: null,
                searchQueryAdviser: null,
            }
        },

        computed: {
            resultQuery() {
                if (this.searchQuery) {
                    return this.universityPermission.filter((item) => {
                        return this.searchQuery.toLowerCase().split(' ').every(v => item.universityName.toLowerCase().includes(v))
                    })
                }
                else {
                    return this.universityPermission;
                }
            },
            resultQueryAdviser() {
                if (this.searchQueryAdviser) {
                    return this.adviserPermission.filter((item) => {
                        return this.searchQueryAdviser.toLowerCase().split(' ').every(v => item.adviserName.toLowerCase().includes(v))
                    })
                }
                else {
                    return this.adviserPermission;
                }
            }
        },

        methods: {
            GoTo: function (link) {
                this.$router.push(link);
            },
            close: function () {
                this.$router.push('/');
            },

            SaveCompanyPermission: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.post('/Permission/SaveCompanyPermission', this.companyPermission, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.isSuccess) {
                            root.loading = false;
                            root.$swal({
                                title: root.$t('Notification.Added'),
                                text: root.$t('Notification.DataSucess'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    root.$router.go();
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: 'Error!',
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: 'Error',
                                text: "Something Went Wrong",
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            GetCompanyPermissionList: function () {
                var root = this;
                var url = '/Permission/CompanyPermissionList';
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.companyPermission = response.data;
                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        type: 'error',
                        title: root.$t('Notification.Error'),
                        text: root.$t('Notification.SomethingWrong'),
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                }).finally(() => root.loading = false);
            },

            SaveUniversityPermission: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.post('/Permission/SaveUniversityPermission', this.universityPermission, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.isSuccess) {
                            root.loading = false;
                            root.$swal({
                                title: root.$t('Notification.Added'),
                                text: root.$t('Notification.DataSucess'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    root.$router.go();
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: 'Error!',
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: 'Error',
                                text: "Something Went Wrong",
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            GetUniversityPermissionList: function () {
                var root = this;
                var url = '/Permission/UniversityPermissionList';
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.universityPermission = response.data;
                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        type: 'error',
                        title: root.$t('Notification.Error'),
                        text: root.$t('Notification.SomethingWrong'),
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                }).finally(() => root.loading = false);
            },

            SaveAdviserPermission: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.post('/Permission/SaveAdviserPermission', this.adviserPermission, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.isSuccess) {
                            root.loading = false;
                            root.$swal({
                                title: root.$t('Notification.Added'),
                                text: root.$t('Notification.DataSucess'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    root.$router.go();
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: 'Error!',
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: 'Error',
                                text: "Something Went Wrong",
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            GetAdviserPermissionList: function () {
                var root = this;
                var url = '/Permission/AdviserPermissionList';
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.adviserPermission = response.data;
                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        type: 'error',
                        title: root.$t('Notification.Error'),
                        text: root.$t('Notification.SomethingWrong'),
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                }).finally(() => root.loading = false);
            },

            //SaveSessionPermission: function () {
            //    var root = this;
            //    this.loading = true;
            //    var token = '';
            //    if (this.$session.exists()) {
            //        token = localStorage.getItem('token');
            //    }

            //    root.$https.post('/Permission/SaveSessionPermission', this.sessionPermission, { headers: { "Authorization": `Bearer ${token}` } })
            //        .then(function (response) {
            //            console.log(response.data)
            //            if (response.data.isSuccess) {
            //                root.loading = false;
            //                root.$swal({
            //                    title: root.$t('Notification.Added'),
            //                    text: root.$t('Notification.DataSucess'),
            //                    type: 'success',
            //                    icon: 'success',
            //                    confirmButtonClass: "btn btn-success",
            //                    buttonsStyling: false
            //                }).then(function (result) {
            //                    if (result) {
            //                        root.$router.go();
            //                    }
            //                });
            //            }
            //            else {
            //                root.$swal({
            //                    title: 'Error!',
            //                    text: response.data.isAddUpdate,
            //                    type: 'error',
            //                    icon: 'error',
            //                    confirmButtonClass: "btn btn-danger",
            //                    buttonsStyling: false
            //                });
            //                root.loading = false;
            //            }
            //        }).catch(error => {
            //            console.log(error)
            //            root.$swal.fire(
            //                {
            //                    icon: 'error',
            //                    type: 'error',
            //                    title: 'Error',
            //                    text: "Something Went Wrong",
            //                    showConfirmButton: false,
            //                    timer: 5000,
            //                    timerProgressBar: true,
            //                });
            //            root.loading = false;
            //        })
            //        .finally(() => root.loading = false);
            //},

            //GetSessionPermissionList: function () {
            //    var root = this;
            //    var url = '/Permission/SessionPermissionList';
            //    var token = '';
            //    if (this.$session.exists()) {
            //        token = localStorage.getItem('token');
            //    }
            //    
            //    root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
            //        if (response.data != null) {
            //            root.sessionPermission = response.data;
            //        }
            //    }).catch(error => {
            //        console.log(error)
            //        root.$swal.fire({
            //            icon: 'error',
            //            type: 'error',
            //            title: root.$t('Notification.Error'),
            //            text: root.$t('Notification.SomethingWrong'),
            //            showConfirmButton: false,
            //            timer: 5000,
            //            timerProgressBar: true,
            //        });
            //    }).finally(() => root.loading = false);
            //},
        },
        created: function () {
            this.GetCompanyPermissionList();
            this.GetUniversityPermissionList();
            this.GetAdviserPermissionList();
            this.GetSessionPermissionList();
        },
        mounted: function () {

        },
    }
</script>
<style scoped>
    .navy {
        background-color: white;
        border-radius: 0.75rem !important;
        background: #fff;
        border: none !important;
        box-shadow: 0rem 0rem 0.9375rem -0.625rem grey;
        color: black;
        font-size: 16px;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        cursor: pointer;
    }

        .navy:hover {
            background-color: #009000;
            color: white !important;
        }

    .hed {
        color: #009000;
        font-size: 32px;
    }
</style>
<style scoped>
    .landing-nav {
        padding: 20px 0px 20px 0px;
    }

    .header {
        border-bottom: none !important;
        background: transparent !important;
    }

    .page-main {
        background-size: cover;
    }

    .m {
        background-image: url("/assets/images/landing/bg.jpg");
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .banner {
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: white;
    }

    .demo-footer {
        background-color: #092f57;
    }

    .btn-outline-primary {
        color: #009000 !important;
        border: 1px solid #009000;
    }

        .btn-outline-primary:hover {
            color: white !important;
        }

    .parent {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        padding-bottom: 50px;
    }

        .parent p {
            color: white;
            margin-top: 56px;
            font-size: 16px;
        }

    .banner h1 {
        font-weight: 700;
        font-size: 58px;
        margin-top: 150px;
    }

    .banner p {
        margin-top: 30px;
        font-size: 16px;
    }

    .con {
        color: white !important;
        margin-top: 20px;
        padding: 20px;
        cursor: pointer;
    }

    @media (min-width: 1274px) {
        .header {
            padding-right: 50px;
        }
    }

    @media screen and (min-width: 300px) and (max-width: 780px) {
        .parent {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: 4fr;
        }

            .parent p {
                margin-top: 36px;
            }

        .btn {
            min-width: 100px !important;
            padding: 3px !important;
            border: 1px solid white;
            color: white !important;
        }
    }

    .ft {
        margin-top: 40px !important;
        margin-right: 200px;
    }

    .copy {
        background-color: #001b39;
        color: white;
        text-align: center;
    }
</style>

