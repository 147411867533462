<template>
    <div class="main-qualification">
        <!-- PAGE-HEADER -->
        <div class="page-header">
            <div>
                <h1 class="page-title"> {{$t('Statistics.Heading')}} </h1>
            </div>
            <div class="ms-auto pageheader-btn">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{$t('Statistics.Heading')}} </a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ $t('Statistics.Dashboard') }}</li>
                </ol>
            </div>
        </div>
        <!-- PAGE-HEADER END -->
        <!-- ROW-4 -->
        <div class="row">
            <div class="col-12 col-sm-12 ">
                <div class="card pt-5 ">
                    <div class="card-header ac">
                        <div class="btn-group mt-2 mb-2">
                            <button type="button" class="btn btn-outline-default btn-pill dropdown-toggle slide-item" data-bs-toggle="dropdown">
                                عمل <span class="caret"></span>
                            </button>
                            <ul class="dropdown-menu" role="menu">
                                <li>
                                    <a v-on:click="DowmloadCSV" href="javascript:void(0)" class="btn btn-pill slide-item">{{ $t('Statistics.DownloadCsv') }}</a>
                                </li>
                                <!--<li>
                        <a v-on:click="DownloadPdf" href="javascript:void(0)" class="btn  btn-pill slide-item">تحميل Pdf</a>
                    </li>-->
                            </ul>
                        </div>


                    </div>
                    <div class="row mt-5 px-5">
                        <div class="col-sm-12 col-md-6 col-xl-6">
                            <div class="card box overflow-hidden">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-9">
                                            <p class="text-muted fs-16 mb-0">{{ $t('Statistics.StudentDetail') }}</p>
                                            <h3 class="mb-2 fw-semibold">{{superAdminList.totalRegisteredStudents}}</h3>


                                        </div>
                                        <div class="col-3 p-0  top-icn dash">
                                            <img src="/assets/images/landing/s.svg" />

                                        </div>
                                        <p class="text-muted mb-0 p-0 mt-2 fs-12">
                                            <span class="icn-box  fw-semibold fs-11 me-1">
                                                <span class="px-1">{{ $t('Statistics.Male') }}</span>
                                                <span class="bx px-2 py-1">{{superAdminList.totalRegisteredStudentsMale}}</span>
                                                <span class="px-1">{{ $t('Statistics.Female') }}</span>
                                                <span class="px-2 py-1 by ">{{superAdminList.totalRegisteredStudentsFemale}}</span>

                                            </span>

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-xl-6">
                            <div class="card box overflow-hidden">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-9">
                                            <p class="text-muted fs-16 mb-0">{{ $t('Statistics.AdvisorDetails') }}</p>
                                            <h3 class="mb-2 fw-semibold">{{superAdminList.totalRegisteredAdviser}}</h3>


                                        </div>
                                        <div class="col-3 p-0  top-icn dash">
                                            <img src="/assets/images/landing/Advisor.svg" />

                                        </div>
                                        <p class="text-muted mb-0 p-0 mt-2 fs-12">
                                            <span class="icn-box  fw-semibold fs-11 me-1">
                                                <span class="px-1">{{ $t('Statistics.Male') }}</span>
                                                <span class=" px-2 py-1 bx">{{superAdminList.totalRegisteredAdviserMale}}</span>
                                                <span class="px-1">{{ $t('Statistics.Female') }}</span>
                                                <span class=" px-2 py-1 by ">{{superAdminList.totalRegisteredAdviserFemale}}</span>

                                            </span>

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-xl-6">
                            <div class="card box overflow-hidden">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-9">
                                            <p class="text-muted fs-16 mb-0">{{ $t('Statistics.AttendSessions') }}</p>
                                            <h3 class="mb-2 fw-semibold">{{superAdminList.totalAttendee}}</h3>


                                        </div>
                                        <div class="col-3 p-0  top-icn dash">
                                            <img src="/assets/images/landing/sessions.svg" />

                                        </div>
                                        <p class="text-muted mb-0 p-0 mt-2 fs-12">
                                            <span class="icn-box  fw-semibold fs-11 me-1">
                                                <span class="px-1">{{ $t('Statistics.Completed') }}</span>
                                                <span class=" px-2 py-1 bx">{{superAdminList.totalAttendeeCompleted}}</span>
                                                <span class="px-1">{{ $t('Statistics.Cancel') }}</span>
                                                <span class=" px-2 py-1 by">{{superAdminList.totalAttendeeCancel}}</span>

                                            </span>

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-xl-6">
                            <div class="card box overflow-hidden">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-9">
                                            <p class="text-muted fs-16 mb-0">{{ $t('Statistics.SurveyDetails') }}</p>
                                            <h3 class="mb-2 fw-semibold">{{superAdminList.totalSurvey}}</h3>


                                        </div>
                                        <div class="col-3 p-0  top-icn dash">
                                            <img src="/assets/images/landing/survey.svg" />

                                        </div>
                                        <p class="text-muted mb-0 p-0 mt-2 fs-12">
                                            <span class="icn-box  fw-semibold fs-11 me-1">
                                                <span class="px-1">{{ $t('Statistics.Completed') }}</span>
                                                <span class=" px-2 py-1 bx">{{superAdminList.totalSessionSurvey}}</span>
                                                <span class="px-1">{{ $t('Statistics.NotGivenYet') }}</span>
                                                <span class=" px-2 py-1 by ">{{superAdminList.totalNotSurvey}}</span>

                                            </span>

                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h2 class="px-5 py-3">{{ $t('Statistics.SessionDetails') }}</h2>
                    <div class="row mt-2 px-5 mb-5">
                        <div class="col-sm-12 col-md-6 col-xl-6">
                            <div class="card box overflow-hidden">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-9">
                                            <p class="text-muted fs-16 mb-0">{{ $t('Statistics.TotalSession') }}</p>
                                            <h3 class="mb-2 fw-semibold">{{superAdminList.totalSession}}</h3>


                                        </div>
                                        <div class="col-3 p-0  top-icn dash">
                                            <img src="/assets/images/landing/workshops.svg" />

                                        </div>
                                        <p class="text-muted mb-0 p-0 mt-2 fs-12">
                                            <span class="icn-box  fw-semibold fs-11 me-1">
                                                <span class="px-1">{{ $t('Statistics.IndividualSession') }}</span>
                                                <span class=" px-2 py-1 bx">{{superAdminList.totalIndividualSession - superAdminList.totalIndividualSessionCancel}}</span>
                                                <span class="px-1">{{ $t('Statistics.GroupSession') }}</span>
                                                <span class=" px-2 py-1 by ">{{superAdminList.totalGroupSession - superAdminList.totalGroupSessionCancel }}</span>
                                                <span class="px-1">{{ $t('Statistics.WorkshopSession') }}</span>
                                                <span class=" px-2 py-1 bx ">{{superAdminList.totalWorkshopSession - superAdminList.totalWorkshopSessionCancel }}</span>

                                            </span>

                                        </p>
                                        <p class="text-muted mb-0 p-0 mt-3 fs-12">
                                            <span class="icn-box  fw-semibold fs-11 me-1">
                                                <span class="px-1">{{ $t('Statistics.Cancel') }}</span>
                                                <span class=" px-2 py-1 by">{{superAdminList.totalCancelSession}}</span>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-xl-6">
                            <div class="card box overflow-hidden">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-9">
                                            <p class="text-muted fs-16 mb-0">{{ $t('Statistics.WorkshopSession') }}</p>
                                            <h3 class="mb-2 fw-semibold">{{superAdminList.totalWorkshopSession}}</h3>


                                        </div>
                                        <div class="col-3 p-0  top-icn dash">
                                            <img src="/assets/images/landing/workshops.svg" />

                                        </div>
                                        <p class="text-muted mb-0 p-0 mt-2 fs-12">
                                            <span class="icn-box  fw-semibold fs-11 me-1">
                                                <span class="px-1">{{ $t('Statistics.Active') }}</span>
                                                <span class=" px-2 py-1 bx">{{superAdminList.totalWorkshopSessionActive}}</span>
                                                <span class="px-1">{{ $t('Statistics.Pending') }}</span>
                                                <span class=" px-2 py-1 by ">{{superAdminList.totalWorkshopSessionPending}}</span>
                                                <span class="px-1">{{ $t('Statistics.Completed') }}</span>
                                                <span class=" px-2 py-1 bx ">{{superAdminList.totalWorkshopSessionCompleted}}</span>

                                            </span>

                                        </p>
                                        <p class="text-muted mb-0 p-0 mt-3 fs-12">
                                            <span class="icn-box  fw-semibold fs-11 me-1">
                                                <span class="px-1">{{ $t('Statistics.Cancel') }}</span>
                                                <span class=" px-2 py-1 by">{{superAdminList.totalWorkshopSessionCancel}}</span>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-xl-6">
                            <div class="card box overflow-hidden">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-9">
                                            <p class="text-muted fs-16 mb-0">{{ $t('Statistics.IndividualSession') }}</p>
                                            <h3 class="mb-2 fw-semibold">{{superAdminList.totalIndividualSession}}</h3>


                                        </div>
                                        <div class="col-3 p-0  top-icn dash">
                                            <img src="/assets/images/landing/single.svg" />

                                        </div>
                                        <p class="text-muted mb-0 p-0 mt-2 fs-12">
                                            <span class="icn-box  fw-semibold fs-11 me-1">
                                                <span class="px-1">{{ $t('Statistics.Active') }}</span>
                                                <span class=" px-2 py-1 bt bx">{{superAdminList.totalIndividualSessionActive}}</span>
                                                <span class="px-1">{{ $t('Statistics.Pending') }}</span>
                                                <span class=" px-2 py-1 by ">{{superAdminList.totalIndividualSessionPending}}</span>
                                                <span class="px-1">{{ $t('Statistics.Completed') }}</span>
                                                <span class=" px-2 py-1 bx ">{{superAdminList.totalIndividualSessionCompleted}}</span>

                                            </span>

                                        </p>
                                        <p class="text-muted mb-0 p-0 mt-3 fs-12">
                                            <span class="icn-box  fw-semibold fs-11 me-1">
                                                <span class="px-1">{{ $t('Statistics.Cancel') }}</span>
                                                <span class=" px-2 py-1 by ">{{superAdminList.totalIndividualSessionCancel}}</span>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-xl-6">
                            <div class="card box overflow-hidden">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-9">
                                            <p class="text-muted fs-16 mb-0">{{ $t('Statistics.GroupSession') }}</p>
                                            <h3 class="mb-2 fw-semibold">{{superAdminList.totalGroupSession}}</h3>


                                        </div>
                                        <div class="col-3 p-0  top-icn dash">
                                            <img src="/assets/images/landing/Total.svg" />

                                        </div>
                                        <p class="text-muted mb-0 p-0 mt-2 fs-12">
                                            <span class="icn-box  fw-semibold fs-11 me-1">
                                                <span class="px-1">{{ $t('Statistics.Active') }}</span>
                                                <span class=" px-2 py-1 bx">{{superAdminList.totalGroupSessionActive}}</span>
                                                <span class="px-1">{{ $t('Statistics.Pending') }}</span>
                                                <span class=" px-2 py-1 by ">{{superAdminList.totalGroupSessionPending}}</span>
                                                <span class="px-1">{{ $t('Statistics.Completed') }}</span>
                                                <span class=" px-2 py-1 bx ">{{superAdminList.totalGroupSessionCompleted}}</span>

                                            </span>

                                        </p>
                                        <p class="text-muted mb-0 p-0 mt-3 fs-12">
                                            <span class="icn-box  fw-semibold fs-11 me-1">
                                                <span class="px-1">{{ $t('Statistics.Cancel') }}</span>
                                                <span class=" px-2 py-1 by">{{superAdminList.totalGroupSessionCancel}}</span>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <loading :name="loading" :active.sync="loading"
                         :can-cancel="false"
                         :is-full-page="true">
                </loading>
            </div><!-- COL END -->
        </div>

    </div>
</template>

<script>
    import moment from 'moment'
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";

    export default {
        name: 'Dashboard',
        components: {
            Loading
        },
        data: function () {
            return {
                loading: false,
                superAdminList: {
                    totalRegisteredStudents : 0,
                    totalRegisteredStudentsMale : 0,
                    totalRegisteredStudentsFemale : 0,

                    totalRegisteredAdviser : 0,
                    totalRegisteredAdviserMale : 0,
                    totalRegisteredAdviserFemale : 0,

                    totalSession : 0,
                    totalCancelSession : 0,

                    totalIndividualSession : 0,
                    totalIndividualSessionActive : 0,
                    totalIndividualSessionPending : 0,
                    totalIndividualSessionCompleted : 0,
                    totalIndividualSessionCancel : 0,

                    totalGroupSession : 0,
                    totalGroupSessionActive : 0,
                    totalGroupSessionPending : 0,
                    totalGroupSessionCompleted : 0,
                    totalGroupSessionCancel : 0,


                    totalWorkshopSession : 0,
                    totalWorkshopSessionActive : 0,
                    totalWorkshopSessionPending : 0,
                    totalWorkshopSessionCompleted : 0,
                    totalWorkshopSessionCancel : 0,

                    totalAttendee : 0,
                    totalAttendeeCancel : 0,
                    totalAttendeeCompleted : 0,

                    totalSurvey : 0,
                    totalSessionSurvey : 0,
                    totalNotSurvey : 0
                },
           
            }
        },
        methods: {

            GetInfo: function () {
                var root = this;
                var token = '';
                root.loading = true;
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Home/SuperAdminDashboardQuery', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.superAdminList = response.data;
                        root.loading = false;

                    }
                });
            },

            DowmloadCSV: function () {

                var root = this;
                var token = '';
                root.loading = true;
                
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.post('/Home/SuperAdminDashboardCsv' , this.superAdminList, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'SuperAdminDashboardReport.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        root.loading = false;

                    });
            },
            DownloadPdf: function () {
                var root = this;
                root.loading = true;

                root.$https.get('/Home/SuperAdminDashboardPdf', this.superAdminList, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        var date = moment().format('DD MMM YYYY');
                        link.setAttribute('download', 'SuperAdmin Report Pdf ' + date + '.pdf');
                        document.body.appendChild(link);
                        link.click();
                        root.loading = false;

                    });
            },

        },
        created: function () {
            this.GetInfo();
        },
        mounted: function () {

        },
    }
</script>
<style scoped>
    .card-header {
        display: flex;
        justify-content: space-between;
    }

    .btn-primary:hover {
        color: white !important;
    }

    .box {
        background-color: #edf4f9;
    }

    .bt {
        border-radius: 10px;
    }

    .bx {
        background: rgba(0, 144, 0, 0.05);
        border-radius: 6px;
        color: #009000;
    }

    .by {
        background: rgba(236, 118, 1, 0.1);
        border-radius: 6px;
        color: #EC7601;
    }
    .slide-item:hover {
        background-color: rgba(0, 144, 0, 0.9);
        color: white !important;
    }
</style>

