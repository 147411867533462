<template>
    <!--<div v-if="role=='Adviser'">
        <multiselect v-model="DisplayValue" :options="options" :disabled="disabled" :multiple="false" track-by="name" :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true" v-bind:placeholder=" $t('Dropdown.SelectOption')" :taggable="true" @tag="AddNewSessionType">
            <p slot="noResult" class="text-danger">  {{ $t('Dropdown.SessionType') }}</p>
            <p slot="noOptions" class="text-danger"></p>
        </multiselect>
    </div>-->
    <div>
        <multiselect v-model="DisplayValue" :options="options" :disabled="disabled" :multiple="false" track-by="name" :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true" v-bind:placeholder=" $t('Dropdown.SelectOption')" >
            <p slot="noResult" class="text-danger">  {{ $t('Dropdown.SessionType') }}</p>
            <p slot="noOptions" class="text-danger"></p>
        </multiselect>
    </div>
</template>
<script>
    import Multiselect from "vue-multiselect";
    export default {
        name: "sessiontypedropdown",
        props: ["values", 'subjectOfSession', 'disabled'],

        components: {
            Multiselect,

        },
        data: function () {
            return {
                options: [],
                value: '',
                role: '',
                render: 0,
            }
        },
        methods: {
            AddNewSessionType: function (name) {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var newSessionType = {
                    id: '00000000-0000-0000-0000-000000000000',
                    name: name,
                    subjectOfSession: this.subjectOfSession,
                    adviserId: localStorage.getItem('UserID'),
                    isActive: true
                }
                root.$https.post('/Setup/SaveSessionType', newSessionType, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.loading = false;
                            root.options.push({
                                id: response.data.id,
                                name: name
                            })
                            root.value = {
                                id: response.data.id,
                                name: name
                            };
                            root.$emit('input', root.value == null ? null : root.value.id);
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },


            getData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.get('/Setup/SessionTypeList?isDropDown=true' + '&subjectOfSession=' + this.subjectOfSession, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {


                    if (response.data != null) {
                        response.data.results.forEach(function (cat) {

                            root.options.push({
                                id: cat.id,
                                name: cat.name,
                                description: cat.description
                            })
                        })
                    }
                }).then(function () {

                    root.value = root.options.find(function (x) {
                        return x.id == root.values;
                    });

                });
            },
        },
        computed: {
            DisplayValue: {
                get: function () {
                    if (this.value != "" || this.value != undefined) {
                        return this.value;
                    }

                    return this.values;
                },
                set: function (value) {
                    this.value = value;
                    this.$emit('input', value == null ? null : value);
                }
            }
        },
        mounted: function () {
            this.getData();
            this.role = localStorage.getItem('RoleName');

        },
    }
</script>