<template>
    <div class="main-region">
        <div class="page-header">
            <div>
                <h1 class="page-title">  {{ $t('WorkshopList.Heading') }}</h1>
            </div>
            <div class="ms-auto pageheader-btn">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">  {{ $t('WorkshopList.Heading') }}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ $t('WorkshopList.Dashboard') }}</li>
                </ol>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-12">
                <div class="card product-sales-main">
                    <div class="card-body">
                        <div class="row mb-5">
                            <div class="col-sm-12 col-md-6 col-lg-3">
                                <label>From Date Time</label> <br />
                                <datetimepicker v-model="fromDate" />
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-3">
                                <label>To Date Time</label> <br />
                                <datetimepicker v-model="toDate" />
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-3">
                                <label> {{ $t('CreateWorkshop.ServiceType') }}</label>
                                <typesofsessiondropdown v-model="workshopType"  />
                            </div>
                        </div>

                        <div class="table-responsive">
                            <table class="table border text-nowrap text-md-nowrap table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>رقم للتذكرة</th>
                                        <th>{{ $t('WorkshopList.University') }}</th>
                                        <th>{{ $t('WorkshopList.ServiceAddres') }} </th>
                                        <th>{{ $t('WorkshopList.SessionLocation') }} </th>
                                        <th> {{ $t('WorkshopList.TimeAndDate') }}</th>
                                        <th>{{ $t('WorkshopList.WorkshopType') }} </th>
                                        <!--<th>{{ $t('WorkshopList.CareeAdvisor') }} </th>-->
                                        <!--<th>{{ $t('WorkshopList.AttendanceIcon') }}  </th>-->
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(region, index) in workShopList" :key="region.id">
                                        <td v-if="currentPage === 1">
                                            {{index+1}}
                                        </td>
                                        <td v-else>
                                            {{((currentPage*10)-10) +(index+1)}}
                                        </td>
                                        <td>{{region.code}}</td>
                                        <td>{{region.universityName}}</td>
                                        <td>
                                            <span v-if="!region.hideSessionType">
                                                {{region.sessionTypeName}}
                                            </span>
                                            <span v-if="region.hideSessionType">
                                               ---
                                            </span>
                                        </td>
                                        <td>
                                            <span v-if="region.workshopType==1">---</span>
                                            <span v-else>{{region.sessionLocationName}}</span>
                                        </td>
                                        <td>{{GetDate(region.sessionStart)}}</td>
                                        <td>
                                            <span v-if="region.workshopType==1"> {{ $t('WorkshopList.Remotely') }} ‎</span>
                                            <span v-if="region.workshopType==2"> {{ $t('WorkshopList.Came') }} ‎</span>
                                        </td>

                                        <!--<td>{{region.adviserName}}</td>-->
                                        <!--<td>
                                            <a v-bind:href="region.remoteLink" v-if="region.workshopType==1"><i class="fa fa-link"></i></a>
                                        </td>-->
                                        <td>
                                            <button class="btn btn-primary" v-on:click="ViewInfo(region.id)"><i class="fa fa-eye"></i></button>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="row">
                            <div class="col-md-6 text-right">
                                <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            </div>
                            <div class="col-md-6">
                                <div class="overflow-auto" v-on:click="getPage()" style="float:left">
                                    <b-pagination pills size="md" v-model="currentPage"
                                                  :total-rows="rowCount"
                                                  :per-page="10"
                                                  :first-text="this.$t('Tabel.First')"
                                                  :prev-text="this.$t('Tabel.Previous')"
                                                  :next-text="this.$t('Tabel.Next')"
                                                  :last-text="this.$t('Tabel.Last')"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import moment from 'moment'
    export default {
        name: 'WorkshopList',
        data: function () {
            return {
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                pageSize: '',
                fromDate: '',
                toDate: '',
                workshopType: '',
                loading: false,
                workShopList: [],

                show: false,
            }
        },
        watch: {
            search: function (val) {
                this.GetData(val, 1);
            },
            fromDate: function () {
                this.GetData(this.search, 1);
            },
            toDate: function () {
                this.GetData(this.search, 1);
            },
            workshopType: function () {
                this.GetData(this.search, 1);
            }
        },
        methods: {
            GoTo: function (link) {
                this.$router.push(link);
            },

            GetDate: function (date) {
                return moment(date).format('LLL');
            },

            getPage: function () {
                this.GetData(this.search, this.currentPage);
            },

            GetData: function (search, currentPage) {
                var root = this;
                var url = '/Sessions/WorkShopSessionList?searchTerm=' + search + '&pageNumber=' + currentPage + '&fromDate=' + this.fromDate + '&toDate=' + this.toDate + '&workshopType=' + this.workshopType;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.workShopList = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        type: 'error',
                        title: root.$t('Notification.Error'),
                        text: root.$t('Notification.SomethingWrong'),
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                }).finally(() => root.loading = false);
            },


            ViewInfo: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Sessions/GetSessionDetail?id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$router.push({
                            path: '/SessionDetail',
                            query: { data: response.data }
                        })
                    }
                });
            },
        },
        created: function () {

        },
        mounted: function () {
            this.GetData(this.search, 1);
        },
    }
</script>
<style scoped>
    .card-header {
        display: flex;
        justify-content: space-between;
    }

    .btn-primary:hover {
        color: white !important;
    }
</style>


