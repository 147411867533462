<template>
    <div class="page-main">
        <div class="container py-5 mb-5">
            <div class="backtomain mb-3">
                <div class="logo mr-3">
                    <a href="javascript:void(0)" v-on:click="GoTo('/')"><span class="h1 fw-bold mb-0"><img src="/assets/images/subol_logo.png" class=" mx-auto img-fluid logoimg" alt="subol_logo" style="max-width:210px;"></span></a>
                </div><br>
            </div>
            <div class="signup_form_title mb-5">
                <h1><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> {{ $t('CreateStudentSession.Heading') }}</font></font></h1>

            </div>
            <div class="row">
                <div class="col-sm-12 col-md-12 col-xl-6 " v-if="isCreateIndividualSession">
                    <div class="card">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('CreateStudentSession.Heading') }}</h5>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="form-group col-md-12">
                                    <label>{{ $t('CreateStudentSession.SessionType') }}</label>
                                    <sessiontypedropdown :subjectOfSession="'Individual'" @input="getSessionDetail" />
                                </div>
                                <div class="form-group col-md-12">
                                    <label>{{ $t('SessionType.Description') }}</label>
                                    <textarea rows="4" v-model="description" class="form-control p-3" disable readonly />
                                </div>
                                <div class="form-group col-md-12">
                                    <label>{{ $t('CreateStudentSession.SessionStartDate') }}</label>
                                    <datepicker v-model="slotDate" :language="ar" :highlighted="highlighted" :format="customFormatter" :disabledDates="disabledDates" placeholder="حدد تاريخ" :inline="true"></datepicker>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer ">
                            <button type="button" class="btn btn-primary btn-round" v-bind:disabled="$v.createStudentSession.$invalid" v-on:click="SaveStudentSession()">{{ $t('CreateStudentSession.Save') }}</button>
                            <button type="button" class="btn btn-danger btn-round" v-on:click="close" href="javascript:void(0)">{{ $t('CreateStudentSession.Cancel') }}</button>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-12 col-xl-6 " v-if="isCreateIndividualSession">
                    <div class="card">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('CreateStudentSession.AdvisorSession') }}</h5>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div v-for="slot in options" :key="slot.id" class="form-group col-md-4">
                                    <div v-on:click="createStudentSession.adviserSlotId=slot.id" class="time-tag d-flex" v-bind:class="createStudentSession.adviserSlotId===slot.id ? 'active_div' : ''">
                                        <span>{{slot.fromTime}}</span>
                                        <span class="mx-1">-</span>
                                        <span>{{slot.toTime}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="row row-sm my-5" v-if="studenNotFound!='Student not found!' && !isCreateIndividualSession">
                <div class="col-lg-4 form-group">
                    <input v-model="nationalId" v-on:keyup="CheckNationalId(nationalId)" type="text" class="form-control" placeholder="أدخل هويتك الوطنية">
                </div>

                <div class="col-lg-4 form-group" v-if="isEligible">
                    <input v-model="otp" type="text" class="form-control" placeholder="ادخل الرمز المرسل لهاتفكم">
                </div>

                <div class="col-lg-4 form-group text-start" v-if="studenNotFound!='Your are eligible for this session!' && studenNotFound!='Student not found!'">
                    <button v-bind:disabled="isValidNationalId" class="btn btn-primary ms-2" v-bind:class="loading? 'btn-loading' : ''" style="cursor:pointer" @click="checkDetails(nationalId)">التالي</button>
                </div>

                <div class="col-lg-4 form-group text-start" v-if="isEligible">
                    <button v-bind:disabled="otp==''" type="button" class="btn btn-primary" v-bind:class="loading? 'btn-loading' : ''" v-on:click="JoinNow()">{{ $t("CreateWorkshop.Join") }}</button>
                </div>

            </div>

            <hr class="mb-5 mt-3" v-if="studenNotFound=='Student not found!'" />
            <div class="row" v-if="studenNotFound=='Student not found!'">
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('Register.FirstName') }}<span class="error text-danger">*</span> {{$t('Register.FirstNameDescription')}}</label>
                    <input v-model="$v.studentRegister.firstName.$model" type="text" class="form-control">
                    <span v-if="$v.studentRegister.firstName.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.firstName.arabic">يرجى كتابه الاسم باللغة العربية<br /></span>
                        <span v-if="!$v.studentRegister.firstName.minLength">يرجى كتابه الاسم بالكامل</span>
                    </span>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('Register.FatherName') }} <span class="error text-danger">*</span> {{$t('Register.FatherNameDescription')}}</label>
                    <input v-model="$v.studentRegister.middleName.$model" type="text" class="form-control">
                    <span v-if="$v.studentRegister.middleName.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.middleName.arabic">يرجى كتابه اسم الاب باللغة العربية<br /></span>
                        <span v-if="!$v.studentRegister.middleName.minLength">يرجى كتابه اسم الاب بالكامل</span>
                    </span>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('Register.FamilyName') }} <span class="error text-danger">*</span> {{$t('Register.FamilyNameDescription')}}</label>
                    <input v-model="$v.studentRegister.lastName.$model" type="text" class="form-control">
                    <span v-if="$v.studentRegister.lastName.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.lastName.arabic">يرجى كتابه لقب العائلة باللغة العربية</span>
                        <span v-if="!$v.studentRegister.lastName.minLength">يرجى كتابه لقب العائلة بالكامل</span>
                    </span>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label for="inputZip">{{ $t('Register.StudentCategories') }}<span class="error text-danger">*</span></label>
                    <studentcategorydropdown v-model="studentRegister.studentCategoryId" :values="studentRegister.studentCategoryId" @input="UniversityLevelRender()" />
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.Email') }}<span class="error text-danger">*</span></label>
                    <input v-model="$v.studentRegister.email.$model" type="email" class="form-control">
                    <span v-if="$v.studentRegister.email.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.email.email">الرجاء إدخال بريد إلكتروني صحيح</span>
                    </span>
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.IdentificationNumber') }}<span class="error text-danger">*</span> </label>
                    <input v-model="$v.studentRegister.nationalId.$model" v-on:keyup="CheckNationalId(studentRegister.nationalId)" type="text" class="form-control">
                    <span v-if="$v.studentRegister.nationalId.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.nationalId.number">الرجاء كتابة الرقم فقط</span>
                    </span>
                    <span v-if="isValidNationalId" class="text-danger">لا يمكن أن يكون أكثر أو أقل من 10 أرقام ويبدأ دائمًا بـ "1" أو "2"</span>

                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.MobileNumber') }}<span class="error text-danger">*</span></label>
                    <input v-model="$v.studentRegister.phoneNumber.$model" type="text" class="form-control" placeholder="05xxxxxxxx">
                    <span v-if="$v.studentRegister.phoneNumber.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.phoneNumber.phoneNumber">الرجاء كتابة رقم هاتف محمول صحيح</span>
                        <span v-if="!$v.studentRegister.phoneNumber.minLength">الرجاء كتابة رقم هاتف محمول صحيح</span>
                    </span>
                </div>


                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.Gender') }}<span class="error text-danger">*</span></label>
                    <genderdropdown v-model="$v.studentRegister.gender.$model" :values="studentRegister.gender" />
                    <span v-if="$v.studentRegister.gender.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.gender.required">يرجى اختيار نوع الجنس</span>
                    </span>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.DateOfBirth') }}<span class="error text-danger">*</span></label>
                    <datepicker v-model="studentRegister.dateOfBirth" />
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.City') }}<span class="error text-danger">*</span></label>
                    <citydropdown v-model="studentRegister.cityId" :isuniversty="'true'" :values="studentRegister.cityId" />
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.University') }}<span class="error text-danger">*</span></label>
                    <universitydropdown v-model="studentRegister.universityId" :optionsvalue="universityIds" :values="studentRegister.universityId" />
                </div>

                <!-- <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.UniversitySpecialization') }}<span class="error text-danger">*</span></label>
                    <universtyspecilizationdropdown v-model="studentRegister.specializationId" :values="studentRegister.specializationId" />
                </div> -->

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.UniversityLevel') }}<span class="error text-danger">*</span></label>
                    <universtyleveldropdown v-model="$v.studentRegister.universityLevelId.$model" :values="studentRegister.universityLevelId" :studentCategoryId="studentRegister.studentCategoryId" :key="universityLevelRender" />
                    <span v-if="$v.studentRegister.universityLevelId.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.universityLevelId.required">يرجى اختيار المستوى الجامعي</span>
                    </span>
                </div>

                <div class="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12" v-if="studenNotFound=='Student not found!'">
                    <button v-on:click="SaveNewUser" v-bind:disabled="$v.studentRegister.$invalid || isValidNationalId" class="btn btn-primary" v-bind:class="loading? 'btn-loading' : ''">{{ $t('Register.Save') }}</button>
                    <button type="button" class="btn btn-danger btn-round mx-1" v-on:click="GoTo('/')" href="javascript:void(0)">{{ $t("CreateWorkshop.Cancel") }}</button>
                </div>

            </div>

            <loading :name="loading" :active.sync="loading"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
        </div>

    </div>
</template>
<script>
    import { required, maxLength, requiredIf, minLength, email } from "vuelidate/lib/validators";
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    import Datepicker from 'vuejs-datepicker';
    import moment from 'moment'
    import { ar } from 'vuejs-datepicker/dist/locale'

    export default {
        name: 'CreateStudentSession',
        components: {
            Loading,
            Datepicker
        },
        data: function () {
            return {
                isValidNationalId: false,
                universityIds: [],
                universityLevelRender: 0,
                ar: ar,
                nationalId: '',
                studenNotFound: '',
                otp: '',
                isEligible: false,
                advisorId: '00000000-0000-0000-0000-000000000000',
                userId: '',
                isCreateIndividualSession: false,

                createStudentSession: {
                    id: '00000000-0000-0000-0000-000000000000',
                    sessionTypeId: '',
                    adviserSlotId: '',
                    studentId: '',
                },
                loading: false,
                slotDate: '',
                options: '',
                description: '',

                highlighted: {
                    dates: [],
                },
                disabledDates: {
                    to: new Date(Date.now() - 864e5),
                    dates: [],
                },

                studentRegister: {
                    id: '00000000-0000-0000-0000-000000000000',
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    roleName: 'Student',
                    registrationCode: '',
                    nationalId: '',
                    phoneNumber: '',

                    dateOfBirth: '',
                    gender: '',
                    email: '',
                    cityId: '',
                    universityId: '',
                    studentCategoryId: '',
                    qualificationId: '',
                    regionId: '',
                    specializationId: '',
                    universityLevelId: '',
                    password: 'suboluniv',
                    confirmPassword: 'suboluniv',
                    isActive: true,
                    quickUser: true,
                },

            }
        },
        validations: {
            createStudentSession: {
                sessionTypeId: {
                    required: required
                },
                adviserSlotId: {
                    required: required
                }
            },

            studentRegister:
            {
                firstName:
                {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    arabic(name) {
                        return /^[\u0621-\u064A\s]+$/.test(name)
                    }
                },
                middleName:
                {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    arabic(name) {
                        return /^[\u0621-\u064A\s]+$/.test(name)
                    }
                },
                lastName:
                {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    arabic(name) {
                        return /^[\u0621-\u064A\s]+$/.test(name)
                    }
                },
                studentCategoryId:
                {
                    required
                },
                gender:
                {
                    required
                },
                universityId:
                {
                    required
                },
                email:
                {
                    required,
                    email
                },
                nationalId:
                {
                    required,
                    number(id) {
                        return /^[0-9\u0660-\u0669]+$/.test(id)
                    }
                },
                phoneNumber:
                {
                    maxLength: maxLength(15),
                    minLength: minLength(10),
                    phonenumber(number) {
                        return /^[0-9\u0660-\u0669]+$/.test(number)
                    }
                },
                universityLevelId: {
                    required: requiredIf((x) => {
                        if (x.studentCategoryId == 'e1c0e4db-a923-406b-98cd-0139694362c6' || x.studentCategoryId == 'e3c0e4db-a923-406b-98cd-0139694362c6' || x.studentCategoryId == 'b12b3957-2c34-48e9-86c2-2958f230dd6f')
                            return true;
                        return false;
                    }),
                },
                // specializationId:
                // {
                //     required: requiredIf((x) => {
                //         if (x.studentCategoryId == 'e1c0e4db-a923-406b-98cd-0139694362c6')
                //             return true;
                //         return false;
                //     }),
                // },

            }

        },
        watch: {
            slotDate: function () {
                this.getData();
            },
        },
        methods: {
            CheckNationalId: function (no) {
                
                if (no.length != 10) {
                    this.isValidNationalId = true;
                }
                else {
                    var letter = no.substring(0, 1)
                    if (letter != '1' && letter != '2' && letter != '١' && letter != '٢') {
                        this.isValidNationalId = true;
                    }
                    else {
                        this.isValidNationalId = false;
                    }
                }
            },

            UniversityLevelRender: function () {
                
                this.universityLevelRender++;
            },

            customFormatter: function (date) {
                this.slotDate = moment(date).format('MM/DD/YYYY');
                return moment(date).format('MM/DD/YYYY');
            },

            GoTo: function (link) {
                this.$router.push(link);
            },

            close: function () {
                this.$router.push('/');
            },
            getSessionDetail(data) {
                this.description = data.description;
                this.createStudentSession.sessionTypeId = data.id;

            },

            getData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if (this.slotDate != null && this.slotDate != '' && this.slotDate != undefined) {
                    this.$https.get('/Setup/AdviserSlotList?isDropDown=true' + '&fromDateTime=' + this.slotDate + '&userId=' + this.userId + '&adviserId=' + this.advisorId, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                        if (response.data != null) {
                            root.options = response.data.results;
                        }
                    });
                }
                else {
                    root.options = [];
                }
            },

            SaveStudentSession: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.createStudentSession.studentId = this.userId;
                root.$https.post('/Sessions/SaveIndividualSession?fromQr=true', this.createStudentSession, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.loading = false;
                            root.$swal({
                                title: root.$t('Notification.Added'),
                                text: root.$t('Notification.DataSucess'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    root.close();
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            getSlotDayForHighlighted: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.get('/Setup/AdviserSlotDayList?userId=' + this.userId, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        console.log(response.data)
                        response.data.list.forEach(function (cat) {
                            root.highlighted.dates.push(new Date(moment(cat).format('YYYY'), moment(cat).format('MM'), moment(cat).format('DD')));
                        });
                        response.data.disableList.forEach(function (cat) {
                            root.disabledDates.dates.push(new Date(moment(cat).format('YYYY'), moment(cat).format('MM'), moment(cat).format('DD')));
                        });
                    }
                });

            },

            SendResendEmailVerification: function (email) {
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/account/ResendSendEmailVerification?email=' + email, { headers: { "Authorization": `Bearer ${token}` } });
            },


            checkDetails: function (nationalId) {
                var root = this;
                this.loading = true;
                
                if (nationalId != '' && nationalId != null && nationalId != undefined && nationalId.length == 10) {
                    root.$https.get('/account/VerifyStudentDetailForSession?id=' + this.advisorId + '&nationalid=' + nationalId + '&isIndividual=true').then(function (response) {
                        if (response.data != null && response.data.isSuccess) {
                            root.loading = false;
                            root.allowQuickRegister = response.data.allowQuickRegister;
                            root.isEligible = response.data.isSuccess;
                            root.userId = response.data.id;
                            root.studenNotFound = response.data.isAddUpdate;

                            root.$swal({
                                title: 'تم ارسال رمز التحقق',
                                text: 'تم إرسال رمز التحقق الى الهاتف!',
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            });
                        }
                        else {

                            var message = '';
                            var title = '';
                            var icon = '';
                            var type = '';

                            if (response.data.isAddUpdate == "Session Close") {
                                title = 'تم إغلاق التسجيل في ورشة العمل';
                                message = 'تم إغلاق التسجيل في ورشة العمل';
                                type = 'error';
                                icon = 'error';
                            }
                            else if (response.data.isAddUpdate == "Your Email and Phone Number is not verified !") {
                                title = 'لم يتم التحقق من البريد الإلكتروني ورقم الهاتف';
                                message = 'لم يتم التحقق من بريدك الإلكتروني ورقم هاتفك';
                                type = 'error';
                                icon = 'error';
                            }
                            else if (response.data.isAddUpdate == "You have not same gender!") {
                                title = 'ليس لديك نفس الجنس';
                                message = 'ليس لديك نفس الجنس';
                                type = 'error';
                                icon = 'error';
                            }
                            else if (response.data.isAddUpdate == "You have not same University!") {
                                title = 'عذرا، انت مسجل في جامعة اخرى';
                                message = 'عذرا، انت مسجل في جامعة اخرى';
                                type = 'error';
                                icon = 'error';
                            }
                            else if (response.data.isAddUpdate == "Student not found!") {
                                title = 'الهوية غير مسجلة نأمل استكمال التسجيل';
                                message = 'الهوية غير مسجلة نأمل استكمال التسجيل';
                                type = 'warning';
                                icon = 'warning';
                            }
                            else {
                                title = 'ليس لديك نفس المستوى الجامعي!';
                                message = 'ليس لديك نفس المستوى الجامعي!';
                                type = 'error';
                                icon = 'error';
                            }

                            if (response.data.isAddUpdate == "Your Email and Phone Number is not verified !") {
                                root.$swal({
                                    title: title,
                                    text: message,
                                    type: "error",
                                    icon: "error",
                                    showCancelButton: true,
                                    confirmButtonColor: "#DD6B55",
                                    confirmButtonText: "Resend Email?",
                                    closeOnConfirm: false,
                                    closeOnCancel: false
                                }).then(function (result) {
                                    if (result.isConfirmed) {
                                        root.SendResendEmailVerification(nationalId);
                                    }
                                });
                            }
                            else {
                                root.$swal({
                                    title: title,
                                    text: message,
                                    type: type,
                                    icon: icon,
                                    confirmButtonClass: "btn btn-danger",
                                    buttonsStyling: false
                                });
                            }

                            root.loading = false;
                            root.isEligible = response.data.isSuccess;
                            root.studenNotFound = response.data.isAddUpdate;
                            root.studentRegister.nationalId = nationalId;

                        }
                    });
                }
                else {
                    root.isEligible = false;
                }
            },

            JoinNow: function () {
                var root = this;
                this.loading = true;


                root.$https.get('/account/VerifyOtpForIndividualSession?nationalId=' + this.nationalId + '&otp=' + this.otp + '&adviserId=' + this.advisorId)
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data) {
                            root.$swal({
                                title: 'تم التحقق!',
                                text: 'تم التحقق من الهوية الوطنية.',
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            });
                            root.loading = false;
                            root.getSlotDayForHighlighted();
                            root.isCreateIndividualSession = true;
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: 'رقم التعريف الشخصي أو الرقم القومي غير صحيح',
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                            root.isCreateIndividualSession = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.isCreateIndividualSession = false;
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            SendEmailVerification: function (id) {
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.get('/account/SendMobileOtpVerification?id=' + id, { headers: { "Authorization": `Bearer ${token}` } });
            },

            SaveNewUser: function () {
                var root = this;
                this.loading = true;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                root.$https.post('/account/StudentRegister', this.studentRegister, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {

                            if (root.studentRegister.id == '00000000-0000-0000-0000-000000000000') {
                                root.SendEmailVerification(response.data.id);
                            }
                            root.studenNotFound = '';
                            root.$swal({
                                title: root.$t('Notification.Created'),
                                text: "تم إرسال رمز التحقق الى الهاتف",
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result.isConfirmed) {
                                    root.checkDetails(root.nationalId);
                                }
                            });
                            root.loading = false;
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-info",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            getUniversityList: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.universityIds = [];

                this.$https.get('/Setup/UniversityListForIndividual?adviserId=' + this.advisorId, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        response.data.forEach(function (cat) {
                            root.universityIds.push({
                                id: cat.id,
                                name: cat.name
                            })
                        })
                    }
                });
            },

        },
        created: function () {
            if (this.$route.query.id != undefined) {
                this.advisorId = this.$route.query.id;
                this.getUniversityList();
            }

            /*this.getSlotDayForHighlighted();*/
        },
        mounted: function () {

        },
    }
</script>
<style scoped>
    .time-tag {
        padding: 10px;
        border: 1px solid rgb(219, 219, 219);
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

        .time-tag:hover {
            background-color: rgb(0,144,0);
            color: #fff;
        }

    .active_div {
        background-color: rgb(0,144,0) !important;
        color: #fff !important;
    }
</style>