<template>
    <div class="main-session-type">
        <div class="page-header">
            <div>
                <h1 class="page-title">{{ $t('SurveyQuestions.Heading') }}</h1>
            </div>
            <div class="ms-auto pageheader-btn">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t('SurveyQuestions.Heading') }}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ $t('SessionType.Dashboard') }}</li>
                </ol>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-12">
                <div class="card product-sales-main">

                    <!--start-->
                    <div class="row p-5" v-if="role != 'Reviewer'">
                        <div class="col-md-4 ">
                            <label>{{ $t('SurveyQuestions.ChooseSessiontype') }}</label>
                            <subject-of-session-dropdown v-model="surveyQuestion.subjectOfSession" :values="surveyQuestion.subjectOfSession" :key="render" />
                        </div>

                        <div class="form-group col-md-8 d-flex flex-column ">
                            <label>{{ $t('SurveyQuestions.Question') }}</label>
                            <textarea rows="1" cols="50" name="comment" form="usrform" class="border p-3" v-model="surveyQuestion.question" @focus="$event.target.select()"></textarea>

                        </div>
                        <div class="form-group col-md-8">
                            <div class="material-switch">
                                <input id="someSwitchOptionPrimary" name="someSwitchOption001" v-model="surveyQuestion.isActive" type="checkbox" />
                                <label for="someSwitchOptionPrimary" class="label-primary"></label>
                            </div>
                        </div>
                        <div class="col-md-4 mt-5 ac">
                            <button type="button" v-on:click="SaveSessionType()" v-bind:disabled="$v.$invalid" class="btn btn-primary btn-pill float-end"> {{ $t('SurveyQuestions.Save') }}</button>
                        </div>
                        <!-- <div class="col-auto align-self-center">
                            <a v-on:click="getCsv" href="javascript:void(0);" class="btn btn-sm btn-outline-primary mx-1">
                                CSV
                            </a>
                        </div> -->

                    </div>

                    <!--end-->
                    <div class="card-body">
                        <div class="table-responsive">

                            <div class="card-header border-bottom">
                                <h2> {{ $t('SurveyQuestions.HeadingTitle') }}</h2>
                                <div class="form-group">
                                    <div class="input-group">
                                        <input v-model="search" class="form-control fc" id="basic-addon6" :placeholder=" $t('Regions.Search')" type="text">

                                    </div>
                                </div>

                            </div>
                            <table class="table border text-nowrap text-md-nowrap table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{{ $t('SessionType.SubjectOfSession') }}</th>
                                        <th>{{ $t('SessionType.SessionTypeName') }}</th>
                                        <th class="text-center">{{ $t('SessionType.Status') }}</th>
                                        <th v-if="role != 'Reviewer'">{{ $t('SessionType.Action') }}</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr v-for="( surveyQuestion, index) in surveyQuestionList" :key=" surveyQuestion.id">
                                        <td v-if="currentPage === 1">
                                            {{index+1}}
                                        </td>
                                        <td v-else>
                                            {{((currentPage*10)-10) +(index+1)}}
                                        </td>


                                        <td>
                                            <span v-if="surveyQuestion.subjectOfSession==1">
                                                {{ $t('SessionType.Single') }}
                                            </span>
                                            <span v-if="surveyQuestion.subjectOfSession==2">
                                                {{ $t('SessionType.Collective') }}
                                            </span>
                                            <span v-if="surveyQuestion.subjectOfSession==3">
                                                {{ $t('SessionType.Workshop') }}
                                            </span>
                                        </td>

                                        <td>{{ surveyQuestion.question}}</td>
                                        <td>
                                            <span v-if=" surveyQuestion.isActive" class="badge rounded-pill bg-primary-gradient my-1">{{ $t('SessionType.Active') }}</span>
                                            <span v-else class="badge rounded-pill bg-danger-gradient my-1">{{ $t('SessionType.InActive') }}</span>
                                        </td>

                                        <td v-if="role != 'Reviewer'">
                                            <a v-on:click="EditInfo(surveyQuestion.id)" href="javascript:void(0)"><img src="/assets/images/Edit.svg" /></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="row">
                            <div class="col-md-6 text-right">
                                <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            </div>
                            <div class="col-md-6">
                                <div class="overflow-auto" v-on:click="getPage()" style="float:left">
                                    <b-pagination pills size="md" v-model="currentPage"
                                                  :total-rows="rowCount"
                                                  :per-page="10"
                                                 :first-text="this.$t('Tabel.First')"
                                                  :prev-text="this.$t('Tabel.Previous')"
                                                  :next-text="this.$t('Tabel.Next')"
                                                  :last-text="this.$t('Tabel.Last')"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import { required } from "vuelidate/lib/validators";

    export default {

        name: 'SurveyQuestions',

        data: function () {
            return {
                render: 0,
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                pageSize: '',
                loading: false,
                role:'',
                surveyQuestionList: [],
                surveyQuestion: {
                    id: '00000000-0000-0000-0000-000000000000',
                    subjectOfSession: 0,
                    question: '',
                    isActive: true,
                }
            }
        },
        validations: {
            surveyQuestion: {
                question: {
                    required: required
                },
                subjectOfSession: {
                    required: required
                }
            }
        },
        watch: {
            search: function (val) {
                this.GetData(val, 1);
            }
        },
        methods: {
            GoTo: function (link) {
                this.$router.push(link);
            },

            getPageSize: function (isDesc) {
                this.isDesc = isDesc;
                this.getPage();
            },

            getPage: function () {
                this.GetData(this.search, this.currentPage);
            },

            SaveSessionType: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.surveyQuestion.subjectOfSession = parseInt(this.surveyQuestion.subjectOfSession);
                root.$https.post('/Setup/SaveSurveyQuestion', this.surveyQuestion, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.loading = false;
                            root.GetData(root.search, 1);
                            
                            root.surveyQuestion.question = '';
                            root.surveyQuestion.subjectOfSession = '';
                            root.surveyQuestion.isActive = true;
                            root.render++;
                            root.$swal({
                                title: root.$t('Notification.Added'),
                                text: root.$t('Notification.DataSucess'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    root.close;

                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            getCsv: function () {
                var root = this;
                var token = '';
                root.loading = true;

                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Setup/GetSurveyQuestionListExcel', { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'GetSurveyQuestion List.csv');
                        document.body.appendChild(link);
                        link.click();
                        root.loading = false;

                    });
            },

            GetData: function (search, currentPage) {
                var root = this;
                var url = '/Setup/GetSurveyQuestionList?searchTerm=' + search + '&pageNumber=' + currentPage;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.surveyQuestionList = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        type: 'error',
                        title: root.$t('Notification.Error'),
                        text: root.$t('Notification.SomethingWrong'),
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                }).finally(() => root.loading = false);
            },


            EditInfo: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Setup/GetSurveyQuestionDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.surveyQuestion.id = response.data.id;
                        root.surveyQuestion.question = response.data.question;
                        root.surveyQuestion.isActive = response.data.isActive;
                        root.surveyQuestion.subjectOfSession = response.data.subjectOfSession;
                        root.render++;
                    }

                });
            },



        },
        created: function () {
            this.role = localStorage.getItem('RoleName');
        },
        mounted: function () {
            this.GetData(this.search, 1);
        },

    }
</script>
<style scoped>
    .card-header {
        display: flex;
        justify-content: space-between;
    }

    .btn-primary:hover {
        color: white !important;
    }
    .ac button:focus{
        color:white !important;
    }
</style>


