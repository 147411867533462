<template>
    <div class="main-review-list">
        <div class="page-header">
            <div>
                <h1 class="page-title">تقرير السجل </h1>
            </div>
            <div class="ms-auto pageheader-btn">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">تقرير السجل</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ $t('ReviewerList.Dashboard') }}</li>
                </ol>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-12">
                <div class="card product-sales-main">
                    <div class="border-bottom align-items-end">
                        <div class="row ps-5">
                            <div class="col-sm-12 col-md-6 col-lg-3 form-group">
                                <div class="input-group">
                                    <label>من التاريخ</label>
                                    <datepicker v-model="fromDate" />
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-3 form-group">
                                <div class="input-group">
                                    <label>حتي اليوم</label>
                                    <datepicker v-model="toDate" />
                                </div>
                            </div>
                        </div>
                        
                        <!--<div class="col-sm-12 col-md-6 col-lg-3 form-group">
                            <label>ابحث</label>
                            <input type="text" v-model="search" class="form-control" />
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-3 form-group">
                            <div class="btn-group mt-2 mb-2">
                                <button type="button" class="btn btn-outline-default btn-pill dropdown-toggle" data-bs-toggle="dropdown">
                                    عمل <span class="caret"></span>
                                </button>
                                <ul class="dropdown-menu" role="menu">
                                    <li><a v-on:click="DowmloadCSV" href="javascript:void(0)" class="btn  btn-pill slide-item">{{ $t('Statistics.DownloadCsv') }}</a></li>
                                    <li><a v-on:click="DownloadPdf" href="javascript:void(0)" class="btn  btn-pill slide-item">تحميل Pdf</a></li>
                                </ul>
                            </div>
                        </div>-->

                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table border text-nowrap text-md-nowrap table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            #
                                        </th>
                                        <th>
                                            تاريخ 
                                        </th>
                                        
                                        <th>
                                            Url
                                        </th>
                                        <th>
                                            الطلاب
                                        </th>
                                        <th>
                                            رقم الجوال
                                        </th>
                                        <th>
                                            رسالة تسجيل
                                        </th>
                                        <th>
                                            هو خطأ
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(student, index) in studentList" :key="index">
                                        <td v-if="currentPage === 1">
                                            {{index+1}}
                                        </td>
                                        <td v-else>
                                            {{((currentPage*10)-10) +(index+1)}}
                                        </td>
                                        <td>{{GetDate(student.date)}}</td>
                                        <td>{{student.url}}</td>
                                        <td>{{student.email}}</td>
                                        <td>{{student.nationalId}}</td>
                                        <td>{{student.logMessage}}</td>
                                        <td>
                                            <span>{{student.isError?'خطأ' : 'نجاح'}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="row">
                            <div class="col-md-6 text-right">
                                <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            </div>
                            <div class="col-md-6">
                                <div class="overflow-auto" v-on:click="getPage()" style="float:left">
                                    <b-pagination pills size="md" v-model="currentPage"
                                                  :total-rows="rowCount"
                                                  :per-page="10"
                                                  :first-text="this.$t('Tabel.First')"
                                                  :prev-text="this.$t('Tabel.Previous')"
                                                  :next-text="this.$t('Tabel.Next')"
                                                  :last-text="this.$t('Tabel.Last')"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <loading :name="loading" :active.sync="loading"
                     :can-cancel="false"
                     :is-full-page="true">
            </loading>
        </div>
    </div>

</template>

<script>
    import moment from 'moment'
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    import axios from 'axios'

    export default {
        name: 'StudentReport',
        components: {
            Loading
        },
        data: function () {
            return {
                count: 0,
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                pageSize: '',
                loading: false,

                fromDate: '',
                toDate: '',
                studentList: [],
            }
        },
        watch: {
            search: function (val) {
                this.GetData(val, 1);
            },

            fromDate: function () {
                this.count++;
                if (this.count != 1) {
                    this.GetData(this.search, 1);
                }

            },

            toDate: function () {
                this.count++;
                if (this.count != 2) {
                    this.GetData(this.search, 1);
                }
                
            }
        },
        methods: {
            GoTo: function (link) {
                this.$router.push(link);
            },
            
            GetDate: function (date) {
                return moment(date).format('YYYY-MM-DD hh:mm a');
            },


            getPage: function () {
                this.GetData(this.search, this.currentPage);
            },

            GetData: function (search, currentPage) {
                var root = this;
                axios.get(this.$ApiServerIp + '/v1/Report/GetLogList?searchTerm=' + search + '&pageNumber=' + currentPage + '&fromDate=' + this.fromDate + '&toDate=' + this.toDate).then(function (response) {
                    if (response.data != null) {
                        root.studentList = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                    }
                }).catch(error => {
                    root.$swal.fire(
                        {
                            icon: 'error',
                            type: 'error',
                            title: root.$t('Notification.Error'),
                            text: error,
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });
                    root.loading = false;
                });
            },

            DowmloadCSV: function () {
                var root = this;
                var token = '';
                root.loading = true;

                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Home/StudentReportListCsv?fromDate=' + this.fromDate + '&toDate=' + this.toDate, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Student Report.csv');
                        document.body.appendChild(link);
                        link.click();
                        root.loading = false;

                    });
            },


        },
        created: function () {

            this.fromDate = moment().startOf('month').format('l');
            this.toDate = moment().endOf('month').format('l');

            this.GetData(this.search, 1);
        },
        mounted: function () {

        },
    }
</script>
<style scoped>

    .btn-primary:hover {
        color: white !important;
    }

    .slide-item:hover {
        background-color: rgba(0, 144, 0, 0.9);
        color: white !important;
    }
</style>


