<template>
    <div class="page">
        <div>
            <!-- CONTAINER OPEN -->
            <div class="card col-md-8 mx-auto text-center p-3">
                <a href="/">
                    <img src="/assets/images/landing/subol.png" class="img-fluid mb-3" alt="">
                </a>

                <div class="col-12 container-login100">
                    <div class="row">
                        <div class="col col-login mx-auto">
                            <div class="card-body">

                                <div class="pt-3" id="forgot">
                                    <h5 v-if="isSuccess" class="nk-block-title text-success">{{message}}</h5>
                                    <h5 v-else class="nk-block-title text-danger">{{message}}</h5>
                                    <!--<div v-if="isSuccess" class="nk-block-des text-success">
                                        <p>  {{ $t('EmailConfirmed.CheckInbox') }} </p>
                                    </div>-->

                                    {{ $t('EmailConfirmed.Cancel') }} <a href="javascript:void(0)" v-on:click="GoToSignup()">   {{ $t('EmailConfirmed.Login') }} </a>
                                </div>

                            </div>
                            <div class="card-footer">
                                <div class="d-flex justify-content-center my-3">
                                    <a href="javascript:void(0)" class="social-login  text-center me-4">
                                        <i class="fa fa-google"></i>
                                    </a>
                                    <a href="javascript:void(0)" class="social-login  text-center me-4">
                                        <i class="fa fa-facebook"></i>
                                    </a>
                                    <a href="javascript:void(0)" class="social-login  text-center">
                                        <i class="fa fa-twitter"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- CONTAINER CLOSED -->
        </div>
    </div>

</template>
<script>
    export default {
        data() {
            return {
                loading: false,
                isSuccess: false,
                message: '',
            }
        },
        methods: {

            GoToSignup: function () {
                this.$router.push('/')
            },

            EmailConfirmed: function (id) {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/account/EmailConfirmed?id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data != null && response.data.isSuccess) {
                            root.isSuccess = response.data.isSuccess;
                            root.message = response.data.isAddUpdate;
                            root.loading = false;
                        }
                        else {
                            root.isSuccess = response.data.isSuccess;
                            root.message = response.data.isAddUpdate;
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            }
        },
        created: function () {
            this.EmailConfirmed(this.$route.query.id);
        },
    }
</script>