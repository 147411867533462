<template>
    <modal :show="show">
        <div class="modal-header">
            <h5 class="modal-title">{{ $t('CreateGroupSession.Reason') }}</h5>
            <a href="javascript:void(0)" v-on:click="close()" class="close">
                <em class="icon ni ni-cross"></em>
            </a>
        </div>
        <div class="modal-body">
            <div class="row">

                <div class="col-md-12">
                    <textarea rows="3" v-model="reason" class="form-control"></textarea>

                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-round" v-on:click="Save()">{{ $t('CreateGroupSession.Save') }}</button>
            <button type="button" class="btn btn-danger btn-round" v-on:click="close()">{{ $t('CreateGroupSession.Cancel') }}</button>
        </div>
        <loading :name="loading" :active.sync="loading"
                 :can-cancel="false"
                 :is-full-page="true"></loading>
    </modal>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    export default {
        components: {
            Loading
        },
        props: ['id', 'show'],
        data: function () {
            return {
                loading: false,
                reason: '',
                role: '',
            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },

            Save: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Sessions/DeleteSession?id=' + this.id + '&reason=' + this.reason, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.id != '00000000-0000-0000-0000-000000000000') {
                            root.loading = false;
                            root.$swal({
                                title: root.$t('Notification.Updated'),
                                text: root.$t('Notification.StatusUpdated'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    if (root.role == 'Student') {
                                        window.location.href = "/StudentDashboard";
                                    }
                                    if (root.role == 'Adviser') {
                                        window.location.href = "/AdvisorDashboard";
                                    }
                                    root.loading = false;
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },
        },
        mounted: function () {
            this.role = localStorage.getItem('RoleName');
        },
    }
</script>