import { render, staticRenderFns } from "./ReviewerRegistered.vue?vue&type=template&id=6578a931&scoped=true&"
import script from "./ReviewerRegistered.vue?vue&type=script&lang=js&"
export * from "./ReviewerRegistered.vue?vue&type=script&lang=js&"
import style0 from "./ReviewerRegistered.vue?vue&type=style&index=0&id=6578a931&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6578a931",
  null
  
)

export default component.exports