<template>
    <div class="main-session-details pb-5" ref="mychild">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="card">
                        <div class="row">
                            <div class="col-12 col-md-12 p-4">
                                <h1 class="mx-3 text-primary">{{ $t("SessionEnded.SessionStatements") }} </h1>
                            </div>

                            <div class="col-12">
                                <table class="table">
                                    <tr>
                                        <th style="width:13%;">{{ $t("SessionEnded.SessionType") }} :</th>
                                        <td style="width:20%;">{{sessionEnded.sessionTypeName}}</td>

                                        <th style="width:13%;">{{ $t("SessionEnded.SessionDuration") }} :</th>
                                        <td style="width:20%;">{{sessionEnded.sessionDurationName}}</td>
                                        <th style="width:14%;"><span v-if="sessionEnded.workshopType==0 || sessionEnded.workshopType==2">{{ $t("CreateGroupSessionDetails.MeetingPlace") }} :</span> </th>
                                        <td style="width:20%;"><span v-if="sessionEnded.workshopType==0 || sessionEnded.workshopType==2">{{sessionEnded.sessionLocationName}}</span></td>
                                    </tr>
                                    <tr>
                                        <th style="width:13%;"> <span v-if="sessionEnded.workshopType==2">{{ $t("SessionEnded.AvailableSeats") }} :</span> </th>
                                        <td style="width:20%;"><span v-if="sessionEnded.workshopType==2">{{sessionEnded.remainingSeats}}</span></td>

                                        <th style="width:13%;"> <span v-if="sessionEnded.workshopType==2">{{ $t("SessionEnded.StudentLimit") }} :</span> </th>
                                        <td style="width:20%;"><span v-if="sessionEnded.workshopType==2">{{sessionEnded.studentLimit}}</span></td>

                                        <th style="width:14%;"><span v-if="role == 'Adviser'">{{ $t("SessionEnded.Code") }} :</span></th>
                                        <td style="width:20%;"><span v-if="role == 'Adviser'">{{sessionEnded.code}}</span></td>
                                    </tr>
                                    <tr>
                                        <th style="width:13%;">{{ $t("SessionEnded.SessionEndTime") }} :</th>
                                        <td style="width:20%;">{{sessionEnded.sessionEndTime}}</td>

                                        <th style="width:13%;"> {{ $t("SessionEnded.SessionStartTime") }} : </th>
                                        <td style="width:20%;">{{sessionEnded.sessionStartTime}}</td>

                                        <th style="width:14%;"> {{ $t("SessionEnded.AdviserUserName") }} : </th>
                                        <td style="width:20%;">{{sessionEnded.adviserUserName}}</td>

                                    </tr>
                                    <tr>
                                        <th style="width:14%;"><span v-if="sessionEnded.subjectOfSession == 2 || sessionEnded.subjectOfSession == 3">{{ $t("SessionEnded.UniversityLevel") }} :</span> </th>
                                        <td style="width:20%;" colspan="5">
                                            <div v-if="sessionEnded.subjectOfSession == 2 || sessionEnded.subjectOfSession == 3">
                                                <span v-for="( universityLevelName) in sessionEnded.universityLevelIdName" :key=" universityLevelName.id">({{universityLevelName}})</span>
                                            </div>

                                        </td>
                                    </tr>
                                </table>
                            </div>

                        </div>
                    </div>

                    <div class="card" v-for="( sessionStudentName) in sessionEnded.sessionStudents" :key=" sessionStudentName.id">
                        <div class="row">
                            <div class="col-12 col-md-12 p-4 fanimate">
                                <h3 class="mx-3 text-primary"> {{ $t("SessionEnded.StudentsStatment") }} </h3>
                            </div>
                            <table class="table border text-nowrap text-md-nowrap">
                                <thead>
                                    <tr>
                                        <th>{{ $t("SessionEnded.Name") }}</th>
                                        <th> {{ $t("SessionEnded.Email") }} </th>
                                        <th> {{ $t("SessionEnded.IdentificationNumber") }} </th>
                                        <th>{{ $t("SessionActive.StudentCategory") }}</th>
                                        <th>{{ $t("SessionActive.Qualification") }}</th>
                                        <th>{{ $t("SessionActive.UniversityLevelName") }}</th>
                                        <th>{{ $t("Regions.Status") }}</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr v-bind:class="{'bg_danger':sessionStudentName.isCancel}">

                                        <td>

                                            <p class="text-muted mb-3 mx-1">{{sessionStudentName.fullName}}</p>
                                        </td>
                                        <td>
                                            <p class="text-muted mb-3 mx-1">{{sessionStudentName.email}}</p>
                                        </td>
                                        <td>
                                            <p class="text-muted mb-3 mx-1">{{sessionStudentName.phoneNumber}}</p>

                                        </td>
                                        <td>
                                            <p v-if="sessionStudentName.categoryName != null" class="text-muted mb-3 mx-1">{{sessionStudentName.categoryName}}</p>
                                            <p v-if="sessionStudentName.categoryName == null" class="text-muted mb-3 mx-1">---</p>
                                        </td>
                                        <td>
                                            <p v-if="sessionStudentName.qualificatioName != null" class="text-muted mb-3 mx-1">{{sessionStudentName.qualificatioName}}</p>
                                            <p v-if="sessionStudentName.qualificatioName == null" class="text-muted mb-3 mx-1">---</p>
                                        </td>
                                        <td>
                                            <p v-if="sessionStudentName.universityLevelName != null" class="text-muted mb-3 mx-1">{{sessionStudentName.universityLevelName}}</p>
                                            <p v-if="sessionStudentName.universityLevelName == null" class="text-muted mb-3 mx-1">---</p>
                                        </td>
                                        <td>
                                            <span v-if="sessionStudentName.isSurvey" class="badge rounded-pill bg-primary-gradient my-1">  {{ $t("SessionEnded.Attend") }}</span>
                                            <span v-else class="badge rounded-pill bg-danger-gradient my-1">   {{ $t("SessionEnded.NotAttend") }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div v-if="sessionStudentName.studentSurvey != null && sessionStudentName.studentSurvey != undefined" class="col-12 col-sm-12">
                                <div class="table-responsive">
                                    <table class="table border text-nowrap text-md-nowrap table-hover">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th> {{ $t("StudentFeedback.Questions") }}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(item, index) in sessionStudentName.studentSurvey.sessionFeedbackQuestion" :key="index">
                                                <td>{{index+1}}.</td>
                                                <td> {{item.question}}</td>
                                                <td class="text-center">
                                                    <a v-if="!sessionStudentName.studentSurvey.isSecondary" href="javascript:void(0)" style="text-decoration: none;">
                                                        <span class="review-color"> <i v-if="item.review>=1" class="fa fa-star str" aria-hidden="true"></i> <i v-if="item.review==0 || item.review<1" class="fa fa-star-o str" aria-hidden="true"></i></span>
                                                        <span class="review-color"><i v-if="item.review>=2" class="fa fa-star str" aria-hidden="true"></i> <i v-if="item.review==0 || item.review<2" class="fa fa-star-o str" aria-hidden="true"></i></span>
                                                        <span class="review-color"><i v-if="item.review>=3" class="fa fa-star str" aria-hidden="true"></i> <i v-if="item.review==0 || item.review<3" class="fa fa-star-o str" aria-hidden="true"></i></span>
                                                        <span class="review-color"><i v-if="item.review>=4" class="fa fa-star str" aria-hidden="true"></i> <i v-if="item.review==0 || item.review<4" class="fa fa-star-o str" aria-hidden="true"></i></span>
                                                        <span class="review-color"><i v-if="item.review>=5" class="fa fa-star str" aria-hidden="true"></i> <i v-if="item.review==0 || item.review<5" class="fa fa-star-o str" aria-hidden="true"></i></span>
                                                    </a>
                                                    <span v-else>{{item.review=='1'? 'نعم' : 'لا'}}</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="col-12 col-sm-12">
                                        <h4>{{ $t("StudentFeedback.Suggestions") }} :</h4>
                                        <p>{{sessionStudentName.studentSurvey.remarks}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-12 col-md-12 fanimate mb-3">
                        <label>
                            {{ $t("SessionEnded.StudentReport") }} :
                        </label>
                        <p>{{sessionEnded.report}}</p>
                    </div>
                    <div class="col-12 col-md-12 fanimate mb-3">
                        <label>
                            {{ $t("SessionEnded.SystemAdminReport") }} :
                        </label>
                        <p>{{sessionEnded.report2}}</p>

                    </div>

                </div>

            </div>
        </div>

    </div>
</template>

<script>



    export default {
        props: ['sessionDetail'],
        name: "SessionStudentDetails",
        data() {
            return {
                sessionEnded: {
                    sessionTypeName: '',
                    statusId: '',
                    sessionLocationName: '',
                    sessionStartTime: '',
                    sessionDurationName: '',
                    sessionEndTime: '',
                    universityName: '',
                    studentLimit: 0,
                    remainingSeats: 0,
                    report: '',
                    report2: '',
                    remoteLink: '',
                    workshopType: '',
                    subjectOfSession: '',
                    universityLevelIdName: [],
                    sessionStudents: [],
                    adviserUserName: '',
                    checkDate: false,
                    role:''
                },
                htmlData: {
                    htmlString: ''
                },
            };
        },


        methods: {

            DowmloadPdf: function () {
               
                this.htmlData.htmlString = this.$refs.mychild.innerHTML;

                var root = this;
                root.$https.post('/Home/DownloadPdf', this.htmlData, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;

                        link.setAttribute('download', 'SessionStudent.pdf');
                        document.body.appendChild(link);
                        link.click();
                        root.$emit("close", true);
                    });
            },

        },
        created: function () {
            this.role = localStorage.getItem('RoleName');

            this.sessionEnded = this.sessionDetail;
            var root = this;
            setTimeout(function () {
                root.DowmloadPdf();
            }, 500)
            
        },
        mounted: function () {

        }
    };
</script>

<style scoped>
    .profile-img-main {
        max-width: 60px;
    }

    .bg_danger {
        background-color: rgb(255 107 107 / 10%);
    }
</style>
