<template>
    <div class="main-session-details pb-5">
        <div class="page-header">
            <div>
                <h1 class="page-title">
                    <font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ $t("SessionEnded.Heading") }}</font></font>
                </h1>
            </div>
        </div>
        <div class="row">
            <div class="card">
                <div class="row">

                    <div class="col-12 col-md-12 p-4 fanimate">
                        <h3 class="mx-3 text-primary">{{ $t("SessionEnded.SessionStatements") }} </h3>
                    </div>

                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1"> {{ $t("SessionEnded.SessionType") }}</h4>
                        {{sessionEnded.sessionTypeName}}
                    </div>
                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1"> {{ $t("SessionEnded.SessionDuration") }}</h4>
                        {{sessionEnded.sessionDurationName}}
                    </div>


                    <div v-if="sessionEnded.workshopType==0 || sessionEnded.workshopType==2" class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">
                            {{ $t("CreateGroupSessionDetails.MeetingPlace") }}
                        </h4>
                        {{sessionEnded.sessionLocationName}}
                    </div>

                    <div v-if="sessionEnded.workshopType==1" class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">
                            {{ $t("SessionEnded.RemoteLink") }}
                        </h4>
                        <a :href="sessionEnded.remoteLink" class="btn btn-light" target="_blank"><i class="fa fa-link" aria-hidden="true"></i></a>

                    </div>

                    <div v-if="sessionEnded.workshopType==2" class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1"> {{ $t("SessionEnded.AvailableSeats") }}</h4>
                        {{sessionEnded.remainingSeats}}
                    </div>

                    <div v-if="sessionEnded.workshopType==2" class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">
                            {{ $t("SessionEnded.StudentLimit") }}
                        </h4>
                        {{sessionEnded.studentLimit}}
                    </div>



                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">{{ $t("SessionEnded.SessionEndTime") }}</h4>
                        {{sessionEnded.sessionEndTime}}
                    </div>
                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">{{ $t("SessionEnded.SessionStartTime") }}</h4>
                        {{sessionEnded.sessionStartTime}}
                    </div>

                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1"> {{ $t("SessionEnded.AdviserUserName") }}</h4>
                        {{sessionEnded.adviserUserName}}
                    </div>

                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1"> {{ $t("SessionEnded.UniversityName") }}</h4>
                        {{sessionEnded.universityName}}
                    </div>

                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1"> {{ $t("SessionEnded.TotalStudentJoin") }}</h4>
                        {{sessionEnded.totalStudentJoin}}
                    </div>

                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">
                            {{ $t("SessionEnded.UniversityLevel") }}
                        </h4>

                        <!--<p class="text-muted mb-3 mx-1" v-for="( universityLevelName) in sessionEnded.universityLevelIdName" :key=" universityLevelName.id">{{universityLevelName}}</p>-->
                        <p class="text-muted mb-3 mx-1">
                            <span v-for="( universityLevelName) in sessionEnded.universityLevelIdName" :key=" universityLevelName.id">({{universityLevelName}})</span>
                        </p>
                    </div>

                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1"> {{ $t("SessionEnded.Code") }}</h4>
                        {{sessionEnded.code}}
                    </div>
                </div>
            </div>

            <div class="card" v-for="( sessionStudentName) in sessionEnded.sessionStudents" :key=" sessionStudentName.id">
                <div class="row">
                    <div class="col-12 col-md-12 p-4 fanimate">
                        <h3 class="mx-3 text-primary"> {{ $t("SessionEnded.StudentsStatment") }} </h3>
                    </div>
                    <table class="table border text-nowrap text-md-nowrap">
                        <thead>
                            <tr>
                                <th> {{ $t("SessionEnded.Picture") }}</th>
                                <th>{{ $t("SessionEnded.Name") }}</th>
                                <th> {{ $t("SessionEnded.Email") }} </th>
                                <th> {{ $t("SessionEnded.IdentificationNumber") }} </th>
                                <th>{{ $t("Regions.Status") }}</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr  v-bind:class="{'bg_danger':sessionStudentName.isCancel}">
                                <td>

                                    <div class="d-flex flex-wrap align-items-center">
                                        <div class="profile-img-main rounded">
                                            <img src="/assets/images/landing/dumy.svg"
                                                 alt="img"
                                                 class="m-0 p-1 rounded hrem-8" />
                                        </div>
                                    </div>

                                </td>
                                <td>

                                    <p class="text-muted mb-3 mx-1">{{sessionStudentName.fullName}}</p>
                                </td>
                                <td>
                                    <p class="text-muted mb-3 mx-1">{{sessionStudentName.email}}</p>
                                </td>
                                <td>
                                    <p class="text-muted mb-3 mx-1">{{sessionStudentName.phoneNumber}}</p>
                                </td>
                                <td>
                                    <span v-if="sessionStudentName.isSurvey" class="btn btn-pill btn-info">  {{ $t("SessionEnded.Attend") }}</span>
                                    <span v-else class="btn btn-pill btn-danger">   {{ $t("SessionEnded.NotAttend") }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="row mt-3 btn_student_group ">
            <!--v-if="sessionActive.subjectOfSession==1 && sessionActive.statusId==2"-->
            <div class="col-12 col-md-12 fanimate mb-3">
                <label>
                      {{ $t("SessionEnded.StudentReport") }}
                </label>
                <textarea rows="3"  v-model="sessionEnded.report" class="form-control" disabled></textarea>
            </div>
            <div class="col-12 col-md-12 fanimate mb-3">
                <label>
                       {{ $t("SessionEnded.SystemAdminReport") }}
                </label>
                <textarea rows="3"  v-model="sessionEnded.report2" class="form-control" disabled></textarea>

            </div>
            <div class="col-md-12">
                <a v-on:click="GoTo()" href="javascript:void(0)" class="btn btn-light">{{ $t("SessionEnded.Cancel") }}</a>

            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SessionView",
        data() {
            return {
                loading: false,
               
                sessionEnded: {
                    report: '',
                    report2:''
                },
            };
        },

        methods: {
            GoTo: function () {
                this.$router.push("/ReviewerDashboard");
            },

            Details: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Sessions/GetSessionActivateDetails?Id=' + id + '&isSessionDetailsNeed=' + true, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$router.push({
                            path: '/SessionEnded',
                            query: { data: response.data }
                        })
                    }
                });
            },

        },
        created: function () {
            
            if (this.$route.query.id != undefined) {
                this.Details(this.$route.query.id);
            }
        },
        mounted: function () {

        }
    };
</script>

<style scoped>
    .profile-img-main {
        max-width: 60px;
    }
    .bg_danger {
        background-color: rgb(255 107 107 / 10%);
    }
</style>
