<template>
        <div class="bx mb-5 py-5">
            <h1 class="my-3 text-white">مقياس الميول</h1>
            <p class="my-5 py-5 text-bold text-white">أكتشف ميولك المهنية وتعرف على شخصيتك من خلال مقياس الميول المهنية</p>
            <a href="https://subol.sa/Account/login?returnUrl=%2FDashboard%2FTendencyScale" class="btn btn-primary btn-pill slide-item py-2 mb-5">الرجاء تسجيل الدخول أولا</a>
        </div>
</template>
<script>
    export default {
        name: "TendencyScale",
        data: function () {
            return {
              
            }
        },
        methods: {
          
        },
        mounted: function () {
          
        },
    };
 </script>
<style scoped>
    .bx {
        margin-top: 5rem;
        padding-right:5rem;
        max-width:400px;
    }
    .btn-primary:hover {
        color: white !important;
        
    }
    .btn-primary {
        justify-content: center;
    }
    @media screen and (max-width: 768px) {
        .bx {
            margin-top: 1rem;
            padding-right: 1rem;
        }
    }
</style>