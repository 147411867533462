<template>
    <div class="main-sec mt-3">
        <div class=" col-md-6 card">
            <div class="modal-header">
                <h5 class="modal-title">{{ $t('SyncSetting.SyncSetting') }}</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="form-group col-sm-6">
                        <label>{{ $t('SyncSetting.AllowSynchronization') }}</label> <br />
                        <div class="material-switch">
                            <input id="someSwitchOptionPrimary" name="someSwitchOption001" v-model="syncSetting.isSync" type="checkbox" />
                            <label for="someSwitchOptionPrimary" class="label-primary"></label>
                        </div>
                    </div>
                    <div class="form-group col-sm-6" v-if="syncSetting.isSync">
                        <label>{{ $t('SyncSetting.DataPush') }}</label> <br />
                        <button type="button" class="btn btn-primary btn-round" v-on:click="ManualPush()">{{ $t('SyncSetting.DataPush') }}</button>
                    </div>

                    <div class="form-group col-sm-12" v-if="syncSetting.isSync">
                        <label>{{ $t('SyncSetting.SyncType') }}</label> <br />
                        <multiselect :options="['تلقائيا', 'جدول']" :clear-on-select="false" v-model="syncSetting.syncType" :show-labels="false" placeholder="اختر صنف">
                        </multiselect>
                    </div>
                    <div class="form-group col-sm-12" v-if="syncSetting.isSync && syncSetting.syncType=='جدول'">
                        <label>{{ $t('SyncSetting.FromTime') }}</label> <br />
                        <date-picker v-model="syncSetting.fromTime"
                                     format="hh:mm a"
                                     value-type="format"
                                     type="time"
                                     placeholder="hh:mm a"></date-picker>
                    </div>
                    <div class="form-group col-sm-12" v-if="syncSetting.isSync && syncSetting.syncType=='جدول'">
                        <label>{{ $t('SyncSetting.ToTime') }}</label> <br />
                        <date-picker v-model="syncSetting.toTime"
                                     format="hh:mm a"
                                     value-type="format"
                                     type="time"
                                     placeholder="hh:mm a"></date-picker>
                    </div>
                    <!--<div class="form-group col-md-12">
                        <label>{{ $t('SyncSetting.IpAddress') }}</label>
                        <input v-model="syncSetting.ipAddress" @focus="$event.target.select()" class="form-control p-3" />
                    </div>-->

                </div>
            </div>

            <div class="modal-footer ">

                <button type="button" class="btn btn-primary btn-round" v-bind:disabled="$v.$invalid" v-on:click="SaveCity()">{{ $t('AddCity.Save') }}</button>
                <button type="button" class="btn btn-danger btn-round" v-on:click="close" href="javascript:void(0)">{{ $t('AddCity.Cancel') }}</button>
            </div>


            <loading :name="loading" :active.sync="loading"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
        </div>
    </div>
</template>
<script>
    import { requiredIf } from "vuelidate/lib/validators";
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    import axios from 'axios'
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import moment from 'moment'
    import Multiselect from 'vue-multiselect';
    export default {
        name: 'syncSetting',
        components: {
            Loading,
            DatePicker,
            Multiselect
        },
        data: function () {
            return {
                syncSetting: {
                    id: '00000000-0000-0000-0000-000000000000',
                    fromTime: '',
                    toTime: '',
                    ipAddress: '',
                    syncType: '',
                    hour: '',
                    isSync: false,
                },

                loading: false,
            }
        },
        validations: {
            syncSetting: {
                syncType: {
                    required: requiredIf(function () {
                        return this.syncSetting.isSync;
                    }),
                },
                //hour: {
                //    required: requiredIf(function () {
                //        if (this.syncSetting.isSync && this.syncSetting.syncType == 'جدول') {
                //            return true
                //        }
                //        else {
                //            return false
                //        }
                //    }),
                //},
            }
        },
        methods: {
            close: function () {
                this.$router.push('/Dashboard');
            },

            SaveCity: function () {
                var root = this;
                this.loading = true;

                axios.post(this.$ApiServerIp + '/v1/Setup/SaveSyncSetting', this.syncSetting)
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.loading = false;
                            root.$swal({
                                title: root.$t('Notification.Added'),
                                text: root.$t('Notification.DataSucess'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            });
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            EditInfo: function () {
                var root = this;
                
                axios.get(this.$ApiServerIp + '/v1/Setup/GetSyncSettingDetail').then(function (response) {
                    if (response.data != null) {
                        root.syncSetting.fromTime = (response.data.fromTime == null || response.data.fromTime == '') ? '' : moment(response.data.fromTime).format('hh:mm a');
                        root.syncSetting.toTime = (response.data.toTime == null || response.data.toTime == '') ? '' : moment(response.data.toTime).format('hh:mm a');
                        root.syncSetting.hour = response.data.hour;
                        root.syncSetting.ipAddress = response.data.ipAddress;
                        root.syncSetting.isSync = response.data.isSync;
                        root.syncSetting.syncType = response.data.syncType;
                    }
                });
            },

            ManualPush: function () {
                
                var root = this;
                axios.get(this.$ApiServerIp + '/v1/Report/ManualPush');

                root.$swal({
                    title: root.$t('Notification.Added'),
                    text: root.$t('Notification.DataSucess'),
                    type: 'success',
                    icon: 'success',
                    confirmButtonClass: "btn btn-success",
                    buttonsStyling: false
                });
            },
        },
        created: function () {
            this.EditInfo();
        },
        mounted: function () {
        },
    }
</script>
