<template>
    <div class="main-session-details pb-5">
        <div class="page-header">
            <div>
                <h1 class="page-title">
                    <font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{ $t("SessionEnded.Heading") }}</font></font>
                </h1>
            </div>
            <div class="ms-auto pageheader-btn">
                <a v-on:click="isDownload=true" href="javascript:void(0)" class="btn btn-primary">مطبع</a>
            </div>
        </div>
        <div class="row">
            <div class="card">
                <div class="row">

                    <div class="col-12 col-md-12 p-4 fanimate">
                        <h3 class="mx-3 text-primary">{{ $t("SessionEnded.SessionStatements") }} </h3>
                    </div>

                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1"> {{ $t("SessionEnded.SessionType") }}</h4>
                        {{sessionEnded.sessionTypeName}}
                    </div>
                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1"> {{ $t("SessionEnded.SessionDuration") }}</h4>
                        {{sessionEnded.sessionDurationName}}
                    </div>


                    <div v-if="sessionEnded.workshopType==0 || sessionEnded.workshopType==2" class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">
                            {{ $t("CreateGroupSessionDetails.MeetingPlace") }}
                        </h4>
                        {{sessionEnded.sessionLocationName}}
                    </div>

                    <div v-if="sessionEnded.workshopType==1" class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">
                            {{ $t("SessionEnded.RemoteLink") }}
                        </h4>
                        <a :href="sessionEnded.remoteLink" class="btn btn-light" target="_blank"><i class="fa fa-link" aria-hidden="true"></i></a>

                    </div>
                    <div v-if="sessionEnded.workshopType==2" class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1"> {{ $t("SessionEnded.AvailableSeats") }}</h4>
                        {{sessionEnded.remainingSeats}}
                    </div>
                    <div v-if="sessionEnded.workshopType==2" class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">
                            {{ $t("SessionEnded.StudentLimit") }}
                        </h4>
                        {{sessionEnded.studentLimit}}
                    </div>



                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">{{ $t("SessionEnded.SessionEndTime") }}</h4>
                        {{sessionEnded.sessionEndTime}}
                    </div>
                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">{{ $t("SessionEnded.SessionStartTime") }}</h4>
                        {{sessionEnded.sessionStartTime}}
                    </div>

                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1"> {{ $t("SessionEnded.AdviserUserName") }}</h4>
                        {{sessionEnded.adviserUserName}}
                    </div>
                    
                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1"> {{ $t("SessionEnded.UniversityName") }}</h4>                        
                        <span v-for="(item, index) in sessionEnded.universityName" :key="index">
                            {{index>0? ',' : ''}} ({{item.name}})
                        </span>
                    </div>
                    
                    <div class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1"> {{ $t("SessionEnded.TotalStudentJoin") }}</h4>
                        {{sessionEnded.totalStudentJoin}}
                    </div>

                    <div v-if="sessionEnded.subjectOfSession == 2 || sessionEnded.subjectOfSession == 3" class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1">
                                {{ $t("SessionEnded.UniversityLevel") }}
                        </h4>

                        <!--<p class="text-muted mb-3 mx-1" v-for="( universityLevelName) in sessionEnded.universityLevelIdName" :key=" universityLevelName.id">{{universityLevelName}}</p>-->
                        <p class="text-muted">
                            <span v-for="( universityLevelName) in sessionEnded.universityLevelIdName" :key=" universityLevelName.id">({{universityLevelName}})</span>
                        </p>
                    </div>

                    <!--<div v-if="sessionEnded.subjectOfSession == 2 || sessionEnded.subjectOfSession == 3" class="col-lg-4 p-4 fanimate">
                        <h4 class="mx-1"> {{ $t("SessionEnded.Code") }}</h4>
                        {{sessionEnded.code}}
                    </div>-->
                </div>
            </div>

            <div class="card" v-for="( sessionStudentName) in sessionEnded.sessionStudents" :key=" sessionStudentName.id">
                <div class="row">
                    <div class="col-12 col-md-12 p-4 fanimate">
                        <h3 class="mx-3 text-primary"> {{ $t("SessionEnded.StudentsStatment") }} </h3>
                    </div>
                    <table class="table border text-nowrap text-md-nowrap">
                        <thead>
                            <tr>
                                <th> {{ $t("SessionEnded.Picture") }}</th>
                                <th>{{ $t("SessionEnded.Name") }}</th>
                                <th> {{ $t("SessionEnded.Email") }} </th>
                                <th> {{ $t("SessionEnded.IdentificationNumber") }} </th>
                                <th>{{ $t("SessionActive.StudentCategory") }}</th>
                                <th>{{ $t("SessionActive.Qualification") }}</th>
                                <th>{{ $t("SessionActive.UniversityLevelName") }}</th>
                                <th>{{ $t("SessionEnded.UniversityName") }}</th>
                                <th>{{ $t("Regions.Status") }}</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr v-bind:class="{'bg_danger':sessionStudentName.isCancel}">
                                <td>

                                    <div class="d-flex flex-wrap align-items-center">
                                        <div class="profile-img-main rounded">
                                            <img src="/assets/images/landing/dumy.svg"
                                                 alt="img"
                                                 class="m-0 p-1 rounded hrem-8" />
                                        </div>
                                    </div>

                                </td>
                                <td>

                                    <p class="text-muted mb-3 mx-1">{{sessionStudentName.fullName}}</p>
                                </td>
                                <td>
                                    <p class="text-muted mb-3 mx-1">{{sessionStudentName.email}}</p>
                                </td>
                                <td>
                                    <p class="text-muted mb-3 mx-1">{{sessionStudentName.phoneNumber}}</p>

                                </td>
                                <td>
                                    <p v-if="sessionStudentName.categoryName != null" class="text-muted mb-3 mx-1">{{sessionStudentName.categoryName}}</p>
                                    <p v-if="sessionStudentName.categoryName == null" class="text-muted mb-3 mx-1">---</p>
                                </td>
                                <td>
                                    <p v-if="sessionStudentName.qualificatioName != null" class="text-muted mb-3 mx-1">{{sessionStudentName.qualificatioName}}</p>
                                    <p v-if="sessionStudentName.qualificatioName == null" class="text-muted mb-3 mx-1">---</p>
                                </td>
                                <td>
                                    <p v-if="sessionStudentName.universityLevelName != null" class="text-muted mb-3 mx-1">{{sessionStudentName.universityLevelName}}</p>
                                    <p v-if="sessionStudentName.universityLevelName == null" class="text-muted mb-3 mx-1">---</p>
                                </td>
                                <td>
                                    <p v-if="sessionStudentName.universityName != null" class="text-muted mb-3 mx-1">{{sessionStudentName.universityName}}</p>
                                    <p v-if="sessionStudentName.universityName == null" class="text-muted mb-3 mx-1">---</p>
                                </td>
                                <td>
                                    <span v-if="sessionStudentName.isSurvey" class="badge rounded-pill bg-primary-gradient my-1">  {{ $t("SessionEnded.Attend") }}</span>
                                    <span v-else class="badge rounded-pill bg-danger-gradient my-1">   {{ $t("SessionEnded.NotAttend") }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="sessionStudentName.studentSurvey != null && sessionStudentName.studentSurvey != undefined" class="col-12 col-sm-12">
                        <div class="table-responsive">
                            <table class="table border text-nowrap text-md-nowrap table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th> {{ $t("StudentFeedback.Questions") }}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in sessionStudentName.studentSurvey.sessionFeedbackQuestion" :key="index">
                                        <td>{{index+1}}.</td>
                                        <td> {{item.question}}</td>
                                        <td class="text-center">
                                            <a v-if="!sessionStudentName.studentSurvey.isSecondary" href="javascript:void(0)" style="text-decoration: none;">
                                                <span class="review-color"> <i v-if="item.review>=1" class="fa fa-star str" aria-hidden="true"></i> <i v-if="item.review==0 || item.review<1" class="fa fa-star-o str" aria-hidden="true"></i></span>
                                                <span class="review-color"><i v-if="item.review>=2" class="fa fa-star str" aria-hidden="true"></i> <i v-if="item.review==0 || item.review<2" class="fa fa-star-o str" aria-hidden="true"></i></span>
                                                <span class="review-color"><i v-if="item.review>=3" class="fa fa-star str" aria-hidden="true"></i> <i v-if="item.review==0 || item.review<3" class="fa fa-star-o str" aria-hidden="true"></i></span>
                                                <span class="review-color"><i v-if="item.review>=4" class="fa fa-star str" aria-hidden="true"></i> <i v-if="item.review==0 || item.review<4" class="fa fa-star-o str" aria-hidden="true"></i></span>
                                                <span class="review-color"><i v-if="item.review>=5" class="fa fa-star str" aria-hidden="true"></i> <i v-if="item.review==0 || item.review<5" class="fa fa-star-o str" aria-hidden="true"></i></span>
                                            </a>
                                            <span v-else>{{item.review=='1'? 'نعم' : 'لا'}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="col-12 col-sm-12">
                                <h4>{{ $t("StudentFeedback.Suggestions") }}</h4>
                                <textarea class="form-control mb-4" rows="3" v-model="sessionStudentName.studentSurvey.remarks" disabled></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3 btn_student_group ">
            <!--v-if="sessionActive.subjectOfSession==1 && sessionActive.statusId==2"-->
            <div class="col-12 col-md-12 fanimate mb-3">
                <label>
                    {{ $t("SessionEnded.StudentReport") }}
                </label>
                <textarea rows="3" v-model="sessionEnded.report" class="form-control"></textarea>
            </div>
            <div class="col-12 col-md-12 fanimate mb-3">
                <label>
                    {{ $t("SessionEnded.SystemAdminReport") }}
                </label>
                <textarea rows="3" v-model="sessionEnded.report2" class="form-control"></textarea>

            </div>
            <div class="d-flex">
                <div v-if="role != 'Reviewer'">
                    <button v-if="sessionEnded.checkDate " v-on:click="Save(sessionEnded.id )" class="btn btn-primary register me-2">
                        {{ $t("SessionEnded.Save") }}
                    </button>
                </div>
                <div >
                    <a v-on:click="GoTo()" href="javascript:void(0)" class="btn btn-light">{{ $t("SessionEnded.Cancel") }}</a>
                </div>
            </div>
            
            <loading :name="loading" :active.sync="loading"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
        </div>
        <session-student-detailPdf :sessionDetail="sessionEnded" v-if="isDownload" @close="isDownload=false" />
    </div>
</template>

<script>
    
    import { required } from "vuelidate/lib/validators";
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    export default {
        name: "SessionStudentDetails",
        components: {
            Loading
        },
        data() {
            return {
                loading: false,
                isDownload: false,
               
                sessionEnded: {
                    report: '',
                    report2:''
                },
                htmlData: {
                    htmlString: ''
                },
                path: '',
                role: ''
            };
        },
        validations: {
            sessionEnded: {
                report: {
                    required: required
                },
                report2: {
                    required: required
                }
            }
        },


        methods: {

            

            GoTo: function () {
                this.$router.push(this.path);
            },

            Save: function (id) {
                var root = this;
                this.loading = true;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.post('/Sessions/AdviserReport?id=' + id, this.sessionEnded, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {

                        if (response.data) {
                            root.$swal({
                                title: root.$t('Notification.Created'),
                                text: root.$t('Notification.SessionCreated'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            });
                            root.loading = false;
                            root.GoTo();
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            

        },
        created: function () {
            if (this.$route.query.screenpath != undefined) {
                this.path = this.$route.query.screenpath;
            }
            else{
                this.path = '/Dashboard';
            }
           

            if (this.$route.query.data != undefined) {
                this.sessionEnded = this.$route.query.data;
                this.report = this.$route.query.data.report;
                this.report2 = this.$route.query.data.report2;
            }
            this.role = localStorage.getItem('RoleName');
        },
        mounted: function () {
            
        }
    };
</script>

<style scoped>
    .profile-img-main {
        max-width: 60px;
    }
    .bg_danger {
        background-color: rgb(255 107 107 / 10%);
    }
</style>
