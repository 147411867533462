<template>
    <div class="page-main">
        <div class="card  p-5 mb-5 mt-3">
            <div class="backtomain mb-3">
                <div class="logo mr-3"></div>
                <br />
            </div>
            <div class="signup_form_title mb-5">
                <h1>
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">
                            {{ $t("CreateGroupSessionDetails.Heading") }}
                        </font>
                    </font>
                </h1>
            </div>

            <div class="row">
                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.SessionType") }}
                    </h4>
                    <p v-if="!createGroupSessionDetails.hideSessionType" class="text-muted mb-3 mx-1">{{createGroupSessionDetails.sessionTypeName}}</p>
                    <p v-if="createGroupSessionDetails.hideSessionType" class="text-muted mb-3 mx-1">---</p>
                </div>

                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.SessionStartDate") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">{{createGroupSessionDetails.sessionStartTime}}</p>
                </div>

                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.SessionDuration") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">{{createGroupSessionDetails.sessionDurationName}}</p>
                </div>

                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.AvailableAppointments") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">{{createGroupSessionDetails.sessionEndTime}}</p>
                </div>

                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.AffiliateUniversities") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">
                        <span v-for="(item, index) in createGroupSessionDetails.universityName" :key="index">
                            {{index>0? ',' : ''}} ({{item.name}})
                        </span>
                    </p>
                </div>

                <div v-if="createGroupSessionDetails.workshopType==0 || createGroupSessionDetails.workshopType==2" class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.MeetingPlace") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">{{createGroupSessionDetails.sessionLocationName}}</p>
                </div>


                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        المستوى الجامعي للطلاب
                    </h4>
                    <p class="text-muted mb-3 mx-1"><span v-for="item in createGroupSessionDetails.universityLevelIdName" :key="item">{{item}}</span></p>
                </div>

            </div>
            <div class="modal-footer ">
                <button v-on:click="JoinNow()" class="btn btn-primary" v-bind:class="loading? 'btn-loading' : ''">{{ $t("CreateWorkshop.Join") }}</button>
                <button type="button" class="btn btn-danger btn-round" v-on:click="GoTo()" href="javascript:void(0)">{{ $t("CreateWorkshop.Cancel") }}</button>
            </div>

        </div>
    </div>
</template>
<script>
    import { required, maxLength, requiredIf, minLength } from 'vuelidate/lib/validators';
    export default {

        data() {
            return {
                universityLevelRender: 0,
                allowQuickRegister: false,
                loading: false,
                isEligible: false,
                studenNotFound: '',
                createGroupSessionDetails: '',
                nationalId: '',
                otp: '',

                studentRegister: {
                    id: '00000000-0000-0000-0000-000000000000',
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    roleName: 'Student',
                    registrationCode: '',
                    nationalId: '',
                    phoneNumber: '',

                    dateOfBirth: '',
                    gender: '',
                    email: '',
                    cityId: '',
                    universityId: '',
                    studentCategoryId: '',
                    qualificationId: '',
                    regionId: '',
                    specializationId: '',
                    universityLevelId: '',
                    password: 'suboluniv',
                    confirmPassword: 'suboluniv',
                    isActive: true,
                    quickUser: true,
                },

            };
        },
        validations: {
            studentRegister:
            {
                firstName:
                {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    arabic(name) {
                        return /^[\u0621-\u064A\s]+$/.test(name)
                    }
                },
                middleName:
                {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    arabic(name) {
                        return /^[\u0621-\u064A\s]+$/.test(name)
                    }
                },
                lastName:
                {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    arabic(name) {
                        return /^[\u0621-\u064A\s]+$/.test(name)
                    }
                },
                studentCategoryId:
                {
                    required
                },
                gender:
                {
                    required
                },
                universityId:
                {
                    required
                },
                nationalId:
                {
                    required,
                    maxLength: maxLength(10),
                    minLength: minLength(10),
                    number(id) {
                        return /^[0-9]*$/.test(id)
                    }
                },
                phoneNumber:
                {
                    maxLength: maxLength(15),
                    minLength: minLength(10),
                    phonenumber(number) {
                        return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(number)
                    }
                },
                universityLevelId: {
                    required: requiredIf((x) => {
                        if (x.studentCategoryId == 'e1c0e4db-a923-406b-98cd-0139694362c6' || x.studentCategoryId == 'e3c0e4db-a923-406b-98cd-0139694362c6' || x.studentCategoryId == 'b12b3957-2c34-48e9-86c2-2958f230dd6f')
                            return true;
                        return false;
                    }),
                },
                specializationId:
                {
                    required: requiredIf((x) => {
                        if (x.studentCategoryId == 'e1c0e4db-a923-406b-98cd-0139694362c6')
                            return true;
                        return false;
                    }),
                },

            }
        },

        methods: {
            GoTo: function () {
                this.$router.push("/Login");
            },

            UniversityLevelRender: function () {
                
                this.universityLevelRender++;
            },

            JoinNow: function () {
                var root = this;
                this.loading = true;


                root.$https.get('/account/JoinStudentSession?sessionId=' + this.createGroupSessionDetails.id + '&studentId=' + this.createGroupSessionDetails.studentId + '&otp=' + this.otp + '&fromEmail=true')
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.$swal({
                                title: root.$t('Notification.Joined'),
                                text: root.$t('Notification.SessionJoined'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            });
                            root.loading = false;
                            root.GoTo();
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            SendEmailVerification: function (id) {
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.get('/account/SendMobileOtpVerification?id=' + id + '&sessionId=' + this.createGroupSessionDetails.id, { headers: { "Authorization": `Bearer ${token}` } });
            },

            SaveNewUser: function () {
                var root = this;
                this.loading = true;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                root.$https.post('/account/StudentRegister', this.studentRegister, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {

                            if (root.studentRegister.id == '00000000-0000-0000-0000-000000000000') {
                                root.SendEmailVerification(response.data.id);
                            }
                            root.studenNotFound = '';
                            root.$swal({
                                title: root.$t('Notification.Created'),
                                text: "تم إرسال رمز التحقق الى الهاتف",
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result.isConfirmed) {
                                    root.checkDetails(root.nationalId);
                                }
                            });
                            root.loading = false;
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-info",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },


            ViewInfo: function (id) {
                var root = this;

                root.$https.get('/account/GetJoinStudentSessionDetail?id=' + id).then(function (response) {
                    if (response.data != null) {
                        root.createGroupSessionDetails = response.data;
                        root.studentRegister.universityId = response.data.universityId;
                    }
                    else {
                        root.$swal({
                            title: root.$t('Notification.Error'),
                            text: 'Session Not Found!',
                            type: 'error',
                            icon: 'error',
                            confirmButtonClass: "btn btn-info",
                            buttonsStyling: false
                        });
                        root.loading = false;
                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            type: 'error',
                            title: root.$t('Notification.Error'),
                            text: root.$t('Notification.SomethingWrong'),
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                    root.loading = false;
                });
            },

            SendResendEmailVerification: function (email) {
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/account/ResendSendEmailVerification?email=' + email, { headers: { "Authorization": `Bearer ${token}` } });
                this.nationalId = '';
            },

            checkDetails: function (nationalId) {
                var root = this;
                this.loading = true;
                
                if (nationalId != '' && nationalId != null && nationalId != undefined && nationalId.length == 10) {
                    root.$https.get('/account/VerifyStudentDetailForSession?id=' + this.createGroupSessionDetails.id + '&nationalid=' + nationalId).then(function (response) {
                        if (response.data != null && response.data.isSuccess) {
                            root.loading = false;
                            root.allowQuickRegister = response.data.allowQuickRegister;
                            root.isEligible = response.data.isSuccess;
                            root.createGroupSessionDetails.studentId = response.data.id;
                            root.studenNotFound = response.data.isAddUpdate;
                            root.$swal({
                                title: 'تم ارسال رمز التحقق',
                                text: 'تم إرسال رمز التحقق الى الهاتف!',
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            });
                        }
                        else {
                            
                            var message = '';
                            var title = '';
                            var icon = '';
                            var type = '';

                            if (response.data.isAddUpdate == "Session Close") {
                                title = 'تم إغلاق التسجيل في ورشة العمل';
                                message = 'تم إغلاق التسجيل في ورشة العمل';
                                type = 'error';
                                icon = 'error';
                            }
                            else if (response.data.isAddUpdate == "Your Email and Phone Number is not verified !") {
                                title = 'لم يتم التحقق من البريد الإلكتروني ورقم الهاتف';
                                message = 'لم يتم التحقق من بريدك الإلكتروني ورقم هاتفك';
                                type = 'error';
                                icon = 'error';
                            }
                            else if (response.data.isAddUpdate == "You have not same gender!") {
                                title = 'ليس لديك نفس الجنس';
                                message = 'ليس لديك نفس الجنس';
                                type = 'error';
                                icon = 'error';
                            }
                            else if (response.data.isAddUpdate == "You have not same University!") {
                                title = 'عذرا، انت مسجل في جامعة اخرى';
                                message = 'عذرا، انت مسجل في جامعة اخرى';
                                type = 'error';
                                icon = 'error';
                            }
                            else if (response.data.isAddUpdate == "Student not found!") {
                                title = 'الهوية غير مسجلة نأمل استكمال التسجيل';
                                message = 'الهوية غير مسجلة نأمل استكمال التسجيل';
                                type = 'warning';
                                icon = 'warning';
                            }
                            else {
                                title = 'ليس لديك نفس المستوى الجامعي!';
                                message = 'ليس لديك نفس المستوى الجامعي!';
                                type = 'error';
                                icon = 'error';
                            }

                            //if (response.data.isAddUpdate == 'Your Email and Phone Number is not verified !') {
                            //    root.$swal({
                            //        title: "Oops...",
                            //        text: response.data.isAddUpdate,
                            //        type: "error",
                            //        icon: "error",
                            //        showCancelButton: true,
                            //        confirmButtonColor: "#DD6B55",
                            //        confirmButtonText: "Resend SMS?",
                            //        closeOnConfirm: false,
                            //        closeOnCancel: false
                            //    }).then(function (result) {
                            //        if (result.isConfirmed) {
                            //            root.SendResendEmailVerification(nationalId);
                            //        }
                            //    });
                            //    root.loading = false;
                            //    root.isEligible = response.data.isSuccess;
                            //    root.studenNotFound = response.data.isAddUpdate;
                            //}
                            //else {
                            root.$swal({
                                title: title,
                                text: message,
                                type: type,
                                icon: icon,
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                            root.isEligible = response.data.isSuccess;
                            root.studenNotFound = response.data.isAddUpdate;
                            root.studentRegister.nationalId = nationalId;
                            //}
                        }
                    });
                }
                else {
                    root.isEligible = false;
                }
            },
        },

        created: function () {

            if (this.$route.query.id != undefined) {
                this.ViewInfo(this.$route.query.id);
            }
        },
    };
</script>

<style scoped>
    .btn-primary {
        margin-left: 10px;
    }

    .media {
        flex-direction: column !important;
    }

    @media only screen and (max-width: 600px) {
        .register {
            max-width: 40%;
        }

        .login {
            max-width: 40%;
        }

        .btn_student_group {
            justify-content: center;
        }
    }
</style>
