<template>
    <div>
        <!--<multiselect v-model="DisplayValue" :options="options" :multiple="false" track-by="name" :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true" v-bind:placeholder=" $t('Dropdown.SelectOption')">
            <p slot="noResult" class="text-danger"> {{ $t('Dropdown.UniversitySpecilization') }}</p>
            <p slot="noOptions" class="text-danger"></p>
        </multiselect>-->
        <multiselect v-model="DisplayValue" v-bind:tag-placeholder=" $t('Dropdown.AddNew')" :preselect-first="true" v-bind:placeholder=" $t('Dropdown.SelectOption')" :clear-on-select="false" :show-labels="false" label="name" track-by="name" :options="options" :multiple="false" :taggable="true" @tag="AddNewSpecialization">
            <p slot="noResult" class="text-danger">  {{ $t('Dropdown.UniversitySpecilization') }} </p>
            <p slot="noOptions" class="text-danger"></p>
        </multiselect>
    </div>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
  name: "universtyspecilization",
  props: ["values"],

        components: {
            Multiselect,
            
        },
        data: function () {
            return {
                options: [],
                value: '',
                render: 0
            }
        },
        methods: {
            AddNewSpecialization: function (specialization) {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var newSpecialization = {
                    id: '00000000-0000-0000-0000-000000000000',
                    name: specialization,
                    isActive: true,
                    isFromSignUp: true,
                }
                root.$https.post('/Setup/SaveSpecialization', newSpecialization, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.loading = false;
                            root.options.push({
                                id: response.data.id,
                                name: specialization
                            })
                            root.value = {
                                id: response.data.id,
                                name: specialization
                            };
                            root.$emit('input', root.value == null ? null : root.value.id);
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            getData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                 
                this.$https.get('/Setup/SpecializationList?isDropDown=true', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    
                     
                    if (response.data != null) {
                        response.data.results.forEach(function (cat) {
                             
                            root.options.push({
                                id: cat.id,
                                name: cat.name
                            })
                       })
                    }
                }).then(function () {
                    root.value = root.options.find(function (x) {
                        return x.id == root.values;
                    })
                });
            },
        },
        computed: {
            DisplayValue: {
                get: function () {
                    if (this.value != "" || this.value != undefined) {
                        return this.value;
                    }
                    return this.values;
                },
                set: function (value) {
                    this.value = value;
                    this.$emit('input', value == null ? null : value.id);
                }
            }
        },
        mounted: function () {
            this.getData();
        },
    }
</script>