<template>
    <div class="page">
        <div class="page-main">
            <!-- app-Header -->
            <div class="hor-header header">
                <div class="container main-container">
                    <div class="d-flex pt-1">
                        <img style="height:54px;" src="/assets/images/brand/main-logo.png" v-on:click="GoTo('/')" />
                        <ul id="firstUl" class="navbar-nav mr-auto">
                            <li class="nav-item ">
                                <a class="text-dark m3-3 vll text-decoration-none" href="#">نبذة عن سبل</a>
                            </li>
                            <li class="nav-item ">
                                <a class="text-dark m3-3 vll text-decoration-none" href="#">إحصائيات</a>
                            </li>
                            <li class="nav-item ">
                                <a class="text-dark m3-3 vll text-decoration-none" href="#">الأسئلة الشائعة</a>
                            </li>
                            <li class="nav-item ">
                                <a class="text-dark m3-3 vll text-decoration-none" href="#">اتصل بنا</a>
                            </li>
                        </ul>
                        <!-- LOGO -->

                        <div class="d-flex order-lg-2 ms-auto header-right-icons mt-3" id="hide">
                            <button class="navbar-toggler navresponsive-toggler d-md-none ms-auto"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent-4"
                                    aria-controls="navbarSupportedContent-4"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon fe fe-more-vertical" style="color:#fff!important"></span>
                            </button>

                            <div class="navbar navbar-collapse responsive-navbar p-0">
                                <div class="collapse navbar-collapse"
                                     id="navbarSupportedContent-4">
                                    <div class="d-flex order-lg-2 m-4 m-lg-0 m-md-1">
                                        <a v-on:click="GoTo('/Login')" href="javascript:void(0)" class="btn btn-pill btn-primary btn-w-md py-2 ct">
                                            {{ $t('LandingPage.Login') }}
                                        </a>
                                        <a v-on:click="GoTo('/Register')" href="javascript:void(0)" class="btn btn-pill btn-primary btn-w-md py-2 ms-1 ct">
                                            {{ $t('LandingPage.Signup') }}
                                        </a>

                                        <a v-on:click="GoTo('/ContactUs')" class=" btn btn-pill btn-primary btn-w-md py-2 ms-1 ct">{{ $t('LandingPage.ContactUs') }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="p-0" id="forHide">
                        <div>
                            <div class="d-flex order-lg-2 my-2 m-lg-0 m-md-1">
                                <a v-on:click="GoTo('/Login')" href="javascript:void(0)" class="btn btn-pill btn-primary btn-w-md py-2 ct">
                                    {{ $t('LandingPage.Login') }}
                                </a>
                                <a v-on:click="GoTo('/Register')" href="javascript:void(0)" class="btn btn-pill btn-primary btn-w-md py-2 ms-1 ct">
                                    {{ $t('LandingPage.Signup') }}
                                </a>

                                <a v-on:click="GoTo('/ContactUs')" class=" btn btn-pill btn-primary btn-w-md py-2 ms-1 ct">{{ $t('LandingPage.ContactUs') }}</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom_nav_bar green_bg_nav">
                    <div class="row">
                        <div class="col-sm-8 col-lg-10 col-8  nav_green">

                            <ul class="navbar-nav mr-auto lg-nav">

                                <li class="nav-item d-flex ">
                                    <div class="nav-link">
                                        <a class="text_wh" @click="GoTo('/TendencyScale')" href="javascript:void(0)">
                                            <img class="nav_img" src="/assets/images/landing/icon26.png" alt="مقياس الميول">
                                            مقياس الميول
                                        </a>
                                    </div>
                                </li>

                                <li class="nav-item d-flex ">
                                    <div class="nav-link">
                                        <a class="text_wh" href="https://subol.sa/Dashboard/FutureOccupations/Index?isInJourney=false)">
                                            <img class="nav_img" src="/assets/images/landing/icon25.png" alt="مهن المستقبل">
                                            مهن المستقبل
                                        </a>

                                    </div>
                                </li>
                                <li class="nav-item d-flex dropdown">
                                    <div class="nav-link">
                                        <a class="text_wh" href="https://subol.sa/Dashboard/PGT?isInJourney=false">
                                            <img class="nav_img" src="/assets/images/landing/icon23.png" alt="أدوات الإرشاد المهني">
                                            أدوات الإرشاد المهني
                                        </a>
                                    </div>
                                    <div class="dropdown-menu">
                                        <a class="dropdown-item" href="https://subol.sa/Dashboard/Articles?articleType=4&isInJourney=False">قصص تشجيعية</a>
                                        <a class="dropdown-item" href="https://subol.sa/Dashboard/Articles?articleType=5&isInJourney=False">مهارات إعداد السيرة الذاتية</a>
                                        <a class="dropdown-item" href="https://subol.sa/Dashboard/Articles?articleType=6&isInJourney=False">مقالات تثقيفية</a>
                                        <a class="dropdown-item" href="https://subol.sa/Dashboard/Articles?articleType=7&isInJourney=False">مهارات المقابلة الشخصية</a>
                                        <a class="dropdown-item" href="https://subol.sa/Dashboard/Articles/Standards?articleType=9&isInJourney=False">معايير التثقيف والارشاد المهني</a>
                                    </div>
                                </li>
                                <li class="nav-item d-flex ">
                                    <div class="nav-link">
                                        <a class="text_wh" href="https://subol.sa/Dashboard/PGT/CommunicatePgOptions?isInJourney=false">
                                            <img class="nav_img" src="/assets/images/landing/icon25.png" alt="تواصل مع المرشد المهني)">
                                            تواصل مع المرشد المهني
                                        </a>
                                        <span class="navbar-label--new" style="color: red;font-size: 60%;">جديد</span>
                                    </div>
                                </li>
                            </ul>

                            <div class="btn-group mt-2 mb-2 mb-nav text-center">
                                <button type="button" class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" style="color:#fff">
                                    خدمات سبل
                                </button>
                                <ul class="dropdown-menu" role="menu" style="background: rgba(29, 181, 7, 0.7); max-width:200px">

                                    <li class="nav-item d-flex ">
                                        <div class="nav-link">
                                            <a class="text_wh" href="javascript:void(0)" @click="GoTo('/TendencyScale')">
                                                <img class="nav_img" src="/assets/images/landing/icon26.png" alt="مقياس الميول">
                                                مقياس الميول
                                            </a>
                                        </div>
                                    </li>
                                    <li class="nav-item d-flex ">
                                        <div class="nav-link">
                                            <a class="text_wh" href="https://subol.sa/Dashboard/FutureOccupations/Index?isInJourney=false">
                                                <img class="nav_img" src="/assets/images/landing/icon25.png" alt="مهن المستقبل">
                                                مهن المستقبل
                                            </a>

                                        </div>
                                    </li>
                                    <li class="nav-item d-flex dropdown">
                                        <div class="nav-link">
                                            <a class="text_wh" href="https://subol.sa/Dashboard/PGT?isInJourney=false">
                                                <img class="nav_img" src="/assets/images/landing/icon23.png" alt="أدوات الإرشاد المهني">
                                                أدوات الإرشاد المهني
                                            </a>
                                        </div>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item" href="https://subol.sa/Dashboard/Articles?articleType=4&isInJourney=False">قصص تشجيعية</a>
                                            <a class="dropdown-item" href="https://subol.sa/Dashboard/Articles?articleType=5&isInJourney=False">مهارات إعداد السيرة الذاتية</a>
                                            <a class="dropdown-item" href="https://subol.sa/Dashboard/Articles?articleType=6&isInJourney=False">مقالات تثقيفية</a>
                                            <a class="dropdown-item" href="https://subol.sa/Dashboard/Articles?articleType=7&isInJourney=False">مهارات المقابلة الشخصية</a>
                                            <a class="dropdown-item" href="https://subol.sa/Dashboard/Articles/Standards?articleType=9&isInJourney=False">معايير التثقيف والارشاد المهني</a>
                                        </div>
                                    </li>
                                    <li class="nav-item d-flex ">
                                        <div class="nav-link">
                                            <a class="text_wh" href="https://subol.sa/Dashboard/PGT/CommunicatePgOptions?isInJourney=false">
                                                <img class="nav_img" src="/assets/images/landing/icon25.png" alt="تواصل مع المرشد المهني)">
                                                تواصل مع المرشد المهني
                                            </a>
                                            <span class="navbar-label--new" style="color: red;font-size: 60%;">جديد</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-sm-4 col-md-2 col-4 nav_green_search">
                            <div class="form-inline search_bg" id="searchform">
                                <span class="mx-2 nav_green_srch">
                                    <button class=" btn-search-in-header">
                                        <img src="/assets/images/landing/search.png" alt="search">
                                    </button>

                                </span>
                                <input name="term" style="color:#fff!important;padding:0px;" class="form-control ls mr-sm-2 green_search" type="search" placeholder="بحث....." aria-label="Search" is-init="true">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- /app-Header -->
            <!-- /app-banner -->
            <div class="m" v-if="video==false && comp==false">
                <div class="container" style="padding:0px">
                    <div class="banner">
                        <div class="row inner">
                            <div class="col-sm-12 col-md-3">
                                <button class="vjs-big-play-button" type="button" title="Play Video" aria-disabled="false" @click="video=true">
                                    <span aria-hidden="true" class="vjs-icon-placeholder"></span>
                                    <span class="vjs-control-text" aria-live="polite">
                                        <i class="fa fa-play-o"></i>
                                    </span>
                                </button>
                            </div>
                            <div class="col-sm-12 col-md-9 banner_txt">
                                <h1> {{ $t('LandingPage.Ways') }}</h1>
                                <p style="max-width:550px">
                                    {{ $t('LandingPage.WaysDescription') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="video_container" v-if="video==true && comp==false">
                <iframe src="https://www.youtube.com/embed/QTyAQFIC8RI"
                        frameborder="0" allowfullscreen autoplay class="video"></iframe>
            </div>
            <router-view></router-view>
        </div>

        <!-- </Footer> -->
        <!--<div class="demo-footer">
            <div class="ro container  parent px-5 px-md-0 pt-5 ">
                <div class=" pt-5">
                    <img src="/assets/images/landing/subol.png" />
                </div>

            </div>

        </div>
        <div class=" copy  ">

            <p class="mt-4">     {{ $t('LandingPage.Footer') }} </p>

        </div>-->
        <div class="footer_bg position-relative">
            <img src="/assets/images/landing/top-left-footer.png" class="topLeftFooter" alt="top-left-footer">
            <footer class="fot">
                <div class="container">
                    <div class="row pb-5 pt-5 text-center ">
                        <div class="col-md-3 pt-5">
                            <img class="footer_img" src="/assets/images/landing/hrdf.png" alt="Human Resources Developent Fund">
                        </div>
                        <!--<div class="col-md-2 pt-5">
                            <img class="footer_img" src="/assets/images/landing/1.png" alt="منصة سبل">
                        </div>-->
                        <div class="col-md-3 pt-5">
                            <h6 class="mar-top text-light ltr">
                                اتصل بنا على الرقم الموحد
                            </h6>
                            <br>
                            <a class="btn btn-light unified-number" href="tel:8001222030">
                                8001222030
                            </a>
                        </div>
                        <div class="col-md-3 d-block text-center pt-5">
                            <a class="text-light link_hov" href="https://suboluniv.com/Login">ابدأ رحلتك</a><br>
                            <a class="text-light link_hov" href="https://suboluniv.com/TermsAndConditions">السیاسات والأحكام</a><br>
                            <a class="text-light link_hov" href="https://suboluniv.com/FandQs">أسئلة وأجوبة</a><br>
                            <a class="text-light link_hov" href="https://subol.sa/home/ImportantWebsites">مواقع مهمة</a>
                        </div>
                        <div class="col-sm-12 col-md-3 pt-5 text-light">
                            <h6 class="text-light">تابعنا عبر مواقع التواصل</h6>
                            <a href="https://twitter.com/hrdf_care" class="text-light" aria-label="twitter"><i class="fa fa-twitter me-2 border border-light rounded-circle p-2"></i></a>
                            <a href="https://www.facebook.com/hrdfnews" class="text-light" aria-label="facebook"><i class="fa fa-facebook-official me-2 border border-light rounded-circle p-2"></i></a>
                            <a href="https://www.linkedin.com/company/hrdf" class="text-light" aria-label="linkedin"><i class="fa fa-linkedin-square me-2 border border-light rounded-circle p-2"></i></a>
                        </div>
                    </div>
                </div>
            </footer>

            <div class="position-relative">

                <img src="/assets/images/landing/bott-left-green-footer.png" class="bottLeftGreenFooter" alt="bott-left-green-footer">
                <img src="/assets/images/landing/bott-left-orange-footer.png" class="bottLeftOrangeFooter" alt="bott-left-orange-footer">
                <img src="/assets/images/landing/bott-right-orange-footer.png" class="bottRightOrangeFooter" alt="bott-right-orange-footer">
                <img src="/assets/images/landing/bott-right-blue-footer.png" class="bottRightBlueFooter" alt="bott-right-blue-footer">
                <div class="text-center text-light mb-0 bottomFooter">
                    {{ $t('LandingPage.Footer') }} {{currentDate}} ©
                </div>
            </div>

        </div>
        <!-- Footer close -->
    </div>
</template>
<script>
    export default {
        name: "landingPg",
        data: function () {
            return {
                video: false,
                comp: false,
                currentDate: ''
            }
        },
        methods: {
            GoTo: function (link) {
                this.$router.push(link);
                this.comp = true;
            },
        },
        mounted: function () {
            if (this.$route.path == "/TendencyScale") {
                this.comp = true;
            }
            this.currentDate = new Date().getFullYear();
        }
    };
</script>
<style scoped>
    @font-face {
        font-family: kufi;
        src: url(/assets/Droid_Kufi.ttf);
    }

    .page {
        font-family: kufi;
    }

    .landing-nav {
        padding: 20px 0px 20px 0px;
    }

    .hor-header {
        background: transparent;
    }

    .header {
        border-bottom: none !important;
    }

    .banner_txt {
        padding: 0px !important;
    }

    .text_wh {
        color: #fff !important;
    }

    .green_bg_nav {
        background-image: url("/assets/images/landing/nv_des.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .nav_green {
        padding-right: 4rem;
    }

    .nav-link {
        display: block;
        padding: 0.5rem 1rem;
    }

    .navbar-nav .nav-link {
        margin-left: 0.1875rem;
        margin-right: 0.1875rem;
        filter: drop-shadow(0px 0px 5px darkgreen);
    }

    .dropdown-menu {
        position: absolute !important;
        left: -74px !important;
        border-radius: 10px;
        padding: 10px;
    }

    .dropdown-item:focus, .dropdown-item:hover {
        color: #009000 !important;
    }

    .page-main {
        background-size: cover;
    }

    .form-inline {
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -ms-flex-align: center;
        align-items: center;
    }

    .nav_green_search {
        padding: 0px !important;
        display: flex;
    }

    .green_search {
        max-width: 120px;
        background: transparent !important;
        border: none !important;
    }

    .mb-nav {
        display: none
    }

    .nav-link.active, .nav-link:hover, .nav-link.focus {
        background-image: url("/assets/images/landing/dd1.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .vll {
        border-left: 0.125rem solid #009000;
        padding-right: 0.2rem;
        padding-left: 0.2rem;
        font-size: 1rem;
    }

    #firstUl {
        align-items: flex-end;
        padding-right: 10px;
        display: none;
    }

    .vjs-big-play-button {
        width: 160px !important;
        height: 155px !important;
        background: url("/assets/images/landing/play-btn.png") -190px -10px;
        transition: none;
        border: 0;
    }

        .vjs-big-play-button:hover {
            background-position: -0.625rem -0.625rem;
        }

    .video_container {
        position: relative;
        width: 100%;
        height: 100vh;
        max-height: 630px;
    }

    .video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-height: 630px;
    }

    .page-main {
        background: url("/assets/images/landing/bg.jpg");
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .banner {
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: white;
    }

    .demo-footer {
        background-color: #092f57;
    }

    .btn-outline-primary {
        color: #009000 !important;
        border: 1px solid #009000;
    }

        .btn-outline-primary:hover {
            color: white !important;
        }

    .parent {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        padding-bottom: 50px;
    }

        .parent p {
            color: white;
            margin-top: 56px;
            font-size: 16px;
        }

    .banner h1 {
        font-weight: 700;
        font-size: 58px;
    }

    .banner p {
        margin-top: 30px;
        font-size: 16px;
    }

    .con {
        color: white !important;
        margin-top: 20px;
        padding: 20px;
        cursor: pointer;
    }

    .header {
        padding-bottom: 0px !important;
    }

    .main-container {
        padding-bottom: 1rem !important;
    }

    .inner {
        margin-top: 150px;
    }

    .dropdown-item {
        font-size: 1rem;
        line-height: 2rem;
    }

    .green_search::placeholder {
        color: #fff;
        font-size: 1rem;
    }

    .banner_txt p {
        font-weight: 300;
        line-height: 2;
        font-size: 1.0rem;
        font-family: kufi;
    }

    .footer_bg {
        background-color: #093254;
        text-align: center !important;
    }

    .pt-5 {
        padding-top: 3rem !important;
    }

    .pb-5 {
        padding-bottom: 3rem !important;
    }

    .position-relative {
        position: relative !important;
    }

    img.topLeftFooter {
        position: absolute;
        left: 0;
        top: -2.5rem;
    }

    .footer_img {
        width: 7rem;
    }

    img.bottLeftGreenFooter {
        position: absolute;
        left: 0;
        top: -2.1875rem;
        z-index: 3;
    }

    img.bottLeftOrangeFooter {
        left: 1.25rem;
        position: absolute;
        z-index: 1;
        top: -2.1875rem;
    }

    img.bottRightOrangeFooter {
        position: absolute;
        z-index: 1;
        right: 3.75rem;
        top: -1.875rem;
    }

    img.bottRightBlueFooter {
        position: absolute;
        z-index: 3;
        top: -3.125rem;
        right: 0;
    }

    .bottomFooter {
        background: #001b39;
        z-index: 2;
        position: relative;
        padding: 1rem;
    }

    .text-light {
        color: #fff !important;
        font-size: 1rem !important;
        line-height: 2rem;
    }

    .unified-number {
        background: #fff !important;
        color: #212529 !important;
    }

    #forHide {
        display: none;
    }

    @media screen and (min-width: 1500px) {
        .navbar-nav .nav-link {
            padding-left: 2.15rem;
            padding-right: 2.15rem;
            font-size: 1.2rem;
        }
    }

    @media screen and (max-width: 1180px) {
        .header.hor-header {
            position: sticky !important;
        }

        .lg-nav {
            display: none;
        }

        .mb-nav {
            display: block;
        }
    }

    @media screen and (max-width: 780px) {
        #hide {
            display: none;
        }

        #forHide {
            display: block;
        }

        .navresponsive-toggler {
            display: none;
        }

        #navbarSupportedContent-4 {
            display: none;
        }

        .parent {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: 4fr;
        }

            .parent p {
                margin-top: 36px;
            }

        .btn {
            min-width: 85px !important;
            padding: 3px !important;
            border: 1px solid #009000;
            color: #009000 !important;
        }

        .ct {
            color: white !important;
        }

        .green_bg_nav {
            background-image: url("/assets/images/landing/nv_mob.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }


        .green_search {
            max-width: 65px
        }

        .inner {
            margin-top: 50px;
        }

        .banner_txt {
            padding: 30px !important;
        }

        .nav_green_srch {
            display: none;
        }
    }

    .ft {
        margin-top: 40px !important;
        margin-right: 200px;
    }

    .copy {
        background-color: #001b39;
        color: white;
        text-align: center;
    }

    .link_hov {
        color: #019000 !important;
    }

        .link_hov:hover {
            color: #fff !important;
        }
</style>

