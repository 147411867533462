<template>
    <div class="page-main">
        <div class="container py-5 mb-5">
            <div class="backtomain mb-3">
                <div class="logo mr-3">
                    <a href="javascript:void(0)" v-on:click="GoToHome"><span class="h1 fw-bold mb-0"><img src="/assets/images/subol_logo.png" class=" mx-auto img-fluid logoimg" alt="subol_logo" style="max-width:210px;"></span></a>
                </div><br>
            </div>
            <div class="signup_form_title mb-5">
                <h1><font style="vertical-align: inherit;"><font style="vertical-align: inherit;"> {{ $t('Register.Heading') }}</font></font></h1>

            </div>

            <div class="row">
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('Register.FirstName') }}<span class="error text-danger">*</span> {{$t('Register.FirstNameDescription')}}</label>
                    <input v-model="$v.studentRegister.firstName.$model" type="text"  class="form-control">
                    <span v-if="$v.studentRegister.firstName.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.firstName.arabic">يرجى كتابه الاسم باللغة العربية<br /></span>
                        <span v-if="!$v.studentRegister.firstName.minLength">يرجى كتابه الاسم بالكامل</span>
                    </span>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('Register.FatherName') }} <span class="error text-danger">*</span> {{$t('Register.FatherNameDescription')}}</label>
                    <input v-model="$v.studentRegister.middleName.$model" type="text" class="form-control">
                    <span v-if="$v.studentRegister.middleName.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.middleName.arabic">يرجى كتابه اسم الاب باللغة العربية<br /></span>
                        <span v-if="!$v.studentRegister.middleName.minLength">يرجى كتابه اسم الاب بالكامل</span>
                    </span>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('Register.FamilyName') }} <span class="error text-danger">*</span> {{$t('Register.FamilyNameDescription')}}</label>
                    <input v-model="$v.studentRegister.lastName.$model" type="text" class="form-control">
                    <span v-if="$v.studentRegister.lastName.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.lastName.arabic">يرجى كتابه لقب العائلة باللغة العربية</span>
                        <span v-if="!$v.studentRegister.lastName.minLength">يرجى كتابه لقب العائلة بالكامل</span>
                    </span>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label for="inputZip">{{ $t('Register.StudentCategory') }}<span class="error text-danger">*</span></label>
                    <studentcategorydropdown v-model="studentRegister.studentCategoryId" :values="studentRegister.studentCategoryId" @input="UniversityLevelRender()" />
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.UniversityNumber') }}<span class="error text-danger">*</span></label>
                    <input v-model="studentRegister.registrationCode" type="text" class="form-control">
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.IdentificationNumber') }}<span class="error text-danger">*</span> </label>
                    <input v-model="$v.studentRegister.nationalId.$model" v-on:keyup="CheckNationalId(studentRegister.nationalId)" type="text" class="form-control">
                    <span v-if="$v.studentRegister.nationalId.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.nationalId.number">الرجاء كتابة الرقم فقط</span>
                    </span>
                    <span v-if="isValidNationalId" class="text-danger">لا يمكن أن يكون أكثر أو أقل من 10 أرقام ويبدأ دائمًا بـ "1" أو "2"</span>
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.MobileNumber') }}<span class="error text-danger">*</span></label>
                    <input v-model="$v.studentRegister.phoneNumber.$model" type="text" class="form-control" placeholder="05xxxxxxxx">
                    <span v-if="$v.studentRegister.phoneNumber.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.phoneNumber.phoneNumber">الرجاء كتابة رقم هاتف محمول صحيح</span>
                        <span v-if="!$v.studentRegister.phoneNumber.minLength">الرجاء كتابة رقم هاتف محمول صحيح</span>
                    </span>
                </div>


                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.DateOfBirth') }}<span class="error text-danger">*</span></label>
                    <datepicker v-model="studentRegister.dateOfBirth" />
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.Gender') }}<span class="error text-danger">*</span></label>
                    <genderdropdown v-model="$v.studentRegister.gender.$model" :values="studentRegister.gender" />
                    <span v-if="$v.studentRegister.gender.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.gender.required">يرجى اختيار نوع الجنس</span>
                    </span>
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4" v-if="!isUpdate">
                    <label>{{ $t('Register.Email') }}<span class="error text-danger">*</span></label>
                    <input v-model="$v.studentRegister.email.$model" type="email" class="form-control">
                    <span v-if="$v.studentRegister.email.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.email.email">الرجاء إدخال بريد إلكتروني صحيح</span>
                    </span>
                </div>


                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4" v-if="!isUpdate">
                    <label>{{ $t('Register.Password') }}<span class="error text-danger">*</span></label>

                    <input v-model="$v.studentRegister.password.$model" v-bind:type="showPassword==false? 'password' : 'text'" class="form-control">
                    <span v-if="$v.studentRegister.password.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.password.minLength"> ثمانية خانات على الاقل</span>

                    </span>
                    <label class="showpass">
                        <a v-on:click="showPasswords()" href="javascript:void(0)" class="fs-10 mx-1">عرض كلمة المرور </a>
                    </label>
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4" v-if="!isUpdate">
                    <label>{{ $t('Register.ConfirmPassword') }}<span class="error text-danger">*</span></label>
                    <input v-model="$v.studentRegister.confirmPassword.$model" v-bind:type="showPassword==false? 'password' : 'text'" class="form-control">
                    <span v-if="$v.studentRegister.confirmPassword.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.confirmPassword.sameAsPassword">كلمة المرور غير مطابقة </span>
                    </span>
                    <label class="showpass">
                        <a v-on:click="showPasswords()" href="javascript:void(0)" class="fs-10 mx-1"> عرض كلمة المرور</a>
                    </label>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label for="inputState">{{ $t('Register.Region') }}<span class="error text-danger">*</span></label>
                    <regionsdropdown v-model="$v.studentRegister.regionId.$model" @input="CityRender()" :values="studentRegister.regionId" />
                    <span v-if="$v.studentRegister.regionId.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.regionId.required">يرجى اختيار المنطقة</span>
                    </span>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.City') }}<span class="error text-danger">*</span></label>
                    <citydropdown v-model="studentRegister.cityId" :regionid="studentRegister.regionId" :key="cityRenders" :values="studentRegister.cityId" />
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.University') }}<span class="error text-danger">*</span></label>
                    <universitydropdown v-model="studentRegister.universityId" @input="CollegeRender()" :regionid="studentRegister.regionId" :key="cityRenders" :values="studentRegister.universityId" />
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.College') }}<span class="error text-danger">*</span></label>
                    <college-dropdown v-model="studentRegister.collegeId" :universityid="studentRegister.universityId" :isall="false" :key="collegeRender" :values="studentRegister.collegeId" />
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.UniversitySpecialization') }}<span class="error text-danger">*</span></label>
                    <universtyspecilizationdropdown v-model="studentRegister.specializationId" :values="studentRegister.specializationId" />
                </div>


                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.UniversityLevel') }}<span class="error text-danger">*</span></label>
                    <universtyleveldropdown v-model="$v.studentRegister.universityLevelId.$model" :values="studentRegister.universityLevelId" :studentCategoryId="studentRegister.studentCategoryId" :key="universityLevelRender" />
                    <span v-if="$v.studentRegister.universityLevelId.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.universityLevelId.required">يرجى اختيار المستوى الجامعي</span>
                    </span>
                </div>


                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label for="inputZip">{{ $t('Register.Qualification') }}<span class="error text-danger">*</span></label>
                    <qualificationdropdown v-model="studentRegister.qualificationId" :values="studentRegister.qualificationId" />
                </div>

            </div>
            <div class="form-group col-md-8" v-if="adviserRoleName == 'Super Admin' && isUpdate">
                <div class="material-switch">
                    <input id="someSwitchOptionPrimary" name="someSwitchOption001" v-model="studentRegister.isActive" type="checkbox" checked />
                    <label for="someSwitchOptionPrimary" class="label-primary"></label>
                </div>
            </div>
            <div class="row mt-3 btn_student_group ps-3">
                <button v-on:click="SaveNewUser" v-bind:disabled="$v.$invalid || isValidNationalId" class="btn btn-primary register col-sm-3 col-md-2 col-lg-1 col-xl-1">{{ $t('Register.Save') }}</button>
                <button v-on:click="GoToSignIn" class="btn btn-light ms-3 login col-sm-3 col-md-2 col-lg-2 col-xl-2">{{ $t('Register.Cancel') }}</button>
            </div>
            <loading :name="loading" :active.sync="loading"
                     :can-cancel="false"
                     :is-full-page="true"></loading>

        </div>
    </div>
</template>


<script>
    import { required, maxLength, sameAs, email, minLength, requiredIf } from 'vuelidate/lib/validators';
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";

    export default {
        components: {
            Loading
        },
        data() {
            return {
                collegeRender: 0,
                loading: false,
                isValidNationalId: false,
                showPassword: false,
                universityLevelRender: 0,
                cityRenders: 0,
                universityRenders: 0,
                isUpdate: false,
                studentRegister: {
                    id: '00000000-0000-0000-0000-000000000000',
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    roleName: 'Student',
                    registrationCode: '',
                    nationalId: '',
                    phoneNumber: '',

                    dateOfBirth: '',
                    gender: '',
                    email: '',
                    cityId: '',
                    universityId: '',
                    studentCategoryId: '',
                    qualificationId: '',
                    regionId: '',
                    specializationId: '',
                    universityLevelId: '',
                    collegeId: '',
                    password: '',
                    confirmPassword: '',
                    isActive: true,

                },
                adviserRoleName: '',

            }
        },
        validations: {
            studentRegister:
            {
                firstName:
                {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    arabic(name) {
                        return /^[\u0621-\u064A\s]+$/.test(name)
                    }
                },
                middleName:
                {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    arabic(name) {
                        return /^[\u0621-\u064A\s]+$/.test(name)
                    }
                },
                lastName:
                {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    arabic(name) {
                        return /^[\u0621-\u064A\s]+$/.test(name)
                    }
                },
                //registrationCode: {
                //    required: requiredIf((x) => {
                //        if (x.studentCategoryId == 'e1c0e4db-a923-406b-98cd-0139694362c6')
                //            return true;
                //        return false;
                //    }),
                //},
                gender:
                {
                    required
                },
                regionId:
                {
                    required
                },
                cityId:
                {
                    required
                },
                studentCategoryId:
                {
                    required
                },
                universityId:
                {
                    required
                },
                specializationId:
                {
                    required: requiredIf((x) => {
                        if (x.studentCategoryId == 'e1c0e4db-a923-406b-98cd-0139694362c6')
                            return true;
                        return false;
                    }),
                },
                qualificationId:
                {
                    required: requiredIf((x) => {
                        if (x.studentCategoryId == 'e1c0e4db-a923-406b-98cd-0139694362c6')
                            return true;
                        return false;
                    }),
                },
                nationalId:
                {
                    required,
                    //number(id) {
                    //    return /\b[12]\d{9}\b/.test(id)
                    //},
                    number(id) {
                        return /^[0-9\u0660-\u0669]+$/.test(id)
                    }
                },
                phoneNumber:
                {
                    maxLength: maxLength(15),
                    minLength: minLength(10),
                    phonenumber(number) {
                        return /^[0-9\u0660-\u0669]+$/.test(number)
                    }
                },
                email:
                {
                    required,
                    email
                },
                collegeId:
                {
                    required
                },
                universityLevelId: {
                    required: requiredIf((x) => {
                        if (x.studentCategoryId == 'e1c0e4db-a923-406b-98cd-0139694362c6' || x.studentCategoryId == 'e3c0e4db-a923-406b-98cd-0139694362c6' || x.studentCategoryId == 'b12b3957-2c34-48e9-86c2-2958f230dd6f')
                            return true;
                        return false;
                    }),
                },
                password:
                {
                    required,
                    minLength: minLength(8),
                },
                confirmPassword:
                {
                    required,
                    sameAsPassword: sameAs('password')
                },
            }
        },

        methods: {
            CheckNationalId: function (no) {
                
                if (no.length != 10) {
                    this.isValidNationalId = true;
                }
                else {
                    var letter = no.substring(0, 1)
                    if (letter != '1' && letter != '2' && letter != '١' && letter != '٢') {
                        this.isValidNationalId = true;
                    }
                    else {
                        this.isValidNationalId = false;
                    }
                }

            },
            showPasswords: function () {

                this.showPassword = this.showPassword == true ? false : true;
            },
            UniversityLevelRender: function () {
                this.universityLevelRender++;
            },
            CityRender: function () {
                this.cityRenders++;
                this.studentRegister.cityId = '';
                this.studentRegister.universityId = '';
            },
            CollegeRender: function () {
                this.collegeRender++;
                this.studentRegister.collegeId = '';
            },
            UniversityRender: function () {
                this.universityRenders++;
            },

            GoToHome: function () {
                this.$router.push('/')
            },

            GoToSignIn: function () {
                if (this.isUpdate) {
                    this.$router.push('/StudentList');
                }
                else
                    this.$router.push('/Login')
            },

            SaveNewUser: function () {
                var root = this;
                this.loading = true;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.post('/account/StudentRegister', this.studentRegister, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {

                            if (root.studentRegister.id == '00000000-0000-0000-0000-000000000000') {
                                root.SendEmailVerification(response.data.id);
                            }

                            root.$swal({
                                title: root.$t('Notification.Created'),
                                text: root.$t('Notification.UserCreated'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            });
                            root.loading = false;
                            if (root.isUpdate) {
                                root.$router.push('/StudentList');
                            }
                            else
                                root.GoToSignIn();
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-info",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            SendEmailVerification: function (id) {
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.get('/account/SendEmailVerification?id=' + id, { headers: { "Authorization": `Bearer ${token}` } });

            },
        },
        created: function () {
            if (this.$route.query.data != undefined) {
                this.studentRegister = this.$route.query.data;
                this.studentRegister.password = "Asdf@123";
                this.studentRegister.confirmPassword = "Asdf@123";
                this.isUpdate = true;
            }
            this.adviserRoleName = localStorage.getItem('RoleName');
        },
    }
</script>
<style scoped>
    @media only screen and (max-width: 600px) {
        .register {
            max-width: 40%;
        }

        .login {
            max-width: 40%;
        }

        .btn_student_group {
            justify-content: center;
        }
    }
</style>