<template>

    <multiselect v-model="DisplayValue" :options="options" :disabled="disabled" :multiple="false" :searchable="false" track-by="name" :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true" v-bind:placeholder=" $t('Dropdown.SelectOption')">
        <p slot="noResult" class="text-danger"> {{ $t('Dropdown.Gender') }} </p>
        <p slot="noOptions"> </p>
    </multiselect>

</template>
<script>
    import Multiselect from "vue-multiselect";
    export default {
        name: "GenderDropdown",
        props: ["values", "both", "disabled"],

        components: {
            Multiselect,

        },
        data: function () {
            return {
                options: [],
                value: '',
                render: 0
            }
        },
        methods: {
            getData: function () {
                var root = this;

                root.options.push({
                    id: 1,
                    name: 'ذكر'
                });
                root.options.push({
                    id: 2,
                    name: 'انثى'
                });

                if (root.both) {
                    root.options.push({
                        id: 3,
                        name: 'كلاهما'
                    });
                }
                

                root.value = root.options.find(function (x) {
                    return x.id == root.values;
                });
            },
        },
        computed: {
            DisplayValue: {
                get: function () {
                    if (this.value != "" || this.value != undefined) {
                        return this.value;
                    }
                    return this.values;
                },
                set: function (value) {
                    this.value = value;
                    this.$emit('input', value == null ? null : value.id);
                }
            }
        },
        mounted: function () {
            this.getData();
        },
    }
</script>