<template>
    <div @click="$refs.imgupload.click()" class="img-wrapper" style="border-style: dashed; border-width: 3px; border-color: #384d69; text-align: center; padding: 20px">

        <img v-if="filePath != null && filePath != undefined && filePath != ''" :src="'data:image/png;base64,' + filePath" />
        <img v-else v-bind:src="image" />

        <div class="col-sm-12 mt-4">
            <input ref="imgupload" hidden type="file" accept="image/*" @change="uploadImage('onClick')" id="file-input">
        </div>
    </div>
</template>

<script>
    export default {
        props: ["path"],
        data: function () {
            return {
                image: '../../images/Placeholder.svg',
                data: [],
                url: '',
                filePath: null
            }
        },
        methods: {
            uploadImage() {
                
                var root = this;
                var file = this.$refs.imgupload.files;
                if (file[0].size < 1000000) {
                    this.filePath = null;
                    var fileData = new FormData();
                    this.image = URL.createObjectURL(this.$refs.imgupload.files[0]);
                    // convert file into FormData object
                    for (var k = 0; k < file.length; k++) {
                        fileData.append("files", file[k]);
                    }

                    this.$https.post('/Home/UploadFilesAsync', fileData)
                        .then(function (response) {
                            if (response.data != null) {
                                root.$emit('picPath', response.data);
                                root.GetBaseImage(response.data);
                            }
                        });
                }
                else {
                    alert("maximum upload image size 1mb");
                }
                
            },

            GetBaseImage: function (filePath) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                if (filePath != null && filePath != undefined && filePath != '') {
                    root.$https.get('/Home/GetBaseImage?filePath=' + filePath, { headers: { "Authorization": `Bearer ${token}` } })
                        .then(function (response) {
                            if (response.data != null) {
                                root.filePath = response.data;
                            }
                        }, function (error) {
                            console.log(error);
                        });
                }
                
            }

        },

        mounted: function () {
            if (this.path != null && this.path != undefined && this.path != '') {
                this.GetBaseImage(this.path);
            }
        }
    }
</script>

<style scoped>
    .img-wrapper {
        height: 185px;
        width: 220px;
        overflow: hidden;
    }

    img {
        height: auto;
        width: 100%
    }
</style>
