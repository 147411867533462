<template>
    <div class="page-main">
        <div class="card  p-5 mb-5 mt-3">
            <div class="backtomain mb-3">
                <div class="logo mr-3"></div>
                <br />
            </div>
            <div class="signup_form_title mb-5">
                <h1>
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">
                            {{ $t("CreateGroupSessionDetails.Heading") }}
                        </font>
                    </font>
                </h1>
            </div>

            <div class="row">
                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.SessionType") }}
                    </h4>
                    <p v-if="!createGroupSessionDetails.hideSessionType" class="text-muted mb-3 mx-1">{{createGroupSessionDetails.sessionTypeName}}</p>
                    <p v-if="createGroupSessionDetails.hideSessionType" class="text-muted mb-3 mx-1">---</p>
                </div>

                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.SessionStartDate") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">{{createGroupSessionDetails.sessionStartTime}}</p>
                </div>

                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.SessionDuration") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">{{createGroupSessionDetails.sessionDurationName}}</p>
                </div>

                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.AvailableAppointments") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">{{createGroupSessionDetails.sessionEndTime}}</p>
                </div>

                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.AffiliateUniversities") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">
                        <span v-for="(item, index) in createGroupSessionDetails.universityName" :key="index+'item'"> {{index>0? ',' : ''}} {{item.name}}</span>
                    </p>
                </div>

                <div v-if="createGroupSessionDetails.workshopType==0 || createGroupSessionDetails.workshopType==2" class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.MeetingPlace") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">{{createGroupSessionDetails.sessionLocationName}}</p>
                </div>


                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        المستوى الجامعي للطلاب
                    </h4>
                    <p class="text-muted mb-3 mx-1">
                        <span v-for="(item,index) in createGroupSessionDetails.universityLevelIdName" :key="index+'abc'">{{item}}</span>
                    </p>
                </div>

            </div>

            <hr class="mb-5 mt-3" v-if="studenNotFound=='Student not found!'" />
            <div class="row" v-if="studenNotFound=='Student not found!'">
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('Register.FirstName') }}<span class="error text-danger">*</span> {{$t('Register.FirstNameDescription')}}</label>
                    <input v-model="$v.studentRegister.firstName.$model" type="text" class="form-control">
                    <span v-if="$v.studentRegister.firstName.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.firstName.arabic">يرجى كتابه الاسم باللغة العربية<br /></span>
                        <span v-if="!$v.studentRegister.firstName.minLength">يرجى كتابه الاسم بالكامل</span>
                    </span>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('Register.FatherName') }} <span class="error text-danger">*</span> {{$t('Register.FatherNameDescription')}}</label>
                    <input v-model="$v.studentRegister.middleName.$model" type="text" class="form-control">
                    <span v-if="$v.studentRegister.middleName.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.middleName.arabic">يرجى كتابه اسم الاب باللغة العربية<br /></span>
                        <span v-if="!$v.studentRegister.middleName.minLength">يرجى كتابه اسم الاب بالكامل</span>
                    </span>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label> {{ $t('Register.FamilyName') }} <span class="error text-danger">*</span> {{$t('Register.FamilyNameDescription')}}</label>
                    <input v-model="$v.studentRegister.lastName.$model" type="text" class="form-control">
                    <span v-if="$v.studentRegister.lastName.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.lastName.arabic">يرجى كتابه لقب العائلة باللغة العربية</span>
                        <span v-if="!$v.studentRegister.lastName.minLength">يرجى كتابه لقب العائلة بالكامل</span>
                    </span>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label for="inputZip">{{ $t('Register.StudentCategories') }}<span class="error text-danger">*</span></label>
                    <studentcategorydropdown v-model="studentRegister.studentCategoryId" :values="studentRegister.studentCategoryId" @input="UniversityLevelRender()" />
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.Email') }}<span class="error text-danger">*</span></label>
                    <input v-model="$v.studentRegister.email.$model" type="email" class="form-control">
                    <span v-if="$v.studentRegister.email.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.email.email">الرجاء إدخال بريد إلكتروني صحيح</span>
                    </span>
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.IdentificationNumber') }}<span class="error text-danger">*</span> </label>
                    <input v-model="$v.studentRegister.nationalId.$model" v-on:keyup="CheckNationalId(studentRegister.nationalId)" type="text" class="form-control">
                    <span v-if="$v.studentRegister.nationalId.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.nationalId.number">الرجاء كتابة الرقم فقط</span>
                    </span><br />
                    <span v-if="isValidNationalId" class="text-danger">لا يمكن أن يكون أكثر أو أقل من 10 أرقام ويبدأ دائمًا بـ "1" أو "2"</span>

                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.MobileNumber') }}<span class="error text-danger">*</span></label>
                    <input v-model="$v.studentRegister.phoneNumber.$model" type="text" class="form-control" placeholder="05xxxxxxxx">
                    <span v-if="$v.studentRegister.phoneNumber.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.phoneNumber.phoneNumber">الرجاء كتابة رقم هاتف محمول صحيح</span>
                        <span v-if="!$v.studentRegister.phoneNumber.minLength">الرجاء كتابة رقم هاتف محمول صحيح</span>
                    </span>
                </div>


                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.Gender') }}<span class="error text-danger">*</span></label>
                    <genderdropdown v-model="$v.studentRegister.gender.$model" :values="studentRegister.gender" />
                    <span v-if="$v.studentRegister.gender.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.gender.required">يرجى اختيار نوع الجنس</span>
                    </span>
                </div>

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.DateOfBirth') }}<span class="error text-danger">*</span></label>
                    <datepicker v-model="studentRegister.dateOfBirth" />
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.City') }}<span class="error text-danger">*</span></label>
                    <citydropdown v-model="studentRegister.cityId" :isuniversty="'true'" :values="studentRegister.cityId" />
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.University') }}<span class="error text-danger">*</span></label>
                    <universitydropdown v-model="studentRegister.universityId" @input="CollegeRender()" :alluniversities="true" :optionsvalue="universityIds" />
                </div>
                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.College') }}<span class="error text-danger">*</span></label>
                    <college-dropdown v-model="studentRegister.collegeId" :universityid="studentRegister.universityId" :isall="false" :key="collegeRender" :values="studentRegister.collegeId" />
                </div>
                <!-- <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <label>{{ $t('Register.UniversitySpecialization') }}<span class="error text-danger">*</span></label>
                <universtyspecilizationdropdown v-model="studentRegister.specializationId" :values="studentRegister.specializationId" />
            </div> -->

                <div class="form-group col-sm-12 col-md-6 col-lg-4 col-xl-4">
                    <label>{{ $t('Register.UniversityLevel') }}<span class="error text-danger">*</span></label>
                    <universtyleveldropdown v-model="$v.studentRegister.universityLevelId.$model" :values="studentRegister.universityLevelId" :studentCategoryId="studentRegister.studentCategoryId" :key="universityLevelRender" />
                    <span v-if="$v.studentRegister.universityLevelId.$error" class="error text-danger">
                        <span v-if="!$v.studentRegister.universityLevelId.required">يرجى اختيار المستوى الجامعي</span>
                    </span>
                </div>

            </div>


            <div class="row row-sm my-5" v-if="studenNotFound!='Student not found!'">
                <div class="col-lg-4 form-group">
                    <input v-model="nationalId" v-on:keyup="CheckNationalId(nationalId)" type="text" class="form-control" placeholder="أدخل هويتك الوطنية">
                </div>

                <div class="col-lg-4 form-group" v-if="isEligible">
                    <input v-model="otp" type="text" class="form-control" placeholder="ادخل الرمز المرسل لهاتفكم">
                </div>

                <div class="col-lg-4 form-group text-start" v-if="studenNotFound!='Your are eligible for this session!' && studenNotFound!='Student not found!'">
                    <button v-bind:disabled="isValidNationalId" class="btn btn-primary ms-2" v-bind:class="loading? 'btn-loading' : ''" style="cursor:pointer" @click="checkDetails(nationalId)">التالي</button>
                </div>

                <div class="col-lg-4 form-group text-start" v-if="isEligible">
                    <button v-bind:disabled="otp==''" type="button" :key="render" class="btn btn-primary" v-bind:class="loading? 'btn-loading' : ''" @click.once="JoinNow()">{{ $t("CreateWorkshop.Join") }}</button>
                </div>

            </div>

            <div class="modal-footer ">
                <button v-if="studenNotFound=='Student not found!'" v-on:click="SaveNewUser" v-bind:disabled="$v.$invalid || isValidNationalId" class="btn btn-primary" v-bind:class="loading? 'btn-loading' : ''">{{ $t('Register.Save') }}</button>
                <button type="button" class="btn btn-danger btn-round" v-on:click="GoTo()" href="javascript:void(0)">{{ $t("CreateWorkshop.Cancel") }}</button>
            </div>
        </div>
        <college-model :universityid="studentUniversityId"
                       :show="show"
                       :nationalid="nationalId"
                       v-if="show"
                       @close="show=false" />
    </div>
</template>
<script>
    import { required, maxLength, requiredIf, minLength, email } from 'vuelidate/lib/validators';
    export default {

        data() {
            return {
                show: false,
                studentUniversityId: '',
                universityIds: [],
                universityLevelRender: 0,
                allowQuickRegister: false,
                loading: false,
                isEligible: false,
                isValidNationalId: false,
                studenNotFound: '',
                createGroupSessionDetails: {},
                nationalId: '',
                otp: '',
                render: 0,
                collegeRender: 0,

                studentRegister: {
                    id: '00000000-0000-0000-0000-000000000000',
                    firstName: '',
                    middleName: '',
                    lastName: '',
                    roleName: 'Student',
                    registrationCode: '',
                    nationalId: '',
                    phoneNumber: '',

                    dateOfBirth: '',
                    gender: '',
                    email: '',
                    cityId: '',
                    universityId: '',
                    collegeId: '',
                    studentCategoryId: '',
                    qualificationId: '',
                    regionId: '',
                    specializationId: '',
                    universityLevelId: '',
                    password: 'suboluniv',
                    confirmPassword: 'suboluniv',
                    isActive: true,
                    quickUser: true,
                },

            };
        },
        validations: {
            studentRegister:
            {
                firstName:
                {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    arabic(name) {
                        return /^[\u0621-\u064A\s]+$/.test(name)
                    }
                },
                middleName:
                {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    arabic(name) {
                        return /^[\u0621-\u064A\s]+$/.test(name)
                    }
                },
                lastName:
                {
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                    arabic(name) {
                        return /^[\u0621-\u064A\s]+$/.test(name)
                    }
                },
                studentCategoryId:
                {
                    required
                },
                gender:
                {
                    required
                },
                universityId:
                {
                    required
                },
                collegeId:
                {
                    required
                },
                email:
                {
                    required,
                    email
                },
                nationalId:
                {
                    required,
                    number(id) {
                        return /^[0-9\u0660-\u0669]+$/.test(id)
                    }
                },
                phoneNumber:
                {
                    maxLength: maxLength(15),
                    minLength: minLength(10),
                    phonenumber(number) {
                        return /^[0-9\u0660-\u0669]+$/.test(number)
                    }
                },
                universityLevelId: {
                    required: requiredIf((x) => {
                        if (x.studentCategoryId == 'e1c0e4db-a923-406b-98cd-0139694362c6' || x.studentCategoryId == 'e3c0e4db-a923-406b-98cd-0139694362c6' || x.studentCategoryId == 'b12b3957-2c34-48e9-86c2-2958f230dd6f')
                            return true;
                        return false;
                    }),
                },
                // specializationId:
                // {
                //     required: requiredIf((x) => {
                //         if (x.studentCategoryId == 'e1c0e4db-a923-406b-98cd-0139694362c6')
                //             return true;
                //         return false;
                //     }),
                // },

            }
        },

        methods: {
            CollegeRender: function () {
                this.collegeRender++;
                this.studentRegister.collegeId = '';
            },

            CheckNationalId: function (no) {
                if (no.length != 10) {
                    this.isValidNationalId = true;
                }
                else {
                    var letter = no.substring(0, 1)
                    if (letter != '1' && letter != '2' && letter != '١' && letter != '٢') {
                        this.isValidNationalId = true;
                    }
                    else {
                        this.isValidNationalId = false;
                    }
                }
            },

            GoTo: function () {
                this.$router.push("/Login");
            },

            UniversityLevelRender: function () {
                
                this.universityLevelRender++;
            },

            JoinNow: function () {
                var root = this;
                this.loading = true;


                root.$https.get('/account/JoinStudentSession?sessionId=' + this.createGroupSessionDetails.id + '&studentId=' + this.createGroupSessionDetails.studentId + '&otp=' + this.otp + '&fromEmail=true' + '&fromQr=true')
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.$swal({
                                title: root.$t('Notification.Joined'),
                                text: root.$t('Notification.SessionJoined'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            });
                            root.loading = false;
                            root.GoTo();
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                            root.render++;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false;
                        root.render++;
                    })
                    .finally(() =>
                        root.loading = false
                    );
            },

            SendEmailVerification: function (id) {
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.get('/account/SendMobileOtpVerification?id=' + id + '&sessionId=' + this.createGroupSessionDetails.id, { headers: { "Authorization": `Bearer ${token}` } });
            },

            SaveNewUser: function () {
                var root = this;
                this.loading = true;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                root.$https.post('/account/StudentRegister', this.studentRegister, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {

                            if (root.studentRegister.id == '00000000-0000-0000-0000-000000000000') {
                                root.SendEmailVerification(response.data.id);
                            }
                            root.studenNotFound = '';
                            root.$swal({
                                title: root.$t('Notification.Created'),
                                text: "تم إرسال رمز التحقق الى الهاتف",
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result.isConfirmed) {
                                    root.checkDetails(root.nationalId);
                                }
                            });
                            root.loading = false;
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-info",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },


            ViewInfo: function (id) {
                var root = this;

                root.$https.get('/account/GetJoinStudentSessionDetail?id=' + id + '&isQuick=true').then(function (response) {
                    if (response.data != null) {
                        root.createGroupSessionDetails = response.data;
                        root.universityIds = response.data.universityName;
                    }
                    else {
                        root.$swal({
                            title: root.$t('Notification.Error'),
                            text: 'Session Not Found!',
                            type: 'error',
                            icon: 'error',
                            confirmButtonClass: "btn btn-info",
                            buttonsStyling: false
                        });
                        root.loading = false;
                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            type: 'error',
                            title: root.$t('Notification.Error'),
                            text: root.$t('Notification.SomethingWrong'),
                            showConfirmButton: false,
                            timer: 5000,
                            timerProgressBar: true,
                        });

                    root.loading = false;
                });
            },

            SendResendEmailVerification: function (email) {
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/account/ResendSendEmailVerification?email=' + email, { headers: { "Authorization": `Bearer ${token}` } });
            },


            checkDetails: function (nationalId) {
                var root = this;
                this.loading = true;
                if (nationalId != '' && nationalId != null && nationalId != undefined && nationalId.length == 10) {
                    root.$https.get('/account/VerifyStudentDetailForSession?id=' + this.createGroupSessionDetails.id + '&nationalid=' + nationalId).then(function (response) {
                        if (response.data != null && response.data.isSuccess) {
                            root.loading = false;
                            root.allowQuickRegister = response.data.allowQuickRegister;
                            root.isEligible = response.data.isSuccess;
                            root.createGroupSessionDetails.studentId = response.data.id;
                            root.studenNotFound = response.data.isAddUpdate;
                            root.$swal({
                                title: 'تم ارسال رمز التحقق',
                                text: 'تم إرسال رمز التحقق الى الهاتف!',
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            });
                        }
                        else {

                            var message = '';
                            var title = '';
                            var icon = '';
                            var type = '';
                            if (response.data.isAddUpdate == "Session Close") {
                                title = 'تم إغلاق التسجيل في ورشة العمل';
                                message = 'تم إغلاق التسجيل في ورشة العمل';
                                type = 'error';
                                icon = 'error';
                            }
                            else if (response.data.isAddUpdate == "Your Email and Phone Number is not verified !") {
                                title = 'لم يتم التحقق من البريد الإلكتروني ورقم الهاتف';
                                message = 'لم يتم التحقق من بريدك الإلكتروني ورقم هاتفك';
                                type = 'error';
                                icon = 'error';
                            }
                            else if (response.data.isAddUpdate == "You have not same gender!") {
                                title = 'ليس لديك نفس الجنس';
                                message = 'ليس لديك نفس الجنس';
                                type = 'error';
                                icon = 'error';
                            }
                            else if (response.data.isAddUpdate == "You have not same University!") {
                                title = 'عذرا، انت مسجل في جامعة اخرى';
                                message = 'عذرا، انت مسجل في جامعة اخرى';
                                type = 'error';
                                icon = 'error';
                            }
                            else if (response.data.isAddUpdate == "Student not found!") {
                                title = 'الهوية غير مسجلة نأمل استكمال التسجيل';
                                message = 'الهوية غير مسجلة نأمل استكمال التسجيل';
                                type = 'warning';
                                icon = 'warning';
                            }
                            else {
                                title = 'ليس لديك نفس المستوى الجامعي!';
                                message = 'ليس لديك نفس المستوى الجامعي!';
                                type = 'error';
                                icon = 'error';
                            }

                            if (response.data.isAddUpdate == "Your Email and Phone Number is not verified !") {
                                root.$swal({
                                    title: title,
                                    text: message,
                                    type: "error",
                                    icon: "error",
                                    showCancelButton: true,
                                    confirmButtonColor: "#DD6B55",
                                    confirmButtonText: "Resend Email?",
                                    closeOnConfirm: false,
                                    closeOnCancel: false
                                }).then(function (result) {
                                    if (result.isConfirmed) {
                                        root.SendResendEmailVerification(nationalId);
                                    }
                                });
                            }
                            else if (response.data.isAddUpdate == "You have not selected your college") {
                                root.$swal({
                                    title: 'خطأ',
                                    text: 'عزيزي المستفيد نأمل منكم تحديد الكلية التابع لها من خلال الدخول على ملفكم الشخصي في منصة الإرشاد المهني في الجامعات',
                                    type: "error",
                                    icon: "error",
                                    showCancelButton: true,
                                    confirmButtonColor: "#DD6B55",
                                    confirmButtonText: "اختر الكلية?",
                                    closeOnConfirm: false,
                                    closeOnCancel: false
                                }).then(function (result) {
                                    if (result.isConfirmed) {                                        
                                        root.studentUniversityId = response.data.id;
                                        root.show = true;
                                    }
                                });
                            }
                            else {
                                root.$swal({
                                    title: title,
                                    text: message,
                                    type: type,
                                    icon: icon,
                                    confirmButtonClass: "btn btn-danger",
                                    buttonsStyling: false
                                });
                            }

                            root.loading = false;
                            root.isEligible = response.data.isSuccess;
                            root.studenNotFound = response.data.isAddUpdate;
                            root.studentRegister.nationalId = nationalId;

                        }
                    });
                }
                else {
                    root.isEligible = false;
                }
            },
        },

        created: function () {

            if (this.$route.query.id != undefined) {
                this.ViewInfo(this.$route.query.id);
            }
        },
    };
</script>

<style scoped>
    .btn-primary {
        margin-left: 10px;
    }

    .media {
        flex-direction: column !important;
    }

    @media only screen and (max-width: 600px) {
        .register {
            max-width: 40%;
        }

        .login {
            max-width: 40%;
        }

        .btn_student_group {
            justify-content: center;
        }
    }
</style>
