<template>
    <div class="main-session-details pb-5 mt-1 ms-3">
        <div class="row">
            <div class="card py-3">
                <div class="row">

                    <div class="col-12 col-md-12 p-4 fanimate">
                        <h3 class="mx-3 text-primary">{{ $t("ContactUs.ContactUs") }} </h3>
                    </div>

                    <div class="col-12 col-md-4 col-sm-6 p-4 fanimate">
                        <h4 class="mx-1"> {{ $t('ContactUs.Name') }}</h4>
                        {{contactDetail.name}}
                    </div>
                    <div class="col-12 col-md-4 col-sm-6 p-4 fanimate">
                        <h4 class="mx-1"> {{ $t('ContactUs.Email') }}</h4>
                        {{contactDetail.email}}
                    </div>
                    <div class="col-12 col-md-4 col-sm-6 p-4 fanimate">
                        <h4 class="mx-1"> {{ $t('ContactUs.RequestType') }}</h4>
                        {{contactDetail.requestType}}
                    </div>
                    <div class="col-12 col-md-4 col-sm-6 p-4 fanimate">
                        <h4 class="mx-1"> {{ $t('ContactUs.Title') }}</h4>
                        {{contactDetail.title}}
                    </div>
                    <div class="col-md-4 my-4">

                        <h4 class="mx-1"> {{ $t('ContactUs.Inquiry') }}</h4>
                        {{contactDetail.message}}

                    </div>
                    <div class="col-md-12 my-3">
                        <a v-on:click="GoTo()" href="javascript:void(0)" class="btn btn-light">{{ $t("SessionEnded.Cancel") }}</a>
                    </div>


                </div>
            </div>
            <loading :name="loading" :active.sync="loading"
                     :can-cancel="false"
                     :is-full-page="true">
            </loading>
        </div>
    </div>
</template>

<script>

    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    export default {
        name: "ContactDetail",
        components: {
            Loading
        },
        data() {
            return {
                loading: false,
                contactDetail: '',
            };
        },



        methods: {
            GoTo: function () {
                this.$router.push("/ContactList").catch(() => { });
            },

        },
        created: function () {

            if (this.$route.query.data != undefined) {
                this.contactDetail = this.$route.query.data;
            }
        },
        mounted: function () {

        }
    };
</script>

<style scoped>
    .profile-img-main {
        max-width: 60px;
    }

    .bg_danger {
        background-color: rgb(255 107 107 / 10%);
    }
</style>
