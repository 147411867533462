import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import Vuelidate from 'vuelidate'
import json from '../public/Config.json';
import i18n from './i18n'
import cors from 'cors'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import moment from 'moment'
Vue.use(moment);
import ar from 'element-ui/lib/locale/lang/en'


import { BootstrapVue, BPagination } from 'bootstrap-vue'
Vue.use(BootstrapVue, BPagination);
import ElementUI from 'element-ui'
import { DatePicker } from 'element-ui';
import locale from 'element-ui/lib/locale'
//import locale from 'element-ui/lib/locale/lang/en'
Vue.use(ElementUI, { locale });
import 'vue-multiselect/dist/vue-multiselect.min.css';


Vue.use(VueSweetalert2);
Vue.use(Vuelidate);
Vue.use(cors);
Vue.use(DatePicker);
Vue.config.productionTip = false;

Vue.component('modal', require('./components/modalcomponent.vue').default);
Vue.component('uploadimage', require('./components/UploadImage.vue').default);
Vue.component('datepicker', require('./components/DatePicker.vue').default);
Vue.component('datetimepicker', require('./components/DateTimePicker.vue').default);

//DropDown
Vue.component('adviser-dropdown', require('./components/General/AdviserDropdown.vue').default);
Vue.component('genderdropdown', require('./components/General/GenderDropdown.vue').default);
Vue.component('citydropdown', require('./components/General/CityDropdown.vue').default);
Vue.component('sessiontypedropdown', require('./components/General/SessionTypeDropdown.vue').default);
Vue.component('sessiondurationsdropdown', require('./components/General/SessionDurationsDropdown.vue').default);
Vue.component('sessionstausesdropdown', require('./components/General/SessionStausesDropdown.vue').default);
Vue.component('regionsdropdown', require('./components/General/RegionsDropdown.vue').default);
Vue.component('universitydropdown', require('./components/General/UniversityDropdown.vue').default);
Vue.component('studentcategorydropdown', require('./components/General/StudentCategoryDropdown.vue').default);
Vue.component('qualificationdropdown', require('./components/General/QualificationDropdown.vue').default);
Vue.component('qualificationdropdown', require('./components/General/QualificationDropdown.vue').default);
Vue.component('universtyleveldropdown', require('./components/General/UniverstyLevelDropdown.vue').default);
Vue.component('universtyspecilizationdropdown', require('./components/General/UniverstySpecilizationDropdown.vue').default);
Vue.component('typesofsessiondropdown', require('./components/General/TypesOfSessionDropdown.vue').default);
Vue.component('subject-of-session-dropdown', require('./components/General/SubjectOfSessionDropdown.vue').default);
Vue.component('multiuniverstydropdown', require('./components/General/MultiUniversityDropdown.vue').default);
Vue.component('sessionlocationdropdown', require('./components/General/SessionLocationDropdown.vue').default);
Vue.component('universitymultiselectdropdown', require('./components/General/UniversityMultiSelectDropdown.vue').default);

Vue.component('studentDropdown', require('./components/General/StudentDropdown.vue').default);
Vue.component('adviserMultiSelectDropdown', require('./components/General/AdviserMultiSelectDropdown.vue').default);

Vue.component('universtylevelmultiselectdropdown', require('./components/General/UniverstyLevelMultiSelectDropdown.vue').default);
Vue.component('advisorslotdropdown', require('./components/General/AdvisorSlotDropdown.vue').default);
Vue.component('reviewer-feedback-session', require('./components/Dashboard/ReviewerFeedBackSession.vue').default);

Vue.component('cancel-session-model', require('./components/Sessions/CancelSessionModel.vue').default);
Vue.component('attachment-list', require('./components/Attachment/AttachmentList.vue').default);
Vue.component('add-attachment-model', require('./components/Attachment/AddAttachmentModel.vue').default);
Vue.component('add-studentDetails-model', require('./components/ImportStudentDetails/AddStudentDetailsModel.vue').default);

Vue.component('sessioncodedropdown', require('./components/General/SessionCodeDropdown.vue').default);
Vue.component('session-student-detailPdf', require('./components/Report/SessionStudentDetailPdf.vue').default);
Vue.component('session-detail-pdf-print', require('./components/Report/SessionDetailPdfPrint.vue').default);
Vue.component('individualsessionqrprint', require('./components/Report/IndividualSessionQrPrint.vue').default);

Vue.component('view-survey-question-model', require('./components/SurveyQuestions/ViewSurveyQuestionModel.vue').default);
Vue.component('survey-schedule-model', require('./components/Report/SurveyScheduleModel.vue').default);
Vue.component('week-dropdown', require('./components/General/WeekDropdown.vue').default);
Vue.component('college-dropdown', require('./components/General/CollegeDropdown.vue').default);
Vue.component('college-model', require('./components/WorkshopList/CollegeModel.vue').default);
//Models
//Vue.component('session-type', require('./components/SessionType/AddSessionType.vue').default);
//Vue.component('session-durations', require('./components/SessionDurations/AddSessionDurations.vue').default);



Vue.prototype.$https = axios;

axios.defaults.baseURL = json.ServerIP;
Vue.prototype.$ServerIp = json.ServerIP;
Vue.prototype.$ApiServerIp = json.ApiServerIP;
Vue.prototype.$locale = locale;

locale.use(ar);

router.beforeEach((to, from, next) => { 
  
    i18n.locale = 'ar';
  next();
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
