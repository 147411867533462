<template>
    <div class="page-main">
        <div class="card  p-5 mb-5 mt-3 ">
            <div class="backtomain mb-3">
                <div class="logo mr-3"></div>
                <br />
            </div>


            <div class="signup_form_title mb-5">
                <h1>
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">
                            {{ $t("CreateGroupSessionDetails.Heading") }}
                        </font>
                    </font>
                </h1>
            </div>

            <div class="row">
                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.Name") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">{{sessionDetails.userName}}</p>
                </div>
                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.SessionType") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">{{sessionDetails.sessionTypeName}}</p>
                </div>

                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.SessionStartDate") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">{{sessionDetails.sessionStartTime}}</p>
                </div>

                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.SessionDuration") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">{{sessionDetails.sessionDurationName}}</p>
                </div>

                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.AvailableAppointments") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">{{sessionDetails.sessionEndTime}}</p>
                </div>

                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.AffiliateUniversities") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">
                        <span v-for="(item, index) in sessionDetails.universityName" :key="index">
                            {{index>0? ',' : ''}} ({{item.name}})
                        </span>
                    </p>
                </div>

                <div v-if="sessionDetails.workshopType==0 || sessionDetails.workshopType==2" class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.MeetingPlace") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">{{sessionDetails.sessionLocationName}}</p>
                </div>

                <div v-if="sessionDetails.workshopType==1" class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.RemoteLink") }}
                    </h4>
                    <a :href="sessionDetails.remoteLink" class="btn btn-light" target="_blank"><i class="fa fa-link" aria-hidden="true"></i></a>
                    <!--<p class="text-muted mb-3 mx-1">{{sessionDetails.remoteLink}}</p>-->
                </div>

                <div v-if="sessionDetails.workshopType==2" class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.StudentLimit") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">{{sessionDetails.studentLimit}}</p>
                </div>

                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.Undergraduatelevel") }}
                    </h4>

                    <p class="text-muted mb-3 mx-1" v-for="( universityLevelName) in sessionDetails.universityLevelIdName" :key=" universityLevelName.id">{{universityLevelName}}</p>
                </div>

            </div>

            <div class="row mt-3 btn_student_group pb-5 ">
                <div class="col-md-12">
                    <a class="btn btn-primary btn-pill pt-2 pb-2" v-on:click="GoTo()" href="javascript:void(0)">{{ $t("CreateGroupSessionDetails.Cancel") }}</a>
                </div>
            </div>








        </div>
    </div>
</template>
<script>


    export default {
        data() {
            return {
                loading: false,
                role: '',
                sessionDetails: {

                },
            };
        },


        methods: {
            GoTo: function () {
                if (this.role == "Super Admin") {
                    this.$router.push('/SessionsReport');
                }
                else {
                    this.$router.push('/AdvisorDashboard');
                }
            },
        },
        created: function () {

            if (this.$route.query.data != undefined) {
                this.sessionDetails = this.$route.query.data;
            }
            this.role = localStorage.getItem('RoleName');
        },
        mounted: function () {

        }
    };
</script>
<style scoped>
    .btn-primary {
        margin-left: 10px;
    }

    .media {
        flex-direction: column !important;
    }

    @media only screen and (max-width: 600px) {
        .register {
            max-width: 40%;
        }

        .login {
            max-width: 40%;
        }

        .btn_student_group {
            justify-content: center;
        }
    }
</style>
