<template>
    <div class="main-region">
        <div class="page-header">
            <div>
                <h1 class="page-title"> {{ $t('ContactUs.ContactUs') }}</h1>
            </div>
            <div class="ms-auto pageheader-btn">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t('ContactUs.ContactUs') }}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ $t('Regions.Dashboard') }}</li>
                </ol>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-12">
                <div class="card product-sales-main">
                    <div class="card-header border-bottom ac">

                        <div class="form-group">
                            <div class="input-group">
                                <input v-model="search" class="form-control fc" id="basic-addon6" :placeholder=" $t('Regions.Search')" type="text">

                            </div>
                        </div>
                        <div class="d-flex">
                            <!--<multiselect @input="filterData" v-model="value" :options="options" :multiple="false" track-by="name" :clear-on-select="false" :show-labels="false" label="name">

                            </multiselect>-->
                            <div class="btn-group mt-2 mb-2">
                                <button type="button" class="btn btn-primary btn-pill dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"> {{value}} <span class="caret"></span> </button>
                                <ul class="dropdown-menu" role="menu" style="">
                                    <li><a v-on:click="filterData('مشاكل في عملية التسجيل')" href="javascript:void(0)">مشاكل في عملية التسجيل</a></li>
                                    <li><a v-on:click="filterData('البلاغات والاقتراحات')" href="javascript:void(0)">البلاغات والاقتراحات</a></li>
                                    <li><a v-on:click="filterData('مشاكل تقنية')" href="javascript:void(0)">مشاكل تقنية</a></li>
                                    <li><a v-on:click="filterData('مشاكل اخرى')" href="javascript:void(0)">مشاكل اخرى</a></li>
                                </ul>
                            </div>
                            <div class="input-group ms-2">

                                <a v-on:click="DowmloadCSV" href="javascript:void(0)" class="btn btn-pill slide-item py-2">{{ $t('Statistics.DownloadCsv') }}</a>

                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table border text-nowrap text-md-nowrap table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{{ $t('ContactUs.Name') }}</th>
                                        <th>{{ $t('ContactUs.Email') }}</th>
                                        <th>{{ $t('ContactUs.RequestType') }}</th>
                                        <th>{{ $t('ContactUs.Title') }}</th>
                                        <th>{{ $t('Regions.Action') }}</th>
                                        <th>تفاصيل</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(contact, index) in contactList" :key="index">
                                        <td v-if="currentPage === 1">
                                            {{index+1}}
                                        </td>
                                        <td v-else>
                                            {{((currentPage*10)-10) +(index+1)}}
                                        </td>
                                        <td>{{contact.name}}</td>
                                        <td>{{contact.email}}</td>
                                        <td>{{contact.requestType}}</td>
                                        <td>{{contact.title}}</td>

                                        <td>
                                            <a v-if="contact.attachment != null && contact.attachment != '' && contact.attachment != undefined" v-on:click="DownloadAttachment(contact.attachment)" class="btn btn-primary btn-sm m-1" href="javascript:void(0)"><i class="fa fa-download" aria-hidden="true"></i></a>
                                        </td>
                                        <td>
                                            <a v-on:click="ContactDetails(contact.id)" href="javascript:void(0)"><img src="/assets/images/Details.svg" /></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="row">
                            <div class="col-md-6 text-right">
                                <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            </div>
                            <div class="col-md-6">
                                <div class="overflow-auto" v-on:click="getPage()" style="float:left">
                                    <b-pagination pills size="md" v-model="currentPage"
                                                  :total-rows="rowCount"
                                                  :per-page="10"
                                                  :first-text="this.$t('Tabel.First')"
                                                  :prev-text="this.$t('Tabel.Previous')"
                                                  :next-text="this.$t('Tabel.Next')"
                                                  :last-text="this.$t('Tabel.Last')"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'ContactList',
        data: function () {
            return {
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                pageSize: '',
                loading: false,
                contactList: [],
                role: '',
                show: false,
                value: ''
            }
        },
        watch: {
            search: function (val) {
                this.GetData(val, 1, this.value);
            }
        },
        methods: {
            GoTo: function (link) {
                this.$router.push(link);
            },

            getPageSize: function (isDesc) {
                this.isDesc = isDesc;
                this.getPage();
            },

            getPage: function () {
                this.GetData(this.search, this.currentPage, this.value);
            },
            filterData: function (value) {
                this.value = value;
                this.GetData(this.search, 1, value);
            },
            GetData: function (search, currentPage, value) {
                var root = this;
                var url = '/Setup/ContactList?searchTerm=' + search + '&pageNumber=' + currentPage + '&requestType=' + value;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.contactList = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        type: 'error',
                        title: root.$t('Notification.Error'),
                        text: root.$t('Notification.SomethingWrong'),
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                }).finally(() => root.loading = false);
            },
            DownloadAttachment(path) {

                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var ext = path.split('.')[1];
                root.$https.get('/Setup/ContactList?filePath=' + path, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'file.' + ext);
                        document.body.appendChild(link);
                        link.click();
                    });
            },
            ContactDetails: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Setup/GetContactDetails?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$router.push({
                            path: '/ContactDetail',
                            query: { data: response.data }
                        })
                    }
                });

            },

            DowmloadCSV: function () {
                var root = this;
                root.loading = true;
                var token = '';

                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/account/ContactListCsv', { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Contact List.csv');
                        document.body.appendChild(link);
                        link.click();
                        root.loading = false;
                    });
            }
        },

        created: function () {
            this.role = localStorage.getItem('RoleName');
        },
        mounted: function () {
            this.GetData(this.search, 1, this.value);
        },
    }
</script>
<style scoped>
    .card-header {
        display: flex;
        justify-content: space-between;
    }

    .btn-primary:hover {
        color: white !important;
    }

    .slide-item {
        border: 1px solid rgba(0, 144, 0, 0.9);
    }

        .slide-item:hover {
            background-color: rgba(0, 144, 0, 0.9);
            color: white !important;
        }

        .slide-item:focus {
            color: white !important;
        }
</style>


