<template>
    <div class="page">
        <div>
            <!-- CONTAINER OPEN -->
            <div class="col mx-auto  text-center">
                <a href="javascript:void(0)" v-on:click="GoToHome">
                    <img src="/assets/images/subol_logo.png" class="img-fluid mb-5" alt="subol_logo" style="max-width:200px;">
                </a>
            </div>
            <div class="col-10 col-sm-10 col-md-8 col-lg-4 mx-auto   " style="max-width:368px;">
                <div class="row">
                    <div class=" card  mx-auto">
                        <div class="  card-body" style="padding:1rem !important">
                            <div class="text-center">
                                <span class="login100-form-title">
                                    {{$t('ForgotPassword.Heading')}}
                                </span>
                                <p class="text-muted">  {{$t('ForgotPassword.EnterYourEmail')}} </p>
                            </div>
                            <div class="py-3" id="forgot">
                                <div class="form-group">
                                    <input class="form-control" v-model="email" id="eMail" :placeholder="$t('ForgotPassword.EnterYourEmail')" type="text">
                                </div>
                                <div class="submit">
                                    <button v-bind:disabled="$v.$invalid" v-on:click="ForgotPassword()" class="btn btn-primary btn-block">{{$t('ForgotPassword.Save')}}</button>
                                </div>
                                <div class="text-center mt-4">
                                    <p class="text-dark mb-0">{{$t('ForgotPassword.ForgetIt')}}<a v-on:click="close()" class="text-primary ms-1" href="javascript:void(0)">{{$t('ForgotPassword.Cancel')}}</a></p>
                                </div>
                            </div>

                        </div>
                        <div class="card-footer">
                            <div class="d-flex justify-content-center my-3">
                                <a href="javascript:void(0)" class="social-login  text-center me-4">
                                    <i class="fa fa-google"></i>
                                </a>
                                <a href="javascript:void(0)" class="social-login  text-center me-4">
                                    <i class="fa fa-facebook"></i>
                                </a>
                                <a href="javascript:void(0)" class="social-login  text-center">
                                    <i class="fa fa-twitter"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- CONTAINER CLOSED -->
        </div>

        <loading :name="loading" :active.sync="loading"
                 :can-cancel="false"
                 :is-full-page="true"></loading>
    </div>
</template>
<script>
    import { required } from 'vuelidate/lib/validators';
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";

    export default {
        props: ['path', 'show'],
        components: {
            Loading
        },
        data: function () {
            return {
                loading: false,
                email: '',
            }
        },
        methods: {
            GoToHome: function () {
                this.$router.push('/')
            },

            close: function () {
                this.$router.push('/Login')
            },

            ForgotPassword: function () {
                var root = this;
                this.loading = true;

                root.$https.get('/account/ForgotPassword?email=' + this.email)
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.isSuccess) {
                            root.loading = false;
                            root.$swal({
                                title: 'تم الإرسال!',
                                text: 'إرسال بريد إلكتروني إلى عنوان بريدك الإلكتروني.',
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    root.close();
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

        },
        validations: {
            email:
            {
                required                
            },
        },
        mounted: function () {

        },

    }
</script>
