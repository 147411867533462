<template>
    <div class="row">
        <div class="col-12 col-sm-12">
            <div class="card product-sales-main">
                <div class="card-header border-bottom d-flex align-items-baseline">
                    <h3 class="card-title mb-0">{{$t('AdvisorDashboard.PendingSessionSchedule')}}</h3>
                    <div class="form-group">
                        <div class="input-group">
                            <input v-model="search" class="form-control fc" id="basic-addon6" :placeholder=" $t('Regions.Search')" type="text">
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table border text-nowrap text-md-nowrap table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>رقم للتذكرة</th>
                                    <!--<th>{{$t('AdvisorDashboard.University')}}</th>-->
                                    <th>{{$t('AdvisorDashboard.SessionType')}}</th>
                                    <th>{{$t('AdvisorDashboard.SessionLocation')}}</th>
                                    <th>{{$t('AdvisorDashboard.TimeAndDate')}}</th>
                                    <th>{{$t('AdvisorDashboard.Status')}}</th>
                                    <th>{{$t('AdvisorDashboard.WorkshopType')}}</th>
                                    <th></th>
                                    <th>    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="( activePendingSession, index) in activePendingSessionList" :key=" activePendingSession.id">
                                    <td v-if="currentPage === 1">
                                        {{index+1}}
                                    </td>
                                    <td v-else>
                                        {{((currentPage*10)-10) +(index+1)}}
                                    </td>
                                    <td>{{ activePendingSession.code}}</td>
                                    <!--<td>
                                        <span v-for="(item, index) in activePendingSession.universityIdName" :key="index">
                                            ({{item.name}}),
                                        </span>
                                    </td>-->
                                    <td>{{ activePendingSession.sessionTypeName}}</td>
                                    <td>
                                        <span v-if="activePendingSession.sessionLocationName!=null">
                                            {{activePendingSession.sessionLocationName}}
                                        </span>
                                        <span v-if="activePendingSession.sessionLocationName==null">
                                            ---
                                        </span>
                                    </td>
                                    <td>{{ activePendingSession.sessionStartTime}}</td>

                                    <td>
                                        <span v-if="activePendingSession.statusId==1">
                                            {{$t('AdvisorDashboard.Pendingsessions')}}
                                        </span>
                                        <span v-if="activePendingSession.statusId==2">
                                            {{$t('AdvisorDashboard.ScheduledAppointments')}}
                                        </span>

                                    </td>
                                    <td>
                                        <span v-if="activePendingSession.subjectOfSession==1"> {{$t('AdvisorDashboard.IndividualSession')}}</span>
                                        <span v-if="activePendingSession.subjectOfSession==2"> {{$t('AdvisorDashboard.GroupSession')}}</span>
                                        <span v-if="activePendingSession.subjectOfSession==3"> {{$t('AdvisorDashboard.WorkShop')}}</span>

                                    </td>
                                    <td>
                                        <div class="btn-list">
                                            <!--<a v-if="activePendingSession.isQuick" href="javascript:void(0)" v-on:click="Pdf(activePendingSession)" class="btn btn-primary btn-sm"><i class="fa fa-print"></i></a>-->
                                            <a v-if="activePendingSession.subjectOfSession!=1" href="javascript:void(0)" v-on:click="Pdf(activePendingSession)" class="btn btn-primary btn-sm"><i class="fa fa-print"></i></a>
                                            <a href="javascript:void(0)" class="btn btn-primary btn-sm" v-on:click="ActivateDetails(activePendingSession.id, activePendingSession.subjectOfSession,activePendingSession.statusId)"><i class="fa fa-file-text-o" aria-hidden="true"></i></a> 
                                            <a href="javascript:void(0)" class="btn btn-primary btn-sm" v-on:click="EditInfo(activePendingSession.id, activePendingSession.subjectOfSession, activePendingSession.isQuick)"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></a> 
                                            <a href="javascript:void(0)" v-on:click="Delete(activePendingSession.id)" class="btn btn-danger btn-sm"><i class="fa fa-trash" aria-hidden="true"></i></a>
                                        </div>
                                        
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                    <div class="row">
                        <div class="col-md-6 text-right">
                            <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                            <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        </div>
                        <div class="col-md-6">
                            <div class="overflow-auto" v-on:click="getPage()" style="float:left">
                                <b-pagination pills size="md" v-model="currentPage"
                                              :total-rows="rowCount"
                                              :per-page="10"
                                              :first-text="this.$t('Tabel.First')"
                                              :prev-text="this.$t('Tabel.Previous')"
                                              :next-text="this.$t('Tabel.Next')"
                                              :last-text="this.$t('Tabel.Last')"></b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <cancel-session-model :id="id" :show="show" @close="getPage()" />
        <session-detail-pdf-print :sessiondetail="sessiondetail" v-if="pdfShow" @close="pdfShow=false" />
    </div>
</template>

<script>
    export default {
        name: 'AdvisorActivePendingSession',
        data: function () {
            return {
                render: 0,
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                pageSize: '',
                loading: false,
                activePendingSessionList: [],

                pdfShow: false,
                sessiondetail: '',

                show: false,
                id: '',
            }
        },
        watch: {
            search: function (val) {
                this.GetData(val, 1);
            },
        },
        methods: {

            GoTo: function (link) {
                this.$router.push(link);
            },


            getPageSize: function (isDesc) {
                this.isDesc = isDesc;
                this.getPage();
            },

            getPage: function () {
                this.GetData(this.search, this.currentPage);
            },

            GetData: function (search, currentPage) {
                var url = '/Home/AdviserDashboardSession?searchTerm=' + search + '&pageNumber=' + currentPage;

                var root = this;
                this.show = false;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        root.activePendingSessionList = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;

                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        type: 'error',
                        title: root.$t('Notification.Error'),
                        text: root.$t('Notification.SomethingWrong'),
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                }).finally(() => root.loading = false);
            },


            Details: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Sessions/GetSessionDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$router.push({
                            path: '/SessionDetails',
                            query: { data: response.data }
                        })
                    }
                });
            },

            ActivateDetails: function (id, subjectOfSession, statusId) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                if (subjectOfSession == 1) {
                    root.$https.get('/Sessions/AdviserSendEmail?Id=' + id + '&statusId=' + statusId, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                        if (response.data != null) {
                            //root.$router.push({
                            //    path: '/SessionActivate',
                            //    query: { data: response.data }
                            //})
                        }
                    });
                }

                root.$https.get('/Sessions/GetSessionActivateDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$router.push({
                            path: '/SessionActivate',
                            query: { data: response.data }
                        })
                    }
                });




            },

            Pdf: function (data) {
                this.pdfShow = true;
                this.sessiondetail = data;

            },

            EditInfo: function (id, subjectOfSession, isQuick) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                //if (subjectOfSession == 1) {
                //    root.$https.get('/Sessions/GetIndividualSessionDetailForEdit?id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                //        if (response.data != null) {
                //            root.$router.push({
                //                path: '/IndividualStudentSession',
                //                query: { data: response.data }
                //            })
                //        }
                //    });
                //}
                if (subjectOfSession == 2) {
                    root.$https.get('/Sessions/GetGroupSessionDetailForEdit?id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                        if (response.data != null) {
                            root.$router.push({
                                path: '/CreateGroupSession',
                                query: { data: response.data }
                            })
                        }
                    });
                }
                if (subjectOfSession == 3) {
                    root.$https.get('/Sessions/GetWorkShopDetailForEdit?id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                        if (response.data != null) {
                            root.$router.push({
                                path: isQuick ? '/CreateQuickWorkshop' : '/CreateWorkshop',
                                query: { data: response.data }
                            })
                        }
                    });
                }
            },


            Delete: function (id) {
                this.id = id;
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Sessions/GetSessionActivateDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {

                        if (response.data.sessionStudents.length > 0) {
                            root.show = true;
                        }
                        else {
                            root.$swal({
                                title: 'Are you sure?',
                                text: "You won't be able to revert this!",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonClass: "btn btn-success",
                                cancelButtonClass: "btn btn-danger",
                                confirmButtonText: 'Yes, delete it!',
                                cancelButtonText: 'No, cancel!',
                                reverseButtons: true
                            }).then(function (result) {
                                if (result.isConfirmed) {
                                    var token = '';
                                    if (root.$session.exists()) {
                                        token = localStorage.getItem('token');
                                    }
                                    root.$https.get('/Sessions/DeletePermanentSession?id=' + id, { headers: { "Authorization": `Bearer ${token}` } })
                                        .then(function (response) {
                                            if (response.data != null) {
                                                root.$swal.fire({
                                                    icon: 'success',
                                                    title: 'Deleted!',
                                                    text: 'Session Has Been Deleted',
                                                    type: 'success',
                                                    confirmButtonClass: "btn btn-success",
                                                    buttonsStyling: false,
                                                });
                                                window.location.href = "/AdvisorDashboard";


                                            } else {
                                                root.$swal.fire({
                                                    icon: 'error',
                                                    title: 'Error!',
                                                    text: 'Error',
                                                    type: 'success',
                                                    cancelButtonClass: "btn btn-danger",
                                                    buttonsStyling: false,
                                                });
                                            }
                                        })
                                }
                                else {
                                    root.$swal('Cancelled', 'Your Session is still intact', 'info');
                                }
                            });
                        }
                    }
                });


            },

        },

        created: function () {


        },
        mounted: function () {
            this.GetData(this.search, 1);
        },
    }
</script>
<style scoped>
    .card-header {
        display: flex;
        justify-content: space-between;
    }

    .banner {
        background-image: url(/assets/images/landing/123.jpg);
        background-repeat: no-repeat;
        background-size: contain;
    }

        .banner h1 {
            color: #009000;
        }

    .card-header h4 {
        color: #009000;
    }

    .card-body p {
        color: #009000;
    }
</style>