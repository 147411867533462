<template>
    <div>
        <multiselect v-model="DisplayValue" :options="options" :multiple="false" :searchable="false" track-by="name" :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true" v-bind:placeholder=" $t('Dropdown.SelectOption')">
            <p slot="noResult" class="text-danger"> {{ $t('Dropdown.SubjectOfSession') }}</p>
            <p slot="noOptions" class="text-danger"></p>
        </multiselect>
    </div>
</template>
<script>
import Multiselect from "vue-multiselect";
export default {
  name: "subjectOfSession",
  props: ["values"],

        components: {
            Multiselect,
            
        },
        data: function () {
            return {
                options: [],
                value: '',
                render: 0
            }
        },
        methods: {
            getData: function () {
                var root = this;

                root.options.push({
                    id: 7,
                    name: 'أسبوع 1'
                });
                root.options.push({
                    id: 14,
                    name: 'أسبوع 2'
                });
                root.options.push({
                    id: 21,
                    name: 'أسبوع 3'
                });
                root.options.push({
                    id: 30,
                    name: 'شهر 1'
                });
                root.options.push({
                    id: 60,
                    name: 'شهر 2'
                });
                root.options.push({
                    id: 90,
                    name: 'شهر 3'
                });


                root.value = root.options.find(function (x) {
                    return x.id == root.values;
                });
            },
        },
        computed: {
            DisplayValue: {
                get: function () {
                    if (this.value != "" || this.value != undefined) {
                        return this.value;
                    }
                    return this.values;
                },
                set: function (value) {
                    this.value = value;
                    this.$emit('input', value == null ? null : value.id);
                }
            }
        },
        mounted: function () {
            this.getData();
        },
    }
</script>