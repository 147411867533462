<template>

    <div class="page">
        <div>
            <!-- CONTAINER OPEN -->
            <div class="col col-login mx-auto text-center">
                <a class="text-center" href="javascript:void(0)" v-on:click="GoToHome">
                    <img src="/assets/images/subol_logo.png" class="img-fluid mb-3" alt="subol_logo" style="max-width:206px;">
                </a>
            </div>
            <div class="container-login100">
                <div class="wrap-login100 p-0">
                    <div class="card-body">
                        <div class="login100-form ">
                            <span class="login100-form-title">
                                {{ $t('Login.Heading') }}
                            </span>
                            <div class="wrap-input100 validate-input mb-5">
                                <div class="form-group">
                                    <input class="form-control" v-model="login.email" @keyup.enter="userlogin" :placeholder="$t('Login.Email')" type="email">
                                </div>
                            </div>

                            <div class="wrap-input100 validate-input">
                                <div class="form-group">
                                    <div class="input-group">
                                        <input v-bind:type="showPassword==false? 'password' : 'text'" v-model="login.password" @keyup.enter="userlogin" class="form-control" :placeholder="$t('Login.Password')">
                                        <a class="input-group-text btn btn-primary text-white" href="javascript:void(0)" v-on:click="showPasswords()">
                                            <i class="fe fe-eye text-white" v-if="!showPassword"></i>
                                            <i class="fe fe-eye-off text-white" v-if="showPassword"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="text-end pt-1">
                                <p class="mb-0"><a href="javascript:void(0)" v-on:click="GoForgotPassword()" class="text-primary ms-1">{{ $t('Login.ForgotPassword') }}</a></p>
                            </div>
                            <div class="container-login100-form-btn">
                                <button v-on:click="userlogin()" class="login100-form-btn btn-primary disabled" v-bind:class="loading ? 'btn-loading' : ''">
                                    {{ $t('Login.LoginButton') }}
                                </button>
                            </div>
                            <div class="text-center pt-3">
                                <p class="text-dark mb-0">   {{ $t('Login.NotMember') }}<a v-on:click="GoToSignup()" href="javascript:void(0)" class="text-primary ms-1">{{ $t('Login.CreateAccount') }}</a></p>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <div class="d-flex justify-content-center my-3">
                            <a href="javascript:void(0)" class="social-login  text-center me-4">
                                <i class="fa fa-google"></i>
                            </a>
                            <a href="javascript:void(0)" class="social-login  text-center me-4">
                                <i class="fa fa-facebook"></i>
                            </a>
                            <a href="javascript:void(0)" class="social-login  text-center">
                                <i class="fa fa-twitter"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- CONTAINER CLOSED -->
        </div>
        <college-model :universityid="studentUniversityId"
                       :nationalid="nationalId"
                       :show="showModel"
                       v-if="showModel"
                       @close="showModel=false" />
    </div>

</template>
<script>
    import { required } from 'vuelidate/lib/validators';

    export default {
        data() {
            return {
                showModel: false,
                studentUniversityId: '',
                nationalId: '',
                render: 0,
                showPassword: false,
                loading: false,
                show: false,
                login: {
                    email: '',
                    password: '',
                    rememberMe: true
                },
                error: '',
                customError: ''
            }
        },
        validations: {
            login:
            {
                email:
                {
                    required
                },
                password:
                {
                    required
                },
            }
        },

        methods: {
            showPasswords: function () {
                this.showPassword = this.showPassword == true ? false : true;
            },

            GoToHome: function () {
                this.$router.push('/')
            },

            GoForgotPassword: function () {
                this.$router.push('/ForgotPassword')
            },

            GoToPrivacy: function () {
                this.$router.push('/TermsAndCondition')
            },

            GoToSignup: function () {
                this.$router.push('/Register')
            },

            userlogin: function () {

                var root = this;
                this.loading = true;
                
                var url = '/account/login';
                this.$https.post(url, this.login).then(function (response) {
                    if (response.data != null) {
                        if (response.data.companyId == "00000000-0000-0000-0000-000000000000") {
                            if (response.data.errorMessage == 'Your Email and Phone Number is not verified !') {
                                root.$swal({
                                    title: "Oops...",
                                    text: response.data.errorMessage,
                                    type: "error",
                                    icon: "error",
                                    showCancelButton: true,
                                    confirmButtonColor: "#DD6B55",
                                    confirmButtonText: "Resend Email?",
                                    closeOnConfirm: false,
                                    closeOnCancel: false
                                }).then(function (result) {
                                    if (result.isConfirmed) {
                                        root.SendResendEmailVerification(root.login.email);
                                    }
                                });

                                root.loading = false;
                            }
                            else if (response.data.errorMessage == "You have not selected your college") {
                                root.loading = false;
                                root.$swal({
                                    title: 'خطأ',
                                    text: 'عزيزي المستفيد نأمل منكم تحديد الكلية التابع لها من خلال الدخول على ملفكم الشخصي في منصة الإرشاد المهني في الجامعات',
                                    type: "error",
                                    icon: "error",
                                    showCancelButton: true,
                                    confirmButtonColor: "#DD6B55",
                                    confirmButtonText: "اختر الكلية?",
                                    closeOnConfirm: false,
                                    closeOnCancel: false
                                }).then(function (result) {
                                    if (result.isConfirmed) {                                        
                                        root.studentUniversityId = response.data.universityId;
                                        root.nationalId = response.data.nationalId;
                                        root.showModel = true;
                                    }
                                });
                            }
                            else {
                                root.$swal.fire(
                                    {
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: response.data.errorMessage,
                                    });
                                root.loading = false;
                            }

                        }
                        else {
                            root.$session.start();

                            root.$swal.fire(
                                {
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 1500,
                                    timerProgressBar: true,
                                    icon: 'success',
                                    title: "<h5 style='color: #000000;'>Logged in Successfully</h5>",
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', root.$swal.stopTimer)
                                        toast.addEventListener('mouseleave', root.$swal.resumeTimer)
                                    }
                                });
                            root.loading = false;

                            localStorage.setItem('token', response.data.token);
                            localStorage.setItem('UserName', response.data.userName);
                            localStorage.setItem('UserEmail', response.data.userEmail);
                            localStorage.setItem('RoleName', response.data.roleName);
                            localStorage.setItem('UserID', response.data.userId);
                            localStorage.setItem('FullName', response.data.fullName);
                            localStorage.setItem('FirstName', response.data.firstName);
                            localStorage.setItem('LastName', response.data.lastName);
                            localStorage.setItem('ProfilePicture', response.data.profilePicture);
                            localStorage.setItem('RegistrationDate', response.data.registrationDate);
                            localStorage.setItem('UniversityId', response.data.UniversityId);
                            localStorage.setItem('IsQuickWorkShop', response.data.isQuickWorkShop);

                            /*root.$router.push('/Dashboard')*/
                            if (response.data.roleName == 'Student') {
                                window.location.href = "/StudentDashboard";
                            }
                            if (response.data.roleName == 'Adviser') {
                                window.location.href = "/AdvisorDashboard";
                            }
                            if (response.data.roleName == 'Reviewer') {
                                window.location.href = "/Dashboard";
                            }
                            if (response.data.roleName == 'Super Admin') {
                                window.location.href = "/Dashboard";
                            }


                        }
                    }

                }).catch(error => {

                    console.log(error)
                    root.$swal.fire(
                        {
                            icon: 'error',
                            title: 'Oops...',
                            text: error,
                        });
                    root.errored = true
                })
                    .finally(() => root.loading = false)
            },

            SendResendEmailVerification: function (email) {
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                this.$https.get('/account/ResendSendEmailVerification?email=' + email, { headers: { "Authorization": `Bearer ${token}` } });
            },
        },
        beforeCreate: function () {

            if (this.$session.exists()) {
                if (localStorage.getItem('RoleName') == 'Student') {
                    window.location.href = "/StudentDashboard";
                }
                if (localStorage.getItem('RoleName') == 'Adviser') {
                    window.location.href = "/AdvisorDashboard";
                }
                if (localStorage.getItem('RoleName') == 'Reviewer') {
                    window.location.href = "/ReviewerDashboard";
                }
                if (localStorage.getItem('RoleName') == 'Super Admin') {
                    window.location.href = "/Dashboard";
                }
            }
        },

    }
</script>
<style scoped>
    .sym {
        display: flex;
        justify-content: space-between
    }
</style>
