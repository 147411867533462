<template>
    <div class="page-main">
        <div class="card  p-5 mb-5 mt-3">
            <div class="backtomain mb-3">
                <div class="logo mr-3"></div>
                <br />
            </div>
            <div class="signup_form_title mb-5">
                <h1>
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;">
                            {{ $t("CreateGroupSessionDetails.Heading") }}
                        </font>
                    </font>
                </h1>
            </div>

            <div class="row">
                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.SessionType") }}
                    </h4>
                    <p v-if="!createGroupSessionDetails.hideSessionType" class="text-muted mb-3 mx-1">{{createGroupSessionDetails.sessionTypeName}}</p>
                    <p v-if="createGroupSessionDetails.hideSessionType" class="text-muted mb-3 mx-1">---</p>
                </div>

                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.SessionStartDate") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">{{createGroupSessionDetails.sessionStartTime}}</p>
                </div>

                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.SessionDuration") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">{{createGroupSessionDetails.sessionDurationName}}</p>
                </div>

                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.AvailableAppointments") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">{{createGroupSessionDetails.sessionEndTime}}</p>
                </div>

                <div class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.AffiliateUniversities") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">
                        <span v-for="(item, index) in createGroupSessionDetails.universityName" :key="index">
                            {{index>0? ',' : ''}} ({{item.name}})
                        </span>
                        
                    </p>
                </div>

                <div v-if="createGroupSessionDetails.workshopType==0 || createGroupSessionDetails.workshopType==2" class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.MeetingPlace") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">{{createGroupSessionDetails.sessionLocationName}}</p>
                </div>

                <div v-if="createGroupSessionDetails.workshopType==1" class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateWorkshop.RemoteLink") }}
                    </h4>
                    <a :href="createGroupSessionDetails.remoteLink"><i class="fa fa-link" aria-hidden="true"></i></a>
                </div>

                <div v-if="createGroupSessionDetails.isCancel" class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        سبب
                    </h4>
                    <p class="text-muted mb-3 mx-1">{{createGroupSessionDetails.cancelReason}}</p>
                </div>

                <div v-if="createGroupSessionDetails.statusId == 3" class="col-12 col-md-4 p-4 fanimate">
                    <h4 class="mx-1">
                        {{ $t("CreateGroupSessionDetails.Report") }}
                    </h4>
                    <p class="text-muted mb-3 mx-1">{{createGroupSessionDetails.report}}</p>
                </div>

            </div>

            <div class="modal-footer ">
                <button type="button" class="btn btn-primary btn-round" v-if="isShow" v-on:click="JoinNow()">{{ $t("CreateWorkshop.Join") }}</button>
                <button type="button" class="btn btn-danger btn-round" v-on:click="GoTo()" href="javascript:void(0)">{{ $t("CreateWorkshop.Cancel") }}</button>
            </div>

            <loading :name="loading" :active.sync="loading"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
        </div>
    </div>
</template>
<script>
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    export default {
        components: {
            Loading
        },
        data() {
            return {
                loading: false,
                isShow: true,
                createGroupSessionDetails: '',
            };
        },

        methods: {
            GoTo: function () {
                this.$router.push("/StudentDashboard");
            },


            JoinNow: function () {
                var root = this;
                this.loading = true;

                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.get('/Sessions/JoinStudentSession?sessionId=' + this.createGroupSessionDetails.id + '&studentId=' + localStorage.getItem('UserID'), { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.$swal({
                                title: root.$t('Notification.Joined'),
                                text: root.$t('Notification.SessionJoined'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            });
                            root.loading = false;
                            root.GoTo();
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },
        },
        created: function () {
            if (this.$route.query.data != undefined) {
                this.createGroupSessionDetails = this.$route.query.data;
                if (this.$route.query.isStudent == 'true') {
                    this.isShow = false;
                }
            }
        },
    };
</script>

<style scoped>
    .btn-primary {
        margin-left: 10px;
    }

    .media {
        flex-direction: column !important;
    }

    @media only screen and (max-width: 600px) {
        .register {
            max-width: 40%;
        }

        .login {
            max-width: 40%;
        }

        .btn_student_group {
            justify-content: center;
        }
    }
</style>
