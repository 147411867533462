<template>
    <div class="main-studentSession  mt-3 row">
        <div class="col-sm-12 col-md-12 col-xl-6 ">
            <div class="card">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('CreateStudentSession.Heading') }}</h5>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label>{{ $t('CreateStudentSession.SessionType') }}</label>
                            <sessiontypedropdown :subjectOfSession="'Individual'" @input="getSessionDetail" />
                        </div>
                        <div class="form-group col-md-12">
                            <label>{{ $t('SessionType.Description') }}</label>
                            <textarea rows="4" v-model="description" class="form-control p-3" disable readonly />
                        </div>
                        <div class="form-group col-md-12">
                            <label>{{ $t('CreateStudentSession.SessionStartDate') }}</label>
                            <datepicker v-model="slotDate" :language="ar" :highlighted="highlighted" :format="customFormatter" :disabledDates="disabledDates" placeholder="حدد تاريخ" :inline="true"></datepicker>
                        </div>
                    </div>
                </div>
                <div class="modal-footer ">
                    <button type="button" class="btn btn-primary btn-round" v-bind:disabled="$v.$invalid" v-on:click="SaveStudentSession()">{{ $t('CreateStudentSession.Save') }}</button>
                    <button type="button" class="btn btn-danger btn-round" v-on:click="close" href="javascript:void(0)">{{ $t('CreateStudentSession.Cancel') }}</button>
                </div>
            </div>
        </div>

        <div class="col-sm-12 col-md-12 col-xl-6 ">
            <div class="card">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('CreateStudentSession.AdvisorSession') }}</h5>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div v-for="slot in options" :key="slot.id" class="form-group col-md-4">
                            <div v-on:click="createStudentSession.adviserSlotId=slot.id" class="time-tag d-flex" v-bind:class="createStudentSession.adviserSlotId===slot.id ? 'active_div' : ''">
                                <span>{{slot.fromTime}}</span>
                                <span class="mx-1">-</span>
                                <span>{{slot.toTime}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <loading :name="loading" :active.sync="loading"
                 :can-cancel="false"
                 :is-full-page="true"></loading>
    </div>
</template>
<script>
    import { required } from "vuelidate/lib/validators";
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    import Datepicker from 'vuejs-datepicker';
    import moment from 'moment'
    import { ar } from 'vuejs-datepicker/dist/locale'

    export default {
        name: 'CreateStudentSession',
        components: {
            Loading,
            Datepicker
        },
        data: function () {
            return {
                ar: ar,
                createStudentSession: {
                    id: '00000000-0000-0000-0000-000000000000',
                    sessionTypeId: '',
                    adviserSlotId: '',
                },
                loading: false,
                slotDate: '',
                options: '',
                description: '',

                highlighted: {
                    dates: [],
                },
                disabledDates: {
                    to: new Date(Date.now() - 864e5),
                    dates: [],
                }

            }
        },
        validations: {
            createStudentSession: {
                sessionTypeId: {
                    required: required
                },
                adviserSlotId: {
                    required: required
                }
            }
        },
        watch: {
            slotDate: function () {
                this.getData();
            },
        },
        methods: {
            customFormatter: function (date) {
                this.slotDate = moment(date).format('MM/DD/YYYY');
                return moment(date).format('MM/DD/YYYY');
            },

            GoTo: function (link) {
                this.$router.push(link);
            },

            close: function () {
                this.$router.push('/StudentDashboard');
            },
            getSessionDetail(data) {
                this.description = data.description;
                this.createStudentSession.sessionTypeId = data.id;

            },

            getData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if (this.slotDate != null && this.slotDate != '' && this.slotDate != undefined) {
                    this.$https.get('/Setup/AdviserSlotList?isDropDown=true' + '&fromDateTime=' + this.slotDate, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                        if (response.data != null) {
                            root.options = response.data.results;
                        }
                    });
                }
                else {
                    root.options = [];
                }
            },

            SaveStudentSession: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.post('/Sessions/SaveIndividualSession', this.createStudentSession, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.loading = false;
                            root.$swal({
                                title: root.$t('Notification.Added'),
                                text: root.$t('Notification.DataSucess'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    root.close();
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            getSlotDayForHighlighted: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                this.$https.get('/Setup/AdviserSlotDayList', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {

                    if (response.data != null) {
                        console.log(response.data)
                        response.data.list.forEach(function (cat) {
                            root.highlighted.dates.push(new Date(moment(cat).format('YYYY'), moment(cat).format('MM'), moment(cat).format('DD')));
                        });
                        response.data.disableList.forEach(function (cat) {
                            root.disabledDates.dates.push(new Date(moment(cat).format('YYYY'), moment(cat).format('MM'), moment(cat).format('DD')));
                        });
                    }
                });

            },

        },
        created: function () {
            this.getSlotDayForHighlighted();
        },
        mounted: function () {

        },
    }
</script>
<style scoped>
    .time-tag {
        padding: 10px;
        border: 1px solid rgb(219, 219, 219);
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

        .time-tag:hover {
            background-color: rgb(0,144,0);
            color: #fff;
        }

    .active_div {
        background-color: rgb(0,144,0) !important;
        color: #fff !important;
    }
</style>