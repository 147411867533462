<template>
    <div class="main-session-duration">
        <div class="page-header">
            <div>
                <h1 class="page-title">{{ $t('SessionDuration.Heading') }}</h1>
            </div>
            <div class="ms-auto pageheader-btn">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t('SessionDuration.Heading') }}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ $t('SessionDuration.Dashboard') }}</li>
                </ol>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-sm-12">
                <div class="card product-sales-main">
                    <div class="card-header border-bottom ac">
                        <div class="form-group">
                            <div class="input-group">
                                <input v-model="search" class="form-control fc" id="basic-addon6"
                                    :placeholder="$t('Regions.Search')" type="text">
                            </div>
                        </div>
                        <div style="display: flex;">
                        <div>
                            <a v-if="role != 'Reviewer'" v-on:click="GoTo('/AddSessionDurations')" href="javascript:void(0)"
                                class="btn btn-primary btn-pill slide-item">{{ $t('SessionDuration.AddSessionDuration')
                                }}</a>
                        </div>
                        <!-- <div class="col-auto align-self-center">
                            <a v-on:click="getCsv" href="javascript:void(0);" class="btn btn-sm btn-outline-primary mx-1">
                                CSV
                            </a>
                        </div> -->
                    </div>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table border text-nowrap text-md-nowrap table-hover">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>{{ $t('SessionDuration.SessionDurationName') }}</th>
                                        <th>{{ $t('SessionDuration.Minutes') }}</th>
                                        <th>{{ $t('SessionDuration.Status') }}</th>
                                        <th v-if="role != 'Reviewer'">{{ $t('SessionDuration.Action') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(SessionDuration, index) in SessionDurationList" :key="SessionDuration.id">
                                        <td v-if="currentPage === 1">
                                            {{ index + 1 }}
                                        </td>
                                        <td v-else>
                                            {{ ((currentPage * 10) - 10) + (index + 1) }}
                                        </td>
                                        <td>{{ SessionDuration.name }}</td>
                                        <td>{{ SessionDuration.minutes }}</td>
                                        <td>
                                            <span v-if="SessionDuration.isActive"
                                                class="badge rounded-pill bg-primary-gradient my-1">{{
                                                    $t('SessionDuration.Active') }}</span>
                                            <span v-else class="badge rounded-pill bg-danger-gradient my-1">{{
                                                $t('SessionDuration.InActive') }}</span>
                                        </td>
                                        <td v-if="role != 'Reviewer'">
                                            <a v-on:click="EditInfo(SessionDuration.id)" href="javascript:void(0)"><img
                                                    src="/assets/images/Edit.svg" /></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="row">
                            <div class="col-md-6 text-right">
                                <span v-if="currentPage === 1 && rowCount === 0"> {{ $t('Pagination.ShowingEntries') }}</span>
                                <span v-else-if="currentPage === 1 && rowCount < 10"> {{ $t('Pagination.Showing') }}
                                    {{ currentPage }} {{ $t('Pagination.to') }} {{ rowCount }} {{ $t('Pagination.of') }}
                                    {{ rowCount }} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage === 1 && rowCount >= 11"> {{ $t('Pagination.Showing') }}
                                    {{ currentPage }} {{ $t('Pagination.to') }} {{ currentPage * 10 }} {{ $t('Pagination.of') }}
                                    {{ rowCount }} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage === 1"> {{ $t('Pagination.Showing') }} {{ currentPage }} {{
                                    $t('Pagination.to') }} {{ currentPage * 10 }} of {{ rowCount }} {{ $t('Pagination.entries')
    }}</span>
                                <span v-else-if="currentPage !== 1 && currentPage !== pageCount"> {{ $t('Pagination.Showing') }}
                                    {{ (currentPage * 10) - 9 }} {{ $t('Pagination.to') }} {{ currentPage * 10 }} {{
                                        $t('Pagination.of') }} {{ rowCount }} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }}
                                    {{ (currentPage * 10) - 9 }} {{ $t('Pagination.to') }} {{ rowCount }} {{ $t('Pagination.of') }}
                                    {{ rowCount }} {{ $t('Pagination.entries') }}</span>
                            </div>
                            <div class="col-md-6">
                                <div class="overflow-auto" v-on:click="getPage()" style="float:left">
                                    <b-pagination pills size="md" v-model="currentPage" :total-rows="rowCount"
                                        :per-page="10" :first-text="this.$t('Tabel.First')"
                                        :prev-text="this.$t('Tabel.Previous')" :next-text="this.$t('Tabel.Next')"
                                        :last-text="this.$t('Tabel.Last')"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'SessionDuration',
    data: function () {
        return {
            search: '',
            currentPage: 1,
            pageCount: '',
            rowCount: '',
            pageSize: '',
            loading: false,
            SessionDurationList: [],
            role: '',
            show: false,
        }
    },
    watch: {
        search: function (val) {
            this.GetData(val, 1);
        }
    },
    methods: {
        GoTo: function (link) {
            this.$router.push(link);
        },

        getPageSize: function (isDesc) {
            this.isDesc = isDesc;
            this.getPage();
        },

        getPage: function () {
            this.GetData(this.search, this.currentPage);
        },

        GetData: function (search, currentPage) {
            var root = this;
            var url = '/Setup/SessionDurationList?searchTerm=' + search + '&pageNumber=' + currentPage;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {
                    root.SessionDurationList = response.data.results;
                    root.pageCount = response.data.pageCount;
                    root.rowCount = response.data.rowCount;
                }
            }).catch(error => {
                console.log(error)
                root.$swal.fire({
                    icon: 'error',
                    type: 'error',
                    title: root.$t('Notification.Error'),
                    text: root.$t('Notification.SomethingWrong'),
                    showConfirmButton: false,
                    timer: 5000,
                    timerProgressBar: true,
                });
            }).finally(() => root.loading = false);
        },
        getCsv: function () {
                var root = this;
                var token = '';
                root.loading = true;

                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Setup/SessionDurationListExcel', { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'SessionDuration List.csv');
                        document.body.appendChild(link);
                        link.click();
                        root.loading = false;

                    });
            },



        EditInfo: function (id) {
            var root = this;
            var token = '';
            if (this.$session.exists()) {
                token = localStorage.getItem('token');
            }
            root.$https.get('/Setup/GetSessionDurationDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                if (response.data != null) {
                    root.$router.push({
                        path: '/AddSessionDurations',
                        query: { data: response.data }
                    })
                }
            });
        },



    },
    created: function () {
        this.role = localStorage.getItem('RoleName');
    },
    mounted: function () {
        this.GetData(this.search, 1);
    },
}
</script>
<style scoped>.card-header {
    display: flex;
    justify-content: space-between;
}

.btn-primary:hover {
    color: white !important;
}</style>


