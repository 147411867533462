<template>
    <div>
        <modal :show="show">
            <div class="modal-header">

                <h5 class="modal-title" id="myModalLabel"> {{ $t('Register.College') }}</h5>

            </div>
            <div class="modal-body">
                <div class="row">

                    <div class="form-group col-sm-12">
                        <label>{{ $t('Register.College') }}<span class="error text-danger">*</span></label>
                        <college-dropdown v-model="collegeId" :universityid="universityid" :isall="false" />
                    </div>

                    <div class="form-group col-sm-12">
                        <label>{{ $t('Register.IdentificationNumber') }}<span class="error text-danger">*</span> </label>
                        <input v-model="nationalId" v-on:keyup="CheckNationalId(nationalId)" type="text" class="form-control">
                        <span v-if="isValidNationalId" class="text-danger">لا يمكن أن يكون أكثر أو أقل من 10 أرقام ويبدأ دائمًا بـ "1" أو "2"</span>
                    </div>
                </div>

            </div>
            <div class="modal-footer justify-content-right">
                <button type="button" class="btn btn-primary btn-round " v-on:click="SaveNow" v-bind:disabled="collegeId=='' || collegeId==null || nationalId=='' || nationalId==null"> {{ $t('Register.Save') }}</button>
                <button type="button" class="btn btn-danger btn-round mr-3 " v-on:click="close()">{{ $t('Register.Cancel') }}</button>
            </div>

        </modal>
    </div>
</template>
<script>

    export default {
        props: ['show', 'universityid', 'nationalid'],
        data: function () {
            return {
                collegeId: '',
                nationalId: '',
                loading: false,
                isValidNationalId: false,
            }
        },
        methods: {
            CheckNationalId: function (no) {
                if (no.length != 10) {
                    this.isValidNationalId = true;
                }
                else {
                    var letter = no.substring(0, 1)
                    if (letter != '1' && letter != '2' && letter != '١' && letter != '٢') {
                        this.isValidNationalId = true;
                    }
                    else {
                        this.isValidNationalId = false;
                    }
                }
            },

            close: function () {
                this.$emit('close');
            },

            SaveNow: function () {
                var root = this;
                this.loading = true;


                root.$https.get('/account/SaveStudentCollege?collegeId=' + this.collegeId + '&nationalId=' + this.nationalId)
                    .then(function (response) {
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.$swal({
                                title: root.$t('Notification.Joined'),
                                text: response.data.isAddUpdate,
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            });
                            root.loading = false;
                            root.close();
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                    })
                    .finally(() =>
                        root.loading = false
                    );
            },

        },
        created: function () {

            this.nationalId = this.nationalid != undefined ? this.nationalid : '';
        },
    }
</script>

