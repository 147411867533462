<template>
    <div hidden id='purchaseInvoice'>
        <div class="row  mt-5">
            <div class="col-12 logo text-center">
                <img src="/assets/images/subol_logo.png" />
            </div>
            <div class="col-12 pr-5 py-2 my-5 pt-3" style="text-align:end; background:#009000 !important; color:white;">
                <h2>بيانات الجلسة</h2>
            </div>
        </div>
        <div class="row pr-3" style=" text-align: start; direction: rtl;">
            <div class="col-4 ">
                <label style="font-weight: bold; ">نوع الجلسة</label>
                <p>{{sessiondetail.sessionTypeName}}</p>
            </div>
            <div class="col-4">
                <label style="font-weight:bold; font-size:20px;">مدة الجلسة</label>
                <p>{{sessiondetail.minutes}}</p>
            </div>
            <div class="col-4">
                <label style="font-weight:bold; font-size:20px;">مكان الجلسة  </label>
                <p>{{sessiondetail.sessionLocationName}}</p>
            </div>
            <div class="col-4 mt-3">
                <label style="font-weight:bold; font-size:20px;">موعد بدا الجلسة</label>
                <p>{{sessiondetail.sessionStartTime}}</p>
            </div>
            <div class="col-4 mt-3">
                <label style="font-weight:bold; font-size:20px;">
                    موعد انتهاء الجلسة
                </label>
                <p>{{sessiondetail.sessionEnd}}</p>
            </div>

            <div class="col-4 mt-3">
                <label style="font-weight:bold; font-size:20px;">الاسم</label>
                <p>{{sessiondetail.adviserName}}</p>
            </div>
            <div class="col-4 mt-3">
                <label style="font-weight:bold; font-size:20px;">اسم الجامعة</label>
                <span v-for="(item, index) in sessiondetail.universityIdName" :key="index">
                    ({{item.name}}),
                </span>
            </div>

            <div class="col-12">
                <label style="font-weight:bold; font-size:20px;"> المستوى الجامعي للطلاب</label>
                <p><span v-for="item in sessiondetail.sessionStudentLevels" :key="item"> {{item}} </span> </p>
            </div>
            <div class="col-lg-12 " style="margin-top:200px; display:flex; justify-content:center; ">
                <qr-code :text="path" error-level="L"></qr-code>
            </div>
        </div>
    </div>

</template>

<script>
    import Vue from 'vue';
    import VueHtmlToPaper from 'vue-html-to-paper';
    import VueQRCodeComponent from 'vue-qrcode-component'
    Vue.component('qr-code', VueQRCodeComponent)

    const options = {
        name: '_blank',
        specs: [
            'fullscreen=yes',
            'titlebar=yes',
            'scrollbars=yes'
        ],
        styles: [
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
            'https://unpkg.com/kidlat-css/css/kidlat.css'
        ],
        timeout: 1000, // default timeout before the print window appears
        autoClose: true, // if false, the window will not close after printing
        windowTitle: window.document.title, // override the window title
    }
    Vue.use(VueHtmlToPaper, options);

    export default {
        props: ['sessiondetail'],

        data: function () {
            return {
                sessiondetails: {
                },
                path: '',
            }
        },

        methods: {
            printInvoice: function () {
                var root = this;
                this.$htmlToPaper('purchaseInvoice', options, () => {
                    root.$emit('close');
                });
            },

        },
        mounted: function () {
            
            var root = this;
            if (this.sessiondetail != null && this.sessiondetail != '' && this.sessiondetail != undefined) {
                this.sessiondetails = this.sessiondetail;
                
                this.path = window.location.origin + '/JoinQuickWorkShop?id=' + this.sessiondetails.id;
                console.log(this.path);
                setTimeout(function () {
                    root.printInvoice();
                }, 125)
            }
        },

    }
</script>

