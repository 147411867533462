<template>
    <div class="page">
        <div class="page-main">
            <!-- app-Header -->
            <div class="hor-header header">
                <div class="container main-container">
                    <div class="d-flex pt-1">
                        <img style="width:100px;" src="/assets/images/landing/subol.png" />
                        <!-- LOGO -->

                        <div class="d-flex order-lg-2 ms-auto header-right-icons mt-3">
                            <button class="navbar-toggler navresponsive-toggler d-md-none ms-auto"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent-4"
                                    aria-controls="navbarSupportedContent-4"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon fe fe-more-vertical"></span>
                            </button>

                            <div class="navbar navbar-collapse responsive-navbar p-0">
                                <div class="collapse navbar-collapse"
                                     id="navbarSupportedContent-4">
                                    <div class="d-flex order-lg-2 m-4 m-lg-0 m-md-1">
                                        <a v-on:click="GoTo('/Login')" href="javascript:void(0)" class="btn btn-pill btn-outline-primary btn-w-md py-2">
                                            {{ $t('LandingPage.Login') }}
                                        </a>
                                        <a v-on:click="GoTo('/Register')" href="javascript:void(0)" class="btn btn-pill btn-outline-primary btn-w-md py-2 ms-1">
                                            {{ $t('LandingPage.Signup') }}
                                        </a>

                                        <a v-on:click="GoTo('/ContactUs')" class=" btn btn-pill btn-primary btn-w-md py-2 ms-1 ct">{{ $t('LandingPage.ContactUs') }}</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
            <!-- /app-Header -->
            <!-- /app-banner -->
            <div class="m">
                <div class="container">
                    <div class="banner">
                        <div class="col-md-6">
                            <h1> {{ $t('LandingPage.Ways') }}</h1>
                            <p>
                                {{ $t('LandingPage.WaysDescription') }}
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- </Footer> -->
        <div class="demo-footer">
            <div class="ro container  parent px-5 px-md-0 pt-5 ">
                <div class=" pt-5">
                    <img src="/assets/images/landing/subol.png" />
                </div>
                
            </div>

        </div>
        <div class=" copy  ">
            
                <p class="mt-4">     {{ $t('LandingPage.Footer') }} </p>
            
        </div>
        <!-- Footer close -->
    </div>
</template>
<script>
    export default {
        name: "landingPage",
        methods: {
            GoTo: function (link) {
                this.$router.push(link);
            },
        }
    };
</script>
<style scoped>
    .landing-nav {
        padding: 20px 0px 20px 0px;
    }

    .header {
        border-bottom: none !important;
       
    }

    .page-main {
       
        background-size: cover;
    }
    .m {
        background-image: url("/assets/images/landing/bg.jpg");
        height:100vh;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .banner {
        
        display: flex;
        justify-content: center;
        flex-direction: column;
        
        color: white;
    }

    .demo-footer {
        background-color: #092f57;
    }

    .btn-outline-primary {
        color: #009000 !important;
        border: 1px solid #009000;
    }
        .btn-outline-primary:hover{
            color: white !important;
        }

        .parent {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: 1fr;
            grid-column-gap: 0px;
            grid-row-gap: 0px;
            padding-bottom: 50px;
        }

        .parent p {
            color: white;
            margin-top: 56px;
            font-size: 16px;
        }

    .banner h1 {
        font-weight: 700;
        font-size: 58px;
        margin-top: 150px;
    }

    .banner p {
        margin-top: 30px;
        font-size: 16px;
    }
    .con{
        color:white !important;
        margin-top: 20px;
        padding:20px;
        cursor: pointer;    
    }

    @media (min-width: 1274px) {
        .header {
            padding-right: 50px;
        }
    }

    @media screen and (min-width: 300px) and (max-width: 780px) {
        .parent {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: 4fr;
        }

            .parent p {
                margin-top: 36px;
            }
        .btn {
            min-width: 100px !important;
            padding: 3px !important;
            border: 1px solid #009000;
            color: #009000 !important;
        }
        .ct {
            color: white !important;
        }

    }
    .ft{
        margin-top:40px !important;
        margin-right: 200px;
    }
    .copy {
        background-color: #001b39;
        color: white;
        text-align:center;
    }
</style>

