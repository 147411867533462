<template>
    <div class="main-bx">
        <!-- app-Header -->
        <div class="hor-header header" style="background:#fff !important; padding-right:0px !important">
            <div class="container main-container">
                <div class="d-flex pt-1">
                    <img style="width: auto; height: 60px; max-width: 210px; cursor: pointer; " src="/assets/images/subol_logo.png" @click="GoToHome()" />
                    <!-- LOGO -->

                    <div class="d-flex order-lg-2 ms-auto header-right-icons mt-3">
                        <button class="navbar-toggler navresponsive-toggler d-md-none ms-auto"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent-4"
                                aria-controls="navbarSupportedContent-4"
                                aria-expanded="false"
                                aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon fe fe-more-vertical"></span>
                        </button>

                        <div class="navbar navbar-collapse responsive-navbar p-0">
                            <div class="collapse navbar-collapse"
                                 id="navbarSupportedContent-4">
                                <div class="d-flex order-lg-2 m-4 m-lg-0 m-md-1">
                                    <a v-on:click="GoTo('/Login')" href="javascript:void(0)" class="btn btn-pill btn-outline-primary btn-w-md py-2">
                                        {{ $t('ContactUs.Login') }}
                                    </a>
                                    <a v-on:click="GoTo('/Register')" href="javascript:void(0)" class="btn btn-pill btn-outline-primary btn-w-md py-2 ms-1">
                                        {{ $t('ContactUs.Signup') }}
                                    </a>

                                    <a v-on:click="GoTo('/ContactUs')" class=" btn btn-pill btn-primary btn-w-md py-2 ms-1 ct"> {{ $t('ContactUs.ContactUs') }}</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <!-- /app-Header -->
        <section class="container ct-main">

            <div class="row mt-5">
                <div class="col-10">
                    <h1 class="hed">  {{ $t('ContactUs.PageHeading') }}</h1>
                </div>
            </div>

            <div class="row justify-content-around mt-5 text-center">
                <a @click="tabs(0)" class="col-md col-sm-12 navy bl border border-primary  py-5 px-2 mx-5 my-2 text-decoration-none ">
                    {{ $t('ContactUs.RegistrationProblem') }}
                </a>
                <a @click="tabs(1)" class="col-md col-sm-12 navy bl border border-secondary  py-5 px-2 mx-5 my-2 text-decoration-none">
                    {{ $t('ContactUs.ReportsSuggestion') }}
                </a>
                <a @click="tabs(2)" class="col-md col-sm-12 navy bl border border-secondary  py-5 px-2 mx-5 my-2 text-decoration-none">
                    {{ $t('ContactUs.TechnicalProblems') }}
                </a>
                <a @click="tabs(3)" class="col-md col-sm-12 navy bl border border-secondary  py-5 px-2 mx-5 my-2 text-decoration-none">
                    {{ $t('ContactUs.OtherProblems') }}
                </a>
            </div>

            <main role="main" class="wrapper">

                <div class="container" style="margin-top:30px;padding:10px;">

                    <h1 class="pt-5 ">{{ $t('ContactUs.ContactUs') }}</h1>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card shadow p-3 mb-5 bg-white ContactUsForm">

                                <div class="card-body" style="padding:30px;">


                                    <form method="post" action="/contactus" enctype="multipart/form-data" class="max-width">

                                        <div class="contact-us">

                                            <div class="opaque"></div>
                                            <div class="cssload-loader"></div>

                                            <div class="form-group row">



                                                <input data-val="true" data-val-number="The field TicketType must be a number." data-val-required="The TicketType field is required." id="TicketType" name="TicketType" type="hidden" value="0" />
                                                <div class="col-sm-12 col-md-3 required-circle" style=" margin-left: -10px ; padding-left: 1px ;">
                                                    <label class="color-for-text-title font-weight-bold">{{ $t('ContactUs.RequestType') }}</label>

                                                </div>
                                                <div class="col-sm-12 col-md-9">
                                                    <!--<select class="form-control" data-val="true" data-val-number="The field نوع الطلب must be a number." data-val-required="حقل &quot;نوع الطلب&quot; مطلوب" id="ddlContactUsType" name="Type">
                                                        <option value="">نوع الطلب</option>
                                                        <option selected="selected" value="6">مشاكل في عملية التسجيل</option>
                                                        <option value="9">البلاغات والاقتراحات</option>
                                                        <option value="15">مشاكل تقنية</option>
                                                        <option value="8">مشاكل اخرى</option>
                                                    </select>-->
                                                    <multiselect @input="getValue()" @remove="getValue()" v-model="value" :options="options" :multiple="false" track-by="id" :clear-on-select="false" :show-labels="false" label="name">

                                                    </multiselect>
                                                    <input 
                                                           class="form-control"
                                                           name="title"
                                                           type="text"
                                                           v-model.trim="$v.newContactUs.requestType.$required"
                                                           style="display:none;">
                                                </div>
                                            </div>




                                            <div class="form-group row">

                                                <div class="col-sm-12 col-md-3  " style=" margin-left: -10px ; padding-left: 1px ;">
                                                    <label class="color-for-text-title font-weight-bold">{{ $t('ContactUs.Name') }}</label>

                                                </div>
                                                <div class="col-sm-12 col-md-9">
                                                    <input v-model="newContactUs.name" class="form-control" data-val="true" data-val-required="حقل &quot;الاسم الكامل&quot; مطلوب" id="Name" name="Name" :placeholder="$t('ContactUs.Name')" type="text" value="" />
                                                </div>
                                            </div>
                                            <div class="form-group row">

                                                <div class="col-sm-12 col-md-3  required-circle" style=" margin-left: -10px ; padding-left: 1px ;">
                                                    <label class="color-for-text-title font-weight-bold">{{ $t('ContactUs.Email') }}</label>

                                                </div>
                                                <div class="col-sm-12 col-md-9">
                                                    <input v-model="newContactUs.email" class="form-control" data-val="true" data-val-required="حقل &quot;البريد الإلكتروني&quot; مطلوب" id="Email" name="Email" type="email" :placeholder="$t('ContactUs.Email')" value="" />
                                                </div>
                                            </div>

                                            <div class="form-group row">

                                                <div class="col-sm-12 col-md-3  required-circle" style=" margin-left: -10px ; padding-left: 1px ;">
                                                    <label class="color-for-text-title font-weight-bold">{{ $t('ContactUs.Title') }}</label>

                                                </div>
                                                <div class="col-sm-12 col-md-9">
                                                    <input v-model="newContactUs.title" class="form-control" data-val="true" data-val-required="حقل &quot;عنوان الرسالة&quot; مطلوب" id="Subject" name="Subject" :placeholder="$t('ContactUs.Title')" type="text" value="" />
                                                </div>
                                            </div>

                                            <div class="form-group row">

                                                <div class="col-sm-12 col-md-3  required-circle" style=" margin-left: -10px ; padding-left: 1px ;">
                                                    <label class="color-for-text-title font-weight-bold">{{ $t('ContactUs.Inquiry') }}</label>

                                                </div>
                                                <div class="col-sm-12 col-md-9">
                                                    <textarea v-model="newContactUs.message" class="form-control" cols="20" data-val="true" data-val-required="حقل &quot;الاستفسار&quot; مطلوب" id="Inquiry" name="Inquiry" :placeholder="$t('ContactUs.Inquiry')" rows="5"></textarea>
                                                </div>
                                            </div>

                                            <div class="form-group row">

                                                <div class="col-sm-12 col-md-3  required-circle" style=" margin-left: -10px ; padding-left: 1px ;">
                                                    <label class="color-for-text-title font-weight-bold">{{ $t('ContactUs.file') }}</label>
                                                </div>
                                                <div class="col-sm-12 col-md-5 ">

                                                    <label class="" style="width:100%" for="File">
                                                        <!--<input type="file" name="File" v-model="newContactUs.Attachment" @input="uploadFile()" id="File" value="" class="form-control input-field" placeholder="الملف:" style="background: #f3f3f4; background: #f3f3f4; border-radius: 20px;height: 32px;border-radius: 30px;" />-->
                                                        <b-form-file v-model="file1"
                                                                     @input="uploadFile()"
                                                                     id="uplaodfile"
                                                                     ref="imgupload1"
                                                                     :no-drop="true"
                                                                     :state="Boolean(file1)"></b-form-file>

                                                    </label>
                                                </div>
                                            </div>



                                            <p class="response contactModalResponse"></p>
                                            <div class="text-right d-flex align-items-baseline">
                                                <button type="button" class="btn btn-primary btn-round" v-bind:disabled="$v.$invalid" v-on:click="SaveContactUs()" style="color:#fff !important">{{ $t('ContactUs.Save') }}</button>
                                                <p class="">
                                                    <a href="javascript:void(0)" class="ps-2" @click="GoToHome()">  {{ $t('ContactUs.Cancel') }}</a>
                                                </p>
                                            </div>


                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </section>
        <div class="demo-footer">
            <div class="ro container  parent px-5 px-md-0 pt-5 ">
                <div class=" pt-5">
                    <img src="/assets/images/subol_logo.png" />
                </div>

            </div>

        </div>
        <div class=" copy  ">

            <p class="py-2 m-0">     {{ $t('LandingPage.Footer') }} </p>

        </div>
    </div>

</template>

<script>
    import { required } from "vuelidate/lib/validators";
    import Multiselect from "vue-multiselect";
    export default {
        name: 'ContactUs',
        components: {
            Multiselect,

        },
        data: function () {
            return {
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                pageSize: '',
                loading: false,
                cityList: [],
                formTab: 0,
                show: false,
                options: [
                    { id: 1, name: "مشاكل في عملية التسجيل" },
                    { id: 2, name: "البلاغات والاقتراحات" },
                    { id: 3, name: "مشاكل تقنية" },
                    { id: 4, name: "مشاكل اخرى" }
                ],
                value: [],
                newContactUs: {
                    id: '00000000-0000-0000-0000-000000000000',
                    requestType: '',
                    name: '',
                    email: '',
                    title: '',
                    message: '',
                    Attachment: ''
                },
                file1: null,


            }
        },
        validations: {
            newContactUs: {
                requestType: {
                    required: required
                },
                name: {
                    required: required
                },
                email: {
                    required: required
                },
                title: {
                    required: required
                },
                message: {
                    required: required
                },
            }
        },

        methods: {
            GoTo: function (link) {
                this.$router.push(link);
            },
            GoToHome: function () {
                this.$router.push('/')
            },
            close: function () {
                this.$router.push('/');
            },
            tabs: function (num) {
                this.value = [];
                this.value.push(this.options[num]);
                var requestVal = this.value.push(this.options[num].name);

                this.newContactUs.requestType = requestVal.name;
            },

            getValue: function () {
                this.newContactUs.requestType = this.value.name
            },


            SaveContactUs: function () {
                var root = this;
                this.loading = true;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                root.$https.post('/Home/SaveContactUsPage', this.newContactUs, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        console.log(response.data)
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.loading = false;
                            root.$swal({
                                title: 'Added!',
                                text: 'Data has been Added successfully.',
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    root.close();
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: 'Error!',
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: 'Error',
                                text: "Something Went Wrong",
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            uploadFile() {
                var root = this;
                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                var file = this.$refs.imgupload1.files;
                var fileData = new FormData();
                for (var k = 0; k < file.length; k++) {
                    fileData.append("files", file[k]);
                    root.fileName = file[k].name;
                }
                root.$https.post('/Home/UploadFilesAsync', fileData, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        if (response.data != null) {
                            root.newContactUs.Attachment = response.data;
                        }
                    },
                        function () {
                            root.loading = false;
                            root.$swal({
                                title: "Error!",
                                text: "Something went wrong",
                                type: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                        });
            },




        },
        created: function () {

        },
        mounted: function () {
            this.formTab = 1;
            this.value.push(this.options[0])
            this.newContactUs.requestType = this.options[0].name;
        },
    }
</script>
<style scoped>
    .navy {
        background-color: white;
        border-radius: 2.25rem !important;
        background: #fff;
        border: none !important;
        box-shadow: 0rem 0rem 0.9375rem -0.625rem grey;
        color: black;
        font-size: 16px;
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        cursor: pointer;
    }

        .navy:hover {
            background-color: #009000;
            color: white !important;
        }

    .hed {
        color: #009000;
        font-size: 32px;
    }
</style>
<style scoped>
    .landing-nav {
        padding: 20px 0px 20px 0px;
    }

    .header {
        border-bottom: none !important;
        background: transparent !important;
    }

    .page-main {
        background-size: cover;
    }

    .m {
        background-image: url("/assets/images/landing/bg.jpg");
        height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .banner {
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: white;
    }

    .demo-footer {
        background-color: #092f57;
    }

    .btn-outline-primary {
        color: #009000 !important;
        border: 1px solid #009000;
    }

        .btn-outline-primary:hover {
            color: white !important;
        }

    .parent {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        padding-bottom: 50px;
    }

        .parent p {
            color: white;
            margin-top: 56px;
            font-size: 16px;
        }

    .banner h1 {
        font-weight: 700;
        font-size: 58px;
        margin-top: 150px;
    }

    .banner p {
        margin-top: 30px;
        font-size: 16px;
    }

    .con {
        color: white !important;
        margin-top: 20px;
        padding: 20px;
        cursor: pointer;
    }

    @media (min-width: 1274px) {
        .header {
            padding-right: 50px;
        }
    }

    @media screen and (min-width: 300px) and (max-width: 780px) {
        .parent {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: 4fr;
        }

            .parent p {
                margin-top: 36px;
            }

        .btn {
            min-width: 100px !important;
            padding: 3px !important;
            border: 1px solid #009000;
            color: #009000 !important;
        }

        .ct {
            color: white !important;
        }

        .ct-main {
            margin-top: 100px !important;
        }
    }

    .ft {
        margin-top: 40px !important;
        margin-right: 200px;
    }

    .copy {
        background-color: #001b39;
        color: white;
        text-align: center;
    }
</style>

