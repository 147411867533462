<template>
    <!-- PAGE -->
    <div class="page">
        <div>
            <!-- CONTAINER OPEN -->
            <div class="col mx-auto text-center pb-5">
                <a href="javascript:void(0)" v-on:click="GoToHome">
                    <img src="/assets/images/subol_logo.png" class="img-fluid" alt="subol_logo" style="max-width:200px;">
                </a>
            </div>
            <div class="col-lg-4 col-md-8 col-sm-12 mx-auto">
                <div class="row">
                    <div class="col col-login ">
                        <form class="card shadow-none" method="post">
                            <div class="card-body">
                                <div class="text-center">
                                    <span class="login100-form-title">
                                        {{$t('ResetPassword.Heading')}}
                                    </span>

                                </div>



                                <div class="pt-3" id="forgot">

                                    <div class="form-group">
                                        <div class="form-label-group">
                                            <label class="form-label" for="email-address"> {{$t('ResetPassword.Password')}}</label>
                                        </div>
                                        <div class="form-control-wrap">
                                            <a tabindex="-1" href="javascript:void(0)" class="form-icon form-icon-right passcode-switch lg" data-target="password">
                                                <em class="passcode-icon icon-show icon ni ni-eye"></em>
                                                <em class="passcode-icon icon-hide icon ni ni-eye-off"></em>
                                            </a>
                                            <input class="form-control" v-model="login.password" @keyup.enter="SaveNewUser" type="password" required id="password" :placeholder="$t('ResetPassword.EnterPassword')">
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="form-label-group">
                                            <label class="form-label" for="password"> {{$t('ResetPassword.ConfirmPassword')}}</label>
                                        </div>
                                        <div class="form-control-wrap">
                                            <a tabindex="-1" href="javascript:void(0)" class="form-icon form-icon-right passcode-switch lg" data-target="confirmPassword">
                                                <em class="passcode-icon icon-show icon ni ni-eye"></em>
                                                <em class="passcode-icon icon-hide icon ni ni-eye-off"></em>
                                            </a>
                                            <input v-model="login.confirmPassword" @keyup.enter="SaveNewUser" type="password" class="form-control" required id="confirmPassword" :placeholder="$t('ResetPassword.EnterConfirmPassword')">
                                        </div>
                                    </div>

                                    <div class="form-group ">
                                        <div class="nk-block-des">
                                            <p>{{ customError }}</p>
                                        </div>
                                        <div class="submit">
                                            <a class="btn btn-primary d-grid" href="javascript:void(0);" v-bind:disabled="$v.$invalid" v-on:click="SaveNewUser">{{$t('ResetPassword.Save')}}</a>
                                        </div>
                                    </div>
                                    <div class="form-note-s2 pt-4">
                                        {{$t('ResetPassword.Cancel')}} <a href="javascript:void(0)" v-on:click="GoToSignup()">{{$t('ResetPassword.Login')}}</a>
                                    </div>
                                </div>

                            </div>
                            <div class="card-footer">
                                <div class="d-flex justify-content-center my-3">
                                    <a href="javascript:void(0)" class="social-login  text-center me-4">
                                        <i class="fa fa-google"></i>
                                    </a>
                                    <a href="javascript:void(0)" class="social-login  text-center me-4">
                                        <i class="fa fa-facebook"></i>
                                    </a>
                                    <a href="javascript:void(0)" class="social-login  text-center">
                                        <i class="fa fa-twitter"></i>
                                    </a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <forgot-password :show="show" v-if="show" @close="show=false" />
            <!-- CONTAINER CLOSED -->
            <loading :name="loading" :active.sync="loading"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
        </div>
    </div>
    <!--END PAGE -->
    <!-- app-root @e -->

</template>
<script>
    import { required, sameAs, minLength} from 'vuelidate/lib/validators';
  
    import "vue-loading-overlay/dist/vue-loading.css";

    export default {
      
        data() {
            return {
                loading: false,
                show: false,
                login: {
                    id: '',
                    password: '',
                    confirmPassword: ''
                },
                error: '',
                customError: ''
            }
        },
        validations: {
            login:
            {
                id:
                {
                    required
                },
                password:
                {
                    required,
                    minLength: minLength(8),
                },
                confirmPassword:
                {
                    required,
                    sameAsPassword: sameAs('password')
                },
            }
        },

        methods: {
            GoToTerms: function () {
                this.$router.push('/TermsAndCondition')
            },

            GoToPrivacy: function () {
                this.$router.push('/TermsAndCondition')
            },
            GoToHome: function () {
                this.$router.push('/')
            },

            GoToSignup: function () {
                this.$router.push('/Login')
            },

            SaveNewUser: function () {
                var root = this;
                this.loading = true;
                
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/account/ResetPassword?id=' + this.login.id + '&password=' + this.login.password + '&confirmPassword=' + this.login.confirmPassword, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {
                        
                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {

                            root.$swal({
                                title: 'Reset!',
                                text: 'Your Password has been Changed successfully.',
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            });
                            root.loading = false;
                            //root.SendEmailVerification(response.data.id);

                            root.$router.push('/')
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-info",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });

                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },

            SendEmailVerification: function (id) {
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                
                this.$https.get('/account/SendResetEmail?id=' + id, { headers: { "Authorization": `Bearer ${token}` } });

            },
        },
        created: function () {
            this.login.id = this.$route.query.id;
        },
    }
</script>
