<template>
    <div>
        <multiselect v-model="DisplayValue" :options="options" :multiple="false" track-by="name" :clear-on-select="false" :show-labels="false" label="name" :preselect-first="true" v-bind:placeholder=" $t('Dropdown.SelectOption')">
            <p slot="noResult" class="text-danger"> {{ $t('Dropdown.AdvisorSlot') }} </p>
            <p slot="noOptions"> </p>

        </multiselect>
    </div>
</template>
<script>
    import moment from 'moment';
    import Multiselect from "vue-multiselect";
    export default {
        name: "AdvisorSlotDropdown",
        props: ["values", "slotDate"],

        components: {
            Multiselect,

        },
        data: function () {
            return {
                options: [],
                value: '',
                render: 0
            }
        },
        methods: {
            GetDate: function (date) {
                return moment(date).format('llll');

            },

            getData: function () {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if (this.slotDate != null && this.slotDate != '' && this.slotDate != undefined) {
                    this.$https.get('/Setup/AdviserSlotList?isDropDown=true' + '&fromDateTime=' + this.slotDate, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                        
                        if (response.data != null) {
                            response.data.results.forEach(function (cat) {

                                root.options.push({
                                    id: cat.id,
                                    name: root.GetDate(cat.toDateTime) + ' - ' + root.GetDate(cat.fromDateTime) 
                                })
                            })
                        }
                    }).then(function () {
                        root.value = root.options.find(function (x) {
                            return x.id == root.values;
                        })
                    });
                }
                else {
                    root.options = [];
                }
            },
        },
        computed: {
            DisplayValue: {
                get: function () {
                    if (this.value != "" || this.value != undefined) {
                        return this.value;
                    }
                    return this.values;
                },
                set: function (value) {
                    
                    this.value = value;
                    this.$emit('input', value == null ? null : value.id);
                }
            }
        },
        mounted: function () {
            this.getData();
        },
    }
</script>