<template>
    <modal :show="show">
        <div class="modal-header">
            <h5 class="modal-title">{{ $t('CreateGroupSession.Reason') }}</h5>
            <a href="javascript:void(0)" v-on:click="close()" class="close">
                <em class="icon ni ni-cross"></em>
            </a>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <input class="form-control file-input" ref="imgupload" type="file" accept="image/*.pdf,txt,text/plain,.doc,.docx" @change="uploadImage('onClick')" id="file-input">
                    </div>

                </div>
                <div class="col-md-12 mt-3">
                    <label>Description</label>
                    <textarea rows="3" v-model="attachement.description" class="form-control"></textarea>

                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" v-bind:disabled="$v.$invalid" class="btn btn-primary btn-round" v-on:click="Save()">{{ $t('CreateGroupSession.Save') }}</button>
            <a href="javascript:void(0)" class="btn btn-danger btn-round" v-on:click="close()">{{ $t('CreateGroupSession.Cancel') }}</a>
        </div>
        <loading :name="loading" :active.sync="loading"
                 :can-cancel="false"
                 :is-full-page="true"></loading>
    </modal>
</template>
<script>
    import { required } from "vuelidate/lib/validators";
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    export default {
        components: {
            Loading
        },
        props: ['show','id'],
        data: function () {
            return {
                loading: false,
                image: '../../images/Placeholder.svg',
                data: [],
                url: '',
                filePath: null,

                attachement: {
                    id: '00000000-0000-0000-0000-000000000000',
                    userId: '',
                    name: '',
                    description: '',
                    filePath: '',
                }
            }
        },
        validations: {
            attachement: {
                userId: {
                    required: required
                },
                filePath: {
                    required: required
                }
            }
        },
        methods: {
            close: function () {
                this.$emit('close');
            },

            uploadImage() {
                
              
                var root = this;
                var file = this.$refs.imgupload.files;

                this.filePath = null;
                var fileData = new FormData();
                this.image = URL.createObjectURL(this.$refs.imgupload.files[0]);
                // convert file into FormData object
                for (var k = 0; k < file.length; k++) {
                    fileData.append("files", file[k]);
                }
                root.attachement.name = file[0].name;

                this.$https.post('/Home/UploadFilesAsync', fileData)
                    .then(function (response) {
                        if (response.data != null) {
                            root.attachement.filePath = response.data;
                        }
                    });
            },

            Save: function () {
                var root = this;
                this.loading = true;

                var token = '';
                if (root.$session.exists()) {
                    token = localStorage.getItem('token');
                }

                if (this.id != '' && this.id != null && this.id != undefined) {
                    this.attachement.userId = this.id;
                }
                else {
                    this.attachement.userId = localStorage.getItem('UserID');

                }

                root.$https.post('/Setup/SaveAttachment', this.attachement, { headers: { "Authorization": `Bearer ${token}` } })
                    .then(function (response) {

                        if (response.data.id != '00000000-0000-0000-0000-000000000000' && response.data.isSuccess) {
                            root.loading = false;
                            root.$swal({
                                title: root.$t('Notification.Added'),
                                text: root.$t('Notification.DataSucess'),
                                type: 'success',
                                icon: 'success',
                                confirmButtonClass: "btn btn-success",
                                buttonsStyling: false
                            }).then(function (result) {
                                if (result) {
                                    //root.close();
                                    root.$router.go();
                                }
                            });
                        }
                        else {
                            root.$swal({
                                title: root.$t('Notification.Error'),
                                text: response.data.isAddUpdate,
                                type: 'error',
                                icon: 'error',
                                confirmButtonClass: "btn btn-danger",
                                buttonsStyling: false
                            });
                            root.loading = false;
                        }
                    }).catch(error => {
                        console.log(error)
                        root.$swal.fire(
                            {
                                icon: 'error',
                                type: 'error',
                                title: root.$t('Notification.Error'),
                                text: root.$t('Notification.SomethingWrong'),
                                showConfirmButton: false,
                                timer: 5000,
                                timerProgressBar: true,
                            });
                        root.loading = false;
                    })
                    .finally(() => root.loading = false);
            },
        },
        mounted: function () {
            this.attachement.userId = localStorage.getItem('UserID');
        },
    }
</script>
<style>

    #profilePicture {
        max-height: 96px;
        max-width: 96px;
    }
</style>