<template>
    <div class="main-review-list">
        <div class="page-header">
            <div>
                <h1 class="page-title">{{ $t('ReviewerDashboard.Sessions') }}</h1>
            </div>
            <div class="ms-auto pageheader-btn">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="javascript:void(0);">{{ $t('ReviewerDashboard.Sessions') }}</a></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ $t('ReviewerList.Dashboard') }}</li>
                </ol>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 col-sm-12 col-md-6 col-xl-3">
                <div class="card overflow-hidden">
                    <div class="card-body" style="padding:1rem !important">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-2 fw-semibold"> {{session.active}} </h3>
                                <p class="text-muted  mb-0">{{ $t('SessionReport.ActiveSession') }}</p>
                            </div>
                            <div class="col col-auto top-icn dash mt-1">
                                <img src="/assets/images/landing/base-1.svg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-md-6 col-xl-3">
                <div class="card overflow-hidden">
                    <div class="card-body" style="padding:1rem !important">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-2 fw-semibold">{{session.completed}}</h3>
                                <p class="text-muted  mb-0">{{ $t('SessionReport.CompletedSession') }}</p>
                            </div>
                            <div class="col col-auto top-icn dash mt-1">
                                <img src="/assets/images/landing/base-2.svg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-md-6 col-xl-3">
                <div class="card overflow-hidden">
                    <div class="card-body" style="padding:1rem !important">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-2 fw-semibold">{{session.pending}}</h3>
                                <p class="text-muted  mb-0"> ا{{ $t('SessionReport.PendingSession') }}</p>
                            </div>
                            <div class="col col-auto top-icn dash mt-1">
                                <img src="/assets/images/landing/base-3.svg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12 col-md-6 col-xl-3">
                <div class="card overflow-hidden">
                    <div class="card-body" style="padding:1rem !important">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-2 fw-semibold"> {{session.total}} </h3>
                                <p class="text-muted  mb-0">{{ $t('SessionReport.TotalSession') }}</p>
                            </div>
                            <div class="col col-auto top-icn dash mt-1">
                                <img src="/assets/images/landing/base-1.svg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-12">
                <div class="card product-sales-main">
                    <div class="row card-header  border-bottom align-items-end">
                        <div class="col-sm-12 col-md-6 col-lg-4  form-group">
                            <div class="input-group">
                                <label>من التاريخ</label>
                                <datepicker v-model="fromDate" :key="render" />
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4 form-group">
                            <div class="input-group">
                                <label>حتي اليوم</label>
                                <datepicker v-model="toDate" :key="render" />
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-4 form-group">
                            <label>ابحث</label>
                            <input type="text" v-model="search" class="form-control" />
                        </div>


                        <div class="col-sm-12 col-md-6 col-lg-4 form-group">

                            <label>الجامعات</label>
                            <universitydropdown :alluniversities="true" v-model="selectUniversty" :key="render" />
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4 form-group">
                            <label>المرشدين</label>
                            <adviser-dropdown v-model="selectAdviser" :key="render" />
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4 form-group">
                            <label>الجلسات</label>
                            <subject-of-session-dropdown v-model="subjectOfSession" :values="subjectOfSession" :key="render" />
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-4 form-group" v-if="subjectOfSession == 3">
                            <label>ورشة عمل سريعة</label>
                            <multiselect :options="['Both', 'Quick WorkShop']" v-model="workShop" :show-labels="false" :accesskey="render">
                            </multiselect>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4 form-group" v-if="subjectOfSession == 3">
                            <label>{{ $t('CreateWorkshop.ServiceType') }}</label>
                            <typesofsessiondropdown v-model="workshopType" />

                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4 form-group">
                            <button class="btn btn-primary btn-pill" @click="ClearFilter()"> {{ $t('SessionReport.ClearAll') }}</button>
                        </div>

                        <div class="col-sm-12 col-md-6 col-lg-3 form-group">
                            <div class="btn-group mt-2 mb-2">
                                <button type="button" class="btn btn-outline-default btn-pill dropdown-toggle" data-bs-toggle="dropdown">
                                    عمل <span class="caret"></span>
                                </button>
                                <ul class="dropdown-menu" role="menu">
                                    <li><a v-on:click="DowmloadCSV('false')" href="javascript:void(0)" class="btn  btn-pill slide-item">{{ $t('Statistics.DownloadCsv') }}</a></li>
                                    <li><a v-on:click="DowmloadCSVSecondServay('true')" href="javascript:void(0)" class="btn  btn-pill slide-item"> تقرير الخدمة الثاني xlsx</a></li>
                                </ul>
                            </div>
                        </div>
                        <!--</div>-->
                    </div>





                    <div class="card-body">

                        <div class="table-responsive">
                            <table class="table border text-nowrap text-md-nowrap table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            #
                                        </th>
                                        <th>
                                            تاريخ الخدمة
                                        </th>
                                        <th>
                                            وقت الخدمة
                                        </th>
                                        <th>
                                            مدة الجلسة
                                        </th>
                                        <th>
                                            شفرة
                                        </th>
                                        <th>
                                            المرشد المهني

                                        </th>
                                        <th>
                                            عنوان الخدمة
                                        </th>
                                        <th>
                                            {{$t('StudentDashboard.MeetingPlace') }}
                                        </th>
                                        <th>
                                            {{$t('AdvisorDashboard.University')}}
                                        </th>
                                        <th>
                                            {{$t('SessionActive.StudentLimit')}}
                                        </th>
                                        <th>
                                            {{$t('SessionActive.AvailableSeats')}}
                                        </th>
                                        <th>
                                            حالة الخدمة
                                        </th>
                                        <th>
                                            عنوان الجلسة
                                        </th>
                                        <th v-if="role != 'Reviewer'">تفاصيل</th>
                                        <th v-if="role != 'Reviewer'">{{ $t('City.Action') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(student, index) in studentList" :key="index">
                                        <td v-if="currentPage === 1">
                                            {{index+1}}
                                        </td>
                                        <td v-else>
                                            {{((currentPage*10)-10) +(index+1)}}
                                        </td>
                                        <td>{{getDate(student.sessionStart)}}</td>
                                        <td>{{student.sessionEnd}}</td>
                                        <td>{{student.sessionDuration}}</td>
                                        <td>{{student.code}}</td>
                                        <td>{{student.adviserName}}</td>
                                        <td>{{student.sessionTypeName}}</td>
                                        <td>
                                            <span v-if="student.sessionLocationName!=null">
                                                {{student.sessionLocationName}}
                                            </span>
                                            <span v-if="student.sessionLocationName==null">
                                                ---
                                            </span>
                                        </td>
                                        <td>
                                            {{student.universityName}}
                                        </td>
                                        <td>

                                            <span v-if="student.studentLimit != null">
                                                {{student.studentLimit}}
                                            </span>
                                            <span v-if="student.studentLimit == null">
                                                ---
                                            </span>
                                        </td>

                                        <td>
                                            <span v-if="student.remainingSeats != null">
                                                {{student.remainingSeats}}
                                            </span>
                                            <span v-if="student.remainingSeats == null">
                                                ---
                                            </span>
                                        </td>
                                        <td>
                                            <span v-if="student.statusId==1">
                                                معلق
                                            </span>
                                            <span v-if="student.statusId==2">
                                                نشيط
                                            </span>
                                            <span v-if="student.statusId==3">
                                                المنتهية
                                            </span>
                                        </td>
                                        <td>
                                            <span v-if="student.subjectOfSession==1">
                                                {{ $t('SessionType.Single') }}
                                            </span>
                                            <span v-if="student.subjectOfSession==2">
                                                {{ $t('SessionType.Collective') }}
                                            </span>
                                            <span v-if="student.subjectOfSession==3">
                                                {{ $t('SessionType.Workshop') }}
                                            </span>
                                        </td>
                                        <td v-if="role != 'Reviewer'">
                                            <a class="btn btn-primary btn-sm" data-bs-placement="top" data-bs-toggle="tooltip" title="أسئلة إحصاء" v-on:click="Details(student.id, false)" href="javascript:void(0)"><i class="fa fa-file-text-o" aria-hidden="true"></i></a>
                                            <a class="btn btn-primary btn-sm ms-2" data-bs-placement="top" data-bs-toggle="tooltip" title="أسئلة الإحصاء الثانية" v-on:click="Details(student.id, true)" href="javascript:void(0)"><i class="fa fa-file-text" aria-hidden="true"></i></a>
                                        </td>
                                        <td v-if="role != 'Reviewer'">
                                            <a class="btn btn-outline-primary btn-sm" v-if="student.statusId != 3 && student.subjectOfSession != 1" v-on:click="EditInfo(student.id, student.subjectOfSession)" href="javascript:void(0)"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></a>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="row">
                            <div class="col-md-6 text-right">
                                <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                                <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            </div>
                            <div class="col-md-6">
                                <div class="overflow-auto" v-on:click="getPage()" style="float:left">
                                    <b-pagination pills size="md" v-model="currentPage"
                                                  :total-rows="rowCount"
                                                  :per-page="10"
                                                  :first-text="this.$t('Tabel.First')"
                                                  :prev-text="this.$t('Tabel.Previous')"
                                                  :next-text="this.$t('Tabel.Next')"
                                                  :last-text="this.$t('Tabel.Last')"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <loading :name="loading" :active.sync="loading"
                     :can-cancel="false"
                     :is-full-page="true">
            </loading>
        </div>
    </div>

</template>

<script>
    import moment from 'moment'
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    import Multiselect from "vue-multiselect";
    export default {
        name: 'SessionsReport',
        components: {
            Loading, Multiselect
        },
        data: function () {
            return {
                workshopType: '',
                workShop: '',
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                pageSize: '',
                loading: false,
                selectUniversty: '',
                selectAdviser: '',
                subjectOfSession: 3,
                fromDate: '',
                toDate: '',
                studentList: [],
                session: {
                    pending: '',
                    active: '',
                    completed: '',
                    total: ''
                },
                render: 0,
                counter: 0,
                role: ''
            }
        },
        watch: {
            search: function (val) {
                this.GetData(val, 1);
            },
            selectUniversty: function (val) {
                this.selectUniversty = val;
                this.GetData(this.search, 1);
            },
            selectAdviser: function (val) {
                this.selectAdviser = val;
                this.GetData(this.search, 1);
            },
            subjectOfSession: function (val) {
                this.subjectOfSession = val;
                this.GetData(this.search, 1);
            },
            workShop: function (val) {
                this.workShop = val;
                this.GetData(this.search, 1);
            },
            workshopType: function () {
                this.GetData(this.search, 1);
            },
            fromDate: function () {
                
                this.counter++;
                if (this.counter != 1) {
                    this.GetData(this.search, 1);
                }
            },

            toDate: function () {
                

                this.counter++;
                if (this.counter != 2) {
                    this.GetData(this.search, 1);

                }

            }
        },
        methods: {
            GoTo: function (link) {
                this.$router.push(link);
            },

            getDate: function (date) {
                return moment(date).format('l');
            },


            getPage: function () {
                this.GetData(this.search, this.currentPage);
            },

            GetData: function (search, currentPage) {
                
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Home/SessionsFeedBackReportList?searchTerm=' + search + '&pageNumber=' + currentPage + '&fromDate=' + this.fromDate + '&toDate=' + this.toDate + "&UniversityId=" + this.selectUniversty + "&AdviserId=" + this.selectAdviser + "&subjectOfSession=" + this.subjectOfSession + "&workShop=" + this.workShop + "&workshopType=" + this.workshopType, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.studentList = response.data.results.sessions;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                        root.session.pending = response.data.results.pendingSession
                        root.session.active = response.data.results.activeSession
                        root.session.completed = response.data.results.completedSession
                        root.session.total = response.data.rowCount;
                    }
                });
            },

            DowmloadCSV: function (isSecondServay) {
                var root = this;
                var token = '';
                root.loading = true;
                
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Home/StudentFeedbackReportListCsv?fromDate=' + this.fromDate + '&toDate=' + this.toDate + '&isSecondServay=' + isSecondServay + "&UniversityId=" + this.selectUniversty + "&AdviserId=" + this.selectAdviser + "&subjectOfSession=" + this.subjectOfSession + "&workShop=" + this.workShop + "&workshopType=" + this.workshopType, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'StudentFeedback Report.xlsx');
                        document.body.appendChild(link);
                        link.click();
                        root.loading = false;

                    });
            },

            DowmloadCSVSecondServay: function (isSecondServay) {
                var root = this;
                var token = '';
                root.loading = true;
                
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Home/StudentFeedbackSecondServayReportListCsv?fromDate=' + this.fromDate + '&toDate=' + this.toDate + '&isSecondServay=' + isSecondServay + "&UniversityId=" + this.selectUniversty + "&AdviserId=" + this.selectAdviser + "&subjectOfSession=" + this.subjectOfSession + "&workShop=" + this.workShop + "&workshopType=" + this.workshopType, { headers: { "Authorization": `Bearer ${token}` }, responseType: 'blob' })
                    .then(function (response) {
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'StudentFeedbackSecondServay Report.csv');
                        document.body.appendChild(link);
                        link.click();
                        root.loading = false;

                    });
            },


            EditInfo: function (id, subjectOfSession) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                if (subjectOfSession == 2) {
                    root.$https.get('/Sessions/GetGroupSessionDetailForEdit?id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                        if (response.data != null) {
                            root.$router.push({
                                path: '/CreateGroupSession',
                                query: { data: response.data }

                            })
                        }
                    });
                }
                if (subjectOfSession == 3) {
                    root.$https.get('/Sessions/GetWorkShopDetailForEdit?id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                        if (response.data != null) {
                            root.$router.push({
                                path: '/CreateWorkshop',
                                query: { data: response.data }
                            })
                        }
                    });
                }
            },

            Details: function (id, isSecondary) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Home/SessionStudentDetails?Id=' + id + '&isSecondary=' + isSecondary, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$router.push({
                            path: '/SessionStudentDetails',
                            query: { data: response.data, screenpath: root.$route.path }
                        })
                    }
                });
            },

            ClearFilter: function () {
                this.search = '';
                this.selectUniversty = '';
                this.selectAdviser = '';
                this.subjectOfSession = '';
                this.workShop = '';
                this.fromDate = moment().startOf('month').format('l');
                this.toDate = moment().endOf('month').format('l');
                this.render++;
                this.GetData(this.search, 1);
            },

        },
        created: function () {

            this.fromDate = moment().startOf('month').format('l');
            this.toDate = moment().endOf('month').format('l');

            this.GetData(this.search, 1);
            this.role = localStorage.getItem('RoleName');

        },
        mounted: function () {

        },
    }
</script>
<style scoped>
    .card-header {
        display: flex;
        justify-content: space-between;
    }

    .btn-primary:hover {
        color: white !important;
    }

    .slide-item:hover {
        background-color: rgba(0, 144, 0, 0.9);
        color: white !important;
    }
</style>


