<template>
    <!-- PAGE-BANNER END -->
    <div class="row">
        <div class="col-12 col-sm-12">
            <div class="card product-sales-main">
                <div class="card-header border-bottom">
                    <h3 class="card-title mb-0">{{$t('AdvisorDashboard.EndedsessionsH')}}</h3>
                    <div class="form-group">
                        <div class="input-group">
                            <input v-model="search" class="form-control fc" id="basic-addon6" :placeholder=" $t('Regions.Search')" type="text">
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table border text-nowrap text-md-nowrap table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>رقم للتذكرة</th>
                                    <!--<th>{{$t('AdvisorDashboard.University')}}</th>-->
                                    <th>{{$t('AdvisorDashboard.SessionType')}}</th>
                                    <th>{{$t('AdvisorDashboard.SessionLocation')}} </th>
                                    <th>{{$t('AdvisorDashboard.TimeAndDate')}}</th>
                                    <th>{{$t('AdvisorDashboard.WorkshopType')}}</th>
                                    <th>سبب</th>
                                    <th></th>


                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="( sessionCompleted, index) in completedSessions" :key=" sessionCompleted.id" v-bind:class="{'bg_danger':sessionCompleted.isCancel}">
                                    <td v-if="currentPage === 1">
                                        {{index+1}}
                                    </td>
                                    <td v-else>
                                        {{((currentPage*10)-10) +(index+1)}}
                                    </td>
                                    <td>{{ sessionCompleted.code}}</td>
                                    <!--<td>{{ sessionCompleted.universityIdName}}</td>-->
                                    <td>{{ sessionCompleted.sessionTypeName}}</td>
                                    <td>
                                        <span v-if="sessionCompleted.sessionLocationName!=null">
                                            {{sessionCompleted.sessionLocationName}}
                                        </span>
                                        <span v-if="sessionCompleted.sessionLocationName==null">
                                            ---
                                        </span>
                                    </td>

                                    <td>{{ sessionCompleted.sessionStartTime}}</td>

                                    <td>
                                        <span v-if="sessionCompleted.subjectOfSession==1"> {{$t('AdvisorDashboard.IndividualSession')}}</span>
                                        <span v-if="sessionCompleted.subjectOfSession==2"> {{$t('AdvisorDashboard.GroupSession')}}</span>
                                        <span v-if="sessionCompleted.subjectOfSession==3">{{$t('AdvisorDashboard.WorkShop')}}</span>
                                    </td>
                                    <td>{{ sessionCompleted.cancelReason}}</td>
                                    <td>

                                        <!--<a v-if="!sessionCompleted.isSentSecondaryEmailSurvey" href="javascript:void(0)" data-toggle="tooltip" data-placement="right" :title="sessionCompleted.code" v-on:click="SendSecondSurveyEmail(sessionCompleted.id, sessionCompleted.subjectOfSession)" class="btn btn-primary btn-sm me-2"><i class="fa fa-envelope-o" aria-hidden="true"></i></a>-->
                                        <a href="javascript:void(0)" data-toggle="tooltip" data-placement="right" :title="sessionCompleted.code" v-on:click="ActivateDetails(sessionCompleted.id)" class="btn btn-primary btn-sm"><i class="fa fa-file-text-o" aria-hidden="true"></i></a>

                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="row">
                        <div class="col-md-6 text-right">
                            <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                            <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        </div>
                        <div class="col-md-6">
                            <div class="overflow-auto" v-on:click="getPage()" style="float:left">
                                <b-pagination pills size="md" v-model="currentPage"
                                              :total-rows="rowCount"
                                              :per-page="10"
                                              :first-text="this.$t('Tabel.First')"
                                              :prev-text="this.$t('Tabel.Previous')"
                                              :next-text="this.$t('Tabel.Next')"
                                              :last-text="this.$t('Tabel.Last')"></b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <view-survey-question-model :surveyQuestion="surveyQuestions" v-if="show" :show="show" @close="getPage()" />
    </div>

</template>

<script>
    export default {
        name: 'AdviserCompletedSession',
        data: function () {
            return {
                render: 0,

                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                pageSize: '',
                loading: false,
                show: false,

                completedSessions: [],
                surveyQuestions: {
                    id: '',
                    questionList: []
                }
            }
        },
        watch: {
            search: function (val) {
                this.CompletedData(val, 1);
            },

        },
        methods: {

            GoTo: function (link) {
                this.$router.push(link);
            },

            getPageSize: function (isDesc) {
                this.isDesc = isDesc;
                this.getPage();
            },

            getPage: function () {
                this.CompletedData(this.search, this.currentPage);
            },


            CompletedData: function (search, currentPage) {
                var url = '/Home/AdviserDashboardCompleted?searchTerm=' + search + '&pageNumber=' + currentPage;
    
                var root = this;
                this.show = false;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.completedSessions = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        type: 'error',
                        title: root.$t('Notification.Error'),
                        text: root.$t('Notification.SomethingWrong'),
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                }).finally(() => root.loading = false);
            },


            ActivateDetails: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Sessions/GetSessionActivateDetails?Id=' + id +'&isSessionDetailsNeed='+true, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$router.push({
                            path: '/SessionEnded',
                            query: { data: response.data }
                        })
                    }
                });
            },

            SendSecondSurveyEmail: function (id, subjectOfSession) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Sessions/GetSecondSurveyQuestion?subjectOfSession=' + subjectOfSession, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        
                        root.surveyQuestions.id = id;
                        root.surveyQuestions.questionList = response.data.results;
                        root.show = true;
                    }
                });
            },
        },

        created: function () {


        },
        mounted: function () {
            this.CompletedData(this.search, 1);
        },
    }
</script>
<style scoped>
    .card-header {
        display: flex;
        justify-content: space-between;
    }

    .banner {
        background-image: url(/assets/images/landing/123.jpg);
        background-repeat: no-repeat;
        background-size: contain;
    }

        .banner h1 {
            color: #009000;
        }

    .card-header h4 {
        color: #009000;
    }

    .card-body p {
        color: #009000;
    }

    .bg_danger {
        background-color: rgb(255 107 107 / 10%);
    }
</style>



