<template>
    <div class="main-dashboard">
        <!-- PAGE-HEADER -->
        <div class="row mt-5">
            <div class="col-sm-12 col-lg-3 col-md-12  card sidebar_body p-4">
                <div id="sidebar" class="sidebar p-4">
                    <div>
                        <h1 class="mb-3"> {{ $t('Dashboard.Heading') }}</h1>
                        <ul class="list-unstyled components mb-5 content   nav-listbar">
                            <li v-for="item in list"
                                :key="item.id"
                                v-bind:class="{ isactive: item.id === activeId }"
                                v-on:click="selectMember(item.id),GoTo(item.path)">
                                <a href="javascript:void(0)" class="icon_list">
                                    <span class="mr-3 sidebar_icon"><img :src="item.img" /></span><span class="sidebar_txt" v-bind:class="{ txtActive: item.id === activeId }" v-on:click="selectMember(item.id)">
                                        {{ item.name }}
                                    </span>
                                </a>
                            </li>
                        </ul>
                        <div class="mb-5"></div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-9 ">
                <div id="content">
                    <div class="row">
                        <div class="col-lg-12">

                            <div class="card admin_banner_bg">
                                <div class="card-body p-5 text-dark">
                                    <div class="statistics-info">
                                        <div class="row">
                                            <div class="col-lg-6 col-sm-6  mt-4 mb-4">
                                                <div class="counter-status txt_banner_admin text-start">
                                                    <h1 class="mb-4 fw-semibold">{{ $t('Dashboard.Hello') }}</h1>
                                                    <h4 class="leading-normal">
                                                        {{ $t('Dashboard.VocationalGuidance') }}
                                                    </h4>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-sm-6 mt-4 mb-4 ">
                                                <div class="counter-status">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- COL-END -->
                    </div>
                    <div class="row">

                        <router-view></router-view>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Dashboard",
        data: function () {
            return {
                render: 0,
                activeId: 0,
                list: [
                    {
                        id: 1,
                        name: this.$t('Dashboard.Regions'),
                        img: "/assets/images/sidebar/regions.svg",
                        path: '/Regions'
                    },
                    {
                        id: 2,
                        name: this.$t('Dashboard.SessionDuration'),
                        img: "/assets/images/sidebar/Timer.svg",
                        path: '/SessionDuration'
                    },
                    {
                        id: 3,
                        name: this.$t('Dashboard.City'),
                        img: "/assets/images/sidebar/regions.svg",
                        path: '/Cities'
                    },
                    {
                        id: 4,
                        name: this.$t('Dashboard.StudentCategory'),
                        img: "/assets/images/sidebar/Meeting.svg",
                        path: '/StudentCategories'
                    },
                    {
                        id: 5,
                        name: this.$t('Dashboard.Qualification'),
                        img: "/assets/images/sidebar/Qualification.svg",
                        path: '/Qualifications'
                    },
                    {
                        id: 6,
                        name: this.$t('Dashboard.UniversityLevel'),
                        img: "/assets/images/sidebar/Universitylevels.svg",
                        path: '/UniversityLevels'
                    },
                    {
                        id: 7,
                        name: this.$t('Dashboard.Specialization'),
                        img: "/assets/images/sidebar/Specialization.svg",
                        path: '/Specializations'
                    },
                    {
                        id: 8,
                        name: this.$t('Dashboard.University'),
                        img: "/assets/images/sidebar/University.svg",
                        path: '/Universities'
                    },
                    {
                        id: 9,
                        name: this.$t('LayoutMenu.College'),
                        img: "/assets/images/sidebar/University.svg",
                        path: '/College'
                    },
                    {
                        id: 10,
                        name: this.$t('Dashboard.SessionType'),
                        img: "/assets/images/sidebar/SessionType.svg",
                        path: '/SessionTypes'
                    },

                    {
                        id: 11,
                        name: this.$t('Dashboard.SessionLocation'),
                        img: "/assets/images/sidebar/SessionLocation.svg",
                        path: '/SessionLocations'
                    },
                    {
                        id: 12,
                        name: this.$t('Dashboard.StudentRegistration'),
                        img: "/assets/images/sidebar/Student.svg",
                        path: '/StudentList'
                    },
                    {
                        id: 13,
                        name: this.$t('Dashboard.AdminRegistration'),
                        img: "/assets/images/sidebar/Admin.svg",
                        path: '/AdminList'
                    },
                    {
                        id: 14,
                        name: this.$t('Dashboard.AdvisorRegistration'),
                        img: "/assets/images/sidebar/Adviser.svg",
                        path: '/AdviserList'
                    },
                    {
                        id: 15,
                        name: this.$t('Dashboard.ReviewerRegistration'),
                        img: "/assets/images/sidebar/Supervisor.svg",
                        path: '/ReviewerList'
                    },
                    {
                        id: 16,
                        name: this.$t('Dashboard.SurveyQuestions'),
                        img: "/assets/images/sidebar/Supervisor.svg",
                        path: '/SurveyQuestions'
                    },
                    {
                        id: 17,
                        name: this.$t('Dashboard.SecondSurveyQuestions'),
                        img: "/assets/images/sidebar/Supervisor.svg",
                        path: '/SecondSurveyQuestions'
                    },
                    {
                        id: 18,
                        name: this.$t('LayoutMenu.Holidays'),
                        img: "/assets/images/sidebar/Supervisor.svg",
                        path: '/Holidays'
                    },
                    {
                        id: 19,
                        name: this.$t('Dashboard.Statistics'),
                        img: "/assets/images/sidebar/Supervisor.svg",
                        path: '/Statistics'
                    },
                    {
                        id: 20,
                        name: this.$t('Dashboard.StundentReport'),
                        img: "/assets/images/sidebar/Supervisor.svg",
                        path: '/StudentReport'
                    },
                    {
                        id: 21,
                        name: this.$t('Dashboard.ReportRequirement'),
                        img: "/assets/images/sidebar/Supervisor.svg",
                        path: '/ReportRequirement'
                    },
                    {
                        id: 22,
                        name: this.$t('Dashboard.ReviewerDashboard'),
                        img: "/assets/images/sidebar/Supervisor.svg",
                        path: '/ReviewerDashboard'
                    },
                    {
                        id: 23,
                        name: this.$t('ReviewerDashboard.Sessions'),
                        img: "/assets/images/sidebar/Supervisor.svg",
                        path: '/SessionsReport'
                    },
                    {
                        id: 24,
                        name: this.$t('Dashboard.Permissions'),
                        img: "/assets/images/sidebar/Supervisor.svg",
                        path: '/Permissions'
                    },
                    {
                        id: 26,
                        name: 'تقارير قياس الرضا والاثر',
                        img: "/assets/images/sidebar/Supervisor.svg",
                        path: '/SessionsFeedBackReport'
                    },
                    //{
                    //    id: 25,
                    //    name: this.$t('Dashboard.EmailNotification'),
                    //    img: "/assets/images/sidebar/Supervisor.svg",
                    //    path: '/EmailNotification'
                    //},

                    //{
                    //    id: 26,
                    //    name: this.$t('Dashboard.Importstudentdetails'),
                    //    img: "/assets/images/sidebar/Supervisor.svg",
                    //    path: '/ImportStudentDetail'
                    //}
                ],
            };
        },
        methods: {
            selectMember: function (id) {
                this.activeId = id;
            },
            GoTo: function (link) {
                this.$router.push(link);
            },

            //GetGraphSummary: function () {
            //    var root = this;
            //    var token = '';
            //    if (this.$session.exists()) {
            //        token = localStorage.getItem('token');
            //    }
            //    root.$https.get('/Home/ReadCsv', { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
            //        if (response.data != null) {
            //            root.render++;
            //        }
            //    }).catch(error => {
            //        console.log(error)
            //    });
            //},
        },
        created: function () { },
        mounted: function () {
            /*this.GetGraphSummary();*/
        },
    };
</script>
<style>
    #basic-addon6 {
        text-align: end;
    }
</style>
<style scoped>
    .nav-listbar {
        max-height: 500px;
    }

    .sidebar_body {
        max-height: 600px;
    }

    .nav-listbar > li {
        border: 1px solid rgba(49, 120, 246, 0.1);
        border-radius: 4px;
        padding: 8px;
        margin-bottom: 8px;
        cursor: pointer;
        max-height: 60px;
    }

    .sidebar_txt {
        font-size: 14px;
        color: #333333;
        padding-right: 8px;
    }

    .sidebar_icon {
        background: #009000;
        border-radius: 5px;
        padding: 7px;
        max-height: 32px;
        max-width: 32px;
        display: flex;
    }

        .sidebar_icon img {
            min-width: 18px;
        }

    .sidebar {
        overflow-x: hidden;
        overflow-y: auto;
    }

    .sidebar_body {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    /* width */
    .sidebar::-webkit-scrollbar {
        width: 3px;
        border-radius: 6.51724px;
    }

    /* Track */
    .sidebar::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    .sidebar::-webkit-scrollbar-thumb {
        background: #009000;
        border-radius: 6.51724px;
        max-height: 20px !important;
    }

        /* Handle on hover */
        .sidebar::-webkit-scrollbar-thumb:hover {
            background: #009000;
        }

    .txt_banner_admin {
        text-align: left;
        color: #fff;
        vertical-align: middle;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .admin_banner_bg {
        background-color: #009000;
    }

    .img_banner {
        opacity: 0.4;
    }

    .isactive {
        background-color: green !important;
    }

    .txtActive {
        color: #fff !important;
    }

    .icon_list {
        display: flex;
        align-items: center;
    }

    @media (max-width: 991px) {
        .sidebar_body {
            display: none;
        }
    }
</style>