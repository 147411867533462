<template>
    <div class="row">
        <div class="col-12 col-sm-12">
            <div class="card product-sales-main">
                <div class="card-header border-bottom">
                    <h3 class="card-title mb-0">{{$t('StudentDashboard.PendingSessionSchedule') }}</h3>
                    <div class="form-group">
                        <div class="input-group">
                            <input v-model="search" class="form-control fc" id="basic-addon6" :placeholder=" $t('Regions.Search')" type="text">

                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table border text-nowrap text-md-nowrap table-hover">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>رقم للتذكرة</th>
                                    <th>{{$t('StudentDashboard.University') }}</th>
                                    <th>{{$t('StudentDashboard.SessionType') }}</th>
                                    <th>{{$t('StudentDashboard.MeetingPlace') }}</th>
                                    <th>{{$t('StudentDashboard.TimeAndDate') }}</th>
                                    <th>{{$t('StudentDashboard.Status') }}</th>
                                    <th>{{$t('StudentDashboard.WorkshopType') }}</th>

                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="( session, index) in scheduledAppointments" :key=" session.id">
                                    <td v-if="currentPage === 1">
                                        {{index+1}}
                                    </td>
                                    <td v-else>
                                        {{((currentPage*10)-10) +(index+1)}}
                                    </td>
                                    <td>{{ session.code}}</td>
                                    <td>{{ session.university}}</td>
                                    <td>
                                        <span v-if="!session.hideSessionType">
                                            {{ session.sessionType}}
                                        </span>
                                        <span v-if="session.hideSessionType">
                                            ---
                                        </span>
                                    </td>
                                    <td>{{ session.sessionLocation}}</td>
                                    <td>{{ session.sessionStart}}</td>
                                    <td>

                                        <span v-if="session.sessionStatus===1">
                                            {{$t('StudentDashboard.Pendingsessions') }}
                                        </span>
                                        <span v-if="session.sessionStatus===2">
                                            {{$t('StudentDashboard.ScheduledAppointments') }}
                                        </span>
                                    </td>
                                    <td>
                                        <span v-if="session.subjectOfSession==1"> {{$t('StudentDashboard.IndividualSession')}}</span>
                                        <span v-if="session.subjectOfSession==2"> {{$t('StudentDashboard.GroupSession')}}</span>
                                        <span v-if="session.subjectOfSession==3"> {{$t('StudentDashboard.WorkShop')}}</span>
                                    </td>

                                    <td>
                                        <a v-on:click="EditInfo(session.id)" href="javascript:void(0)" class="btn btn-primary btn-sm me-2"><i class="fa fa-eye" aria-hidden="true"></i></a>
                                        <a class="btn btn-danger btn-sm" v-on:click="Delete(session.id)" href="javascript:void(0)"><i class="fa fa-trash" aria-hidden="true"></i></a>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="row">
                        <div class="col-md-6 text-right">
                            <span v-if="currentPage===1 && rowCount === 0">  {{ $t('Pagination.ShowingEntries') }}</span>
                            <span v-else-if="currentPage===1 && rowCount < 10">  {{ $t('Pagination.Showing') }} {{currentPage}}  {{ $t('Pagination.to') }} {{rowCount}}  {{ $t('Pagination.of') }} {{rowCount}}  {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage===1 && rowCount >= 11  "> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage===1"> {{ $t('Pagination.Showing') }} {{currentPage}} {{ $t('Pagination.to') }} {{currentPage*10}} of {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage!==1 && currentPage!==pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{currentPage*10}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                            <span v-else-if="currentPage === pageCount"> {{ $t('Pagination.Showing') }} {{(currentPage*10)-9}} {{ $t('Pagination.to') }} {{rowCount}} {{ $t('Pagination.of') }} {{rowCount}} {{ $t('Pagination.entries') }}</span>
                        </div>
                        <div class="col-md-6">
                            <div class="overflow-auto" v-on:click="getPage()" style="float:left">
                                <b-pagination pills size="md" v-model="currentPage"
                                              :total-rows="rowCount"
                                              :per-page="10"
                                              :first-text="this.$t('Tabel.First')"
                                              :prev-text="this.$t('Tabel.Previous')"
                                              :next-text="this.$t('Tabel.Next')"
                                              :last-text="this.$t('Tabel.Last')"></b-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <loading :name="loading" :active.sync="loading"
                     :can-cancel="false"
                     :is-full-page="true"></loading>
        </div>
        <cancel-session-model :id="id" :show="show" @close="getPage()" />

    </div>

</template>

<script>
    import Loading from 'vue-loading-overlay';
    import "vue-loading-overlay/dist/vue-loading.css";
    export default {
        name: 'StudentActivePendingSession',
        components: {
            Loading
        },
        data: function () {
            return {
                search: '',
                currentPage: 1,
                pageCount: '',
                rowCount: '',
                pageSize: '',
                loading: false,
                scheduledAppointments: [],
                show: false,
                id: '',
            }
        },
        watch: {
            search: function (val) {
                this.GetData(val, 1);
            }
        },
        methods: {

            getPageSize: function (isDesc) {
                this.isDesc = isDesc;
                this.getPage();
            },

            getPage: function () {
                this.GetData(this.search, this.currentPage);
            },

            GetData: function (search, currentPage) {
                var root = this;
                var url = '/Home/StudentDashboardActiveList?searchTerm=' + search + '&pageNumber=' + currentPage;
                var token = '';
                this.show = false;
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get(url, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.scheduledAppointments = response.data.results;
                        root.pageCount = response.data.pageCount;
                        root.rowCount = response.data.rowCount;
                    }
                }).catch(error => {
                    console.log(error)
                    root.$swal.fire({
                        icon: 'error',
                        type: 'error',
                        title: root.$t('Notification.Error'),
                        text: root.$t('Notification.SomethingWrong'),
                        showConfirmButton: false,
                        timer: 5000,
                        timerProgressBar: true,
                    });
                }).finally(() => root.loading = false);
            },


            EditInfo: function (id) {
                var root = this;
                var token = '';
                if (this.$session.exists()) {
                    token = localStorage.getItem('token');
                }
                root.$https.get('/Sessions/GetSessionDetail?Id=' + id, { headers: { "Authorization": `Bearer ${token}` } }).then(function (response) {
                    if (response.data != null) {
                        root.$router.push({
                            path: '/SessionDetail',
                            query: { data: response.data, isStudent: 'true' }
                        })
                    }
                });
            },

            Delete: function (id) {
                this.id = id;
                this.show = true;
                
            },
        },
        created: function () {

        },
        mounted: function () {
            this.GetData(this.search, 1);
        },
    }
</script>
<style scoped>
    .card-header {
        display: flex;
        justify-content: space-between;
    }

    .banner {
        background-image: url(/assets/images/landing/123.jpg);
        background-repeat: no-repeat;
        background-size: contain;
    }

        .banner h1 {
            color: #009000;
        }

    .card-header h4 {
        color: #009000;
    }

    .card-body p {
        color: #009000;
    }

    @media screen and (min-width: 280px) and (max-width: 1080px) {
        .btn {
            width: 150px !important;
        }
    }
</style>



