<template>
    <el-date-picker v-model="inputValue"
                    type="date"
                    placeholder=""
                    style="width: 100%; direction:ltr;"
                    :disabled="disabled"
                    format='MM/dd/yyyy'>
    </el-date-picker>
</template>
<script>
    import moment from 'moment'
    import 'element-ui/lib/theme-chalk/index.css';
    export default {
        props: ['value', 'disabled'],
        data: function () {
            return {
                inputValue: '',
                editField: '',
                //pickerOptions: {
                //    highlighted: {
                //        ranges: [
                //            {
                //                from: new Date(2022, 10, 1),
                //                to: new Date(2022, 10, 25),
                //            },
                //        ]
                //    },
                //    shortcuts: [{
                //        text: 'Today',
                //        onClick(picker) {
                //            picker.$emit('pick', new Date());
                //        }
                //    }, {
                //        text: 'Yesterday',
                //        onClick(picker) {
                //            const date = new Date();
                //            date.setTime(date.getTime() - 3600 * 1000 * 24);
                //            picker.$emit('pick', date);
                //        }
                //    }, {
                //        text: 'A week ago',
                //        onClick(picker) {
                //            const date = new Date();
                //            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                //            picker.$emit('pick', date);
                //        }
                //    }]
                //},

            }
        },
        mounted: function () {
            this.inputValue = this.value;
        },
        updated: function () {
            var input = this.inputValue == null ? null : moment(String(this.inputValue)).format('l');
            this.inputValue = input;
            this.$emit('input', this.inputValue);
        }
    }
</script>
